import React from "react"
import PropTypes from "prop-types"
import { Button, Icon, useFetcher } from "@design-system"

export const RemoveTemplateSection = ({ expertId, cycleId, learningBlockId, templateId, readOnly = false }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle'


  return (
    <fetcher.Form method="post" className="flex flex-col items-center">
      <input type="hidden" name="_action" value="remove_template_section" />
      <input type="hidden" name="expert_id" value={expertId} />
      <input type="hidden" name="cycle_id" value={cycleId} />
      <input type="hidden" name="learning_block_id" value={learningBlockId} />
      <input type="hidden" name="template_id" value={templateId} />
      <Button type="submit" variant='outlined' loading={isLoading} disabled={isLoading || readOnly} className='h-6 w-6 p-1'>
        <Icon name="cross" className='text-xxs font-bold' />
      </Button>
    </fetcher.Form>
  )
}
RemoveTemplateSection.propTypes = {
  expertId: PropTypes.number,
  cycleId: PropTypes.number,
  learningBlockId: PropTypes.number,
  templateId: PropTypes.number,
  sectionId: PropTypes.number,
  readOnly: PropTypes.bool,
}
