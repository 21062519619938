/* eslint-disable react/prop-types */
import React from 'react'
import ProgressIndicator from './ProgressIndicator'

const AbilityCard = ({ ability, onClick, className }) => {
  return (
    <div className="p-6 bg-white rounded" onClick={onClick}>
      <div className={`flex justify-between items-center ${className}`}>
        <div className="flex flex-row items-center">
          <div className="min-w-16">
            <ProgressIndicator
              percent={ability.demonstrations / ability.target}
              twsize="16"
              cutoff="0"
            >
              <div className="flex items-center justify-center">
                <span className="text-xs">
                  {ability.demonstrations}
                  /{ability.target || 16}
                </span>
              </div>
            </ProgressIndicator>
          </div>
          <span className="text-lg ml-4">{ability.title}</span>
        </div>
      </div>
    </div>
  )
}

export default AbilityCard
