import PropTypes from 'prop-types'
import React from 'react'

const Student = ({ student }) => {
  const { name, picture } = student
  const avatarUrl = picture ||
    `https://ui-avatars.com/api/?name=${name
      .split(' ')
      .map(n => n.substring(0, 5))
      .join('+')}&bold=true&format=png`

  return <div className="flex items-center">
    <img
      className="inline-block h-8 w-8 rounded-full"
      src={avatarUrl}
      alt={`${name}'s avatar.`}
    />
    <p className="ml-3 text-sm leading-6 text-gray-50 font-bold">{name}</p>
  </div>
}

Student.propTypes = {
  student: PropTypes.shape({
    name: PropTypes.string,
    picture: PropTypes.string
  })
}
export default Student
