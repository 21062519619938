import React from 'react'
import PropTypes from 'prop-types'
import Stars from '@components/stars'

const OpportunitiesList = ({ opportunities }) => {

  return (<ul className="flex flex-col space-y-2">
    {opportunities.filter(o => o.ability?.short !== 'FEEDBACK').map(opportunity =>
      <li key={opportunity.id}>
        <div className={`relative flex flex-row justify-between items-stretch rounded-full bg-white border border-gray-40`}>
          <div className=" flex items-center space-x-2 truncate">
            <h3 className="text-gray-70 font-normal mx-3">{opportunity.title}</h3>
            {opportunity.off_program && <span className="text-xs font-bold bg-gray-40 px-1 text-white rounded whitespace-nowrap">Off-Program</span>}
          </div>
          {opportunity.level !== undefined && <Stars className="mx-3" numFilled={opportunity.level} numHollow={4 - opportunity.level} />}
          {opportunity.progress !== undefined && (
            <div className="w-24 my-0.5 mx-0.5 rounded-full border border-gray-40 bg-white relative overflow-hidden">
              <span className="absolute bg-gray-20 top-0 left-0 h-full" style={{ width: opportunity.progress + '%' }}></span>
              <div className="relative z-10 text-sm font-bold text-center">Book {opportunity.identifier}</div>
            </div >
          )}
        </div>
      </li>
    )}
  </ul>
  )
}

OpportunitiesList.propTypes = {
  opportunities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }))
}

export default OpportunitiesList
