import React from 'react'
import { Typography } from '@design-system'
import { TaskPerk } from './types'
import { FinalTaskPerkCard } from './final-task-perk-card'

type FinalBodyProps = {
  taskUnits: TaskPerk[]
  taskAbilities: TaskPerk[]
}

export function FinalTaskBody({ taskUnits, taskAbilities }: FinalBodyProps) {
  return (
    <>
      {taskUnits.length > 0 && (
        <Typography variant="body" weight="bold" className="pb-2">
          Units ({taskUnits.length})
        </Typography>
      )}

      <div className="flex flex-col gap-3">
        {taskUnits.map((el, index) => {
          return (
            <div key={`${el.type}-${el.id}`}>
              <input type="hidden" name={`${el.type}[${index}][id]`} value={el.id}></input>
              <FinalTaskPerkCard
                name={`${el.type}[${index}][finalAssessmentMastery]`}
                title={el.title}
                currentMastery={el.mastery}
                studentReflection={el.selfReflection}
                lastAssessmentValue={el.finalAssessmentMastery ?? el.levelUp}
                isOffProgram={el.isOffProgram}
                isExempt={el.isExempt}
              />
            </div>
          )
        })}
      </div>
      {taskAbilities.length > 0 && (
        <Typography variant="body" weight="bold" className="pb-2 pt-6">
          Abilities ({taskAbilities.length})
        </Typography>
      )}

      <div className="flex flex-col gap-3">
        {taskAbilities.map((el, index) => {
          return (
            <div key={`${el.type}-${el.id}`}>
              <input type="hidden" name={`${el.type}[${index}][id]`} value={el.id}></input>
              <FinalTaskPerkCard
                name={`${el.type}[${index}][finalAssessmentMastery]`}
                title={el.title}
                currentMastery={el.mastery}
                studentReflection={el.selfReflection}
                lastAssessmentValue={el.finalAssessmentMastery ?? el.levelUp}
                isExempt={el.isExempt}
                isOffProgram={el.isOffProgram}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}
