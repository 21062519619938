import React from 'react'
import { PropTypes } from 'prop-types'
import { Button } from '@design-system'
import AddCycleTemplate from './add-cycle-template'
import RemoveCycleTemplate from './remove-cycle-template'

const ManageCycles = ({ id, cycles = [], defaultCycles = [] }) => {
  const availableCycles = defaultCycles.filter(cycle => !cycles.some(c => Number(c.id) === Number(cycle.id)))
  return (
    <ul className='flex flex-col items-start space-y-1'>
      {availableCycles.length > 0 &&
        <li className='w-full'>
          <AddCycleTemplate id={id} availableCycles={availableCycles} />
        </li>
      }
      {(cycles.length > 0) ? cycles.map(cycle => (
        <li key={`cycle_${id}_${cycle.id}`} >
          <RemoveCycleTemplate id={id} cycle_id={cycle.id} title={cycle.title} />
        </li>
      )) :
        <li><Button size="xs" variant="outlined" disabled={true}>No Cycles</Button></li>}
    </ul>
  )
}
ManageCycles.propTypes = {
  id: PropTypes.number,
  cycles: PropTypes.array,
  defaultCycles: PropTypes.array,
}

export default ManageCycles
