import React, { forwardRef } from 'react'
import axios from 'axios'
import ReactDatePicker from 'react-datepicker'
import { Outlet, useLoaderData, useParams, useSearchParams, redirect } from 'react-router-dom'
import format from 'date-fns/format'
import { RadioGroup } from '@headlessui/react';
import capitalize from '@utils/capitalize'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

interface Event {
  id: number,
  attendable_id: number,
  start_at: string,
  sms_sent_at?: string,
}

export interface HouseAttendanceLoaderData {
  event: Event,
  eventKinds: string[],
  attendanceEditableHouses: Array<{ id: number, title: string }>
}

const DatePickerInputButton = forwardRef((
  { value, onClick, placeholder, fullWidth }: { fullWidth: boolean, value: string, placeholder?: string, onClick: () => void },
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  return (
    <button
      type="button"
      className={`bg-white text-gray-90 border border-gray-40
    hover:bg-gray-10 hover:border-gray-50 hover:text-gray-100
    dark:bg-gray-90 dark:text-gray-5 dark:border-gray-70
    dark:hover:bg-gray-95 dark:hover:border-gray-50 dark:hover:text-gray-5
    dark:focus-within:ring-1 dark:focus-within:!bg-gray-100 dark:focus-within:border-gray-50 dark:focus-within:text-gray-5
    focus-within:ring-1 focus-within:ring-accent focus-within:!bg-white focus-within:text-gray-100 focus-within:border-gray-50)
    h-12 p-2 flex justify-between items-center rounded-lg text-left ${fullWidth ? 'w-full' : 'w-52'}`}
      onClick={onClick}
      ref={ref}
    >
      <p>{value || placeholder}</p>
      <FontAwesomeIcon icon={faChevronDown} size="sm" />
    </button>
  )
})

export async function loader({ request, params }) {
  const { search } = new URL(request.url)
  const { data } = await axios.get(`/pages/students/${params.student_id}/houses/${params.houseId}/attendances${search}`)
  if (data?.event?.id) {
    const eventId = data.event.id
    if (
      !params.eventId && eventId
      || (params.eventId && Number(params.eventId) !== eventId)
    ) {
      return redirect(`${eventId}${search}`)
    }
  }

  return data
}

export function Element() {
  const { houseId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    event,
    eventKinds,
    attendanceEditableHouses,
  } = useLoaderData() as HouseAttendanceLoaderData
  const selectedDate = searchParams.get('date') ? new Date(searchParams.get('date')) : new Date()

  const handleChangeDate = (date: Date) => {
    setSearchParams(prev => ({ ...Object.fromEntries(prev), date: format(date, 'yyyy-MM-dd') }))
  }

  const handleUpdateEventKind = (kind: string) => {
    setSearchParams(prev => ({ ...Object.fromEntries(prev), kind }))
  }

  return (
    <div className="flex flex-col gap-4 items-start">
      <div className="flex gap-2">
        <ReactDatePicker
          selected={selectedDate}
          onChange={handleChangeDate}
          maxDate={new Date()}
          placeholderText="Pick a date"
          /* @ts-expect-error */
          customInput={<DatePickerInputButton fullWidth={false} />}
          todayButton="Today"
        />
      </div>
      <RadioGroup value={searchParams.get('kind') || eventKinds[0]} onChange={handleUpdateEventKind}>
        <div className="flex items-center gap-2">
          {eventKinds.map(kind => (
            <RadioGroup.Option
              key={kind}
              value={kind}
              className={({ active, checked }) => `
                ${active ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300' : ''}
                ${checked ? 'bg-sky-900/75 text-white' : 'bg-white'}
                relative flex cursor-pointer rounded-lg px-3 py-px shadow-md focus:outline-none
              `}
            >
              {({ checked }) => (
                <RadioGroup.Description as="span" className={`text-sm text-center min-w-20 ${checked ? 'text-sky-100' : 'text-gray-50'}`}>
                  <span>{capitalize(kind)}</span>
                </RadioGroup.Description>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      {!event && <h1>No House Event found for filters above.</h1>}
      {event &&
        <>
          <p className="text-xl font-bold mt-3">{attendanceEditableHouses.find(h => Number(h.id) === Number(houseId))?.title || ''}</p>
          <Outlet />
        </>
      }
    </div>
  )
}

export const StudentHouseAttendancesRoute = { Element, loader }
