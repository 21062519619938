import React from 'react'
import PropTypes from 'prop-types'
import { FirstSectionList } from './first-section-list'
import { SectionList } from './section-list'
import { AddSection } from './add-section'

export const CycleSectionList = ({ cycles, studentId }) => {
  const cycleColors = {
    "Cycle 1" : 'text-orange-50',
    "Cycle 2" : 'text-cyan-50', 
    "Cycle 3" : 'text-green-50', 
    "Cycle 4" : 'text-blue-50', 
    "Cycle 5" : 'text-yellow-50', 
    "Cycle 6" : 'text-pink-50'
  }

  return (
    cycles.map(cycle => {
      const sectionsAmount = cycle.sections.length
      const hasCycle = cycle.cycle_id !== null
      const showAddSection = hasCycle && sectionsAmount > 0
      return (
        <React.Fragment key={`student_${studentId}_cycle_${cycle.cycle_id}`}>
          <tr key={cycle.cycle_id} className="bg-white">
            <td className='align-top pt-2' rowSpan={showAddSection || (hasCycle && sectionsAmount === 0) ? sectionsAmount+1 : sectionsAmount }>
              {!hasCycle && <span className='text-nowrap'>Missing</span>}
              {hasCycle && <span className={`py-1 text-nowrap rounded text-xs font-bold ${cycleColors[cycle.cycle_title.substring(0,7)]}`}>{cycle.cycle_title}</span>}
            </td>
            <FirstSectionList section={cycle.sections[0]} cycleId={cycle.cycle_id} studentId={studentId} />
          </tr>
          {sectionsAmount > 1 &&
            <SectionList sections={cycle.sections.slice(1)} studentId={studentId} cycleId={cycle.cycle_id} />
          }
          {showAddSection &&
            <tr className='bg-white'>
              <AddSection cycleId={cycle.cycle_id} studentId={studentId} />
            </tr>
          }
        </React.Fragment>
      )
    })
  )
}
CycleSectionList.propTypes = {
  cycles: PropTypes.array,
  studentId: PropTypes.number,
}
