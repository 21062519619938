import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Student from '../student'
import useSWR from 'swr'

const MeetUnitLevels = ({ unitLevels, cycle_id }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const params = unitLevels.filter(skl => skl.unit_id > 0).reduce((acc, unit) => ({
    ...acc,
    [unit.unit_id]: Number(unit.level)
  }), {})
  const urlSearchParams = new URLSearchParams({
    ...params, cycle_id
  })

  const { data: studentsData } = useSWR(`/dashboard/students-meeting-units-levels?${urlSearchParams.toString()}`)
  const students = studentsData?.result || []

  return (
    <>
      <div className={`fixed inset-0 overflow-y-auto ${modalOpen ? 'z-10' : 'hidden'}`}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div onClick={() => setModalOpen(false)} className="fixed inset-0 transition-opacity">
            <div className='absolute inset-0 bg-gray-50 opacity-75'></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-90" id="modal-headline">
                    Eligible Students
                  </h3>
                  <div className="mt-2">
                    {(students.length > 0 && (
                      <ul className="grid grid-cols-2 gap-2 gap-x-6 text-sm leading-5 text-gray-50 overflow-y-auto max-h-96">
                        {students.length > 0 && students.map((student) => (
                          <li key={student.id} className="mb-2">
                            <Student
                              student={student}
                            />
                          </li>
                        ))}
                      </ul>
                    )) || 'Looks like no one can make it...'}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-20 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                <button onClick={(e) => { e.preventDefault(); setModalOpen(false) }} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-20 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-70 shadow-sm hover:text-gray-50 focus:outline-none focus:border-blue-20 focus:ring-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                  Ok
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <span onClick={() => setModalOpen(true)} className="hover:underline cursor-pointer">{students.length} qualified students</span>
    </>
  )
}

MeetUnitLevels.propTypes = {
  unitLevels: PropTypes.array,
  cycle_id: PropTypes.number,
}
export default MeetUnitLevels
