import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import useConfirmModal from '@hooks/useConfirmModal'
import { useLoaderData, useSubmit, Form, useActionData } from 'react-router-dom'
import { Button } from '@designsystem'
import { formatInTimeZone } from 'date-fns-tz'
import { useToast } from '@hooks/useToast'

export default function SwapRequestsRouteRoute() {
  const [rejectReason, setRejectReason] = useState('')
  const loaderData = useLoaderData()
  const submit = useSubmit()
  const confirm = useConfirmModal()
  useToast(useActionData())

  const statusOptions = [
    {
      name: 'All',
      value: 'All',
    },
    {
      name: 'Pending',
      value: 'pending',
    },
    {
      name: 'Approved',
      value: 'approved',
    },
    {
      name: 'Rejected',
      value: 'rejected',
    },
  ]

  const [selectedStatus, setSelectedStatus] = useState('pending')
  const [data, setData] = useState([])

  const handleRejectSubmit = (e) => {
    e.preventDefault()
    const formEl = e.currentTarget
    confirm({
      title: 'Reject swap request',
      confirmLabel: 'Reject request',
      children: <ConfirmationModalBody formEl={formEl} setRejectReason={setRejectReason} />,
    }).then((confirmed) => {
      confirmed && submit(formEl)
      setRejectReason('')
    })
  }

  const handleStatusChange = (status) => {
    setSelectedStatus(status)

    if (status === 'All') {
      // If "All" is selected, show all data
      setData(loaderData.result)
    } else {
      // Otherwise, filter data based on the selected status
      const filteredData = loaderData.result.filter((item) => item.status === status)
      setData(filteredData)
    }
  }

  useEffect(() => {
    setData(loaderData.result.filter((item) => item.status === 'pending'))
  }, [loaderData.result])

  return (
    <section className="flex flex-col space-y-5">
      <h1 className="font-bold text-5xl">Swap requests</h1>
      <div className="flex flex-row space-x-2">
        {statusOptions.map((option) => (
          <Button
            key={option.value}
            size="sm"
            onClick={() => handleStatusChange(option.value)}
            variant={selectedStatus === option.value ? 'filled' : 'outlined'}
          >
            {option.name} (
            {option.value === statusOptions.at(0).name
              ? loaderData.result.length
              : loaderData.result.filter((item) => item.status === option.value).length}
            )
          </Button>
        ))}
      </div>
      {data.length ? (
        <table className="w-full table-auto text-left border border-gray-10 align-top text-align-top">
          <thead>
            <tr className="font-bold">
              <td className="border p-3 align-top">ID</td>
              <td className="border p-3 align-top">Student name</td>
              <td className="border p-3 align-top">Current expedition</td>
              <td className="border p-3 align-top">Units</td>
              <td className="border p-3 align-top">Learning Block</td>
              <td className="border p-3 align-top">New expedition</td>
              <td className="border p-3 align-top">Units</td>
              <td className="border p-3 align-top">Learning Block</td>
              <td className="border p-3 align-top">Reason</td>
              <td className="border p-3 align-top">Requested at</td>
              <td className="border p-3 align-top">Approval</td>
            </tr>
          </thead>
          <tbody>
            {data.map(
              (
                {
                  id,
                  student_name,
                  current_expedition,
                  current_expedition_expert,
                  current_units,
                  current_learning_block,
                  current_students_total,
                  current_students_maximum,
                  new_expedition,
                  new_expedition_expert,
                  new_units,
                  new_learning_block,
                  new_students_total,
                  new_students_maximum,
                  reason,
                  reject_reason,
                  requested_at,
                  status,
                },
                i
              ) => (
                <tr key={i}>
                  <td className="border p-3 align-top">{id}</td>
                  <td className="border p-3 align-top">{student_name}</td>
                  <td className="border p-3 align-top">
                    <p className="font-bold">{current_expedition}</p>
                    <p>{current_expedition_expert}</p>
                  </td>
                  <td className="border px-3 align-top">
                    <ul className="flex flex-col flex-wrap">
                      <li role="presentation">
                        {current_units.length > 0
                          ? current_units.map((unit) => (
                            <a
                              key={unit.id}
                              className={
                                unit.is_new
                                  ? 'my-2.5 block rounded px-2 py-1 font-bold bg-green-10'
                                  : 'my-2.5 block rounded px-2 py-1 font-bold bg-gray-10'
                              }
                            >
                              {unit.title}
                            </a>
                          ))
                          : '-'}
                      </li>
                    </ul>
                  </td>
                  <td className="border p-3 align-top">
                    <p>{current_learning_block}</p>
                    <p>
                      {current_students_total && current_students_maximum
                        ? `${current_students_total}/${current_students_maximum} students`
                        : '-'}
                    </p>
                  </td>
                  <td className="border p-3 align-top">
                    <p className="font-bold">{new_expedition}</p>
                    <p>{new_expedition_expert}</p>
                  </td>
                  <td className="border px-3 align-top">
                    <ul className="flex flex-col flex-wrap">
                      <li>
                        {new_units.length > 0
                          ? new_units.map((unit) => (
                            <a
                              key={unit.id}
                              className={
                                unit.is_new
                                  ? 'my-2.5 block rounded px-2 py-1 font-bold bg-green-10'
                                  : 'my-2.5 block rounded px-2 py-1 font-bold bg-gray-10'
                              }
                            >
                              {unit.title}
                            </a>
                          ))
                          : '-'}
                      </li>
                    </ul>
                  </td>
                  <td className="border p-3 align-top">
                    <p>{new_learning_block}</p>
                    <p>
                      {new_students_total && new_students_maximum
                        ? `${new_students_total}/${new_students_maximum} students`
                        : '-'}
                    </p>
                  </td>
                  <td className="border p-3 align-top">{reason}</td>
                  <td className="border p-3 align-top">{formatDateToEasternTime(requested_at)}</td>
                  <td className="border p-3 align-top">
                    {status === 'pending' ? (
                      <div>
                        <Form method="post">
                          <input type="hidden" name="formType" value="approveRequest" />
                          <input type="hidden" name="swapRequestId" value={id} />
                          <Button type="submit" size="sm">
                            Approve
                          </Button>
                        </Form>
                        <br />
                        <Form method="post" onSubmit={handleRejectSubmit}>
                          <input type="hidden" name="formType" value="rejectRequest" />
                          <input type="hidden" name="swapRequestId" value={id} />
                          <input type="hidden" name="rejectReason" value={rejectReason} />
                          <Button type="submit" size="sm" variant="outlined">
                            Reject
                          </Button>
                        </Form>
                      </div>
                    ) : status === 'approved' ? (
                      <p className="font-bold text-green-50">Approved</p>
                    ) : (
                      <div>
                        <p className="font-bold text-danger-50">Rejected</p>
                        <p>{reject_reason}</p>
                      </div>
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      ) : (
        <h3 className="font-medium text-2xl">It looks like there aren't any swap requests!</h3>
      )}
    </section>
  )
}

const formatDateToEasternTime = (dateString) => {
  if (!dateString) return null
  const date = new Date(dateString)
  return formatInTimeZone(date, 'America/New_York', "MMM do 'at' HH:mm 'ET'")
}

function ConfirmationModalBody({ setRejectReason }) {
  const handleReasonChange = (e) => {
    setRejectReason(e.target.value)
  }

  return (
    <div className="flex flex-col space-y-6">
      <p className="text-lg">Please share with student the reason why this swap request is being rejected.</p>
      <label className="font-bold text-lg" htmlFor="rejectReason">
        Reason
      </label>
      <textarea
        type="text"
        name="rejectReason"
        placeholder="Please inform reason"
        rows={5}
        onChange={handleReasonChange}
      />
    </div>
  )
}

ConfirmationModalBody.propTypes = {
  setRejectReason: PropTypes.func.isRequired,
}
