import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@design-system'

export const cycleLimitWarnClass = ({ cycle }) => {
  const exceededLimits = cycle.exceeded_expeditions_limit || cycle.exceeded_sections_limit
  if (exceededLimits) return 'bg-warning-5 border-2 border-warning-30 dark:bg-warning-90 dark:border-warning-80'
  return ''
}

export const CycleLimitWarn = ({ expeditionLimit, sectionLimit }) => {
  const exceeded = expeditionLimit || sectionLimit
  if (!exceeded) return null

  return (
    <div className='flex flex-row w-fit relative group cursor-help'>
      <Icon name='alert-triangle' size='xs' className='text-warning-50 font-bold' />
      <div className='hidden group-hover:block absolute ml-2 top-0 left-full z-30 bg-white dark:bg-black drop-shadow border border-alpha/15'>
        <ul className='p-2 text-nowrap'>
          {expeditionLimit && <li>- Unique expeditions limit exceeded</li>}
          {sectionLimit && <li>- Number of sections limit exceeded</li>}
        </ul>
      </div>
    </div>
  )
}
CycleLimitWarn.propTypes = {
  expeditionLimit: PropTypes.bool,
  sectionLimit: PropTypes.bool,
}
