import React from 'react'
import axios from 'axios'
import { Form, Outlet, useLoaderData, useLocation, useNavigation, useSubmit, redirect } from 'react-router-dom'
import { Tab } from '@headlessui/react'
import { Button } from '@designsystem'
import SoraLink from '@components/link'
import JourneyScheduleMetrics from './journey-schedule-metrics'
import useConfirmModal from '@hooks/useConfirmModal'
import serializeFormData from '@utils/serializeFormData'

const loader = async ({ params }) => {
  const { processId } = params
  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/schedule/review`)
  return result?.data
}

const action = async ({ params, request }) => {
  const { processId } = params
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)

  try {
    await axios.post(`/backoffice/workbench/journey-builder/processes/${processId}/schedule/review`, { ...serializedFormData })
    return redirect('../generated/students')
  } catch (error) {
    return {
      toast: { message: 'There was an error running Journey Builder. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const navigation = useNavigation()
  const confirm = useConfirmModal()
  const submit = useSubmit()
  const { pathname } = useLocation()
  const { metrics } = useLoaderData()

  const isFormSubmitting = navigation.state !== 'idle'

  const tabs = [
    { label: 'Students', path: 'students' },
    { label: 'Experts', path: 'experts' },
    { label: 'Expeditions', path: 'expeditions' },
  ]

  const currentIndex = tabs.findIndex(t => pathname.includes(t.path.slice(1)))

  const handleGenerate = async (event) => {
    event.preventDefault()
    const result = await confirm({
      title: 'Are you sure?',
      subtitle: 'This action will generate the schedule for the selected variant. This action cannot be undone.',
      confirmLabel: 'Generate',
      cancelLabel: 'Cancel',
    })

    if (result) {
      submit(event.target.form)
    }
  }

  return (
    <>
      <div className="flex flex-row">
        <Tab.Group selectedIndex={currentIndex >= 0 ? currentIndex : 0}>
          <Tab.List className='flex-auto'>
            {tabs.map(t => (
              <Tab
                key={t.label}
                className={({ selected }) => `p-3 text-xl truncate focus:outline-none border-b-2 border-transparent ${selected ? 'font-bold border-accent cursor-default' : 'hover:underline'}`}
              >
                <SoraLink to={t.path}>
                  {t.label}
                </SoraLink>
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
        <JourneyScheduleMetrics metrics={metrics} />
      </div>
      <div className="flex-auto relative">
        <div className='w-full text-sm space-y-4 px-2 pb-4 overflow-y-scroll absolute top-0 bottom-20'>
          <Outlet />
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0">
        <div className="flex flex-row justify-end align-middle p-4 space-x-3 rounded-xl border border-gray-30 bg-white dark:bg-black">  
          <Button type="button" as={SoraLink} to="../../schedule/config" variant="outlined" disabled={isFormSubmitting}>
            Back
          </Button>
          <Form method="post" id="journey-builder-form">
            <input type="hidden" name="_action" value="generate" />
            <Button type='submit' disabled={isFormSubmitting} loading={isFormSubmitting} onClick={handleGenerate}>Generate</Button>
          </Form>
        </div>
      </div>
    </>
  )
}

export const JourneyBuilderScheduleReviewRoute = {
  action,
  loader,
  Element,
}
