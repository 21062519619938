import React from "react"
import axios from "axios"
import { useLoaderData } from "react-router"
import { Typography } from "@design-system"
import serializeFormData from "@utils/serializeFormData"
import { EmployeeSelector } from "./employee-selector"
import { LearningBlocksList } from "./learning-blocks-list"

const loader = async({ params, request }) => {
  const url = new URL(request.url)
  const searchParams = url.searchParams
  const cycleId = searchParams.get('cycle_id')
  const employeeId = searchParams.get('employeeId')

  const result = await axios.get('/backoffice/employee/learning-blocks', { params: { employeeId, cycleId } })
  return result?.data
}

const action = async ({ request }) => {
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)

  try {
    await axios.post(`/backoffice/employee/learning-blocks`, { ...serializedFormData })
  } catch (error) {
    return {
      toast: { message: 'There was an error to work with this process. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }

  return null
}

const Element = () => {
  const { employees, currentId, learningBlocks } = useLoaderData()

  return (
    <div className='flex flex-col gap-3'>
      <Typography variant="heading-3" weight="bold" className="mb-6">Learning Blocks</Typography>
      {(employees.length > 0) && <EmployeeSelector employees={employees} defaultValue={currentId} />}
      <LearningBlocksList employeeId={currentId} learningBlocks={learningBlocks} />
    </div>
  )
}

export const EmployeeLearningBlocksRoute = {
  loader,
  action,
  Element,
}
