import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import PropTypes from 'prop-types'
import { Popover as HPopover, Transition } from '@headlessui/react'
import { usePopper } from 'react-popper'

const Popover = ({ children, className, position = 'left' }) => {
  const positionStyle = getPositionStyle(position)
  const [referenceElement, setReferenceElement] = React.useState()
  const [popperElement, setPopperElement] = React.useState()
  const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: position })
  return (
    <HPopover className={`inline-block relative ${className}`}>
      {({ open }) => (
        <>
          <HPopover.Button ref={setReferenceElement} className="focus:outline-none">
            <FontAwesomeIcon icon={faInfoCircle} />
          </HPopover.Button>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <HPopover.Panel
              ref={setPopperElement}
              style={{ ...styles.popper, zIndex: open ? 50 : undefined }}
              {...attributes.popper}
            >
              <div className={`bg-white rounded-lg shadow-lg ring-1 ring-black/5 p-5 ${positionStyle}`}>
                {children}
              </div>
            </HPopover.Panel>
          </Transition>
        </>
      )}
    </HPopover>
  )
}

const getPositionStyle = (position) => ({
  left: 'mr-1',
  right: 'ml-1',
})[position] || 'mr-1'

Popover.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right']),
}

export default Popover
