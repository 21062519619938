import React from 'react'
import { FlagTypes, FlagTypesProps, FlagProps } from './types'
import { cn, Icon } from '@design-system'

const FLAG_TYPES: Record<FlagTypes, FlagTypesProps> = {
  checkin: {
    bgColor: 'bg-danger-50',
    name: 'flag-2-filled',
  },
  celebrate: {
    bgColor: 'bg-yellow-50',
    name: 'star-filled',
  },
}

export const Flag = ({ variant }: FlagProps) => {
  const { bgColor, name } = FLAG_TYPES[variant]
  return (
    <span className={cn("absolute bottom-0 -right-1 text-white rounded-md w-4 h-4 flex justify-center items-center", bgColor)}>
      <Icon name={name} size="3xs" />
    </span>
  )
}
