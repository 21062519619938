import React from 'react'
import useCurrentUser from "@hooks/useCurrentUser"
import { NavigateCycle } from '@hooks/useNavigateWithCycle'

export default function WildcardRedirectRoute() {
  const currentUser = useCurrentUser()

  if (!currentUser)
    return null

  if (currentUser.role === 'student')
    return <NavigateCycle to={`/student/${currentUser.id}/home`} />

  if (currentUser.role === 'guardian')
    return <NavigateCycle to={`/student/${currentUser.students_ids[0]}/home`} />

  return <NavigateCycle to="/employee/expeditions/edit" />
}