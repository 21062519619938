import React, { Suspense } from 'react'
import { useParams, useLoaderData, Await, useSearchParams, useRevalidator } from 'react-router-dom'
import { RadioGroup } from '@headlessui/react'
import { StudentsList } from './studentsList'
import { TasksList } from './tasksList'

const FacilitateTasks = () => {
  const { id } = useParams()
  const loaderData = useLoaderData()
  const revalidator = useRevalidator()
  const [searchParams, setSearchParams] = useSearchParams()
  const isDetailedView = searchParams.get('v') === 'detailed'
  function handleChangeView(v) {
    setSearchParams((params) => ({ ...Object.fromEntries(params.entries()), v }))
  }

  return (
    <>
      <div className="flex items-center space-x-3 py-2">
        <p>View:</p>
        <RadioGroup value={searchParams.get('v') || 'summarized'} onChange={handleChangeView}>
          <div className="flex items-center space-x-2">
            <RadioGroup.Option
              value="summarized"
              className={({ active, checked }) => `
                ${active ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300' : ''}
                ${checked ? 'bg-sky-900/75 text-white' : 'bg-white'}
                relative flex cursor-pointer rounded-lg px-3 py-px shadow-md focus:outline-none
             `}
            >
              {({ checked }) => (
                <RadioGroup.Description
                  as="span"
                  className={`text-sm text-center min-w-20 ${checked ? 'text-sky-100' : 'text-gray-50'}`}
                >
                  <span>Summarized</span>
                </RadioGroup.Description>
              )}
            </RadioGroup.Option>
            <RadioGroup.Option
              value="detailed"
              className={({ active, checked }) => `
                ${active ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300' : ''}
                ${checked ? 'bg-sky-900/75 text-white' : 'bg-white'}
                relative flex cursor-pointer rounded-lg px-3 py-px shadow-md focus:outline-none
             `}
            >
              {({ checked }) => (
                <RadioGroup.Description
                  as="span"
                  className={`text-sm text-center min-w-20 ${checked ? 'text-sky-100' : 'text-gray-50'}`}
                >
                  <span>Detailed</span>
                </RadioGroup.Description>
              )}
            </RadioGroup.Option>
          </div>
        </RadioGroup>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <Await resolve={loaderData.result}>
          {(d) =>
            isDetailedView ? (
             <>
              <StudentsList
                theme_id={id}
                tasks={d.tasks}
                students={d.students}
                hasTasksDetailsRedesignFeatureEnabled={d.hasTasksDetailsRedesignFeatureEnabled}
              />
             </>
            ) : (
              <>
              <TasksList
                theme_id={id}
                tasks={d.tasks}
                onConvertToFinal={revalidator.revalidate}
                hasTasksDetailsRedesignFeatureEnabled={d.hasTasksDetailsRedesignFeatureEnabled}
              />
              </>
            )
          }
        </Await>
      </Suspense>
    </>
  )
}

export default FacilitateTasks
