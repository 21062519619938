import React from 'react'
import { useFetcher } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { Button, Icon } from '@design-system'

const RemoveCycleTemplate = ({ id, cycle_id, title }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle'

  return (
    <fetcher.Form method="post" className="flex flex-col justify-center">
      <input type="hidden" name="_action" value="remove_template_cycle" />
      <input type="hidden" name="journey_templates_id" value={id} />
      <input type="hidden" name="cycle_id" value={cycle_id} />
      <Button type="submit" size="xs" variant='outlined' loading={isLoading} disabled={isLoading}>
        <Icon name='cross' size='xs' />
        <span>{title}</span>
      </Button>
    </fetcher.Form>
  )
}
RemoveCycleTemplate.propTypes = {
  id: PropTypes.number,
  cycle_id: PropTypes.number,
  title: PropTypes.string,
}


export default RemoveCycleTemplate
