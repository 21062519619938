import React from 'react'
import DatePicker from 'react-datepicker'
import { useLoaderData, useSearchParams } from 'react-router-dom'
import { Button } from '@designsystem'
import Select from '@components/forms/select'
import SoraLink from '@components/link'
import { Disclosure, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp, faArrowAltCircleDown } from "@fortawesome/free-regular-svg-icons"
import _debounce from 'lodash/debounce'
import format from 'date-fns/format'
import type { RegistrationChangesReportLoaderData, AvailableFilters } from './types'
import { RegistrationChangesReportTable } from './report-table'

export default function RegistrationChangesReport() {
  const { registrationChanges, filterOptions } = useLoaderData() as RegistrationChangesReportLoaderData
  return (
    <div className="flex flex-col overflow-x-auto p-2 gap-y-3 min-h-[550px]">
      <Filters filterOptions={filterOptions}/>
      <div className="flex flex-col w-full">
        <ExportCsvBtn/>
        <RegistrationChangesReportTable data={registrationChanges}/>
      </div>
    </div>
  )
}

function Filters({ filterOptions }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const onChangeFilter = _debounce((value: string, filterName: AvailableFilters) => {
    setSearchParams((sp: URLSearchParams) => {
      if (!value) {
        sp.delete(filterName);
      } else {
        sp.set(filterName, value);
      }

      // Ensure page count is reset to properly show filtered results
      sp.delete('page');

      return sp;
    });
  }, 300)

  function toLocalDate(dtStr: string) {
    const [year, month, day] = dtStr.split('-').map(n => parseInt(n, 10));
    if (year && month && day) {
      return new Date(year, month - 1, day);
    }
  }

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <div className="flex items-center">
            <div className="text-left">
              <p className="font-bold">Filters</p>
              <p className="text-sm mt-1">Refine your report by selecting specific or a combination of filters.</p>
            </div>
            <Disclosure.Button className="ml-3">
              <FontAwesomeIcon size="xl" icon={open ? faArrowAltCircleDown : faArrowAltCircleUp}/>
            </Disclosure.Button>
          </div>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="grid grid-cols-3 gap-2">
              <div className="col-span-1 flex flex-col border border-gray-20 p-3 rounded-xl max-h-24 leading-none space-y-2">
                <p className="font-bold">Search</p>
                <input
                  placeholder="Search for student or experience..."
                  type="search"
                  className="border px-3 py-[6px] border-gray-20 text-gray-60 placeholder:text-gray-60 rounded-lg"
                  defaultValue={searchParams.get('search')}
                  onChange={(e) => {
                    onChangeFilter(e.target.value, 'search')
                  }}
                />
              </div>
              <div className="col-span-1 flex flex-col border border-gray-20 p-3 rounded-xl max-h-24 leading-none space-y-2">
                <p className="font-bold">Category</p>
                <Select
                  options={filterOptions.category}
                  defaultValue={filterOptions.category.filter(a => searchParams.get('category')?.split(',').includes(a.value))}
                  isMulti
                  name="category"
                  placeholder="Select..."
                  onChange={e => {
                    onChangeFilter(e.length && e.map(o => o.value), 'category')
                  }}
                />
              </div>
              <div className="col-span-1 flex flex-col border border-gray-20 p-3 rounded-xl max-h-24 leading-none space-y-2 min-w-fit">
                <p className="font-bold">Date</p>
                <div className="flex items-center w-full gap-8">
                  <DatePicker
                    className="rounded-lg h-[39px]"
                    showTimeSelect={false}
                    dateFormat="MM/dd/yyyy"
                    onChange={dt => {
                      onChangeFilter(dt && format(dt, 'yyyy-MM-dd'), 'start')
                    }}
                    todayButton="Today"
                    placeholderText="Start"
                    selected={searchParams.get('start') && toLocalDate(searchParams.get('start'))}
                    maxDate={searchParams.get('end') && new Date(searchParams.get('end'))}
                    enableTabLoop={false}
                    isClearable
                    popperProps={{
                      placement: 'left-start',
                    }}
                  />
                  <DatePicker
                    className="rounded-lg h-[39px]"
                    showTimeSelect={false}
                    dateFormat="MM/dd/yyyy"
                    onChange={dt => {
                      onChangeFilter(dt && format(dt, 'yyyy-MM-dd'), 'end')
                    }}
                    todayButton="Today"
                    placeholderText="End"
                    selected={searchParams.get('end') && toLocalDate(searchParams.get('end'))}
                    minDate={searchParams.get('start') && new Date(searchParams.get('start'))}
                    enableTabLoop={false}
                    isClearable
                    popperProps={{
                      placement: 'left-start',
                    }}
                  />
                </div>
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}

function ExportCsvBtn() {
  const [searchParams] = useSearchParams()
  return (
    <div className="flex justify-end">
      <Button
        as={SoraLink}
        to={`/api/pages/admin/reports/registration-changes?${searchParams.toString()}&output=csv`}
        target='_blank'
        role="link"
        size="xs"
        variant="nude"
      >
        Download as CSV
      </Button>
    </div>
  )
}
