import React from 'react'
import PropTypes from 'prop-types'

export const StudentsTag = ({ students }) => {
  return (
    <div className='flex flex-col gap-2'>
      <span className='text-nowrap'>Total: {students.length}</span>
      <div className='flex flex-row flex-wrap gap-3'>
        {students.map(student => (
          <div key={student} className='rounded py-1 px-2 bg-gray-10 font-bold text-xs whitespace-nowrap'>{student}</div>
        ))}
      </div>
    </div>
  )
}
StudentsTag.propTypes = {
  students: PropTypes.array,
}
