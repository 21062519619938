/* eslint-disable react/prop-types */
import React from 'react'
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useBlockLayout,
  usePagination,
} from 'react-table'
import { CSVDownloader } from 'react-papaparse'
import { toast } from 'sonner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faChevronUp,
  faTimes,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import TextFilter from '@components/table/filters/TextFilter'
import MultiselectFilter from '@components/table/filters/MultiselectFilter'
import YearFilter from '@components/table/filters/YearFilter'
import SoraLink from '@components/link'
import capitalize from '@utils/capitalize'
import HouseCell from '@pages/students/house-cell'
import GuardiansCell from '@pages/students/guardian-cell'
import LeavesCell from '@pages/students/leaves-cell'
import useCurrentUser from '@hooks/useCurrentUser'
import useClipboard from '@hooks/useClipboard'
import useQuery from '@hooks/useQuery'
import { Button, Icon, Typography } from '@design-system'
import { useLoaderData, useRevalidator } from 'react-router-dom'
import GraduationDateCell from '@pages/students/graduation-date-cell'
import ExpectedGraduationDateCell from '@pages/students/expected-graduation-date-cell'
import useUserPermissions from '@hooks/useUserPermissions'
import { Popover, Switch, Transition } from '@headlessui/react'
import { usePopper } from 'react-popper'

export default function EmployeeStudentsRoute() {

  const [queryParams, setQueryParams] = useQuery()
  const { hasPermission } = useUserPermissions()
  const canEditStudentLeaves = hasPermission('edit_student_leaves')
  const { students, isSelectedCycleCurrent, isSuspensionFeatureEnabled } = useLoaderData()
  const [hiddenColumns, setHiddenColumns] = React.useState([
    'school_stage',
    'address',
    'guardian_1_email',
    'guardian_2_email'
  ])

  const [_status, copy] = useClipboard()
  const currentUser = useCurrentUser()
  const revalidator = useRevalidator()
  const mutate = revalidator.revalidate

  const changeStudentData = (student_id, newValue) => {
    if (student_id && newValue) {
      addTimelineEvent(currentUser.id, student_id, newValue)
    }
    mutate()
  }

  const toggleCohortView = () => {
    if (queryParams?.cohort_ids === 'all')
      return setQueryParams({ ...queryParams, cohort_ids: currentUser?.cohorts.join(',') })
    return setQueryParams({ ...queryParams, cohort_ids: 'all' })
  }

  const columns = React.useMemo(
    () => {
      const tableCols = [
        {
          Header: 'Name',
          accessor: 'name',
          Filter: TextFilter,
          Cell: (data) => (
            <SoraLink to={`${data.cell.row.original.id}`} className="flex items-center group space-x-2 w-full text-left">
              <span className="max-w-9/12">{data.value}</span>
              <FontAwesomeIcon className="hidden group-hover:flex" icon={faExternalLinkAlt} />
            </SoraLink>
          ),
          width: 250
        },
        {
          accessor: 'guardian_1_email',
          disableFilters: true,
          isHidden: true, // Custom prop to prevent showing in column visibility toggle
        },
        {
          accessor: 'guardian_2_email',
          disableFilters: true,
          isHidden: true, // Custom prop to prevent showing in column visibility toggle
        },
        {
          Header: 'ID',
          accessor: 'id',
          Filter: TextFilter,
          width: 130
        },
        {
          Header: 'Program',
          accessor: 'program',
          Filter: MultiselectFilter,
          filter: 'includesSome',
        },
        {
          Header: 'Cohort',
          accessor: 'cohort',
          Cell: (data) => data.cell.row.original.cohort === 'n/a' ?
            <div>
              {data.cell.row.original.cohort}
              <div className="text-xs text-gray-50 mt-1">Last campus: {data.cell.row.original.latest_cohort_title}</div>
            </div>
            : <div>{isSelectedCycleCurrent ? data.cell.row.original.current_cohort_title : data.cell.row.original.cohort}</div>,
          Filter: MultiselectFilter,
          filter: 'includesSome',
        },
        {
          Header: 'House',
          accessor: 'house',
          Cell: (data) => <HouseCell
            studentId={data.cell.row.original.id}
            house={isSelectedCycleCurrent ? data.cell.row.original.current_house_title : data.cell.row.original.house}
            afterSubmit={changeStudentData}
            latestHouse={data.cell.row.original.latest_house_title}
          />,
          Filter: MultiselectFilter,
          filter: 'includesSome',
        },
        {
          Header: 'School Stage',
          accessor: 'school_stage',
          Filter: MultiselectFilter,
          filter: 'includesSome',
        },
        {
          Header: 'Status',
          accessor: 'status',
          Filter: MultiselectFilter,
          filter: 'includesSome',
        },
        {
          Header: 'Leave History',
          accessor: 'house_history',
          Cell: ({ cell: { row: { original } } }) => <LeavesCell
            studentId={original.id}
            housesHistory={original.houses_history}
            studentStatus={original.status_raw}
            afterSubmit={changeStudentData}
            canEditStudentLeaves={canEditStudentLeaves}
          />,
          Filter: <></>,
          filter: 'includesSome',
        },
        {
          Header: 'Expeditions (Stem / Hum. / Elec.)',
          accessor: 'expeditions',
          Cell: ({ cell: { row: { original: { expeditions } } } }) => {
            return (
              <div>
                <span
                  className={
                    expeditions.stems_registered <
                      getRequiredStems(expeditions.program_status)
                      ? 'text-danger-40'
                      : 'text-green-50'
                  }
                >
                  {expeditions.stems_registered}S
                </span>
                ,
                <span
                  className={
                    expeditions.humanities_registered <
                      getRequiredHumanities(expeditions.program_status)
                      ? 'text-danger-40'
                      : 'text-green-50'
                  }
                >
                  {expeditions.humanities_registered}H
                </span>
                ,<span>{expeditions.electives_registered}E</span>
              </div>
            )
          },
          Filter: <></>,
          width: 250,
        },
        {
          Header: 'Registration Progress',
          accessor: 'registration_progress',
          Filter: <></>,
          Cell: ({ cell, value }) => {
            return cell?.row?.original?.status === 'Active' ? (
              <SoraLink
                to={`../../../student/${cell.row.original.id}/register/your-registrations/expedition`}
                target="_blank"
                className="flex underline items-center group space-x-2 w-full text-left"
              >
                <span className="max-w-9/12">{value ? `${value}%` : '-'}</span>
                <FontAwesomeIcon className="hidden group-hover:flex" icon={faExternalLinkAlt} />
              </SoraLink>
            ) : '-'
          }
        },
        {
          Header: 'Graduation Progress',
          accessor: 'graduation_progress',
          Filter: <></>,
          Cell: ({ value }) => {
            return <span className="max-w-9/12">{value ? `${value}%` : '-'}</span>
          }
        },
        {
          Header: 'Math',
          accessor: 'math',
          Filter: TextFilter,
        },
        {
          Header: 'Languages',
          accessor: 'languages',
          Filter: TextFilter,
        },
        {
          Header: 'House Advisor',
          accessor: 'house_advisor',
          Filter: MultiselectFilter,
          filter: 'includesSome',
        },
        {
          Header: 'Year Standing',
          accessor: 'year_standing',
          Filter: MultiselectFilter,
          filter: 'includesSome',
        },
        {
          Header: 'Start Date',
          accessor: 'start_date',
          Filter: YearFilter,
          filter: 'includesSome',
        },
        {
          Header: 'Graduation Date',
          accessor: 'graduation_date',
          Cell: (data) => <GraduationDateCell
            student_id={data.cell.row.original.id}
            graduation_date={data.cell.row.original.graduation_date}
            source_graduation_date={data.cell.row.original.source_graduation_date}
            afterSubmit={changeStudentData}
          />,
          Filter: <></>,
          filter: 'includesSome',
        },
        {
          Header: 'Expected Graduation',
          accessor: 'expected_graduation',
          Cell: (data) => <ExpectedGraduationDateCell
            school_stage={data.cell.row.original.school_stage}
            expected_graduation={data.cell.row.original.expected_graduation}
            start_date={data.cell.row.original.start_date}
          />,
          Filter: YearFilter,
          filter: 'includesSome',
        },
        {
          Header: 'E-mail',
          accessor: 'email',
          Filter: TextFilter,
          width: 250,
        },
        {
          Header: 'Address',
          accessor: 'address',
          Filter: TextFilter,
          width: 250,
        },
        {
          Header: 'State',
          accessor: 'state',
          Filter: TextFilter,
          width: 150,
        },
        {
          Header: 'Guardians',
          accessor: 'guardians',
          disableSortBy: true,
          Filter: <></>,
          Cell: (data) => <GuardiansCell guardians={data.cell.row.original.guardians} />,
        },
      ]
      if (isSuspensionFeatureEnabled) {
        tableCols.splice(
          8,
          0,
          {
            Header: 'Suspended',
            accessor: 'is_suspended',
            Filter: MultiselectFilter,
            filter: 'includesSome',
          }
        )
      }
      return tableCols
    },
    [
      mutate,
      isSuspensionFeatureEnabled,
      hiddenColumns /* Even though it's not directly used here, it must be a dependency in order for react-table to rerender when hiding a column*/
    ]
  )

  const defaultColumn = React.useMemo(
    () => ({
      width: 200,
    }),
    []
  )

  const initialState = React.useMemo(
    () => ({
      hiddenColumns, // Add this column, so it can be used in the filter in the UI, but do not display it.
      pageSize: 10
    }),
    [hiddenColumns]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows, // All/filtered rows
    filteredRows,
    prepareRow,
    page, // Paginated/filtered rows
    setFilter,
    setAllFilters,
    setGlobalFilter,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    pageOptions,
    state,
  } = useTable(
    { columns, data: students, defaultColumn, initialState, autoResetFilters: false, autoResetGlobalFilter: false },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useBlockLayout,
  )

  function toggleColumnVisibility(columnAccessor) {
    if (columnAccessor === 'show_all') {
      return setHiddenColumns(columns.filter(c => c.isHidden === true).map(c => c.accessor))
    }
    if (columnAccessor === 'hide_all') {
      return setHiddenColumns(columns.map(c => c.accessor))
    }

    const isHidden = hiddenColumns.some(hc => hc === columnAccessor)
    if (isHidden) {
      setHiddenColumns(hiddenColumns.filter(hc => hc !== columnAccessor))
    } else {
      setHiddenColumns((hc) => [...hc, columnAccessor])
    }
  }

  return (
    <div>
      <Typography variant="heading-3" weight="bold" className="mb-6">Students</Typography>
      <table className="max-h-full overflow-y-auto text-sm" {...getTableProps()}>
        <thead className="sticky -top-8 -left-4 z-10 space-y-3">
          <div className="sticky -left-4 w-[60rem] z-90">
            <div className="flex items-center space-x-3">
              {
                !!currentUser?.cohorts?.length &&
                <button
                  onClick={toggleCohortView}
                  className="text-sm -mt-1 font-bold hover:underline text-blue-50 p-1 block">
                  {queryParams?.cohort_ids === 'all' ? 'Show my cohorts' : 'Show all cohorts'}
                </button>
              }
              <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
              <ColumnVisibilityPopover columns={columns.filter(c => !c.isHidden)} hiddenColumns={hiddenColumns} toggleColumnVisibility={toggleColumnVisibility} />
              <Button variant="outlined" color="soft" asChild>
                <CSVDownloader
                  data={() => {
                    return filteredRows.map(({ original: row }) => {
                      const csvRow = {
                        id: row.id,
                        name: row.name,
                        program: row.program,
                        cohort: row.current_cohort_title,
                        house: row.current_house_title,
                        status: row.status,
                        house_history: (row.house_history || []).join(' ') || '',
                        expeditions: `${row.expeditions.stems_registered}S ${row.expeditions.humanities_registered}H ${row.expeditions.electives_registered}E`,
                        registration_progress: row.registration_progress ? row.registration_progress + '%' : '',
                        graduation_progress: row.graduation_progress ? row.graduation_progress + '%' : '',
                        math: (row.math || []).join(' '),
                        languages: (row.languages || []).join(' '),
                        house_advisor: row.house_advisor,
                        year_standing: row.year_standing,
                        start_date: row.start_date,
                        graduation_date: row.graduation_date,
                        expected_graduation: row.expected_graduation,
                        email: row.email,
                        address: row.address || '',
                        state: row.state || '',
                        guardians: row.guardians.map(g => `${g.name}(${g.email})`).join(' ')
                      }

                      for (const hc of hiddenColumns) {
                        delete csvRow[hc]
                      }

                      return csvRow
                    })
                  }}
                  filename={`${new Date().getTime()}_student_data_export`}
                  bom
                >
                  <Icon name="file-download" />
                  Export
                </CSVDownloader>
              </Button>
            </div>
            <TableFilters filters={state.filters} setFilter={setFilter} setAllFilters={setAllFilters} />
          </div>
          <StudentHeaderGroups headerGroups={headerGroups} />
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, idx) => {
            prepareRow(row)
            return <Row key={idx} row={row} copy={copy} isSuspensionFeatureEnabled={isSuspensionFeatureEnabled} />
          })}
        </tbody>
        {page.length === 0 && <div className="text-left py-4 border pl-5 bg-white">No results! Try a different query.</div>}
      </table>
      {(state?.globalFilter || state?.filters?.length) ? <div><span className='text-xxs'>Students found: {rows.length}</span></div> : null}
      <div className="flex items-center pt-1 space-x-3 pb-2">
        {canPreviousPage && <Button variant='outlined' color='soft' size="xs" onClick={previousPage}><Icon size="xs" name="arrow-left" /></Button>}
        <span className="space-x-1">Page:{' '}<strong>{state.pageIndex + 1} of {pageOptions.length || 1}</strong></span>
        {canNextPage && <Button variant='outlined' color='soft' size="xs" onClick={nextPage}><Icon size="xs" name="arrow-right" /></Button>}
        <div className="flex items-center space-x-2">
          <span>Size:</span>
          <select
            value={state.pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
            className="rounded-lg max-h-10 text-black"
          >
            {[10, 30, 50, 0].map(pageSize => (
              <option key={pageSize} value={pageSize || Number.MAX_SAFE_INTEGER}>
                {pageSize || 'All'}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}

const StudentHeaderGroups = ({ headerGroups }) => {
  return <>
    {headerGroups.map((headerGroup, id) => (
      <div
        key={id}
        {...headerGroup.getHeaderGroupProps()}
        className="dark:bg-black bg-gray-10 text-left align-bottom border-b border-white"
      >
        {headerGroup.headers.map((column, id) => (
          <div
            key={id}
            className="first:border-l border-r border-t first:sticky first:-left-4 first:z-50"
            {...column.getHeaderProps()}
          >
            <div
              {...column.getSortByToggleProps()}
              className="px-4 py-2 flex justify-between bg-white dark:bg-black"
            >
              <div>{column.render('Header')}</div>
              <span>{column.isSorted && (<FontAwesomeIcon icon={column.isSortedDesc ? faChevronDown : faChevronUp} />)}</span>
            </div>
            {column.canFilter && <div className="px-4 py-2 dark:bg-black bg-gray-10">{column.render('Filter')}</div>}
          </div>
        ))}
      </div>
    ))}
  </>
}

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  const [value, setValue] = React.useState(globalFilter)
  const changeHandler = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  const clearInput = () => {
    changeHandler(undefined)
    setValue('')
  }

  return (
    <div className="flex w-full py-1 pr-3 items-center rounded-full divide-x border-2 border-gray-10 border-invisible h-full bg-white dark:bg-gray-100 focus-within:border-blue-40">
      <input
        className="flex-auto rounded-full pl-5 !outline-none !border-none !ring-0 text-black dark:bg-gray-100 dark:text-white"
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          changeHandler(e.target.value)
        }}
        placeholder="Search name, house advisor, guardian, tracks..."
      />
      <Button onClick={clearInput} variant="ghost" size="xs"><Icon name="cross" size="xs" /></Button>
    </div>
  )
}

const TableFilters = ({ filters, setFilter, setAllFilters }) => {
  if (!filters?.length) return null
  return (
    <div className="flex items-center sticky -left-4 z-10 space-x-3 mt-3">
      <span className="text-xs">Active Filters:</span>
      <div className="flex space-x-3">
        {filters.map((filter, id) => (
          <div
            className="flex items-center dark:bg-gray-80 bg-blue-20 px-3 text-sm rounded-xl space-x-2 hover:cursor-default"
            key={id}
          >
            <span>
              {capitalize(filter.id?.replace('_', ' '))}:{` `}
              {Array.isArray(filter.value) ? filter.value.join(', ') : filter.value}
            </span>
            <FontAwesomeIcon icon={faTimes} className="cursor-pointer" onClick={() => setFilter(filter.id, null)} />
          </div>
        ))}
        <Button
          onClick={() => setAllFilters([])}
          variant="outlined"
          size="xs"
        >
          Clear all
        </Button>
      </div>
    </div>
  )
}

const Row = ({ row, copy, isSuspensionFeatureEnabled }) => (
  <div
    {...row.getRowProps()}
    className={isSuspensionFeatureEnabled && row?.original?.is_suspended === 'Yes' ? 'bg-danger-10 hover:bg-danger-20' : 'dark:bg-black bg-white dark:hover:bg-gray-95 hover:bg-blue-5'}
  >
    {row.cells.map((cell, idx) => {
      const cellProps = cell.getCellProps()
      cellProps.style.backgroundColor = 'inherit' // Tailwind does not support inherit on v2
      return (
        <div
          key={idx}
          className="first:sticky first:-left-4 first:border-l border-b border-r px-4 py-2 group"
          {...cellProps}
        >
          <span className="flex justify-between">
            {cell.render('Cell')}{' '}
            {cell.value && cell.value !== 'n/a' && !Array.isArray(cell.value) && (
              <button
                className="border invisible group-hover:visible px-1 rounded-lg bg-white hover:bg-gray-10 self-start"
                onClick={() => {
                  copy(cell.value)
                  toast('Value Copied!')
                }}
              >
                <FontAwesomeIcon className="text-gray-50" icon={faCopy} />
              </button>
            )}
          </span>
        </div>
      )
    })}
  </div>
)

const getRequiredStems = (program_title) =>
  program_title === 'Full-Time' || program_title === 'Part-Time STEM' ? 1 : 0

const getRequiredHumanities = (program_title) =>
  program_title === 'Full-Time' || program_title === 'Part-Time Humanities'
    ? 1
    : 0

async function addTimelineEvent(employee_id, student_id, diff) {
  if (!diff) return
  await fetch(`/api/timeline-event/create`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      student_id,
      employee_id,
      type: 'Information Update',
      context: {
        diff,
      },
    }),
  })
}

function ColumnVisibilitySwitch({ column, onChange, hiddenColumns }) {
  const enabled = !hiddenColumns.some(hc => hc === column.accessor)
  return (
    <div className="flex items-center space-x-3 w-64">
      <Switch
        checked={enabled}
        onChange={() => onChange(column.accessor)}
        className={`${enabled ? 'bg-blue-50' : 'bg-gray-10'
          } relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span
          className={`${enabled ? 'translate-x-6' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
      <p>{column.Header || ''}</p>
    </div>
  )
}

function ColumnVisibilityPopover({ columns, hiddenColumns, toggleColumnVisibility, className, placement = 'bottom' }) {
  const [referenceElement, setReferenceElement] = React.useState()
  const [popperElement, setPopperElement] = React.useState()
  const { styles, attributes } = usePopper(referenceElement, popperElement, { placement })
  return (
    <Popover>
      <Popover.Button ref={setReferenceElement} className="focus:outline-none">
        <Button variant="outlined" color="soft">
          Column Visibility
          <Icon name="chevron-down" />
        </Button>
      </Popover.Button>
      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <div className="bg-white rounded-lg shadow-lg ring-1 ring-black/5 p-3 mr-1 space-y-2">
            <div className="flex items-center ml-auto text-right space-x-2">
              <p onClick={() => toggleColumnVisibility('show_all')} className="hover:cursor-pointer underline">Show All</p>
              <p>/</p>
              <p onClick={() => toggleColumnVisibility('hide_all')} className="hover:cursor-pointer underline">Hide All</p>
            </div>
            {columns.map(c => (
              <ColumnVisibilitySwitch
                key={c.accessor}
                column={c}
                onChange={toggleColumnVisibility}
                hiddenColumns={hiddenColumns}
              />
            ))}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
