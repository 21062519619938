import React from 'react'
import PropTypes from 'prop-types'
import { RemoveSection } from './remove-section'
import { UnitList } from './unit-list'

export const SectionList = ({ sections, cycleId, studentId }) => {
  const categoryView = (title) => {
    switch (title.toLowerCase()) {
      case 'stem':
        return (<span className='rounded py-1 px-2 bg-green-5 font-bold text-xs text-black whitespace-nowrap'>Stem</span>)
      case 'humanities':
        return (<span className='rounded py-1 px-2 bg-turquoise-5 font-bold text-xs text-black whitespace-nowrap'>Humanities</span>)
      case 'math_learning_goal':
        return (<span className='rounded py-1 px-2 bg-purple-5 font-bold text-xs text-black whitespace-nowrap'>Math</span>)
      case 'electives':
        return (<span className='rounded py-1 px-2 bg-orange-5 font-bold text-xs text-black whitespace-nowrap'>Electives</span>)
    }
  }

  return (
    sections.map(section => {
      const isRedNotice = !section.learning_block_id || !section.employee_id || section.has_conflict ? 'bg-danger-10 text-danger-60' : 'bg-white'
      return (
        <tr key={`student_${studentId}_cycle_${cycleId}_section_${section.section_id}`} className={`${isRedNotice}`}>
          <td className='p-2'>
            <RemoveSection sectionId={section.section_id} />
          </td>
          <td className='text-nowrap p-2'>{section.learning_block_title || 'Not found'}</td>
          <td className='text-nowrap p-2'>{section.employee_name || 'Not found'}</td>
          <td className='p-2'>
            <div className='flex flex-row gap-2 items-center'>
              {section.is_request_list ? <span>⭐</span> : ''}
              <span>{section.template_title}</span>
              {categoryView(section.template_category)} 
              {section.is_pathways ? <span className='px-2 py-1 bg-yellow-10 text-yellow-70 text-xs rounded ml-1'>Pathways</span> : ''}
            </div>
          </td>
          <td className='p-2'>{section.is_first_time}</td>
          <td className='p-2'>
            <UnitList units={section.new_units} />
          </td>
        </tr>
      )
    })
  )
}
SectionList.propTypes = {
  sections: PropTypes.array,
}
