import React from 'react'
import PropTypes from 'prop-types'
import { SectionItem } from './section-item'
import { CycleLimitWarn, cycleLimitWarnClass } from './cycle-limit-warn'

export const FirstCycleSectionList = ({ cycle, expertId }) => {
  const cycleColors = {
    "Cycle 1" : 'text-orange-50',
    "Cycle 2" : 'text-cyan-50', 
    "Cycle 3" : 'text-green-50', 
    "Cycle 4" : 'text-blue-50', 
    "Cycle 5" : 'text-yellow-50', 
    "Cycle 6" : 'text-pink-50'
  }

  if (!cycle) { 
    return (
      <td colSpan={6}>
        <span className='text-alpha/40 italic'>This expert doesn't have assigned and/or scheduled templates</span>
      </td>
    )
  }

  const sectionsAmount = cycle.data.length+1 || 0
  
  return (
    <React.Fragment>
      <td rowSpan={sectionsAmount} className={`align-top p-2 ${cycleLimitWarnClass({cycle})}`}>
        <div className='flex flex-row items-center gap-2'>
          <span className={`py-1 text-nowrap rounded text-xs font-bold ${cycleColors[cycle.cycle_title.substring(0,7)]}`}>{cycle.cycle_title}</span>
          <CycleLimitWarn expeditionLimit={cycle.exceeded_expeditions_limit} sectionLimit={cycle.exceeded_sections_limit} />
        </div>
      </td>
      <SectionItem section={cycle.data[0]} expertId={expertId} cycleId={cycle.cycle_id} />
    </React.Fragment>
  )
}
FirstCycleSectionList.propTypes = {
  cycle: PropTypes.object,
  expertId: PropTypes.number,
}
