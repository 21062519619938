import React from 'react'
import { useFetcher } from 'react-router-dom'
import { Button, Icon } from '@design-system'
import useConfirmModal from '@hooks/useConfirmModal'

export const RefreshTemplateButton = () => {
  const fetcher = useFetcher()
  const confirm = useConfirmModal()
  const action = 'refresh_math_templates'
  const isLoading = fetcher.state !== 'idle'

  const handleRefresh = async (event) => {
    const form = event.currentTarget.form

    event.preventDefault()
    const title = 'Are you sure you want to refresh Math?'
    const subtitle = 'This action will refresh all Math templates and has the potential to lose some changes.'
    const accepted = await confirm({ title, subtitle })
    if (!accepted) return false
    
    fetcher.submit(form)
  }

  return (
    <fetcher.Form id='refresh-template-form' method='post'>
      <input type="hidden" name="_action" value={action} />
      <Button size='sm' loading={isLoading} disabled={isLoading} onClick={handleRefresh}>
        <Icon name='refresh-ccw' size='xs' />
        <span>Refresh Math Templates</span>
      </Button>
    </fetcher.Form>
  )
}
