import React from 'react'
import PropTypes from 'prop-types'
import { useFetcher } from 'react-router-dom'
import { Button, Icon } from '@design-system'

export const RemoveSection = ({ sectionId }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle' && fetcher.data?.section_id === sectionId

  return (
    <fetcher.Form method="post" className="flex flex-col justify-center">
      <input type="hidden" name="_action" value="remove_section" />
      <input type="hidden" name="section_id" value={sectionId} />
      <Button type="submit" variant='outlined' disabled={isLoading} loading={isLoading} className='h-6 w-6 p-1'>
        <Icon name='cross' className='text-xxs font-bold' />
      </Button>
    </fetcher.Form>
  )
}
RemoveSection.propTypes = {
  sectionId: PropTypes.number,
}
