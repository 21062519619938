import React from 'react'
import { ActionFunctionArgs, LoaderFunctionArgs, redirect, useLoaderData, useNavigate } from 'react-router-dom'
import axios from 'axios'
import serializeFormData from '@utils/serializeFormData'
import { unstable_Dialog as Dialog, Typography, Button, Combobox, unstable_Datepicker as Datepicker, useFetcher } from '@design-system'
import { TextField } from '@designsystem'
import SlateTextarea from '@components/forms/slate-textarea'

async function loader({ request, params }: LoaderFunctionArgs) {
  const { houseId, reportId, reportItemId } = params
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/houses/${houseId}/overview/${reportId}/${reportItemId}/note?${searchParams.toString()}`)
  return data
}

async function action({ request, params }: ActionFunctionArgs) {
  const { houseId, reportId, reportItemId } = params
  const formData = await request.formData()
  const { data } = await axios.post(`/backoffice/houses/${houseId}/overview/${reportId}/${reportItemId}/note`, serializeFormData(formData))
    .catch(error => {
      return {
        data: {
          toast: {
            appearance: 'error',
            message: error.response.data.error.message
          },
          errors: error.response.data.errors,
        },
      }
    })
  if (data.success) {
    return redirect('..')
  }
  return data
}

interface LoaderDataProps {
  initialValues: {
    title: string
    type: string
    date: string
    content: any[]
  }
  noteTypes: Array<{ value: string, label: string }>
}

function Element() {
  const data = useLoaderData() as LoaderDataProps
  const navigate = useNavigate()
  const fetcher = useFetcher()
  const formErrors = fetcher.data?.errors || []
  return (
    <Dialog
      open={true}
      onOpenChange={() => navigate('../')}
    >
      <Dialog.Content className="px-0 max-w-[700px]" hideCloseButton>
        <Dialog.Header className="flex border-b border-neutral-200 px-6 pb-6">
          <Typography variant="subheadline" weight="bold">
            Create a Note
          </Typography>
          <Typography>Document your actions in student notes for future reference.</Typography>
        </Dialog.Header>
        <fetcher.Form method="POST" noValidate>
          <div className="grid lg:grid-cols-2 gap-4 p-6">
            <div className="lg:col-span-2">
              <TextField name="title" label="Title" defaultValue={data.initialValues.title} required error={formErrors.find(err => err.fieldName === 'title')?.message} />
            </div>
            <Combobox name="type" label="Type" required defaultValue={data.initialValues.type} search={<Combobox.Search placeholder="Search..." />} error={formErrors.find(err => err.fieldName === 'type')?.message}>
              {data.noteTypes?.map(({ value, label }) => (
                <Combobox.Item key={value} value={value}>{label}</Combobox.Item>
              ))}
            </Combobox>
            <div className="space-y-1">
              <label htmlFor="date" className="text-sm font-sans font-normal text-gray-100 dark:text-gray-20">Date <span className='text-danger-50'>*</span></label>
              <Datepicker name='date' defaultSelected={data.initialValues.date} />
              {formErrors.find(err => err.fieldName === 'date')?.message && <Typography variant="footnote" color="danger">{formErrors.find(err => err.fieldName === 'date')?.message}</Typography>}
            </div>
            <div className="lg:col-span-2">
              <div className="space-y-1">
                <label htmlFor="date" className="text-sm font-sans font-normal text-gray-100 dark:text-gray-20">Content <span className='text-danger-50'>*</span></label>
                <SlateTextarea
                  /* @ts-ignore: Unreachable code error */
                  id="content"
                  name="content"
                  value={data.initialValues.content}
                  placeholderText="What action did you take? (e.g. Message student, scheduled 1:1 etc)"
                  className="h-36"
                  autoFocus
                />
                {formErrors.find(err => err.fieldName === 'content')?.message && <Typography variant="footnote" color="danger">{formErrors.find(err => err.fieldName === 'content')?.message}</Typography>}
              </div>
            </div>
          </div>
          <Dialog.Footer className="px-6 flex justify-end">
            <Button type="button" variant="outlined" onClick={() => navigate('../')}>Skip</Button>
            <Button type="submit" loading={fetcher.state === 'submitting'}>Save</Button>
          </Dialog.Footer>
        </fetcher.Form>
      </Dialog.Content>
    </Dialog >
  )
}


export const HouseOverviewReportNoteDialogRoute = {
  loader,
  action,
  Element,
}


