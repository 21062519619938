import React from 'react'
import PropTypes from 'prop-types'

const JourneyScheduleMetrics = ({ metrics }) => {
  return (
    <div className="flex flex-row p-3 gap-3">
      <div className="flex-auto"></div>
      <div>
        <div className="flex flex-col p-3 space-y-2 bg-white font-bold">
          <span>Allocation</span>
          <span className="text-xl">{metrics.allocation}</span>          
        </div>
      </div>
      <div>
        <div className="flex flex-col p-3 space-y-2 bg-white font-bold">
          <span>Template Request Hits</span>
          <div className="flex flex-col p-2 gap-2">
            {metrics.request_hits_per_cycle.map((rh, idx) => (
              <span key={`metric_request_hit_${idx}`} className="text-xl">{rh}</span>
            ))}
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col p-3 space-y-2 bg-white font-bold">
          <span>Unique students with 1+ request list hits</span>
          <div className="flex flex-col p-2 gap-2">
            {metrics.student_request_hits_plus_one_per_cycle.map((rh, idx) => (
              <span key={`metric_request_hit_${idx}`} className="text-xl">{rh}</span>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
JourneyScheduleMetrics.propTypes = {
  metrics: PropTypes.object.isRequired,
}

export default JourneyScheduleMetrics
