import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Form, useLoaderData, useSubmit, useActionData, redirect, Link } from 'react-router-dom'
import { toast } from 'sonner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import useClipboard from '@hooks/useClipboard'
import { useThisPageTitle } from '@hooks/usePageTitle'
import useUserPermissions from '@hooks/useUserPermissions'
import useConfirmModal from '@hooks/useConfirmModal'
import joinFirstLastName from '@utils/joinFirstLastName'
import SoraLink from '@components/link'
import { Button } from '@designsystem'

type LoaderData = Awaited<ReturnType<typeof loader>>
type ActionData = Awaited<ReturnType<typeof action>>

async function loader({ params }) {
  const { data: { theme, students } } = await axios.get(`/pages/employee/expeditions/${params.experienceId}/registrations`)
  return { theme, students }
}

async function action({ request, params }) {
  const formData = await request.formData()
  const jsonData = Object.fromEntries(formData)

  if (jsonData.mode === 'add') {
    try {
      await axios.post(`/expeditions/${params.experienceId}/registrations-with-waitlist`, {
        student_id: jsonData.student_id,
        section_id: jsonData.section_id,
        notes: jsonData.notes,
        should_ignore_schedule_conflicts: jsonData.should_ignore_schedule_conflicts === 'true',
      })
      return { ok: true }
    } catch (error) {
      return { ok: false, error }
    }
  }

  if (jsonData.mode === 'ban') {
    try {
      await axios.delete(`/expeditions/${params.experienceId}/registrations?student_id=${jsonData.student_id}`)
      return { ok: true }
    } catch (error) {
      return { ok: false, error }
    }
  }
}


export function NEW_ExpeditionRegistrationsRoute() {
  const confirm = useConfirmModal()

  const { hasPermission } = useUserPermissions()
  const [, copy] = useClipboard()

  const submit = useSubmit()
  const { theme: expedition, students } = useLoaderData() as LoaderData
  const actionData = useActionData() as ActionData
  const [shouldIgnoreConflicts, setShouldIgnoreConflicts] = useState(false)
  const [showAddStudentForm, setShowStudentSelect] = useState(false)

  const registeredStudentsIds = expedition?.students?.map((student) => student.id) || []

  const [selectedSection, setSelectedSection] = useState(0)

  useThisPageTitle(`${expedition?.title || 'Expedition'} - Registrations`, {
    backTo: '/employee/expeditions/edit',
  })

  const availableStudents = students.filter((student) => !registeredStudentsIds.includes(student.id))

  const sections = expedition.section_ids.filter(Boolean)

  useEffect(() => {
    const isError = actionData?.ok === false
    if (isError) {
      const message =
        actionData?.error?.response?.data?.message ||
        actionData?.error?.response?.data?.error?.message ||
        actionData?.error?.message
      toast.error(message)
    }
  }, [actionData])

  useEffect(() => {
    const formEl = document.getElementById('add-form') as HTMLFormElement
    const isError = actionData?.ok === false
    if (isError && actionData?.error?.response?.data?.code === 'conflict') {
      confirm(`There's a schedule conflict. Would you like to ignore it and register the student anyway?`).then(
        (res) => {
          setShouldIgnoreConflicts(res)
          res && submit(formEl)
          formEl?.reset()
        }
      )
    } else {
      formEl?.reset()
      setShouldIgnoreConflicts(false)
    }
  }, [actionData])

  const hasEditStudentRegistrationPermission = hasPermission('edit_student_registrations')
  const showActionsColumn = !expedition.archived && hasEditStudentRegistrationPermission

  function copyTableContentToClipboard() {
    try {
      const output = ['Student Name\tStudent Email\tGuardian Name\tGuardian Email']
      for (const student of expedition.students) {
        const [guardian] = student.guardians
        output.push([
          student.name,
          student.sora_email,
          joinFirstLastName(guardian),
          guardian.email || ''
        ].join('\t'))
      }
      copy(output.join("\n"))
      toast.success('Table Content Copied!')
    } catch (e) {
      toast.error('Something went wrong')
    }
  }

  function copyGuardiansToClipboard() {
    try {
      copy(expedition.students.map(s => s.guardians[0].email || '').join("\n"))
      toast.success('Content Copied!')
    } catch (e) {
      toast.error('Something went wrong')
    }
  }

  function copyStudentsToClipboard(prop) {
    try {
      copy(expedition.students.map(s => s[prop]).join("\n"))
      toast.success('Content Copied!')
    } catch (e) {
      toast.error('Something went wrong')
    }
  }

  function onAddStudent(e) {
    e.preventDefault()
    const formEl = e.target.form
    confirm(
      "Double-check this student's schedule for conflicts and that they're in the right cohort for this experience! Are you sure you want to enroll?"
    ).then((res) => {
      res && submit(formEl)
      !res && formEl.reset()
    })
  }

  return (
    <div className="flex flex-col">
      <ul className="flex flex-row justify-center space-x-4">
        {sections.length > 1 &&
          sections.map((section_id, index) => (
            <li key={section_id}>
              <button
                className={`uppercase font-bold border-2 border-blue-70 p-2 rounded
              ${selectedSection === section_id ? 'bg-blue-70 text-white' : 'bg-white text-blue-70'}
            `}
                onClick={() => setSelectedSection(section_id)}
              >
                <h3>Group {index + 1}</h3>
              </button>
            </li>
          ))}
      </ul>
      <span className="ml-auto text-blue-40 font-bold hover:cursor-pointer mb-1" onClick={copyTableContentToClipboard} data-testid="copy-table-content-btn">
        Export
      </span>
      <table className="table-auto w-full mb-2" data-testid="registration-table">
        <thead className="bg-white">
          <tr>
            <th className="border px-4 py-2 space-x-2">
              <span>Name</span>
              <FontAwesomeIcon icon={faCopy} size="sm" className="hover:cursor-pointer" title="Copy Student Names" onClick={() => copyStudentsToClipboard('name')} data-testid="copy-student-names-btn" />
            </th>
            <th className="border px-4 py-2 space-x-2">
              <span>Email</span>
              <FontAwesomeIcon icon={faCopy} size="sm" className="hover:cursor-pointer" title="Copy Student E-mails" onClick={() => copyStudentsToClipboard('sora_email')} data-testid="copy-student-emails-btn" />
            </th>
            <th className="border px-4 py-2 space-x-2">
              <span>Guardian</span>
              <FontAwesomeIcon icon={faCopy} size="sm" className="hover:cursor-pointer" title="Copy Guardian E-mails" onClick={copyGuardiansToClipboard} data-testid="copy-guardian-emails-btn" />
            </th>
            {showActionsColumn && <th className="border px-4 py-2">Actions</th>}
          </tr>
        </thead>
        <tbody className="bg-white">
          {expedition.students
            .filter((st) => st.section_id === selectedSection)
            .map((student, i) => {
              const [guardian] = student.guardians
              return (
                <tr key={student.id} data-testid="registration-table-row">
                  <td className="border px-4 py-2" data-testid={`registration-table-row-${i}-student-name`}>
                    <SoraLink
                      as={Link}
                      to={`/employee/students/${student.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline"
                    >
                      {student.name}
                    </SoraLink>
                  </td>
                  <td className="border px-4 py-2" data-testid={`registration-table-row-${i}-student-email`}>{student.sora_email}</td>
                  <td className="border px-4 py-2 space-x-1" data-testid={`registration-table-row-${i}-guardian-data`}>
                    {guardian && (
                      <>
                        <span>{guardian.first_name} {guardian.last_name || ''}</span>
                        {
                          guardian?.email &&
                          <>
                            <span>-</span>
                            <span
                              className="py-1 px-2 bg-gray-10 cursor-pointer rounded"
                              onClick={() => {
                                copy(guardian.email)
                                toast.success('Value Copied!')
                              }}
                            >
                              {guardian.email}
                              <FontAwesomeIcon className="text-gray-50 ml-2" size="sm" icon={faCopy} />
                            </span>
                          </>
                        }
                      </>
                    )}
                  </td>
                  {showActionsColumn && (
                    <td className="border py-2" data-testid={`registration-table-row-${i}-remove-btn`}>
                      <Form method="post" className="flex">
                        <input type="hidden" name="mode" value="ban" />
                        <input type="hidden" name="student_id" value={student.id} />
                        <button type="submit" className="mx-auto bg-danger-50 text-white rounded font-bold py-1 px-2">
                          <FontAwesomeIcon icon={faBan} />
                        </button>
                      </Form>
                    </td>
                  )}
                </tr>
              )
            })}
        </tbody>
      </table>
      {
        expedition.max_students > expedition.students.length &&
        !expedition.archived &&
        hasEditStudentRegistrationPermission && (
          <Form method="post" id="add-form">
            <input type="hidden" name="mode" value="add" />
            <input
              type="hidden"
              id="should_ignore_schedule_conflicts"
              name="should_ignore_schedule_conflicts"
              value={String(shouldIgnoreConflicts)}
            />
            <input type="hidden" name="section_id" value={selectedSection} />
            <div className={`flex flex-col w-1/3 min-h-min border rounded mb-2 space-y-3 p-2 ${showAddStudentForm ? '' : 'hidden'}`}>
              <p className="font-bold">Student to add:</p>
              <input type="text" name="notes" className={`mb-2 py-1 rounded pr-8 bg-white ${showAddStudentForm ? '' : 'invisible'}`} placeholder="Notes on registration change" />
              <select
                name="student_id"
                id="select-students"
                defaultValue="0"
                className={`mb-2 py-1 rounded pr-8 bg-white ${showAddStudentForm ? '' : 'invisible'}`}
              >
                <option disabled hidden value="0">
                  Select student
                </option>
                {availableStudents.map((student) => (
                  <option key={student.id} value={student.id}>
                    {student.name}
                  </option>
                ))}
              </select>
              <div className="flex items-center space-x-3 justify-end">
                <Button size="xs" variant="outlined" onClick={() => setShowStudentSelect(false)}>Cancel</Button>
                <Button size="xs" type="submit" onClick={onAddStudent}>Add Student</Button>
              </div>
            </div>
            {!showAddStudentForm && <Button size="xs" onClick={() => setShowStudentSelect(b => !b)}>+ Add</Button>}
          </Form>
        )
      }
    </div>
  )
}

NEW_ExpeditionRegistrationsRoute.loader = loader
NEW_ExpeditionRegistrationsRoute.action = action