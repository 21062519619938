import axios from 'axios'
import { defer } from 'react-router-dom'

export async function loader({ params, request }) {
  const fetchData = async () => {
    const v = new URL(request.url).searchParams.get('v')
    const { data } = await axios.get(`/pages/employee/expeditions/${params.id}/attendances?v=${v === 'aggregated' ? 'aggregated' : ''}`)
    return data
  }
  return defer({ result: fetchData() })
}
