import React from 'react'
import axios from 'axios'
import { redirect, useLoaderData } from 'react-router-dom'
import { LearningBlocks, ILearningBlocks } from '@components/learning-blocks'

export default function EmployeeStudentLearningBlocksRoute() {
  const data = useLoaderData() as { learningBlocks: ILearningBlocks }
  return (
    <section>
      <div className="py-4">
        <LearningBlocks learningBlocks={data.learningBlocks} hideToggle={true} />
      </div>
    </section>
  )
}

export const loader = async ({ request, params }) => {

  try {
    const searchParams = new URL(request.url).searchParams
    const cycle_id = searchParams.get('cycle_id')
    const { data } = await axios.get(`/pages/employee/students/${params.student_id}/learning-blocks${cycle_id ? `?cycle_id=${cycle_id}` : ''}`)
    return data
  } catch (e) {
    console.error(e)
    return redirect('/')
  }
}

export const action = async ({ request, params }) => {
  return {}
}
