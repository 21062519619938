
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'
import PropTypes from 'prop-types'
import React from 'react'

const Stars = ({ numFilled = 0, className = '', maxStars = 4 }) => {
  const integerNum = Math.floor(numFilled)
  const partialNum = numFilled - integerNum
  const hollowNum = maxStars - Math.ceil(numFilled)
  return <ul className={`flex flex-row ${className}`}>
    {[...Array(integerNum > 0 ? integerNum : 0)].map((_, i) => (
      <li key={i}><FontAwesomeIcon icon={faStar} /></li>
    ))}
    {partialNum > 0 && <li><FontAwesomeIcon icon={faStarHalfAlt} /></li>}
    {[...Array(hollowNum > 0 ? hollowNum : 0)].map((_, i) => (
      <li key={i}><FontAwesomeIcon icon={farStar} /></li>
    ))}
  </ul>
}

Stars.propTypes = {
  numFilled: PropTypes.number,
  numHollow: PropTypes.number,
  maxStars: PropTypes.number,
  className: PropTypes.string,
}

export default Stars
