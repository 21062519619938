import React from 'react'
import PropTypes from 'prop-types'
import { SectionItem } from './section-item'
import { SectionList } from './section-list'
import { AddSection } from './add-section'
import { CycleLimitWarn, cycleLimitWarnClass } from './cycle-limit-warn'

export const CycleSectionList = ({ cycles, expertId }) => {
  const cycleColors = {
    "Cycle 1" : 'text-orange-50',
    "Cycle 2" : 'text-cyan-50', 
    "Cycle 3" : 'text-green-50', 
    "Cycle 4" : 'text-blue-50', 
    "Cycle 5" : 'text-yellow-50', 
    "Cycle 6" : 'text-pink-50'
  }

  return (
    cycles.map(cycle => {
      const sectionsAmount = cycle.data.length
      const showAddSection = sectionsAmount > 0
      return (
        <React.Fragment key={`expert_${expertId}_cycle_${cycle.cycle_id}`}>
          <tr key={cycle.cycle_id} className="bg-white dark:bg-black">
            <td className={`align-top pt-2 px-2 ${cycleLimitWarnClass({cycle})}`} rowSpan={sectionsAmount+1}>
              <div className='flex flex-row items-center gap-2'>
                <span className={`py-1 text-nowrap rounded text-xs font-bold ${cycleColors[cycle.cycle_title.substring(0,7)]}`}>{cycle.cycle_title}</span>
                <CycleLimitWarn expeditionLimit={cycle.exceeded_expeditions_limit} sectionLimit={cycle.exceeded_sections_limit} />
              </div>
            </td>
            <SectionItem section={cycle.data[0]} cycleId={cycle.cycle_id} expertId={expertId} />
          </tr>
          {sectionsAmount > 1 &&
            <SectionList sections={cycle.data.slice(1)} expertId={expertId} cycleId={cycle.cycle_id} />
          }
          {showAddSection &&
            <tr className='bg-white dark:bg-black'>
              <AddSection cycleId={cycle.cycle_id} expertId={expertId} />
            </tr>
          }
        </React.Fragment>
      )
    })
  )
}
CycleSectionList.propTypes = {
  cycles: PropTypes.array,
  expertId: PropTypes.number,
}
