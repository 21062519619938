import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import axios from 'axios'
import useConfirmModal from '@hooks/useConfirmModal'
import RubricDrawer from '@components/rubric-drawer'
import UnitAbilitySearchBar from '@components/facilitate/feedback/unit-ability-search-bar'
import SelfAssessmentPickedList from '@components/submission/self-assessment/self-assessment-picked-list'

const UnitsAbilitiesSelfAssessment = forwardRef(({
  apiUrl,
  selectedUnits = [],
  selectedAbilities = [],
  onUpdateSelectedUnits,
  onUpdateSelectedAbilities,
  disabled,
  noDataMessage,
  taskType,
  allowAddNew,
  isAssessed,
  showNumericValue = true,
}, ref) => {
  const { data, mutate } = useSWR(apiUrl ? apiUrl : null)
  const confirm = useConfirmModal()
  const drawerRef = useRef(null)

  useImperativeHandle(ref, () => ({
    refreshComponent: () => {
      mutate(apiUrl, axios.get(apiUrl).then(res => {
        const unitList = res.data.result?.units?.filter(u => u.picked) || []
        const abilityList = res.data.result?.abilities?.filter(a => a.picked) || []
        onUpdateSelectedUnits([...unitList])
        onUpdateSelectedAbilities([...abilityList])
      }))
    },
    getMissingItems: (unitIds, abilityIds) => {
      const unitsMissing = data?.result?.units?.filter(unit => unit.picked && unit.flag === 'ontrack' && !unitIds.includes(unit.id)).map(u => { u.levelAssessed = 0; return u }) || []
      const abilitiesMissing = data?.result?.abilities?.filter(ability => ability.picked && ability.flag === 'ontrack' && !abilityIds.includes(ability.id)).map(a => { a.levelAssessed = 0; return a }) || []

      return {
        units: unitsMissing,
        abilities: abilitiesMissing
      }
    }
  }))

  if (!data) return (<span>Loading</span>)
  const units = data.result?.units.sort((a, b) => String(a.topic_title + a.title).localeCompare(String(b.topic_title + b.title)))
  const selectedUnitIds = selectedUnits.map(u => u.id)
  const unitsPreSelected = data.result?.units?.filter(unit => unit.picked && !selectedUnitIds.includes(unit.id)) || []
  const unitsLastStateSelected = [...unitsPreSelected, ...selectedUnits]

  const abilities = data.result?.abilities.sort((a, b) => String(a.title).localeCompare(String(b.title)))
  const selectedAbilityIds = selectedAbilities.map(a => a.id)
  const abilitiesPreSelected = data.result?.abilities?.filter(ability => ability.picked && !selectedAbilityIds.includes(ability.id)) || []
  const abilitiesLastStateSelected = [...abilitiesPreSelected, ...selectedAbilities].filter((ab) => ab.faculty_has_assessed || ab.student_has_self_assessed)

  const noUnitsAndAbilities = unitsLastStateSelected.length === 0 && abilitiesLastStateSelected.length === 0

  const handleSelected = (type, obj) => {
    obj.flag = 'ontrack'
    switch (type) {
      case 'unit':
        onUpdateSelectedUnits([...selectedUnits, ...[obj]])
        break
      case 'ability':
        onUpdateSelectedAbilities([...selectedAbilities, ...[obj]])
        break
    }
  }

  const handleRemoveItem = (type, obj) => async () => {
    if (await confirm('Are you sure you want to remove it?') === true) {
      switch (type) {
        case 'unit':
          onUpdateSelectedUnits(selectedUnits.filter(su => su.id !== obj.id))
          break
        case 'ability':
          onUpdateSelectedAbilities(selectedAbilities.filter(sa => sa.id !== obj.id))
          break
      }
    }
  }

  const handleChangeItem = (type, obj) => {
    switch (type) {
      case 'unit':
        onUpdateSelectedUnits([...selectedUnits.filter(u => u.id !== obj.id), ...[obj]])
        break
      case 'ability':
        onUpdateSelectedAbilities([...selectedAbilities.filter(a => a.id !== obj.id), ...[obj]])
        break
    }
  }

  const handleSaveItem = (type, obj) => {
    let payload = {}
    if (type === 'unit') payload.units = [obj]
    if (type === 'ability') payload.abilities = [obj]
  }

  const handleShowRubricsItem = (type, id) => {
    drawerRef.current.open(type, id)
  }

  return (
    <div className='flex flex-col gap-3'>
      <RubricDrawer ref={drawerRef} />
      {(!disabled && allowAddNew) &&
        <UnitAbilitySearchBar
          units={units}
          abilities={abilities}
          selectedUnits={unitsLastStateSelected}
          selectedAbilities={abilitiesLastStateSelected}
          onSelect={handleSelected}
        />
      }

      <div className='flex flex-col space-y-5'>
        <div>
          <SelfAssessmentPickedList
            type='unit'
            list={unitsLastStateSelected}
            title={`Units (${unitsLastStateSelected?.length})`}
            onRemoveItem={handleRemoveItem}
            onChangeItem={handleChangeItem}
            onSaveItem={handleSaveItem}
            onShowRubricsItem={handleShowRubricsItem}
            disabled={disabled}
            taskType={taskType}
            isAssessed={isAssessed}
            showNumericValue={showNumericValue}
          />
        </div>
        <div>
          <SelfAssessmentPickedList
            type='ability'
            list={abilitiesLastStateSelected}
            title={`Abilities (${abilitiesLastStateSelected?.length})`}
            onRemoveItem={handleRemoveItem}
            onChangeItem={handleChangeItem}
            onSaveItem={handleSaveItem}
            disabled={disabled}
            taskType={taskType}
            isAssessed={isAssessed}
            onShowRubricsItem={handleShowRubricsItem}
            showNumericValue={showNumericValue}
          />
        </div>
      </div>

      {noUnitsAndAbilities &&
        <div className='flex flex-col'>{noDataMessage}</div>
      }
    </div>
  )
})
UnitsAbilitiesSelfAssessment.displayName = 'UnitsAbilitiesSelfAssessment'
UnitsAbilitiesSelfAssessment.propTypes = {
  apiUrl: PropTypes.string,
  selectedUnits: PropTypes.array,
  selectedAbilities: PropTypes.array,
  onUpdateSelectedUnits: PropTypes.func,
  onUpdateSelectedAbilities: PropTypes.func,
  disabled: PropTypes.bool,
  allowAddNew: PropTypes.bool,
  noDataMessage: PropTypes.any,
  taskType: PropTypes.string,
  isAssessed: PropTypes.bool,
  showNumericValue: PropTypes.bool,
}

export default UnitsAbilitiesSelfAssessment
