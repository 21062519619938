import SessionsInfo from '@components/sessions-info'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ExpeditionRow from '@components/expedition-row/expedition-row'
import ExpeditionPeerCard from '@components/expedition-row/expedition-peer-card'
import SessionItem from '@components/expedition-row/session-item'
import useSWR from 'swr'
import ExpeditionFileCard from '@components/expedition-row/expedition-file-card.jsx'
import { getCategoryColor } from '@utils/categoryColors.js'
import { useThisPageTitle } from '@hooks/usePageTitle.jsx'
import MyOtherSeparator from '@components/my-other-separator/index.jsx'
import { RenderedSlate } from '@components/forms/slate-textarea'
import { PortalModal } from '@components/modal'

const ExpeditionHome = () => {
  const { expedition_id, student_id = 91 } = useParams()

  const expedition = null

  const firstSession = expedition?.sessions[0]
  const lastSession =
    expedition && expedition.sessions[expedition.sessions.length - 1]
  const startDate =
    firstSession && new Date(firstSession.timestamptz).toISOString()
  const endDate = lastSession && new Date(lastSession.timestamptz).toISOString()

  const [openSession, setOpenSession] = useState(expedition_id)
  const { data: { result: preWork } = { result: [] } } = useSWR(
    () =>
      `/tasks/pre-work?theme_id=${expedition.id}&student_id=${student_id
      }&startDate=${startDate || ''}&endDate=${endDate || ''}`
  )
  const [showing, setShowing] = useState(1)
  const [showReminder, setShowReminder] = useState(true)
  const abTestingPBSExpedition = [1046, 1093]

  let pageTitle = expedition?.title || 'Loading...'

  useThisPageTitle(pageTitle, {
    backTo: '/',
  })

  useEffect(() => {
    const shouldShow = window.localStorage.getItem(`exp-reminder-${expedition_id}`)
    shouldShow === true ? setShowReminder(shouldShow) : setShowReminder(!shouldShow)
  }, [expedition_id])

  if (!expedition) {
    return null
  }

  const categoryColor = getCategoryColor(expedition.category)

  const sessionWithPreWork = expedition.sessions.map((sess, idx) => {
    return {
      ...sess,
      preWork: preWork.filter(
        (preWorkItem) =>
          preWorkItem.due_at > expedition.sessions?.[idx - 1]?.timestamptz &&
          preWorkItem.due_at < sess.timestamptz
      ),
    }
  })
  const isValidFile = (item, selector) => {
    return item[selector].filter(
      (desc) =>
        desc.type === 'file' || desc.type === 'video' || desc.type === 'image'
    )
  }
  const filesFromPreWork = preWork
    .filter((preWorkItem) => preWorkItem?.body)
    .map((item) => isValidFile(item, 'body'))
    .filter((file) => file.length > 0)
    .flat(1)

  const filesFromExpeditions = expedition?.sessions
    .filter((sess) => sess?.rte_description)
    .map((item) => isValidFile(item, 'rte_description'))
    .filter((file) => file.length > 0)
    .flat(1)

  const filesFromDesc = expedition?.rte_description.filter(
    (desc) =>
      desc.type === 'file' || desc.type === 'video' || desc.type === 'image'
  )

  const allFiles = filesFromPreWork
    .concat(filesFromExpeditions)
    .concat(filesFromDesc)

  const tabsButtons = [
    {
      id: 1,
      onClick: () => setShowing(1),
      classes: `${showing === 1 ? 'bg-blue-90' : 'bg-gray-80'}`,
      text: 'Map',
      enabled: !!firstSession,
    },
    {
      id: 2,
      onClick: () => setShowing(2),
      classes: `${showing === 2 ? 'bg-blue-90' : 'bg-gray-80'}`,
      text: 'Files',
      enabled: allFiles.length > 0,
    },
    {
      id: 3,
      onClick: () => setShowing(3),
      classes: `${showing === 3 ? 'bg-blue-90' : 'bg-gray-80'}`,
      text: 'Peers',
      enabled: true,
    },
  ]

  return (
    <>
      <div className="grid grid-cols-1 auto-rows-auto xl:grid-flow-col xl:gap-4 xl:grid-cols-4 xl:overflow-hidden">
        <div className="col-span-1 xl:col-span-3 xl:col-start-1 flex justify-between">
          <div>
            <h1 className={`text-2xl font-bold ${categoryColor.text}`}>
              {expedition.title}
            </h1>
            <div>
              <RenderedSlate
                value={[
                  expedition?.rte_description || [],
                  expedition?.tools_and_materials || [],
                  expedition?.overview || [],
                ].flat()}
              />
            </div>
            {expedition.sessions.length > 0 && (
              <h5 className="uppercase font-bold text-xs">
                <SessionsInfo sessions={expedition.sessions} />
                {abTestingPBSExpedition.includes(expedition_id) && <button className='bg-blue-90 mt-2 text-white text-1xl md:text-sm px-8 py-2 rounded-1xl text-center cursor-pointer' onClick={() => setShowReminder(true)}>Reminders</button>}
              </h5>
            )}
          </div>
        </div>

        <ul className="col-span-1 col-start-1 xl:col-start-3 flex mt-4 items-center justify-between xl:justify-self-end space-x-2">
          {tabsButtons
            .filter((b) => b.enabled)
            .map((el) => (
              <li
                onClick={el.onClick}
                className={`${el.classes} font-bold text-white text-1xl md:text-lg px-8 py-2 rounded-1xl text-center cursor-pointer`}
                key={el.id}
              >
                {el.text}
              </li>
            ))}
        </ul>

        {showing === 1 && (
          <>
            <div className="col-span-1 col-start-1 xl:col-span-3 xl:col-start-1 mt-8 xl:pr-4">
              {sessionWithPreWork.map((session, index) => {
                const dateNow = new Date(Date.now())
                const lastSession = sessionWithPreWork[index - 1]
                const isClosestDay =
                  new Date(session.timestamptz) > new Date() &&
                  new Date(lastSession?.timestamptz) < dateNow
                return (
                  <div key={session.id}>
                    {isClosestDay && (
                      <MyOtherSeparator myLabel="Past" otherLabel="Upcoming" />
                    )}
                    <ExpeditionRow
                      isClosestDay={isClosestDay}
                      session={session}
                      title={`Session ${index + 1}`}
                      timeStampTZ={session.timestamptz}
                      zoomLink={session.conference_url}
                      openSession={openSession}
                      setOpenSession={setOpenSession}
                    />
                  </div>
                )
              })}
            </div>

            <div className="col-span-1 col-start-1 xl:row-span-2 xl:row-start-2 xl:col-start-4 mt-5 xl:mt-0">
              <div className="flex flex-col items-end">
                {sessionWithPreWork.map((session, index) => {
                  return (
                    <SessionItem
                      title={`Session ${index + 1}`}
                      key={session.id}
                      student_id={student_id}
                      openSession={openSession}
                      setOpenSession={setOpenSession}
                      sessionId={session.id}
                      timeStampTZ={session.timestamptz}
                      attendableId={session.attendable_id}
                    />
                  )
                })}
              </div>
            </div>
          </>
        )}

        {showing === 2 && (
          <ul className="col-span-1 md:col-span-4 md:col-start-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-20">
            {allFiles.map((file, idx) => (
              <li className="flex flex-wrap mt-4" key={idx}>
                <ExpeditionFileCard
                  employee={expedition.employees[0]}
                  file={file}
                />
              </li>
            ))}
          </ul>
        )}

        {showing === 3 && (
          <div className="col-span-1 md:col-span-4 md:col-start-1">
            <div className="flex flex-wrap mt-4">
              {expedition.students.map((student) => (
                <ExpeditionPeerCard student={student} key={student.id} />
              ))}
            </div>
          </div>
        )}
        {abTestingPBSExpedition.includes(expedition_id) &&
          <PortalModal
            isOpen={showReminder}
            onClose={() => {
              window.localStorage.setItem(`exp-reminder-${expedition_id}`, false)
              setShowReminder(false)
            }}
          >
            <header className="border-b px-6 py-4 text-lg font-bold min-w-[30rem] pr-20">
              <h3>How to Maximize Pace towards Graduation</h3>
            </header>
            <section className="px-6 py-4 max-w-[30rem]">
              <ul className='list-disc px-4'>
                <li>Complete all your tasks on time. Late work may not be graded.</li>
                <li>Take responsibility for your submissions. Verify that your files have been uploaded to Sora Home. If your files do not upload, use backups like gmail or heartbeat.</li>
                <li>Make sure experts have permission to open your files on Google Drive.</li>
                <li>Make sure your files are compatible. Some experts may not be able to open “.pages” or “.keynote” files. Send more accessible file types like “.pdf”, “.doc”, “.docx”, “.txt”, or Google Docs.</li>
                <li>If you don’t understand something, use email and heartbeat. They’re available 24/7.</li>
                <li>Actively ask questions and proactively seek help.</li>
                <li>Show up on time with mics and cameras on for each session.</li>
                <li>Participate during sessions with voice and chat contributions. </li>
              </ul>
            </section>
          </PortalModal>
        }
      </div>
    </>
  )
}

export default ExpeditionHome
