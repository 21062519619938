import useSWR from 'swr'

const useTaskRelations = ({ task_id } = {}) => {
  const { data, error, mutate } = useSWR(
    task_id && `/tasks/${task_id}/relations`
  )

  return {
    taskRelations: data?.result || [],
    isError: error,
    mutate,
    isLoading: !data,
  }
}

export default useTaskRelations
