import React from 'react'
import PropTypes from 'prop-types'
import { SectionItem } from './section-item'

export const SectionList = ({ sections, cycleId, expertId }) => {
  return (
    sections.map(section => {
      return (
        <tr key={`expert_${expertId}_cycle_${cycleId}_section__${section.learning_block_id}_${section.template_id}`} className='bg-white dark:bg-black'>
          <SectionItem section={section} cycleId={cycleId} expertId={expertId} />
        </tr>
      )
    })
  )
}
SectionList.propTypes = {
  sections: PropTypes.array,
}
