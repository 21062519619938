import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@design-system'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'
import RemoveProcess from './remove-process'
import CloneProcess from './clone-process'
import { ProcessTitle } from './process-title'
import { VariantNote } from './variant-note'

export const ListProcess = ({ title, type, groupProcesses = [], emptyElement = null }) => {
  const navigate = useNavigateWithCycle()

  if (type === 'past') {
    return (
      <div className='flex flex-col'>
        <span className='py-4 text-xl font-bold'>{title}</span>
        <ul className='flex flex-col space-y-4'>
          {groupProcesses?.map(g => (
            <li key={g.group_id} className='bg-white rounded flex flex-col p-4 space-x-2'>
              <div className='flex flex-row items-center justify-between'>
                <ProcessTitle id={g.group_id} title={g.group_title} className='font-bold' />
                <Button variant='outlined' onClick={() => { navigate(`./${g.processes[0].id}/schedule/generated/students`) }}>Report</Button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  if (groupProcesses.length === 0) return emptyElement

  return (
    <div className='flex flex-col'>
      <span className='py-4 text-xl font-bold'>{title}</span>
      <ul className='flex flex-col space-y-4'>
        {groupProcesses?.map(g => (
          <li key={g.group_id} className='bg-white rounded flex flex-col p-4 space-x-2'>
            <ProcessTitle id={g.group_id} title={g.group_title} className='pb-2 border-b border-b-black' />
            <div>
              <ul className='flex flex-col'>
                {g.processes.map(process => (
                  <li key={process.id} className='flex flex-row justify-between items-center p-4 space-x-2'>
                    <div className='flex flex-row flex-auto items-center space-x-3'>
                      <span className='font-bold text-lg'>Variant #{process.variant_number}</span>
                      <span className='text-sm'>{(new Date(process.updated_at)).toLocaleDateString(['en-US'], { dateStyle: 'medium' })}</span>
                      <VariantNote processId={process.id} defaultValue={process.note} />
                    </div>
                    <div className='flex flex-row space-x-2'>
                      <RemoveProcess processId={process.id} />
                      {process.cloneableSteps.length >= 1 && <CloneProcess processId={process.id} cloneableSteps={process.cloneableSteps} />}
                      <Button variant='outlined' size='sm' onClick={() => { navigate(`./${process.id}`) }}>Continue</Button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
ListProcess.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  groupProcesses: PropTypes.array,
  emptyElement: PropTypes.element,
}
