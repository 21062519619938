import React from 'react'
import axios from 'axios'
import { useLoaderData, useRevalidator } from 'react-router-dom'
import format from 'date-fns/format'
import { Button } from '@designsystem'
import useTasks from '@hooks/useTasks'
import useToggle from '@hooks/useToggle'
import { useThisPageTitle } from '@hooks/usePageTitle'
import EditableTask from './editable-task'
import getBrowserTimezoneAbbreviation from '@utils/getTimezoneAbbreviation'

type LoaderData = Awaited<ReturnType<typeof loader>>

async function loader({ params }) {
  const { data } = await axios.get(`/pages/employee/expeditions/${params.experienceId}/tasks`)
  return data
}

export function NEW_ExpeditionTasksRoute() {
  const [isAdding, toggleAdding] = useToggle(false)
  const {
    theme: expedition,
    tasks,
    sessions,
    events,
    isPlaylabEnabled,
  } = useLoaderData() as LoaderData
  const { revalidate: mutate } = useRevalidator()

  useThisPageTitle(`${expedition.title || 'Expedition'} - Tasks`)
  const { create, update, remove } = useTasks()

  const minDate = new Date(expedition.start_timestamp)
  const maxDate = new Date(expedition.end_timestamp)
  const hasFinalTask = tasks.some(t => t.type === 'final')

  return (
    <div className="mb-10">
      <div className="flex flex-col space-y-3 mb-1">
        <div className='flex flex-row'>
          <div className="flex ml-auto gap-4">
            <Button onClick={toggleAdding} variant="outlined" color="neutral" size="sm">
              + Add Task
            </Button>
          </div>
        </div>
        {!hasFinalTask && <span className="text-lg text-danger-50 font-bold">Missing Final Task!</span>}
        {isAdding && (
          <EditableTask
            isPlaylabEnabled={isPlaylabEnabled}
            task={{
              expedition,
              title: 'Task Title',
              due_at: minDate,
              theme_id: expedition.id,
              theme_session_id: null,
              units: [],
              abilities_v2: [],
            }}
            sessions={sessions}
            minDate={minDate}
            maxDate={maxDate}
            isAdding={isAdding}
            onCreate={(data) => { toggleAdding(); create(data).finally(mutate) }}
            onUpdate={(data) => update(data).finally(mutate)}
            onRemove={(data) => remove(data).finally(mutate)}
            onCancel={toggleAdding}
          />
        )}
      </div>
      <div className="space-y-5 py-3">
        {events.map((e) => {
          const { type, data, t } = e
          return (
            <div key={`${type}:${data.id}:${data.updated_at}`} className={`flex flex-col px-3 border-l-[3px] ${type === 'task' ? 'border-l-turquoise-50' : 'border-l-blue-70'} min-h-20 justify-center space-y-1`}>
              <span className="text-gray-70 font-bold uppercase leading-none">
                {type === 'task' ? 'Task' : data.title}
              </span>
              {
                type === 'task'
                  ? <EditableTask
                    isPlaylabEnabled={isPlaylabEnabled}
                    task={{ ...data, expedition }}
                    sessions={sessions}
                    minDate={minDate}
                    maxDate={maxDate}
                    onUpdate={(data) => update(data).finally(mutate)}
                    onRemove={(data) => remove(data).finally(mutate)}
                  />
                  : <span className="italic">
                    {format(new Date(t), 'M/d/yy p')} ({getBrowserTimezoneAbbreviation()})
                  </span>
              }
            </div>
          )
        })}
      </div>
    </div>
  )
}

NEW_ExpeditionTasksRoute.loader = loader