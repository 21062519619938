import React, { useState } from "react"
import PropTypes from "prop-types"
import { useFetcher } from "react-router-dom"
import { Button, Combobox, Icon } from "@design-system"

export const AddSection = ({ id, studentId, cycleId }) => {
  const fetcher = useFetcher()
  const [canSubmit, setCanSubmit] = useState(false)
  const isLoading = fetcher.state !== 'idle'
  const sectionGroups = fetcher.data?.groups

  const handleCancel = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('_action', 'cancel')
    fetcher.submit(formData, { method: 'post' })
  }

  const handleChange = (event) => {
    event.preventDefault()
    setCanSubmit(event.target.form['section'].value.length > 0)
  }

  return (
    <td colSpan={7} className="px-2">
      <div className="relative">
        {(sectionGroups) &&	
          <div className='bg-white p-2 border drop-shadow absolute bottom-0 z-30'>
            <div className="flex flex-col gap-2">
              <div>
                <Button variant='ghost' size="xs" onClick={handleCancel}>
                  <Icon name="arrow-left" size='xs' />
                </Button>
              </div>
              <fetcher.Form method="post" className="flex flex-row space-x-2" onChange={handleChange}>
                <input type="hidden" name="student_id" value={studentId} />
                <input type="hidden" name="cycle_id" value={cycleId} />
                <input type="hidden" name="_action" value="add_section" />
                <Combobox name='section' placeholder='Select a section' loading={isLoading} search={<Combobox.Search placeholder="Search a template" />} className="w-[300px]" popoverAlign="start">
                  {sectionGroups.map(group => {
                    return (
                      <Combobox.Group key={`group_${group.id}`} heading={group.title}>
                        {group.sections.map(section => {
                          const requestedLabel = `${section.template_requested ? '⭐' : ''}`
                          const templateLabel = `${section.template_title} ${section.template_category ? `(${section.template_category})` : ''}`
                          const classSizeLabel = `(Class size: ${section.student_amount})`
                          const sectionValue = `${section.learning_block_id}_${section.expert_id}_${section.template_id}_${section.theme_id}`

                          return (
                            <Combobox.Item key={`opt_student_${studentId}_cycle_${cycleId}_section_${sectionValue}`} value={sectionValue} disabled={section.schedule_conflicted || !section.learning_block_available}>
                              {`${requestedLabel} ${templateLabel} - ${section.expert_name} - ${classSizeLabel}`}
                            </Combobox.Item>
                          )
                        })}
                      </Combobox.Group>
                    )
                  })}
                </Combobox>
                <Button type="submit" disabled={!canSubmit}>Add</Button>
              </fetcher.Form>
            </div>
          </div>
        }
        <fetcher.Form method="get" className="flex flex-col items-start py-2">
          <input type="hidden" name="student_id" value={studentId} />
          <input type="hidden" name="cycle_id" value={cycleId} />
          <input type="hidden" name="_action" value="get_sections" />
          <Button type="submit" variant="outlined" size="xs" disabled={isLoading} loading={isLoading}>
            Attach to a section
          </Button>
        </fetcher.Form>
      </div>
    </td>
  )
}
AddSection.propTypes = {
  id: PropTypes.number,
  studentId: PropTypes.number,
  cycleId: PropTypes.number,
}
