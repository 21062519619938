import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from '@design-system'

export const StudentTimeline = ({ columns, rows }) => {

  const subColumns = () => {
    const cols = []
    let idx = -1

    for (const c of columns) {
      idx++
      for (const sc of c.subCol) {
        cols.push({ key: `subCol_${sc}_${idx}`, title: c.subCol.length > 1 ? sc : '' })
      }
    }

    return cols
  }

  const unitStatusStyle = (status) => {
    if (!status) return ''
    switch (status) {
      case 'recovery':
        return 'bg-danger-10'
      case 'completed':
        return 'bg-green-10'
      case 'soon':
        return 'text-gray-60 bg-gray-30'
      case 'main':
        return 'bg-blue-10'
      default:
        return ''
    }
  }

  return (
    <div className='overflow-x-auto flex flex-col p-2 gap-2'>
      <div className='flex flex-row'>
        <table className='w-full'>
          <thead>
            <tr>
              <th rowSpan={2} className='p-2'>
                <div className='group relative'>
                  <div className='ring-0 cursor-help'>
                    <Button size='xs' variant='ghost' disabled={true}>
                      <span>Caption</span>
                      <Icon name='info' size='xs' />
                    </Button>
                  </div>
                  <table className='bg-white absolute hidden z-10 top-full left-0 group-hover:block shadow-xl border-2 border-alpha/20'>
                    <tbody className='border'>
                      <tr>
                        <td className={`p-3 border-solid border-4 border-blue-70`}>A</td>
                        <td className='px-2 border'>Studying/Scheduled to acquire</td>
                      </tr>
                      <tr>
                        <td className={`p-3 ${unitStatusStyle('completed')}`}>A</td>
                        <td className='px-2 border'>Completed</td>
                      </tr>
                      <tr>
                        <td className={`p-3 ${unitStatusStyle('recovery')}`}>A</td>
                        <td className='px-2 border'>Need to recover</td>
                      </tr>
                      <tr>
                        <td className={`p-3 ${unitStatusStyle('main')}`}>A</td>
                        <td className='px-2 border'>Main placement</td>
                      </tr>
                      <tr>
                        <td className={`p-3 ${unitStatusStyle('soon')}`}>A</td>
                        <td className='px-2 border'>Coming years</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </th>
              {columns.map((c, idx) => (
                <th key={`mainCol_${c.title}_${idx}`} colSpan={c.subCol.length} rowSpan={c.subCol.length > 1 ? 1 : 2} className='whitespace-nowrap p-2 border-black border-solid border'>{c.title}</th>
              ))}
            </tr>
            <tr>
              {subColumns().map(sc => {
                if (sc.title.length > 0) return (<th key={sc.key} className='p-2 text-xs border-black border-solid border'>{sc.title}</th>)
                return null
              })}
            </tr>
          </thead>
          <tbody>
            {rows.map(r => (
              <tr key={r.cycle_title} className='even:bg-gray-15'>
                <td className='whitespace-nowrap p-2 font-bold border-black border-solid border'>{`${r.cycle_title}`}</td>
                {r.units.map((u, idx) => <td key={`row_unit_${u?.title}_${idx}`} className={`p-2 text-sm border-solid  ${unitStatusStyle(u?.status)} ${u?.doing ? 'border-blue-70 border-4 ' : 'border border-black'}`}>{u?.title}</td>)}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='flex flex-row pt-10'>
        
      </div>
    </div>
  )
}
StudentTimeline.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
}
