const getTitleForSubmission = (status, temporal_status, submission_status) => {
  if (temporal_status === 'late') return 'Late'
  if (temporal_status === 'missed') return 'Missed'

  if (submission_status === 'reviewed') return 'Reviewed'
  if (submission_status === 'flagged') return 'Flagged'
  if (submission_status === 'submitted') return 'Submitted'

  return 'Pending'
}

export default getTitleForSubmission
