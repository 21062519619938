import React from "react"
import { useLoaderData } from "react-router-dom"
import axios from "axios"

const loader = async ({ params }) => {
  const { processId } = params
  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/schedule/generated/expeditions`)
  return result?.data
}

const Element = () => {
  const { templates } = useLoaderData()

  const mountSections = (templateId, item, studentIdx, isFirst = false) => {
    const isRedNotice = item.has_conflict ? 'bg-danger-10 text-danger-60' : 'bg-white'
    return (
      <React.Fragment key={`template_${templateId}_lb_${item.learning_block_id}_${item.employee_id}`}>
        <div className={`flex flex-col ${isRedNotice} p-3 ${isFirst ? 'mt-4' : ''}`}>{item.cycle_title || 'Unassigned'}&nbsp;</div>
        <div className={`flex flex-col ${isRedNotice} p-3 ${isFirst ? 'mt-4' : ''}`}>{item.learning_block_title || 'Unassigned'}&nbsp;</div>
        <div className={`flex flex-col ${isRedNotice} p-3 ${isFirst ? 'mt-4' : ''}`}>{item.employee_name || 'Unassigned'}&nbsp;</div>
        <div className={`flex flex-row items-start space-y-1 gap-3 ${isRedNotice} p-3 ${isFirst ? 'mt-4' : ''}`}>
          <div className='w-[50px] py-1 whitespace-nowrap'>Total: {item.student_qty}</div>
          <div className='flex flex-row flex-wrap gap-2'>
            {item.students.map(std => (
              <div key={std} className="py-1 px-2 h-fit text-xs rounded bg-gray-20 text-black">
                {std}
              </div>
            ))}
          </div>
          &nbsp;
        </div>
      </React.Fragment>
    )
  }

  const templateRow = (template, idx) => {
    return (
      <React.Fragment key={`student_${template.id}`}>
        <div className='flex flex-col bg-white rounded-l-xl mt-4 p-3' style={{ gridRow: `span ${template.data.length}` }}>
          <div className="flex flex-row gap-1">
            <span className='font-bold'>{template.title}</span>
          </div>
          <span>{template.category}</span>
        </div>
        {mountSections(template.id, template.data[0], idx, true)}
        {((template.data.length) > 1) && template.data.slice(1).map(x => mountSections(template.id, x, idx))}
      </React.Fragment>
    )
  }

  return (
    <div className='grid grid-cols-[300px_100px_200px_200px_1fr] auto-cols-max w-full'>
      <div className='font-bold text-lg p-3 sticky top-0 bg-gray-5 border-b border-gray-30'>Template</div>
      <div className='font-bold text-lg p-3 sticky top-0 bg-gray-5 border-b border-gray-30'>Cycle</div>
      <div className='font-bold text-lg p-3 sticky top-0 bg-gray-5 border-b border-gray-30'>Learning Block</div>
      <div className='font-bold text-lg p-3 sticky top-0 bg-gray-5 border-b border-gray-30'>Expert</div>
      <div className='font-bold text-lg p-3 sticky top-0 bg-gray-5 border-b border-gray-30'>Students</div>
      {templates.map(templateRow)}
    </div>
  )
}

export const JourneyBuilderScheduleGeneratedExpeditionsRoute = {
  loader,
  Element,
}
