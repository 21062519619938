/* eslint-disable react/prop-types */
import { Button } from '@designsystem'
import { faArrowRight, faRotateBack } from '@fortawesome/free-solid-svg-icons'
import { useEditor } from '@components/forms/short-slate-textarea'
import { useParams, useRevalidator, useLoaderData } from 'react-router-dom'
import { toast } from 'sonner'
import AiMidCycleReportDrawer from './ai-report/ai-mid-cycle-report-drawer'
import axios from 'axios'
import CommentThread from '@components/comment-thread'
import React, { useRef, useState } from 'react'
import useCurrentUser from '@hooks/useCurrentUser'

const MidCycleTab = ({ data, lastTypedMessage, setSetLastTypedMessage }) => {
  const commentRef = useRef()
  const currentUser = useCurrentUser()

  const loaderData = useLoaderData()
  const { task_submission_id, task_id, student_id } = useParams()
  const revalidator = useRevalidator()
  const mutate = revalidator.revalidate
  const isAssessed = data.task_submission?.assessment?.status === 'assessed'

  // Ai related
  const [aiInsert] = useState()
  const [open, setOpen] = useState(false)
  const editor = useEditor()

  const handleSave =
    (status = 'draft') =>
      async () => {
        try {
          await axios.post(`/assessments/task_submission/${task_submission_id}/mid_cycle`, { status })
          await commentRef.current.submitCommentsBeforePublish()
          toast.success(status === 'assessed' ? 'Submission assessed' : 'Submission saved')
          mutate()
        } catch (err) {
          toast.error('Assessment failed, please try again later!')
        }
      }

  const handleInsertFromAi = (slateMessage) => {
    editor.children = slateMessage
  }

  return (
    <div className="flex flex-col flex-auto py-6 gap-6">
      <CommentThread
        ref={commentRef}
        taskId={task_id}
        studentId={student_id}
        taskSubmissionId={task_submission_id}
        user={currentUser}
        className="border-t border-t-gray-30 border-b border-b-gray-30 py-3"
        placeholder={"Describe the student's progress so far"}
        hideSendButton={true}
        hideAiButton={!loaderData.taskSubmissionDetail.isAiMidCycleReportsEnabled}
        onClickAi={() => {
          setOpen(true)
        }}
        readOnly={isAssessed}
        collapsable={false}
        lastTypedMessage={lastTypedMessage}
        onChange={setSetLastTypedMessage}
        customEditor={editor}
      />

      <div className="flex flex-row justify-end">
        {!isAssessed && (
          <Button endIcon={faArrowRight} onClick={handleSave('assessed')}>
            Send
          </Button>
        )}
        {isAssessed && (
          <Button endIcon={faRotateBack} onClick={handleSave('draft')}>
            Un-publish
          </Button>
        )}
      </div>

      <div className="flex flex-col place-items-end">
        {loaderData.taskSubmissionDetail.isAiMidCycleReportsEnabled && (
          <AiMidCycleReportDrawer
            open={open}
            setOpen={setOpen}
            aiInsert={aiInsert}
            setAiInsert={handleInsertFromAi}
          ></AiMidCycleReportDrawer>
        )}
      </div>
    </div>
  )
}

export default MidCycleTab
