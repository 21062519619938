import PropTypes from 'prop-types'
import React from 'react'

const ExpeditionPeerCard = ({ student }) => {

  return (
    <div className="bg-white w-[31.25rem] h-24 xl:m-4 my-2 rounded-md shadow-sm p-4 relative">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img src={student.picture} className="w-9 h-9 rounded-full" />
          <h4 className="font-bold text-lg ml-4">{student.first_name} {student.last_name}</h4>
        </div>
      </div>
    </div>
  )
}

ExpeditionPeerCard.propTypes = {
  student: PropTypes.shape({
    first_name: PropTypes.any,
    last_name: PropTypes.any,
    picture: PropTypes.any
  })
}

export default ExpeditionPeerCard
