
import React from 'react'
import axios from 'axios'
import { Form, useActionData, useNavigation, useSubmit } from 'react-router-dom'
import { Button } from '@designsystem'
import useConfirmModal from '@hooks/useConfirmModal'
import { useToast } from '@hooks/useToast'

export async function action() {
  try {
    await axios.put('/pages/admin/workbench/google/student-groups-sync')
    return {
      toast: {
        message: 'The Sync has started. It may take a while, please wait...',
        appearance: 'success'
      }
    }
  } catch (error) {
    return {
      toast: {
        message: 'Something went wrong while syncing...',
        appearance: 'error'
      }
    }
  }
}

type ActionData = {
  toast: {
    message: string,
    appearance: 'success' | 'error'
  }
}

export default function WorkbenchGoogleSyncGroupsRoute() {
  const submit = useSubmit()
  const confirmAction = useConfirmModal()
  const navigation = useNavigation()
  useToast(useActionData() as ActionData)
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const confirmationMessage = {
      title: 'Confirm Sync', subtitle: 'Are you sure you want to sync the student groups?', children: <GoogleSyncConfirmationModalMessage />
    }
    if (await confirmAction(confirmationMessage)) {
      submit(formData, { method: 'PUT' })
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <h1 className="font-bold text-2xl">
        Sync Groups
      </h1>
      <span>Here, you can synchronize all cohort Google Groups. Ensure active students are added to their respective cohort Google Group if they aren't already. Update any active students placed in the wrong Google Group to their correct cohort. Lastly, remove any inactive students from their Google Group.</span>
      <span>Here are the current google groups: athena@soraschools.com, chronos@soraschools.com, nova@soraschools.com*.</span>
      <span className="italic font-bold text-sm">*This sync auto executes daily at 6PM UTC Time. Execute this in case you recently changed something and want to see the changes reflected immediately.</span>
      <Form method="PUT" onSubmit={handleSubmit}>
        <Button
          type="submit"
          loading={navigation.state === 'submitting'}
        >
          {navigation.state === 'submitting' ? 'Syncing...' : 'Sync Students with Cohort Google Groups'}
        </Button>
      </Form>
    </div>
  )
}


function GoogleSyncConfirmationModalMessage() {
  return (
    <>
      <span>This sync will:</span>
      <ul className="list-disc ml-4">
        <li>Add active students that are not currently in a Google Group to their cohort G. Group</li>
        <li>Update active students that in the incorrect Google Group to their correct cohort G. Group</li>
        <li>Remove inactive students from their Google Group</li>
        <br />
        <li>
          Google Groups to sync:
          <ul className="list-disc ml-4">
            <li>athena@soraschools.com</li>
            <li>chronos@soraschools.com</li>
            <li>nova@soraschools.com</li>
          </ul>
        </li>
      </ul>
    </>
  )
}