import { useEffect, useState } from 'react'

const useCountdown = (end) => {
  const [countdown, setCountdown] = useState({})

  useEffect(() => {
    if (!end)
      return
    const cycleEnd = new Date(end).getTime()
    const x = setInterval(() => {
      const distance = cycleEnd - Date.now()
      if (distance <= 0) {
        setCountdown({
          days: 0, hours: 0, minutes: 0, seconds: 0
        })
        clearInterval(x)
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24))
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((distance % (1000 * 60)) / 1000)
        setCountdown({
          days, hours, minutes, seconds
        })
      }
    }, 1000)
    return () => {
      clearInterval(x)
    }
  }, [end])

  return countdown
}

export default useCountdown
