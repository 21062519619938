import PropTypes from 'prop-types'
import React from 'react'
import formatDistance from 'date-fns/formatDistance'

const ExpeditionFileCard = ({ file, employee }) => {
  if (file.type === 'video') {
    return <div className="w-full flex flex-col items-center justify-center bg-white rounded-xl shadow-md p-4">
      <a href={file.url} download className="no-underline uppercase h-full w-full flex items-center justify-center">
        <h3 className="text-blue-80 font-bold text-xl">Video</h3>
      </a>
      <div className="w-full">
        <div className="aspect-w-16 aspect-h-9 clear-right">
          <iframe
            src={file.url}
            width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
          />
        </div>
        <div className="flex justify-between w-full mt-4">
          <span className="text-xs font-bold">{employee.first_name} {employee.last_name}</span>
        </div>
      </div>
    </div>
  }

  if (file.type === 'image') {
    return <div className="w-full flex flex-col items-center justify-center bg-white rounded-xl shadow-md p-4">
      <a href={file.url} download className="no-underline uppercase h-full w-full flex items-center justify-center">
        <h3 className="text-blue-80 font-bold text-xl">{file.filename?.split('.')[1]}</h3>
      </a>
      <div className="w-full">
        <div className="aspect-w-16 aspect-h-9 clear-right">
          <img
            src={file.url}
            className='block w-full rounded'
          />
        </div>
        <div className="flex justify-between w-full mt-4">
          <span className="text-xs font-bold">{employee.first_name} {employee.last_name}</span>
        </div>
      </div>
    </div>
  }

  return (
    <div className="w-full flex flex-col items-center justify-center bg-white rounded-xl shadow-md p-4">
      <a href={file.url} download className="block no-underline uppercase flex items-center justify-center mb-4">
        <div className="text-blue-80 font-bold text-xl h-52 w-32 flex items-center justify-center border">{file.filename?.split('.')[1]}</div>
      </a>
      <a href={file.url} download className="text-xs font-bold m-auto break-words">{file.filename?.split('.')[0]}</a>
      <div className="flex justify-between w-full mt-4">
        <span className="text-xs font-bold">{employee.first_name} {employee.last_name}</span>
        {file.date && <span className="text-xs">{formatDistance(Date.now(), new Date(file.date))}</span>}
      </div>
    </div>
  )
}

ExpeditionFileCard.propTypes = {
  employee: PropTypes.shape({
    first_name: PropTypes.any,
    last_name: PropTypes.any
  }),
  file: PropTypes.shape({
    date: PropTypes.any,
    type: PropTypes.string,
    filename: PropTypes.string,
    url: PropTypes.any
  })
}

export default ExpeditionFileCard
