import React from 'react'
import PropTypes from 'prop-types'
import { StudentTags } from './student-tags'
import { EditableLearningBlock } from './editable-learning-block'

export const SectionItem = ({ section, cycleId, templateId }) => {
  if (cycleId) {
    return (
      <React.Fragment key={`template_${templateId}_cycle_${cycleId}_${section.learning_block_id}_${section.employee_id}`}>
        <td className={`text-nowrap p-2 pl-0 align-top ${section.has_conflict ? 'bg-warning-5' : ''}`}>
          <EditableLearningBlock expertId={section.employee_id} cycleId={cycleId} learningBlockId={section.learning_block_id} title={section.learning_block_title} readOnly={section.scheduled} />
        </td>
        <td className={`p-2 pl-0 align-top ${section.has_conflict ? 'bg-warning-5' : ''}`}>
          <span>{section.employee_name}</span>
        </td>
        <td className={`p-2 pl-0 align-top ${section.has_conflict ? 'bg-warning-5' : ''}`}>
          {section.scheduled && <span className='px-2 py-1 bg-blue-10 text-blue-60 font-bold text-xs rounded'>Scheduled</span>}
          {!section.scheduled && <span className='px-2 py-1 bg-pink-10 text-pink-60 font-bold text-xs rounded'>New Section</span>}
        </td>
        <td className={`pl-0 align-top ${section.has_conflict ? 'bg-warning-5' : ''}`}>
          <StudentTags students={section.students} />
        </td>
      </React.Fragment>
    )
  }

  return (
    <>
      <td className='pl-0 align-top bg-danger-10'>
        <StudentTags students={section.students} />
      </td>
    </>
  )
}
SectionItem.propTypes = {
  section: PropTypes.object,
  cycleId: PropTypes.number,
  templateId: PropTypes.number,
}
