import React, { useEffect } from 'react'
import { Outlet, useParams, useOutletContext, useLoaderData } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import RedDot from '@components/red-dot'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'
import axios from 'axios'

const FacilitateExperiencesType = () => {
  const navigate = useNavigateWithCycle()
  const { id } = useParams()
  const [query] = useOutletContext()

  const data = useLoaderData()

  useEffect(() => {
    if (id) document.getElementById(`exp_${id}`)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [id])

  const handleSelectExperience = (selectedId) => () => {
    navigate(Number(selectedId) !== Number(id) ? String(selectedId) : '/employee/facilitate/experiences/expedition')
  }
  const handleAttendance = (selectedId) => (e) => {
    e.stopPropagation()
    navigate(`/employee/expeditions/${selectedId}/attendances`)
  }

  const filteredExperiences = data?.experiences.filter(xp => {
    return query.trim().length === 0
      || query.length < 3
      || (query.length > 2 && xp.keywords?.includes(query.toLowerCase()))
  }) || []

  const experiences = filteredExperiences.map((xp) => {
    const selected = Number(id) === Number(xp.id)
    return (
      <li
        id={`exp_${xp.id}`}
        key={`facilitate_exp_${xp.id}`}
        className={`flex flex-col w-full border-b border-b-gray-40 first:rounded-t-lg last:border-none ${selected ? 'bg-gray-10' : ''}`}
      >
        <div className={`flex flex-row justify-between items-center rounded hover:bg-gray-10 py-3 px-2 ${selected ? 'font-bold' : ''}`}>
          <div className='flex flex-row items-center'>
            <RedDot show={xp.pending} />
            <p className="hover:font-bold hover:cursor-pointer" onClick={handleSelectExperience(xp.id)}>{xp.title}</p>
          </div>
          <div className='px-2'>
            {xp.has_sessions &&
              <button className='px-2 py-1 mr-4 hover:underline' onClick={handleAttendance(xp.id)}>
                Attendance
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='ml-2' />
              </button>
            }
            <button className="hover:bg-gray-30 py-1 px-2 rounded-full" onClick={handleSelectExperience(xp.id)}><FontAwesomeIcon icon={selected ? faAngleUp : faAngleDown} /></button>
          </div>
        </div>
        {selected &&
          <div className='p-2'>
            <Outlet />
          </div>
        }
      </li>
    )
  })

  return (
    <div className='py-2'>
      {filteredExperiences.length !== 0 && data && <ul className='w-full flex flex-col min-h-43'>{experiences}</ul>}
      {filteredExperiences.length === 0 && data && <div className='w-full text-center text-gray-40 py-20'>No record found</div>}
      {!data && <div className='w-full text-center text-gray-40 py-20'><div>Loading</div></div>}
    </div>
  )
}

export default FacilitateExperiencesType

export async function loader({ request, params }) {
  const url = new URL(request.url)
  const cycle_id = url.searchParams.get("cycle_id")
  const type = params.type
  const { data } = await axios(`/pages/employee/facilitate/experiences/${type}?cycle_id=${cycle_id}`)
  return data
}
