import React, { ForwardedRef, forwardRef } from 'react'
import { unstable_Dialog as Dialog, Button, Icon, IconProps } from '@design-system'
import { SoraNavLink } from '@components/link'

export interface NavButtonProps {
  title: string
  path: string
  icon: IconProps['name']
  iconActive: IconProps['name']
  disabled?: boolean
}

export const NavButtonAside = forwardRef<HTMLButtonElement, NavButtonProps>(
  ({ title, path, icon, iconActive, ...props }: NavButtonProps, ref: ForwardedRef<HTMLButtonElement>) => (
    <li className="my-0.5">
      <Button asChild ref={ref} variant="ghost" className="!ring-0 gap-3 w-full justify-start" {...props}>
        <SoraNavLink to={path} className="group font-normal aria-[current=page]:font-bold">
          <Icon data-testid="icon" name={icon} className="block group-aria-[current=page]:hidden" />
          <Icon data-testid="iconActive" name={iconActive} className="hidden group-aria-[current=page]:block" />
          <span data-testid="label" className='block sm:hidden xl:block tracking-normal'>{title}</span>
          <Tooltip title={title} />
        </SoraNavLink>
      </Button>
    </li>
  )
)

export const NavButtonDrawer = forwardRef<HTMLButtonElement, NavButtonProps>(
  ({ title, path, icon, iconActive, ...props }: NavButtonProps, ref: ForwardedRef<HTMLButtonElement>) => (
    <li className="my-0.5">
      <Dialog.Close asChild>
        <Button asChild ref={ref} variant="ghost" className="!ring-0 gap-3 w-full justify-start" {...props}>
          <SoraNavLink to={path} className="group font-normal aria-[current=page]:font-bold">
            <Icon data-testid="icon" name={icon} className="block group-aria-[current=page]:hidden" />
            <Icon data-testid="iconActive" name={iconActive} className="hidden group-aria-[current=page]:block" />
            <span data-testid="label" className='block tracking-normal'>{title}</span>
          </SoraNavLink>
        </Button>
      </Dialog.Close>
    </li>
  )
)

const Tooltip = ({ title }) => (
  <div role="tooltip" className="hidden sm:inline-block xl:hidden group-hover:visible group-hover:opacity-100 absolute left-full z-10 invisible px-3 py-2 text-sm font-medium text-white bg-gray-100 border border-gray-90 rounded-xl shadow-sm opacity-0 dark:bg-gray-70">
    {title}
    <div className="h-0 w-0 border-x-[7px] border-x-transparent border-b-[7px] border-b-gray-100 absolute -mt-0.5 -mr-1 right-full top-1/2 -rotate-90"></div>
  </div>
)
