import React from 'react'
import { useNavigation, useSearchParams } from 'react-router-dom'
import { Tab } from '@headlessui/react'
import SoraLink from '@components/link'
import { Spinner } from '@design-system'

const SchoolStageFilter = () => {
  const navigation = useNavigation()
  const [searchParams, setSearchParams] = useSearchParams()

  const currentSchoolStage = searchParams.get('school_stage') || 'hs'
  const isLoading = navigation.state !== 'idle'

  const handleChange = (stage) => (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (currentSchoolStage === stage) return
    searchParams.delete('school_stage')
    searchParams.set('school_stage', stage)
    setSearchParams(searchParams)
  }

  const tabs = [
    { label: 'High School', value: 'hs' },
    { label: 'Middle School', value: 'ms' },
  ]

  return (
    <div className="flex flex-row">
      <Tab.Group selectedIndex={currentSchoolStage === 'hs' ? 0 : 1}>
        <Tab.List className='flex-auto space-x-4'>
          {tabs.map(t => (
            <Tab
              key={t.label}
              className={({ selected }) =>
                `pt-8 pb-3 focus:outline-none whitespace-nowrap ${selected
                  ? 'font-bold border-b-2 border-b-black-300'
                  : 'truncate ...'
                }`
              }
            >
              <SoraLink onClick={handleChange(t.value)} className='flex flex-row px-2 gap-2 items-center'>
                {(isLoading && currentSchoolStage === t.value) && <Spinner size='xs' />}
                <span>{t.label}</span>
              </SoraLink>
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  )
}

export default SchoolStageFilter
