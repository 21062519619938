import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { redirect } from 'react-router'

export async function loader({ request }) {
  const searchParams = new URL(request.url).searchParams
  const path = searchParams.get('path')
  const cycle_id = searchParams.get('cycle_id')
  const theme = searchParams.get('theme')
  const nonNullParams = Object.fromEntries(Object.entries({ theme, cycle_id }).filter(([_, v]) => v !== null && v !== undefined))
  const downSearchParams = new URLSearchParams(nonNullParams)
  return redirect(`/flutter${path}?${downSearchParams.toString()}`)
}

export default function IndexRoute() {
  return (
    <div
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center"
    >
      <FontAwesomeIcon icon={faSpinner} spin className="text-black dark:text-white text-3xl" />
    </div>
  )
}
