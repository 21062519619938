import PropTypes from 'prop-types'
import React from 'react'
import AiReportDrawer from '../ai-report-drawer'

const AiMidCycleReportDrawer = ({ open, setOpen, aiInsert, setAiInsert }) => {
  return (
    <AiReportDrawer
      open={open}
      setOpen={setOpen}
      aiInsert={aiInsert}
      setAiInsert={setAiInsert}
    ></AiReportDrawer>
  )
}

AiMidCycleReportDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  aiInsert: PropTypes.string,
  setAiInsert: PropTypes.func,
}

export default AiMidCycleReportDrawer
