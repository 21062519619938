/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react'
import { useParams, useRevalidator } from 'react-router-dom'
import useSWR from 'swr'
import axios from 'axios'
import { toast } from 'sonner'
import TaskSubmissionStatus from './feedback/task-submission-status'
import UnitsAbilitiesFeedback from './feedback/units-abilities-feedback'
import Feedback from './feedback/feedback'
import CommentThread from '@components/comment-thread'
import useCurrentUser from '@hooks/useCurrentUser'
import useConfirmModal from '@hooks/useConfirmModal'
import PreSetCommentDrawer from '@components/preset-comment/drawer'
import { faArrowRight, faRefresh, faRotateBack } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@designsystem'

const TaskFeedbackTab = ({ data, showStudents, lastTypedMessage, setSetLastTypedMessage, contextData, setContextData }) => {
  const commentRef = useRef()
  const abilityUnitRef = useRef(null)
  const drawerPresetCommentRef = useRef(null)
  const currentUser = useCurrentUser()
  const confirm = useConfirmModal()
  const { task_submission_id, task_id, student_id, theme_id } = useParams()
  const [errorOnLastSubmission, setErrorOnLastSubmission] = useState(null)
  const revalidator = useRevalidator()
  const mutate = revalidator.revalidate

  const [submissionStatus, setSubmissionStatus] = useState(null)

  const { data: dataPresetComment, mutate: refreshPresetComment } = useSWR(task_id ? `/preset-comments/task/${task_id}` : null)

  const { task_submission } = data

  const isAssessed = task_submission?.assessment?.status === 'assessed'
  const taskType = task_submission?.task_type

  const setUnits = (units) => {
    delete contextData.units
    setContextData({ ...contextData, ...{ units } })
  }

  const setAbilities = (abilities) => {
    delete contextData.abilities
    setContextData({ ...contextData, ...{ abilities } })
  }

  const handleSubmissionStatus = (option) => async () => {
    if (contextData.units?.length > 0 || contextData.abilities?.length > 0) {
      if (!await confirm('You have changes if you continue you will discard them, are you sure you want to do this?')) return
    }
    setUnits([])
    setAbilities([])
    setSubmissionStatus(option)
  }

  const handleUpdateItems = (type) => (items) => {
    switch (type) {
      case 'unit':
        setUnits(items)
        break
      case 'ability':
        setAbilities(items)
        break
    }
  }

  const handlePublish = async (assessmentType, payload) => {
    axios.post(`/assessments/task_submission/${task_submission_id}/${assessmentType}`, payload).then(() => {
      toast.success(isAssessed ? 'Submission assessed' : 'Submission saved')
      mutate()
      setErrorOnLastSubmission(false)
      if (isAssessed) abilityUnitRef?.current?.refreshComponent()
    }).catch((error) => {
      console.error(error)
      setErrorOnLastSubmission(true)
      toast.error('Assessment failed, please try again later!')
    })
  }

  const handleSave = (status = 'draft') => async () => {
    const isAssessed = status === 'assessed'
    const payload = { task_id, student_id, status, abilities: contextData.abilities, units: contextData.units, submission_status: !submissionStatus ? 'ontrack' : submissionStatus }
    const assessmentType = taskType === 'final' ? 'final' : 'feedback'

    if (taskType === 'final') {
      const missingItems = abilityUnitRef.current.getMissingItems(
        payload.units.map(u => u.id),
        payload.abilities.map(u => u.id)
      )
      payload.abilities = [...payload.abilities, ...missingItems.abilities]
      payload.units = [...payload.units, ...missingItems.units]
    }

    let confirmationText = 'When publishing it will no longer be possible to change,\nare you sure you want to publish right now?'

    if (isAssessed && assessmentType === 'feedback') {
      const pickedAbiltiesFeedbackMissing = contextData.abilities?.filter(a => !a.feedbacks || a.feedbacks?.filter(f => !f.deleted && Number(f.task_submission_id) === Number(task_submission_id)).length === 0) ?? []
      const pickedUnitsFeedbackMissing = contextData.units?.filter(u => !u.feedbacks || u.feedbacks?.filter(f => !f.deleted && Number(f.task_submission_id) === Number(task_submission_id)).length === 0) ?? []
      if (pickedAbiltiesFeedbackMissing.length > 0 || pickedUnitsFeedbackMissing.length > 0) {
        confirmationText = 'Looks like you haven\'t given feedback on the individual units & abilities tagged to this task! Students rely on this real time feedback to know how they\'re doing. It will also become a reference for you to assess mastery at the end of cycle.\n\nAre you sure you want to send this to the student without unit & ability feedback?'
      }
    }

    if (!isAssessed || await confirm(confirmationText) === true) {
      handlePublish(assessmentType, payload)
      await commentRef.current.submitCommentsBeforePublish()
    }
  }

  const handleSaveItem = (payload) => {
    payload.submission_status = 'ontrack'
    payload.student_id = student_id
    payload.task_id = task_id
    return axios.post(`/assessments/task_submission/${task_submission_id}/feedback`, payload)
  }

  const handleRemoveFeedbackItem = async (type, id) => {
    axios.delete(`assessments/${type}/feedback/${id}`).then(() => {
      if (type === 'ability') {
        const abilitiesUpdated = contextData.abilities.map(a => { a.feedbacks = a.feedbacks?.map(f => { if (f.comment_id === id) f.deleted = true; return f }); return a })
        setAbilities(abilitiesUpdated)
      }
      if (type === 'unit') {
        const unitsUpdated = contextData.units.map(u => { u.feedbacks = u.feedbacks?.map(f => { if (f.comment_id === id) f.deleted = true; return f }); return u })
        setUnits(unitsUpdated)
      }
      toast.success('Feedback removed')
    }).catch((error) => {
      setErrorOnLastSubmission(true)
      console.error(error)
      toast.error('Problem deleting feedback, please try again later!')
    })
  }

  const isFlagged = submissionStatus?.includes('flag')

  return (
    <div className='flex flex-col flex-auto py-6 gap-6'>
      <TaskSubmissionStatus
        taskSubmissionStatus={task_submission?.status}
        temporal_status={task_submission?.temporal_status}
        submission_status={task_submission?.submission_status}
        submissionStatus={submissionStatus}
        onChangeStatus={handleSubmissionStatus}
        disabled={isAssessed}
      />
      <CommentThread
        ref={commentRef}
        taskId={task_id}
        studentId={student_id}
        taskSubmissionId={task_submission_id}
        user={currentUser}
        className='border-t border-t-gray-30 border-b border-b-gray-30 py-3'
        placeholder={!isAssessed && isFlagged ? 'Reason for flagging' : null}
        hideSendButton={!isAssessed && isFlagged}
        lastTypedMessage={lastTypedMessage}
        onChange={setSetLastTypedMessage}
      />
      <Feedback submissionStatus={submissionStatus} assessment={task_submission?.assessment}>
        <Feedback.OnTrackStep>
          <PreSetCommentDrawer ref={drawerPresetCommentRef} list={dataPresetComment?.preset_comments} refreshList={refreshPresetComment} taskId={task_id} themeId={theme_id} />
          <UnitsAbilitiesFeedback
            ref={abilityUnitRef}
            apiUrl={`/task-submissions/${task_submission_id}/theme/${theme_id}/student/${student_id}/units_abilities${taskType === 'final' ? '/final' : ''}`}
            selectedUnits={contextData.units}
            selectedAbilities={contextData.abilities}
            onUpdateSelectedUnits={handleUpdateItems('unit')}
            onUpdateSelectedAbilities={handleUpdateItems('ability')}
            disabled={isAssessed}
            compressed={showStudents}
            saveItem={handleSaveItem}
            allowAddFeedback={true}
            presetComments={dataPresetComment?.preset_comments}
            onOpenPresetManager={drawerPresetCommentRef?.current?.open}
            refreshPresetComments={refreshPresetComment}
            taskType={taskType}
            taskSubmissionId={task_submission_id}
            onRemoveFeedbackItem={handleRemoveFeedbackItem}
          />
        </Feedback.OnTrackStep>
        <Feedback.Footer>
          {({ assessed }) => {
            return assessed
              ?
              (
                <>
                  <div className='flex-auto'></div>
                  {errorOnLastSubmission
                    ? <ErrorButton onClick={handleSave('draft')} />
                    : (
                      <Button endIcon={faRotateBack} onClick={handleSave('draft')}>
                        Un-publish
                      </Button>
                    )
                  }
                </>
              )
              :
              (
                <>
                  <div className='flex-auto'></div>
                  {errorOnLastSubmission
                    ? <ErrorButton onClick={handleSave('assessed')} />
                    : (
                      <Button endIcon={faArrowRight} onClick={handleSave('assessed')}>
                        Send
                      </Button>
                    )
                  }
                </>
              )
          }
          }
        </Feedback.Footer>
      </Feedback>
    </div >
  )
}

const ErrorButton = ({ onClick }) => {
  return (
    <Button color="danger" onClick={onClick} endIcon={faRefresh}>
      Retry
    </Button>
  )
}


export default TaskFeedbackTab
