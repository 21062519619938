import React from 'react'
import axios from 'axios'
import { useLoaderData, useParams } from 'react-router-dom'
import serializeFormData from '@utils/serializeFormData'
import { ExpeditionList } from './expedition-list'

const loader = async ({ params, request }) => {
  const { processId } = params
  const searchParams = new URL(request.url).searchParams
  const action =  searchParams.get('_action') || 'default'
  const assignmentId =  searchParams.get('assignment_id')

  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/assignments/expeditions`, { params: { action, assignment_id: assignmentId }})
  return result?.data
}

const action = async ({ params, request }) => {
  const { processId } = params
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const action = serializedFormData._action

  try {
    await axios.post(`/backoffice/workbench/journey-builder/processes/${processId}/assignments/expeditions`, { ...serializedFormData, action })
    return null
  } catch (error) {
    return {
      toast: { message: 'There was an error running Journey Builder. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const { processId } = useParams()
  const { data, experts } = useLoaderData()

  return (
    <div>
      <table>
        <thead className='sticky top-0 bg-screen-primary z-50'>
          <tr className='text-left'>
            <th className='px-1 w-[500px]'>Templates</th>
            <th></th>
            <th className='px-1'>Experts</th>
            <th className='px-1'>Allowed Cycles</th>
            <th className='px-1'>Request Hits</th>
            <th className='px-1'>Units</th>
            <th className='px-1'>Students</th>
          </tr>
          <tr>
            <th colSpan={7} className='h-px bg-alpha/5' />
          </tr>
        </thead>
        <tbody>
          <ExpeditionList processId={Number(processId)} templates={data} experts={experts} />
        </tbody>
      </table>
    </div>
  )
}

export const JourneyBuilderAssignmentsExpeditionsRoute = {
  loader,
  action,
  Element,
}
