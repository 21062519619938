import React from 'react'
import { useParams, useNavigate, useLoaderData } from 'react-router-dom'
import MetricButton from '@components/facilitate/metrics/metric-button'
import TaskList from '@components/facilitate/metrics/task-list'
import { Labels } from '@components/facilitate/metrics/constants'
import axios from 'axios'

export async function loader({ params }) {
  const { data } = await axios(`/pages/employee/facilitate/assessment/${params.theme_id}/task/${params.task_id}/${params.student_id}/expedition/metrics`)
  return data
}

const ExpeditionTabMetrics = () => {
  const { theme_id, student_id } = useParams()
  const navigate = useNavigate()
  const data = useLoaderData()
  const openTask = taskId => () => navigate(`/employee/facilitate/assessment/${theme_id}/task/${taskId}/${student_id}`)
  return (
    <div className="flex flex-col gap-8">
      {data === undefined && (
        <p>Loading...</p>
      )}
      {data && Object.entries(data).map(([status, tasks]) => (
        <section className="flex flex-col gap-3" key={status}>
          <MetricButton label={Labels[status]} value={tasks.length} direction="horizontal" active disabled positiveValue={tasks.length === 0}/>        
          <TaskList status={status} items={tasks} onClick={openTask} />
        </section>
      ))}
    </div>
  )
}

export default ExpeditionTabMetrics
