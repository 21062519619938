import React from "react"
import { redirect, useActionData, useLoaderData } from "react-router-dom"
import axios from "axios"
import serializeFormData from "@utils/serializeFormData"
import { useToast } from "@hooks/useToast"
import { ExpertSectionList } from "./expert-list"

const loader = async ({ params, request }) => {
  const { processId } = params
  const searchParams = new URL(request.url).searchParams
  
  const action = searchParams.get('_action') || 'default'
  const expertId = searchParams.get('expert_id')
  const cycleId = searchParams.get('cycle_id')
  const templateId = searchParams.get('template_id')

  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/schedule/review/experts`, { params: { action, expert_id: expertId, cycle_id: cycleId, template_id: templateId } })
  return result?.data
}

const action = async ({ params, request }) => {
  const { processId } = params
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const action = formData.get('_action')

  if (action === 'cancel') return null

  try {
    const { data } = await axios.post(`/backoffice/workbench/journey-builder/processes/${processId}/schedule/review/experts`, { ...serializedFormData, action })
    return (action === 'generate') ? redirect('../../generated/students') : data
  } catch (error) {
    return {
      toast: { message: 'There was an error running Journey Builder. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const { experts } = useLoaderData()
  useToast(useActionData())

  return (
    <table className="w-full">
      <thead className='top-0 bg-screen-primary z-50 text-left sticky'>
        <tr>
          <th>Expert</th>
          <th>Cycle</th>
          <th className="w-[45px]"></th>
          <th className="px-2">Learning Block</th>
          <th>Template</th>
          <th className="w-[250px]">Units</th>
          <th className="w-[250px]">Students</th>
        </tr>
        <tr>
          <th colSpan={7} className="h-px bg-alpha/5" />
        </tr>
      </thead>
      <tbody>
        <ExpertSectionList experts={experts} />
      </tbody>
    </table>
  )
}

export const JourneyBuilderScheduleReviewExpertsRoute = {
  action,
  loader,
  Element,
}
