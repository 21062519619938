import * as React from 'react'
import axios from 'axios'
import { Await, defer, Outlet, useAsyncValue, useLoaderData, useParams } from 'react-router-dom'
import EmptyStateIllustration from './illustration.svg'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'
import { Skeleton, Pill, unstable_Accordion as Accordion, Typography, EmptyState } from '@design-system'

interface LoaderData {
  experiences: Array<{
    id: number,
    title: string,
    imageUrl: string,
    imageAlt: string,
    time: string,
    tasksToBeAssessed?: number,
  }>
}

async function fetchData(url: string) {
  const res = await axios.get(url)
  return res.data
}

async function loader({ request, params }) {
  const url = new URL(request.url)
  const cycle_id = url.searchParams.get('cycle_id')
  const data = fetchData(`/backoffice/facilitate/${params.type}?cycle_id=${cycle_id}`)
  return defer({ data })
}

function Element() {
  const loaderData = useLoaderData() as { data: LoaderData }

  return (
    <React.Suspense fallback={<ExperiencesSkeleton />}>
      <Await resolve={loaderData.data}>
        <ExperiencesSection />
      </Await>
    </React.Suspense>
  )
}

function ExperiencesSection() {
  const params = useParams()
  const navigate = useNavigateWithCycle()
  const loaderData = useAsyncValue() as LoaderData

  const handleNavigate = (id: number) => {
    const destination = params?.experienceId && params?.experienceId === id.toString() ? '.' : id.toString()
    navigate(destination)
  }

  if (!loaderData.experiences.length) {
    return <FacilitateEmptyState variant={params.type === 'expeditions-learning-goals' ? 'expeditions-and-learning-goals' : 'activities-and-ises'} />
  }

  return (
    <Accordion type="single" collapsible defaultValue={params?.experienceId} className="gap-4 flex flex-col">
      {loaderData.experiences.map((experience) => (
        <Accordion.Item key={experience.id} value={String(experience.id)}>
          <Accordion.Trigger onClick={() => handleNavigate(experience.id)}>
            <div className="grid grid-cols-1 w-full">
              <div className="flex justify-between items-center">
                <div className="flex gap-4 items-center">
                  <figure className="h-28 w-32 flex-shrink-0">
                    <img className="w-32 h-28 rounded-sm aspect-square object-cover" src={experience.imageUrl} alt={experience.imageAlt} />
                  </figure>
                  <div className="flex flex-col gap-1 items-start mr-3">
                    <Typography variant="heading-6" weight="bold" className="text-start line-clamp-1 overflow-hidden text-ellipsis">{experience.title}</Typography>
                    <Typography variant="body">{experience.time}</Typography>
                  </div>
                </div>
                {experience.tasksToBeAssessed ? (
                  <div className="flex flex-col gap-1 justify-self-end mr-8">
                    <Pill color="blue-40">
                      <Pill.Value>{experience.tasksToBeAssessed} tasks</Pill.Value>
                    </Pill>
                    <Typography variant="footnote" weight="bold" className="whitespace-nowrap">to be assessed</Typography>
                  </div>
                ) : null}
              </div>
            </div>
          </Accordion.Trigger>
          <Accordion.Content>
            <Outlet />
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion>
  )

}

interface FacilitateEmptyStateProps {
  variant: 'expeditions-and-learning-goals' | 'activities-and-ises'
}

function FacilitateEmptyState({ variant }: FacilitateEmptyStateProps) {
  const { title, subtitle } = {
    'expeditions-and-learning-goals': {
      title: 'No expeditions or learning goals',
      subtitle: 'You are not facilitating any expeditions or learning goals in the selected cycle'
    },
    'activities-and-ises': {
      title: 'No activities or ISEs',
      subtitle: 'You are not facilitating any activities or ISEs in the selected cycle'
    }
  }[variant]
  
  return (
    <EmptyState>
      <EmptyState.Illustration>
        <img src={EmptyStateIllustration} alt="Hand drawn illustration of a puppet pointing to a rotatory globe" />
      </EmptyState.Illustration>
      <EmptyState.Title>{title}</EmptyState.Title>
      <EmptyState.Description>{subtitle}</EmptyState.Description>
    </EmptyState>
  )
}

function ExperiencesSkeleton() {
  return (
    <div className="flex flex-col gap-4">
      {Array.from({ length: 7 }, (_, j) => (
        <Skeleton key={`skeleton-${j}`} className="h-44" />
      ))}
    </div>
  )
}

export const FacilitateTypeRoute = { Element, loader }
