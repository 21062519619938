import * as React from 'react'
import axios from 'axios'
import {
  useLoaderData,
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useSearchParams,
  useSubmit,
  Form,
  useNavigate,
  useActionData,
  useNavigation,
} from 'react-router-dom'
import SoraLink from '@components/link'
import { useToast } from '@hooks/useToast'
import { StandardTaskBody } from './standard-task-body'
import { ActionData, LoaderData } from './types'
import { FinalTaskBody } from './final-task-body'
import { EmptyPerksSection } from './empty-perks-section'
import { Button, Combobox, Icon, Typography } from '@design-system'
import { useFormPersistence } from '@hooks/useFormPersistence'

async function loader({ params, request }: LoaderFunctionArgs) {
  const url = new URL(request.url)
  const searchParams = url.searchParams
  const localState = JSON.parse(window.localStorage.getItem(url.pathname))

  if (localState) {
    const localPerksIds = localState?.abilities.map((el) => `abilities_${el.id}`).concat(localState?.units.map((el) => `units_${el.id}`))
    const searchPerks = searchParams.getAll('perks[]')

    localPerksIds?.forEach((el) => {
      if (!searchPerks.includes(el)) {
        searchParams.append('perks[]', el)
      }
    })
  }

  const result = await axios.get(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}/feedback?${searchParams.toString()}`
  )
  if (result.status === 401) throw new Response('Not authorized', { status: 401 })

  const remoteState = result.data?.taskPerks

  if (localState) {
    for (const key of Object.keys(remoteState)) {
      const localPerks = localState[key]
      if (localPerks) {
        remoteState[key] = remoteState[key].map((el) => {
          const localPerk = localPerks.find((localPerk) => Number(localPerk.id) === el.id)
          if (localPerk) {
            return { ...el, ...localPerk, comment: localPerk.comment ? JSON.parse(localPerk.comment) : el.comment }
          }
          return el
        })
      }
    }
  }

  return result.data
}

async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData()
  const result = await axios.post(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}/feedback`,
    formData
  )
  return result.data
}

function Element() {
  return (
    <div className="flex flex-col min-w-72 w-[28.75rem] bg-white dark:bg-gray-90 border-x gap-4 py-4 px-6">
      <div className="flex flex-row justify-between items-center">
        <Typography variant="heading-5" weight="bold">
          Units and abilities feedback
        </Typography>
        <Button variant="outlined" color="soft" size="xs" asChild>
          <SoraLink to="..">
            <Icon name="cross" size="xs" />
          </SoraLink>
        </Button>
      </div>
      <FeedbackForm />
    </div>
  )
}

function FeedbackForm() {
  const { taskPerks, availablePerks, taskType, presetComments, shouldEnableFeedback } = useLoaderData() as LoaderData
  const submit = useSubmit()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const actionData = useActionData() as ActionData
  const navigation = useNavigation()
  const persistenceFormRef = useFormPersistence({ disableBlocker: true })

  useToast(actionData)

  React.useEffect(() => {
    if (actionData?.shouldCloseFeedbackPage) {
      navigate('..')
    }
  }, [actionData?.shouldCloseFeedbackPage])

  const taskUnits = taskPerks.units
  const taskAbilities = taskPerks.abilities
  const defaultPerks = [
    ...new Set([
      ...searchParams.getAll('perks[]'),
      ...taskUnits.map((el) => `${el.type}_${el.value}`),
      ...taskAbilities.map((el) => `${el.type}_${el.value}`),
    ]),
  ]

  return (
    <>
      <div className="flex flex-col flex-1 overflow-auto">
        <Form method="get" onChange={(e) => submit(e.currentTarget)}>
          <Combobox
            name="perks[]"
            multiple
            placeholder="Select extra units and abilities"
            emptyText="We have not found units/abilities that match your search"
            search={<Combobox.Search placeholder="Type here to search for units and abilities..." />}
            defaultChecked
            defaultValue={defaultPerks}
          >
            {availablePerks.map((topic) => {
              return (
                <Combobox.Group heading={`${topic.topicTitle}`}>
                  {topic.data.map((el, index) => {
                    return (
                      <Combobox.Item key={index} value={`${el.type}_${el.value}`}>
                        {el.label}
                      </Combobox.Item>
                    )
                  })}
                </Combobox.Group>
              )
            })}
          </Combobox>
        </Form>
        {taskUnits.length + taskAbilities.length === 0 && <EmptyPerksSection />}
        <Form ref={persistenceFormRef} method="POST" id="unitsAndAbilitiesFeedbackForm" className="pt-4">
          {taskType === 'final' && <FinalTaskBody taskUnits={taskUnits} taskAbilities={taskAbilities} />}
          {taskType === 'default' && (
            <StandardTaskBody taskUnits={taskUnits} taskAbilities={taskAbilities} presetComments={presetComments} />
          )}
        </Form>
      </div>
      <Button
        size="lg"
        type="submit"
        name="_action"
        form="unitsAndAbilitiesFeedbackForm"
        value={taskType === 'final' ? 'submit_final_feedback' : 'submit_feedback'}
        loading={navigation.state !== 'idle'}
        disabled={!shouldEnableFeedback}
      >
        Submit feedback
      </Button>
    </>
  )
}

export const FacilitateExperienceTaskStudentFeedbackRoute = {
  loader,
  action,
  Element,
}
