import React, { useRef, useState } from 'react'
import { useOutletContext, useParams, useNavigate, Outlet, useLoaderData } from 'react-router-dom'
import axios from 'axios'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@designsystem'
import MetricsList from '@components/facilitate/metrics/metrics-list'
import UnitsAbilitiesFeedback from '@components/facilitate/feedback/units-abilities-feedback'

const ExpeditionTab = () => {
  const abilityUnitRef = useRef(null)
  const { theme_id, student_id, metric } = useParams()
  const { showStudents } = useOutletContext()
  const loaderData = useLoaderData()
  const dataMetrics = loaderData.dataMetrics
  const navigate = useNavigate()

  const [units, setUnits] = useState([])
  const [abilities, setAbilities] = useState([])

  const handleUpdateItems = (type) => (items) => {
    switch (type) {
      case 'unit':
        setUnits(items)
        break
      case 'ability':
        setAbilities(items)
        break
    }
  }

  const handleSaveItem = (payload) => {
    payload.student_id = student_id
    return axios.post(`/experiences/${theme_id}/student/${student_id}/feedback`, payload)
  }

  const noDataHandle = () => {
    return (
      <div className='flex-auto flex flex-col justify-center items-center text-center '>
        <span className='text-gray-40 border-t border-b border-t-gray-30 border-b-gray-30 py-4'>There's no units and abilities tagged in this expedition</span>
      </div>
    )
  }

  const handleBackExpeditionProgress = () => navigate(-1)

  const handleOpenTaskList = () => _ => navigate(`metrics/all`)

  const amountMissed = dataMetrics?.metrics?.new_total_missed
  const tasksOnTimeDivision = `${dataMetrics?.metrics?.total_ontime || 0}/${dataMetrics?.metrics?.total_tasks || 0}`

  return (
    <div className='flex flex-col flex-auto py-6 gap-6'>
      {!dataMetrics && <span>Loading metrics...</span>}
      {metric ? (
        <div>
          <Button variant="nude" size="sm" startIcon={faChevronLeft} onClick={handleBackExpeditionProgress}>
            Back Expedition Progress
          </Button>
        </div>
      ) : (
        <>
          {dataMetrics && (
            <MetricsList
              amountFlagged={dataMetrics.metrics?.total_flagged}
              amountMissed={amountMissed}
              amountOntime={tasksOnTimeDivision}
              percentAttendance={dataMetrics.metrics?.percentage_attendance}
              onClick={handleOpenTaskList}
              vertical
            />
          )}
          <UnitsAbilitiesFeedback
            ref={abilityUnitRef}
            apiUrl={`/experiences/${theme_id}/student/${student_id}/units_abilities`}
            selectedUnits={units}
            selectedAbilities={abilities}
            onUpdateSelectedUnits={handleUpdateItems('unit')}
            onUpdateSelectedAbilities={handleUpdateItems('ability')}
            disabled={false}
            compressed={showStudents}
            saveItem={handleSaveItem}
            noDataMessage={noDataHandle()}
          />
        </>
      )}
      <Outlet />
    </div>
  )
}

export default ExpeditionTab
