import serializeFormData from '@utils/serializeFormData'
import axios from 'axios'

const action = async ({ request }) => {
  const formData = await request.formData()
  const formType = formData.get('formType')

  if (formType === 'rejectRequest') {
    const { swapRequestId, rejectReason } = serializeFormData(formData)
    const { data } = await axios.post(`/pages/employee/expeditions/swap-requests/${swapRequestId}/reject`, { rejectReason })
    return data
  }

  if (formType === 'approveRequest') {
    const { swapRequestId } = serializeFormData(formData)
    const { data } = await axios.post(`/pages/employee/expeditions/swap-requests/${swapRequestId}/approve`)
    return data
  }
}

export default action
