import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useFetcher } from 'react-router-dom'
import { Button, Icon } from '@design-system'

export const CyclesTemplate = ({ id, selectedCycles = [] }) => {
  const fetcher = useFetcher()
  const [availableCycles, setAvailableCycles] = useState(undefined)
  const isLoading = fetcher.state !== 'idle' && Number(fetcher.formData?.get('journey_step_assignment_students_id')) === Number(id)

  const cycleColors = {
    "Cycle 1" : 'border-orange-50 text-orange-50',
    "Cycle 2" : 'border-cyan-50 text-cyan-50', 
    "Cycle 3" : 'border-green-50 text-green-50', 
    "Cycle 4" : 'border-blue-50 text-blue-50', 
    "Cycle 5" : 'border-yellow-50 text-yellow-50', 
    "Cycle 6" : 'border-pink-50 text-pink-50'
  }

  useEffect(() => {
    setAvailableCycles(fetcher.data?.cycles)
  }, [fetcher.data])

  const handleAddCycle = async (event) => {
    if (isNaN(event.target.value)) return false
    fetcher.submit(event.target.form)
  }

  const handleCancel = () => {
    delete fetcher.data?.cycles
    setAvailableCycles(undefined)
  }

  return (
    <div className='relative'>
      {availableCycles &&
        <ul className='flex flex-col items-start space-y-1 border p-1 absolute bg-white z-40 right-0 drop-shadow'>
          <li>
            <Button variant='ghost' size="xs" onClick={handleCancel}>
              <Icon name="arrow-left" size='xs' />
            </Button>
          </li>
          {availableCycles.length > 0 &&
            <li className='w-full'>
              <fetcher.Form method="post" className="flex flex-row gap-2 items-center">
                <input type="hidden" name="journey_step_assignment_students_id" value={id} />
                <input type="hidden" name="_action" value="add_student_cycle_template" />
                <select name='cycle_id' className='m-0 rounded border border-gray-200 text-xs' disabled={isLoading} onChange={handleAddCycle}>
                  <option value=''>Select a cycle</option>
                  {availableCycles.map(cycle => (
                    <option key={`cycle_${cycle.id}`} value={cycle.id}>{cycle.title}</option>
                  ))}
                </select>
              </fetcher.Form>
            </li>
          }
          {(selectedCycles.length > 0) ? selectedCycles.map(cycle => (
            <li key={`cycle_${id}_${cycle.id}`} >
              <fetcher.Form method="post" className="flex flex-col justify-center">
                <input type="hidden" name="_action" value="remove_student_cycle_template" />
                <input type="hidden" name="journey_step_assignment_students_id" value={id} />
                <input type="hidden" name="cycle_id" value={cycle.id} />
                <Button type="submit" size="xs" variant='ghost' loading={isLoading}>
                  <span className={`flex flex-row gap-2 text-nowrap rounded text-xs font-bold ${cycleColors[cycle.title.substring(0,7)]}`}>
                    <Icon name="minus-rec" size='xs' />
                    {cycle.title}
                  </span>
                </Button>
              </fetcher.Form>
            </li>
          )) : 
          <li><Button size="xs" variant="outlined" disabled={true}>No Cycles</Button></li>}
        </ul>
      }
      <fetcher.Form method="get" >
        <input type="hidden" name="_action" value="get_available_cycles" />
        <input type="hidden" name="assignment_id" value={id} />
        <div className='flex flex-row gap-1 items-center'>
          <ul className='flex flex-col items-start space-y-1'>
            {selectedCycles.length > 0 && selectedCycles.map((cycle) => (
              <li key={`cycle_${id}_${cycle.id}`} >
                <span className={`py-1 px-2 text-nowrap rounded text-xs font-bold border ${cycleColors[cycle.title.substring(0,7)]}`}>{cycle.title}</span>
              </li>
            ))}
            {selectedCycles.length === 0 && 
              <li>
                <span className='py-1 px-2 text-nowrap rounded text-xs font-bold bg-gray-5'>No Cycles</span>
              </li>
            }
          </ul>
          <div>
            <Button type='submit' variant='ghost' size='xs'>
              <Icon name="edit-2" />
            </Button>
          </div>
        </div>
      </fetcher.Form>
    </div>
  )

  
}
CyclesTemplate.propTypes = {
  id: PropTypes.number,
  selectedCycles: PropTypes.array,
  availableCycles: PropTypes.array,
}
