import PropTypes from 'prop-types'
import React, { useState } from 'react'
import useToggle from '@hooks/useToggle'
import SelectUnits from '@components/forms/select-units'
import { Button } from '@designsystem'
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons'

const EditableActivityUnitLevel = ({ initialUnitLevel, onDelete, onSubmit, availableUnits } = {}) => {

  const initialState = initialUnitLevel || {
    unit: null,
  }
  const [unitLevel, setUnitLevel] = useState(initialState)
  const [submitting, setSubmitting] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [editing, toggleEditing] = useToggle(false)

  const handleCancel = e => {
    e.preventDefault()
    setUnitLevel(initialState)
    toggleEditing(false)
  }

  const handleUnitChange = (unit_id, unit) =>
    setUnitLevel(prev => ({
      ...prev,
      unit,
      unit_id,
    }))

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitting(true)
    onSubmit(unitLevel)
      .then(({ error }) =>
        !error && toggleEditing(false)
      ).finally(() =>
        setSubmitting(false)
      )
  }

  const handleDelete = e => {
    e.preventDefault()
    setDeleting(true)
    onDelete(unitLevel.id).finally(() =>
      setDeleting(false)
    )
  }

  if (!initialUnitLevel && !editing)
    return <Button size="sm" variant="outlined" onClick={toggleEditing} startIcon={faAdd}>
      Add Unit
    </Button>

  if (!editing) {
    return <div className="items-center font-bold bg-gray-10 border rounded p-2" onClick={toggleEditing}>
      {initialUnitLevel.unit.title}
    </div>
  }

  const selected_ids = [unitLevel.unit?.id].filter(Boolean)
  const nonArchivedUnits = availableUnits.filter(u => !u.archived)

  return <form className="flex flex-col justify-between bg-gray-10 border rounded p-2 text-sm h-[6.5rem]">
    <ul className="grid grid-cols-9 gap-2">
      {unitLevel.id && <input type="hidden" name="id" value={unitLevel.id} />}
      <li className="col-span-11">
        <SelectUnits
          isMulti={false}
          units={nonArchivedUnits}
          selected_ids={selected_ids}
          onChange={handleUnitChange} />
      </li>
    </ul>
    <div className="mt-2 flex flex-row justify-start space-x-2">
      <Button size="xs" loading={submitting} onClick={handleSubmit}>
        {initialUnitLevel
          ? 'Update this unit'
          : 'Add this unit'}
      </Button>
      <Button size="xs" variant="outlined" onClick={handleCancel}>
        {initialUnitLevel ? 'Discard changes' : 'Cancel'}
      </Button>
      {initialUnitLevel && (
        <Button size="xs" variant="outlined" startIcon={faTrash} loading={deleting} onClick={handleDelete} tooltip="Delete" />
      )}
    </div>
  </form>
}

EditableActivityUnitLevel.propTypes = {
  availableUnits: PropTypes.any,
  initialUnitLevel: PropTypes.shape({
    unit: PropTypes.shape({
      title: PropTypes.any
    })
  }),
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func
}

export default EditableActivityUnitLevel
