import React, { useState } from 'react'
import { Tab } from '@headlessui/react'
import { Outlet, useParams, createSearchParams, useLoaderData } from 'react-router-dom'
import useCycles from '@hooks/useCycles'
import useNavigateWithCycle, { NavigateCycle } from '@hooks/useNavigateWithCycle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import RedDot from '@components/red-dot'
import { Typography } from '@design-system'

export default function Experiences() {
  const loaderData = useLoaderData()
  const { type } = useParams()
  const navigate = useNavigateWithCycle()
  const { cycle_id, selectedCycle } = useCycles()
  const [query, setQuery] = useState('')
  const cycleId = selectedCycle ? selectedCycle.id : cycle_id

  const types = [
    { key: 'expedition', title: 'Expeditions', pending: loaderData.result.some(i => i.type === 'expedition' && i.pending) },
    { key: 'activity', title: 'Activities', pending: loaderData.result.some(i => i.type === 'activity' && i.pending) },
    { key: 'goal', title: 'Goals', pending: loaderData.result.some(i => i.type.includes('goal') && i.pending) },
    { key: 'independent_study', title: 'Independent Studies', pending: loaderData.result.some(i => i.type === 'independent_study' && i.pending) },
  ]

  if (!types.find(t => t.key === type)) {
    return <NavigateCycle to={`${types[0].key}`} replace={true} />
  }

  const handleOnChangeIndex = (index) => {
    navigate({ pathname: `${types[index].key}`, search: `?${createSearchParams({ cycle_id: cycleId })}` })
  }

  return (
    <>
      <Typography variant="heading-3" weight="bold" className="mb-6">Facilitate</Typography>
      <div className='flex flex-col gap-2 pt-5 rounded-xl border border-gray-40 px-5 py-2 bg-white'>
        <div className='flex flex-row'>
          <div className='flex flex-row bg-gray-5 rounded-lg justify-between items-center px-2 min-w-86 w-[calc(33%+1rem)]'>
            <span className='text-gray-60 pl-1'>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </span>
            <input type='text' onChange={(event) => setQuery(event.target.value)} className='flex-auto border-none outline-none focus:ring-0 bg-transparent appearance-none text-sm placeholder-gray-60' placeholder='Search for Experiences, Tasks and Students' />
          </div>
        </div>
        <Tab.Group onChange={handleOnChangeIndex} selectedIndex={types.findIndex(i => i.key === type)}>
          <Tab.List className='flex '>
            {types.map(({ title, pending }) => (
              <Tab
                key={`tab_${title}`}
                className={({ selected }) => `py-2 focus:outline-none px-2 hover:underline ${selected ? 'font-bold border-b-2 border-b-accent' : ''}`}
              >
                <div>
                  <span className="inline-block -mr-1"><RedDot show={pending} /></span>
                  {title}
                </div>
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            {types.map(({ key }) => (
              <Tab.Panel key={`tabpanel_${key}`}>{type === key && <Outlet context={[query]} />}</Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  )
}
