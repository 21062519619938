import React from 'react'
import axios from 'axios'
import { useFetcher, useLoaderData } from 'react-router-dom'
import AttendanceTable from '@components/attendance-table'
import useAttendances, { getKey } from '@hooks/useAttendances'
import startOfDay from 'date-fns/startOfDay'
import { Button } from '@design-system'

type Event = {
  id: number,
  attendable_id: number,
  start_at: string,
  sms_sent_at?: string,
}

type HouseStudents = Array<{ id: number, name: string }>

async function loader({ params }) {
  const { data } = await axios.get(`/pages/students/${params.student_id}/houses/${params.houseId}/attendances/${params.eventId}`)
  return data
}

async function action({ params }) {
  try {
    await axios.post(`/backoffice/houses/${params.houseId}/attendances/${params.eventId}`)
    return {
      toast: {
        message: 'SMS sent to parents',
        appearance: 'success',
      }
    }
  } catch (error) {
    return {
      toast: {
        message: 'Error sending SMS to parents',
        appearance: 'error',
      }
    }
  }
}

interface ParentsMessageButtonProps {
  startAt: string,
  smsSentAt: string,
  absencesCount: number,
}

export function ParentsMessageButton({ absencesCount, smsSentAt, startAt }: ParentsMessageButtonProps) {
  const eventStarted = new Date(Date.now()) > startOfDay(new Date(startAt))
  const numericParentsLabel = `${absencesCount} parent${absencesCount === 1 ? '' : 's'}`
  const disabled = !!(!eventStarted || smsSentAt || absencesCount === 0)

  return (
    <Button disabled={disabled} type="submit">
      {!eventStarted ? 'Can\'t message parents before event day'
        : smsSentAt ? `${numericParentsLabel} notified at ${new Date(smsSentAt).toLocaleTimeString([], { day: 'numeric', month: 'short' })}`
          : absencesCount === 0 ? 'No absents, Yay!'
            : `Send now to ${numericParentsLabel}!`
      }
    </Button>
  )
}

function Element() {
  const fetcher = useFetcher()
  const { event: selectedEvent, houseStudents } = useLoaderData() as { event: Event, houseStudents: HouseStudents }
  const attendableId = selectedEvent?.attendable_id
  const {
    statuses,
    getCheckedFor,
    getPendingFor,
    getDisabledFor,
    getStudentStatus,
    setAttendanceForKey,
    getStudentNotificationStatus,
  } = useAttendances({ attendable_id: attendableId })
  const countAbscencesForSelectedAttendable = selectedEvent?.sms_sent_at
    ? houseStudents.map(student => getStudentNotificationStatus(student.id)).filter(Boolean).length
    : houseStudents.filter(student => !getCheckedFor(getKey(attendableId, student.id, 'excused')) && !getCheckedFor(getKey(attendableId, student.id, 'present'))).length

  return (
    <div className="border border-gray-30 dark:border-gray-90 rounded">
      <AttendanceTable
        statuses={statuses}
        students={houseStudents}
        attendable_id={attendableId}
        getCheckedFor={getCheckedFor}
        getDisabledFor={getDisabledFor}
        isStudent={true}
        getStudentStatus={getStudentStatus}
        getPendingFor={(...args) => getPendingFor(...args)}
        onChange={(key, checked) => setAttendanceForKey(key, checked)}
      />
      <fetcher.Form className="border border-gray-30 dark:border-gray-90 rounded" method="post">
        <div className="flex flex-row justify-center m-4">
          <span className="text-sm bg-blue-2 py-2 px-4 rounded border border-blue-30 dark:border-gray-100 dark:text-black">
            Notification Button is one-time use per attendance sheet. Make sure you've collected all the absences before sending!
          </span>
        </div>
        <div className="flex flex-row justify-center m-4">
          <ParentsMessageButton
            startAt={selectedEvent?.start_at}
            smsSentAt={selectedEvent?.sms_sent_at}
            absencesCount={countAbscencesForSelectedAttendable}
          />
        </div>
      </fetcher.Form>
    </div>
  )
}

export const StudentHouseAttendanceEventRoute = { Element, loader, action }
