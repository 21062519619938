import React, { Fragment } from 'react'
import getBrowserTimezoneAbbreviation from '@utils/getTimezoneAbbreviation'
import { Form, useSubmit } from 'react-router-dom'
import { Switch } from '@headlessui/react'
import format from 'date-fns/format'

export const LearningBlocks = (props: ILearningBlocksProps) => {
  const submitForm = useSubmit()
  if (!props?.learningBlocks?.length) return <></>

  const tz = getBrowserTimezoneAbbreviation()

  function toggleLearningBlockAvailability(lbID: number) {
    return function (available: boolean) {
      const form = new FormData()
      form.set('id', lbID + '')
      form.set('available', available + '')
      submitForm(form, { method: 'post' })
    }
  }

  return (
    <div className="grid grid-cols-12 gap-8" data-testid="learning-block-grid">
      {props.learningBlocks.map((lb) => {
        const [scheduleStart, scheduleEnd] = lb.schedules
        const todayYMD = format(new Date(), 'yyyy-MM-dd')
        const startTime = format(new Date(`${todayYMD}T${scheduleStart.time}Z`), 'HH:mm')
        const endTime = format(new Date(`${todayYMD}T${scheduleEnd.time}Z`), 'HH:mm')
        return (
          <div
            className={`flex flex-col col-span-6 border p-5 rounded-lg border-gray-10 shadow ${
              lb.available ? 'bg-white' : 'bg-gray-10'
            }`}
            key={`${lb.id}-${lb.user_id}`}
            data-testid="learning-block-item"
          >
            <span
              className={`text-xl text-center md:text-left mb-1 ${lb.available ? 'font-bold' : ''}`}
              data-testid="learning-block-item-title"
            >
              {lb.title}
            </span>
            <div className="flex flex-col md:flex-row text-left items-center w-full space-x-2 mb-6">
              <span className="capitalize" data-testid="learning-block-item-schedule-start">{`${dayOfWeekToDayName(
                scheduleStart.day_of_the_week
              )}, ${startTime}`}</span>
              <span>-</span>
              <span className="capitalize" data-testid="learning-block-item-schedule-end">{`${dayOfWeekToDayName(
                scheduleEnd.day_of_the_week
              )}, ${endTime} (${tz})`}</span>
            </div>
            {!!props.hideToggle ? (
              <span data-testid="learning-block-item-availability">{lb.available ? 'Available' : 'Unavailable'}</span>
            ) : (
              <Form method="POST">
                <div className="flex items-center space-x-3 text-lg">
                  <Switch
                    defaultChecked={lb.available}
                    as={Fragment}
                    onChange={toggleLearningBlockAvailability(lb.id)}
                    data-testid="learning-block-item-switch"
                  >
                    {({ checked }) => (
                      <button
                        className={`${
                          checked ? 'bg-blue-50' : 'bg-gray-10'
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                      >
                        <span
                          className={`${
                            checked ? 'translate-x-6' : 'translate-x-1'
                          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                        />
                      </button>
                    )}
                  </Switch>
                  <span data-testid="learning-block-item-availability">
                    {lb.available ? 'Available' : 'Unavailable'}
                  </span>
                </div>
              </Form>
            )}
          </div>
        )
      })}
    </div>
  )
}

type LearningBlockSchedule = {
  day_of_the_week: number
  tag: string
  time: string
}

type LearningBlock = {
  id: number
  title: string
  schedules: LearningBlockSchedule[]
  available: boolean
  user_id: number
}

export type ILearningBlocks = LearningBlock[]

interface ILearningBlocksProps {
  learningBlocks: ILearningBlocks
  hideToggle?: boolean
}

function dayOfWeekToDayName(dayOfWeek: number): string {
  return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'][dayOfWeek] || ''
}
