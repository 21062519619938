import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from '@design-system'
import { useFetcher } from 'react-router-dom'

export const ProcessTitle = ({ id, title, className = '' }) => {
  const [isEditable, setIsEditable] = useState(false)
  const fetcher = useFetcher()

  const isLoading = fetcher.state === 'loading' && fetcher.formData.get('groupProcessId') === String(id)



  return (
    <div className={`flex flex-row items-center gap-2 ${className}`}>
      {isEditable && (
        <fetcher.Form method='post' className='flex flex-row items-center gap-2' onSubmit={(event) => {
          event.preventDefault()
          fetcher.submit(event.target)
          setIsEditable(false)
        }}>
          <input type="hidden" name="_action" value='update_process_title' />
          <input type="hidden" name="groupProcessId" value={id} />
          <input type='text' name="title" className='font-normal rounded border border-alpha/10 focus:border-black' defaultValue={title} />
          <Button variant='outlined' size='xs' onClick={() => { setIsEditable(false) }} disabled={isLoading}>
            <Icon name='cross' size='sm' />
          </Button>
          <Button size='xs' type='submit' disabled={isLoading} loading={isLoading} >
            <Icon name='check' size='sm' />
          </Button>
        </fetcher.Form>
      )}
      {!isEditable && (
        <>
          <span>{title}</span>
          <Button variant='ghost' size='xs' onClick={() => { setIsEditable(true) }} loading={isLoading}>
            <Icon name='edit-2' size='sm'/>
          </Button>
        </>
      )}
      
    </div>
  )
}
ProcessTitle.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}
