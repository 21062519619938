import React from 'react'
import PropTypes from 'prop-types'

const Feedback = ({ children, submissionStatus, assessment }) => {
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) return React.cloneElement(child, { submissionStatus, assessment })
    return child
  })

  return (
    <div className='flex flex-col flex-auto'>
      {childrenWithProps}
    </div>
  )
}
Feedback.propTypes = {
  children: PropTypes.node,
  submissionStatus: PropTypes.string,
  assessment: PropTypes.object,
}

Feedback.FlaggedStep = ({ children, submissionStatus }) => {
  if (!submissionStatus || !submissionStatus.includes('flag')) return null
  return (
    <div className='pt-3'>
      {children}
    </div>
  )
}
Feedback.FlaggedStep.displayName = 'FlaggedStep'
Feedback.FlaggedStep.propTypes = {
  children: PropTypes.node,
  submissionStatus: PropTypes.string,
}

Feedback.OnTrackStep = ({ children, submissionStatus }) => {
  if (submissionStatus && submissionStatus.includes('flag')) return null
  return (
    <div className='py-3'>
      {children}
    </div>
  )
}
Feedback.OnTrackStep.displayName = 'OnTrackStep'
Feedback.OnTrackStep.propTypes = {
  children: PropTypes.node,
  submissionStatus: PropTypes.string,
}

Feedback.Footer = ({ children, assessment }) => {
  return (
    <div className='flex-1 flex flex-col justify-end py-2'>
      <div className='flex flex-row justify-between items-center gap-2'>
        {children({ assessed: assessment?.status === 'assessed' })}
      </div>
    </div>
  )
}
Feedback.Footer.displayName = 'Footer'
Feedback.Footer.propTypes = {
  children: PropTypes.func,
  assessment: PropTypes.object,
}

export default Feedback
