import React from 'react'
import { useFetcher } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { Button, Icon } from '@design-system'

const AddCycleTemplate = ({ id, availableCycles = [] }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle' && Number(fetcher.formData?.get('journey_templates_id')) === Number(id)

  return (
    <fetcher.Form method="post" className="flex flex-row gap-2 items-center">
      <input type="hidden" name="journey_templates_id" value={id} />
      <input type="hidden" name="_action" value="add_template_cycle" />
      <select name='cycle_id' className='m-0 rounded border border-gray-200 text-xs' disabled={isLoading}>
        <option value=''>Select a cycle</option>
        {availableCycles.map(cycle => (
          <option key={`cycle_${cycle.id}`} value={cycle.id}>{cycle.title}</option>
        ))}
      </select>
      <Button type="submit" size='xs' loading={isLoading} disabled={isLoading}>
        <Icon name='plus' size='xs' />
      </Button>
    </fetcher.Form>
  )
}
AddCycleTemplate.propTypes = {
  id: PropTypes.number,
  availableCycles: PropTypes.array,
}

export default AddCycleTemplate
