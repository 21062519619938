import * as React from 'react'
import axios from 'axios'
import { useNavigate, useLoaderData, LoaderFunctionArgs } from 'react-router-dom'
import { unstable_Dialog as Dialog, Button, unstable_Table as Table, Typography, Card } from '@design-system'

interface LoaderData {
  id: number,
  type: string,
  resourceId: string,
  errors: Array<string>,
  info: Array<string>,
}
async function loader({ params }: LoaderFunctionArgs) {
  const res = await axios.get(`/backoffice/workbench/student-heartbeat-server/jobs/${params.jobId}`)
  return res.data
}

function Element() {
  const [infoFilter, setInfoFilter] = React.useState<'errors' | 'info'>('errors')
  const loaderData = useLoaderData() as LoaderData
  const pickedData = loaderData?.[infoFilter]
  const navigate = useNavigate()

  return (
    <Dialog open onOpenChange={() => navigate(-1)}>
      <Dialog.Content className="w-[600px] flex flex-col gap-4 h-[600px]">
        <Dialog.Header className="flex items-center border-b border-neutral-200 px-6 pb-6">
          <Typography variant="subheadline" weight="bold">
            Logs details
          </Typography>
        </Dialog.Header>
        <Card>
          <Card.Content className="flex flex-col gap-2">
            <Typography>
              <b>Job ID:</b> {loaderData.id}
            </Typography>
            <Typography>
              <b>Type:</b> {loaderData.type}
            </Typography>
            <Typography>
              <b>Resource ID:</b> {loaderData.resourceId}
            </Typography>
          </Card.Content>
        </Card>
        <div className="flex gap-4">
          <Button variant="ghost" color={infoFilter === 'errors' ? 'accent' : 'dark'} onClick={() => setInfoFilter('errors')}>Errors</Button>
          <Button variant="ghost" color={infoFilter === 'info' ? 'accent' : 'dark'} onClick={() => setInfoFilter('info')}>Infos</Button>
        </div>
        <Table className="border-separate border border-gray-30 rounded-lg">
          <Table.Header className="bg-white dark:bg-gray-100 dark:border-gray-90">
            <Table.Row>
              <Table.Head className="w-48 first:pl-4 capitalize">{infoFilter}</Table.Head>
            </Table.Row>
          </Table.Header>
          <Table.Body className="[&_tr:last-child]:border-1">
            {pickedData && pickedData?.length ? pickedData?.map((data, index) => (
              <Table.Row key={index} className="last-of-type:rounded-b-md">
                <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{data}</Table.Cell>
              </Table.Row>
            )) : (
              <Table.Row className="last-of-type:rounded-b-md">
                <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">No {infoFilter} logged for this job.</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <Dialog.Footer>
          <Button onClick={() => navigate(-1)}>Close</Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}
export const WorkbenchStudentHearbeatServerJobsLogsRoute = { loader, Element }
