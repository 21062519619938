import React from 'react'
import PropTypes from 'prop-types'
import { FirstCycleSectionList } from './first-cycle-section-list'
import { SectionList } from './section-list'
import { CycleSectionList } from './cycle-section-list'
import { AddSection } from './add-section'

export const ExpertSectionList = ({ experts }) => {
  return (
    experts.map(expert => {
      const cyclesAmount = expert.cycles.length
      const sectionsAmount = expert.cycles.reduce((acc, curr) => acc + (curr.data.length+1), 0)
      return (
        <React.Fragment key={`expert_${expert.id}`}>
          <tr>
            <td colSpan={7}>&nbsp;</td>
          </tr>
          <tr className='bg-white dark:bg-black'>
            <td rowSpan={sectionsAmount === 0 ? 1 : sectionsAmount} className='p-2 align-top'>
              <span className='font-bold'>[{expert.id}] {expert.name}</span>
            </td>
            <FirstCycleSectionList cycle={expert.cycles[0]} expertId={expert.id} />
          </tr>
          {(expert.cycles[0]?.data.length > 1) &&
            <SectionList sections={expert.cycles[0].data.slice(1)} expertId={expert.id} cycleId={expert.cycles[0].cycle_id} />
          }
          {(expert.cycles[0]?.data.length > 0 && expert.cycles[0].cycle_id !== null) &&
            <tr className='bg-white dark:bg-black'>
              <AddSection cycleId={expert.cycles[0].cycle_id} expertId={expert.id} />
            </tr>
          }
          {(cyclesAmount > 1) && 
            <CycleSectionList cycles={expert.cycles.slice(1)} expertId={expert.id} />
          }
        </React.Fragment>
      )
    })
  )
}
ExpertSectionList.propTypes = {
  experts: PropTypes.array
}
