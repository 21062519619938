/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import useQuery from '@hooks/useQuery'
import { useParams } from 'react-router-dom'
import useYearlyCycleData from '@hooks/useYearlyCycleData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import useCycles from '@hooks/useCycles'

const TargetDataVisual = () => {
  const { student_id } = useParams()
  const { cycles, selectedCycle } = useCycles()
  const [queryParams, setParams] = useQuery()
  let { cycle_id = selectedCycle?.id } = queryParams

  const schoolYears = [...new Set(cycles?.map((cycle) => cycle.school_year))]
  const getShortYear = (y) => String(y).slice(-2)
  const optionYears = schoolYears.map((year, i) => {
    return {
      label: `Year ${i + 1} ('${getShortYear(year - 1)}-'${getShortYear(
        year
      )})`,
      value: year,
    }
  })

  const [selectedYear, setSelectedYear] = useState(null)

  useEffect(() => {
    setSelectedYear(optionYears.find(
      (option) => option.value === selectedCycle?.school_year
    ))
  }, [selectedCycle])

  const { data: cyclesInYear, target: targetData } = useYearlyCycleData(
    student_id,
    selectedYear,
    cycles,
    selectedCycle,
  )

  const cycleTarget = Number(targetData?.units_cycle_target || 0) + Number(targetData?.abilities_cycle_target || 0)

  const Y_AXIS_MAX = !Object.keys(cyclesInYear).length
    ? 25
    : Math.max.apply(null, [
      cycleTarget,
      ...Object.values(cyclesInYear).map(c =>
        Number(c.units.total.expected) +
        Number(c.abilities.total.expected)
      )
    ])

  let numberOfCycles = cyclesInYear
    ? Object.keys(cyclesInYear).length < 6
      ? 6
      : Object.keys(cyclesInYear).length
    : 6

  const handleCycleChange = (cycleId) => {
    const targetCycle = cycles.find((cycle) => cycle.id === cycleId)
    const newYear = optionYears.find(
      (option) => option.value === targetCycle.school_year
    )
    setSelectedYear(newYear)
    setParams({ ...queryParams, cycle_id: cycleId })
  }

  const handleSelectYear = (e) => {
    setSelectedYear(optionYears.find((option) => option.value === Number(e.target.value)))
  }

  const Post2022 = () => (
    <div className="relative bg-white dark:bg-gray-95 rounded-lg p-8 h-80 border border-gray-30 dark:border-gray-90 grid grid-cols-4 gap-8 w-full">
      <div className="flex flex-col justify-end">
        <div className="relative h-full">
          <div className="flex flex-row items-center space-x-2 absolute mt-[-9px] left-0 right-0" style={{
            top: cycleTarget > Y_AXIS_MAX ? '0%' : `${100 - (cycleTarget / Y_AXIS_MAX) * 100}%`,
          }}>
            <div className="text-sm font-bold text-green-50">Target</div>
            <span className="flex-grow border-dotted border-t-2 h-[1px] border-green-50"></span>
          </div>
        </div>
        <div className="text-sm font-bold h-14">Cycle</div>
      </div>
      <div className={`col-span-3 grid grid-cols-${numberOfCycles} gap-2 lg:gap-8 h-full`}>
        {cyclesInYear &&
          Object.keys(cyclesInYear)?.map((c_id, idx) => {
            const cycleData = cyclesInYear[c_id]
            const newExpected = Number(cycleData.units.new.expected) + Number(cycleData.abilities.new.expected)
            const newCompleted = Number(cycleData.units.new.demos) + Number(cycleData.abilities.new.demos)
            return (
              <div
                className="col-span-1 flex flex-col h-full"
                key={`cycle-${idx}`}
              >
                <div className="flex-1 flex flex-col space-y-1 justify-end relative">
                  {cycle_id == c_id && (
                    <div
                      className="group absolute border-green-50 border-t-2 border-dotted h-[1px] w-full block"
                      style={{
                        top: cycleTarget > Y_AXIS_MAX ? '0%' : `${100 - (cycleTarget / Y_AXIS_MAX) * 100}%`,
                      }}
                    >
                      <div className="group relative">
                        <div className="p-4 bg-white dark:bg-gray-100 border-gray-30 dark:border-gray-90 rounded-lg shadow w-[200px] absolute group-hover:block hidden z-50 text-sm">
                          You must achieve{' '}
                          {targetData?.units_cycle_target} new units
                          and{' '}
                          {targetData?.abilities_cycle_target}{' '}
                          new ability demonstrations this cycle to be on pace for graduation.
                        </div>
                      </div>
                    </div>
                  )}
                  <>
                    {false && (cycleData.units.total.expected >
                      cycleData.units.total.demos ||
                      cycleData.abilities.total.expected >
                      cycleData.abilities.total.demos) &&
                      new Date(cycleData.end_timestamp) < new Date() && (
                        <div className="mx-auto">
                          <div className="relative group">
                            <FontAwesomeIcon
                              className="cursor-pointer text-white bg-danger-50 hover:bg-danger-40 p-1 rounded-full h-3 w-3"
                              icon={faExclamation}
                            />
                            <div className="absolute top-[-100px] group-hover:block hidden right-8">
                              <div className="p-4 bg-white dark:bg-gray-100 border-gray-30 dark:border-gray-90 rounded-lg shadow w-[250px]">
                                <p className="text-sm">
                                  You missed units and abilities
                                  demonstrations in this cycle.
                                </p>
                                <div className="grid grid-cols-2 gap-4 mt-4">
                                  <div className="border-r">
                                    <span className="text-sm font-bold">
                                      Units
                                    </span>
                                    <div className="flex flex-col">
                                      <span className="text-xs text-gray-40">
                                        Demonstrated
                                      </span>
                                      <span className="text-xl text-green-50 font-bold">
                                        {cycleData.units.total.demos}
                                      </span>
                                    </div>
                                    <div className="flex flex-col">
                                      <span className="text-xs text-gray-40">
                                        Target
                                      </span>
                                      <span className="text-xl text-gray-70 font-bold">
                                        {cycleData.units.total.expected}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    <span className="text-sm font-bold">
                                      Abilities
                                    </span>
                                    <div className="flex flex-col">
                                      <span className="text-xs text-gray-40">
                                        Demonstrated
                                      </span>
                                      <span className="text-xl text-green-50 font-bold">
                                        {cycleData.abilities.total.demos}
                                      </span>
                                    </div>
                                    <div className="flex flex-col">
                                      <span className="text-xs text-gray-40">
                                        Target
                                      </span>
                                      <span className="text-xl text-gray-70 font-bold">
                                        {
                                          cycleData.abilities.total
                                            .expected
                                        }
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    <div
                      className="bg-gray-30 dark:bg-gray-60 mx-auto w-2 rounded-full group relative"
                      style={{
                        height: `${((newExpected - newCompleted) / Y_AXIS_MAX) * 100}%`,
                      }}
                    >
                      <div className="p-4 bg-white dark:bg-gray-100 border-gray-30 dark:border-gray-90 rounded-lg shadow w-[250px] absolute right-[200%] hidden group-hover:block bottom-0">
                        <p className="text-sm">
                          In this cycle you have the potential to demonstrate
                          the following:
                        </p>
                        <div className="grid grid-cols-2 gap-4 mt-4">
                          <div className="border-r">
                            <span className="text-sm font-bold">
                              New units
                            </span>
                            <div className="flex flex-col">
                              <span className="text-xl font-bold">
                                {cycleData.units.new.expected}
                              </span>
                            </div>
                          </div>
                          <div>
                            <span className="text-sm font-bold">
                              New abilities
                            </span>
                            <div className="flex flex-col">
                              <span className="text-xl font-bold">
                                {cycleData.abilities.new.expected}
                              </span>
                            </div>
                          </div>
                          <div className="border-r">
                            <span className="text-sm font-bold">
                              Total units
                            </span>
                            <div className="flex flex-col">
                              <span className="text-xl font-bold">
                                {cycleData.units.total.expected}
                              </span>
                            </div>
                          </div>
                          <div>
                            <span className="text-sm font-bold">
                              Total abilities
                            </span>
                            <div className="flex flex-col">
                              <span className="text-xl font-bold">
                                {cycleData.abilities.total.expected}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ height: `${(newCompleted / Y_AXIS_MAX) * 100}%` }}
                      className={`mx-auto w-2 rounded-full relative group ${cycle_id == c_id ? 'bg-green-50 dark:bg-green-20' : 'bg-accent'}`}
                    >
                      <div className="p-4 bg-white dark:bg-gray-100 border-gray-30 dark:border-gray-90 rounded-lg shadow w-[250px] absolute right-[200%] hidden group-hover:block bottom-0">
                        <p className="text-sm">
                          In this cycle you have demonstrated the following:
                        </p>
                        <div className="grid grid-cols-2 gap-4 mt-4">
                          <div className="border-r">
                            <span className="text-sm font-bold">New Units</span>
                            <div className="flex flex-col">
                              <span className="text-xl text-green-50 font-bold">
                                {cycleData.units.new.demos}
                              </span>
                            </div>
                          </div>
                          <div>
                            <span className="text-sm font-bold">
                              New Abilities
                            </span>
                            <div className="flex flex-col">
                              <span className="text-xl text-green-50 font-bold">
                                {cycleData.abilities.new.demos}
                              </span>
                            </div>
                          </div>
                          <div className="border-r">
                            <span className="text-sm font-bold">Total Units</span>
                            <div className="flex flex-col">
                              <span className="text-xl text-green-50 font-bold">
                                {cycleData.units.total.demos}
                              </span>
                            </div>
                          </div>
                          <div>
                            <span className="text-sm font-bold">
                              Total Abilities
                            </span>
                            <div className="flex flex-col">
                              <span className="text-xl text-green-50 font-bold">
                                {cycleData.abilities.total.demos}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
                <button
                  type="button"
                  onClick={() => handleCycleChange(parseInt(c_id))}
                  className={`mt-2 rounded-lg border border-gray-30 ${cycle_id == c_id ? 'bg-gray-10 dark:bg-black dark:border-gray-80' : 'bg-white dark:bg-gray-100 dark:border-gray-90'
                    } h-10 hover:ring-1`}
                >
                  {cycleData.number_in_school_year}
                </button>
              </div>
            )
          })}
      </div>
    </div>
  )

  const Pre2022 = () => (
    <div className="relative bg-white dark:bg-gray-95 rounded-lg p-8 h-80 border border-gray-30 dark:border-gray-90 grid grid-cols-4 gap-8 w-full">
      <div className="flex flex-col justify-between">
        <div className="text-sm font-bold">Cycle</div>
      </div>
      <div className={`col-span-3 grid grid-cols-${numberOfCycles} gap-8 h-full`}>
        <div className={`col-span-${numberOfCycles} flex flex-col space-y-8`}>
          <div
            className={`flex-1 p-2 bg-gray-10 dark:bg-gray-100 w-full text-sm flex items-center align-center`}
          >
            <p className="mx-auto">
              Sorry, the target feature is only available for cycles after the
              2022 school year.
            </p>
          </div>
        </div>
      </div>
    </div>
  )

  const DataForYear = () =>
    selectedYear?.value > 2022 ? <Post2022 /> : <Pre2022 />

  return (
    <>
      <div className="mb-4 flex gap-4 max-w-full">
        <select name="year" value={selectedYear?.value}
          onChange={handleSelectYear}
          className="block py-2 pr-8 rounded text-md border-gray-40 dark:border-gray-70 dark:text-gray-5 dark:bg-gray-90 dark:hover:bg-gray-95 dark:focus:bg-gray-100"
        >
          {optionYears.map(year => (
            <option key={year.value} value={year.value}>
              {year.label}
            </option>
          ))}
        </select>
      </div>
      <DataForYear />
    </>
  )
}

export default TargetDataVisual
