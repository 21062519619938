import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ActionFunctionArgs, redirect, useNavigate } from 'react-router-dom'
import { Button, Typography } from '@design-system'
import { unstable_Dialog as Dialog, unstable_Checkbox as Checkbox, useFetcher } from '@design-system'
import { TextField } from '@designsystem'
import { CreatePresetCommentSlateTextArea } from './create-preset-comment-slate-text-area'

async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData()
  const res = await axios.post(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}/comment-bank/preset-comment/create`,
    formData
  )

  return { status: res.status, ...res.data }
}

function Element() {
  const fetcher = useFetcher()
  const navigate = useNavigate()

  // Intentionally Not using redirect()  to keep using the custom fetcher toast
  useEffect(() => {
    if (fetcher.data?.status === 200 && fetcher.formData.get('_action') === 'create_preset_comment') {
      navigate('../')
    }
  }, [fetcher.data])

  return (
    <div className="flex flex-col w-[460px] overflow-hidden bg-white border-x">
      <Dialog
        open={true}
        onOpenChange={(value) => {
          navigate('../')
        }}
      >
        <Dialog.Content className="px-0 max-w-[700px]">
          <Dialog.Header className="flex items-center justify-center  border-b border-neutral-200 pb-4">
            <Typography variant="heading-5" weight="bold">
              Create new comment
            </Typography>
          </Dialog.Header>

          <fetcher.Form method="POST" id="createPresetCommentForm">
            <div className="flex flex-col px-14 pt-8 gap-6">
              <TextField placeholder="Comment Title" name="title" required trim />
              <CreatePresetCommentSlateTextArea name="body" />
              <Typography variant="callout">
                This comment will be saved in your comment bank and you can reuse across students and assignments
                related to this{' '}
                <Typography className="inline" variant="callout" weight="bold">
                  same expedition instance.
                </Typography>
              </Typography>
              <div className="flex gap-2">
                <Checkbox id="all_experiences_checkbox" name="allExperiences" />
                <Typography variant="body" asChild>
                  <label htmlFor="all_experiences_checkbox">Make comment available across all my expeditions</label>
                </Typography>
              </div>
              <div className="flex justify-end">
                <Button type="submit" name="_action" value="create_preset_comment">
                  Save comment
                </Button>
              </div>
            </div>
          </fetcher.Form>
        </Dialog.Content>
      </Dialog>
    </div>
  )
}
export const FacilitateExperienceTaskStudentCommentBankCreateRoute = {
  Element,
  action,
}
