import React from 'react'
import { useFetcher } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import ManageCycles from './manage-cycles'
import UnitList from './unit-list'
import ExpertList from './expert-list'
import { Spinner } from '@design-system'

const RemoveTemplate = ({ template, defaultCycles }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle'

  const categoryView = (title) => {
    switch (title.toLowerCase()) {
      case 'stem':
        return (<span className='rounded py-1 px-2 bg-green-5 font-bold text-xs whitespace-nowrap'>Stem</span>)
      case 'humanities':
        return (<span className='rounded py-1 px-2 bg-turquoise-5 font-bold text-xs whitespace-nowrap'>Humanities</span>)
      case 'math_learning_goal':
        return (<span className='rounded py-1 px-2 bg-purple-5 font-bold text-xs whitespace-nowrap'>Math</span>)
      case 'electives':
        return (<span className='rounded py-1 px-2 bg-orange-5 font-bold text-xs whitespace-nowrap'>Electives</span>)
    }
  }

  const removeTemplate = (e) => {
    e.preventDefault()
    fetcher.submit(e.target.form)
  }

  return (
    <tr className='hover:bg-gray-20'>
      <td className='py-1 px-3 align-top'>
        <fetcher.Form name={`picked_template_${template.id}`} method='post'>
          <input type="hidden" name="_action" value='remove_template' />
          <input type="hidden" name="template_id" value={template.template_id} />
          {isLoading ? <Spinner/> : <input type="checkbox" className='cursor-pointer' onChange={removeTemplate} checked={true} />}
        </fetcher.Form>
      </td>
      <td className='py-1 px-3 align-top'>{template.template_id}</td>
      <td className='py-1 px-3 align-top flex flex-row gap-2 items-center'>
        <span>{template.title}</span>
        {template.is_scheduled && <span className='px-2 py-1 bg-blue-10 text-blue-50 text-xs rounded ml-1'>Scheduled</span>}
        {template.is_pathways && <span className='px-2 py-1 bg-yellow-10 text-yellow-70 text-xs rounded ml-1'>Pathways</span>}
      </td>
      <td className='py-1 px-3 align-top'>{categoryView(template.category)}</td>
      <td className='py-1 px-3 align-top w-[150px]'>
        <div className='flex flex-col items-start space-y-1'>
          <ExpertList experts={template.experts} />
        </div>
      </td>
      <td className='py-1 px-3 align-top w-[300px]'>
        <div className='flex flex-col items-start space-y-1'>
          <UnitList units={template.units} />
        </div>
      </td>
      <td className='py-1 px-3 align-top'>{template.request_list_hits}</td>
      <td className='py-1 px-3 align-top'>
        <ManageCycles id={template.id} cycles={template.cycles} defaultCycles={defaultCycles} />
      </td>
    </tr>
  )
}
RemoveTemplate.propTypes = {
  template: PropTypes.object,
  defaultCycles: PropTypes.array,
}

export default RemoveTemplate
