import React from 'react'
import { Outlet, useLoaderData, useParams } from 'react-router-dom'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'
import { Button } from '@designsystem'
import axios from 'axios'

export async function loader({ params }) {
  const { data } = await axios(`/experiences/${params.theme_id}/assessment`)
  return data
}

export default function Assessment() {
  const navigate = useNavigateWithCycle()
  const { theme_id } = useParams()

  const loaderData = useLoaderData()
  const { competition_interval_id, title, type } = loaderData.experience

  const handleBackToExperiences = () => {
    const xpType = type.includes('goal') ? 'goal' : type.includes('track') ? 'expedition' : type
    navigate({ pathname: `/employee/facilitate/experiences/${xpType}/${theme_id}`, search: `?cycle_id=${competition_interval_id}` })
  }

  return (
    <div className='flex flex-col h-full'>
      <div className='flex flex-row mb-8 gap-2'>
        <Button variant="nude" color="cta" size="sm" startIcon={faAngleLeft} onClick={handleBackToExperiences}>
          Back to Facilitate: {title}
        </Button>
      </div>
      <div className='flex flex-row gap-2'>
        <Outlet />
      </div>
    </div>
  )
}