
import axios from 'axios'

import ExpeditionForm from '@components/expedition-form'
import React from 'react'
import { useThisPageTitle } from '@hooks/usePageTitle'
import { useLoaderData, redirect, useNavigation, useActionData } from 'react-router-dom'
import serializeFormData from '@utils/serializeFormData'
import { useToast } from '@hooks/useToast'
import { getLocalStorageData } from '@utils/formPersistence'
import { DataPersistenceBanner } from '@components/data-persistence-banner'

export default function NewExpeditionRoute() {
  useThisPageTitle('New Expedition')
  const navigation = useNavigation()
  const loaderData = useLoaderData()
  useToast(useActionData())

  const employees = loaderData.employees
  const cohorts = loaderData.cohorts
  const units = loaderData.units
  const cycles = loaderData.cycles
  const defaultExpeditionData = loaderData.defaultExpeditionData
  const isLocalStorageData = loaderData.isLocalStorageData

  return <div className="flex flex-col gap-6">
    {isLocalStorageData ? (
      <DataPersistenceBanner
        variation='localData'
        onDiscardChanges={() => {
          window.localStorage.removeItem(location.pathname)
          window.location.reload()
        }}
      />
    ) : null}
    <ExpeditionForm
      cohorts={cohorts}
      employees={employees}
      cycles={cycles}
      units={units}
      initialExpedition={defaultExpeditionData}
      submitting={navigation.state === 'submitting'}
      isHeartbeatFeatureFlagEnabled={loaderData.isHeartbeatFeatureFlagEnabled}
      isMsHeartbeatFeatureFlagEnabled={loaderData.isMsHeartbeatFeatureFlagEnabled}
    />
  </div>
}

export const loader = async ({ request }) => {
  const url = new URL(request.url)
  const cycle_id = url.searchParams.get("cycle_id")

  try {
    const { data } = await axios.get(`/pages/employee/expeditions/new${cycle_id ? `?cycle_id=${cycle_id}` : ''}`)
    const localStorageData = getLocalStorageData(url.pathname)
    const isLocalStorageData = !!localStorageData

    const defaultExpeditionData = {
      ...{
        type: 'expedition',
        units_levels: [],
        sessions: [],
        competition_interval_id: data.selectedCycleId,
        cohort_id: 1
      },
      ...(localStorageData || {})
    }
    return {
      employees: data.employees,
      cohorts: data.cohorts,
      units: data.units,
      cycles: data.cycles,
      isHeartbeatFeatureFlagEnabled: data.isHeartbeatFeatureFlagEnabled,
      defaultExpeditionData,
      isLocalStorageData,
      isMsHeartbeatFeatureFlagEnabled: data.isMsHeartbeatFeatureFlagEnabled,
    }
  } catch (e) {
    return redirect(`/${cycle_id ? `?cycle_id=${cycle_id}` : ''}`)
  }
}

export const action = async ({ request }) => {
  const formData = await request.formData()
  let serializedData = serializeFormData(formData)
  serializedData.employees_ids = formData.getAll('employees_ids').join(',')
  const typeParam = serializedData.type.includes('goal') ? 'goal' : serializedData.type

  serializedData.enable_heartbeat = serializedData.enable_heartbeat === 'on' ? true : false

  try {
    await axios.post(`/pages/employee/expeditions/new`, serializedData)
    return redirect(`/employee/expeditions/edit?cycle_id=${serializedData.competition_interval_id}&type=${typeParam}`)
  } catch (error) {
    return {
      toast: {
        message: error?.response?.data?.errors?.details?.map(det => det.message).join('. ') || 'There was an error while creating this expedition.',
        appearance: 'error'
      }
    }
  }
}


