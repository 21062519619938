import React from 'react'
import PropTypes from 'prop-types'
import { FirstSectionList } from './first-section-list'

export const FirstCycleSectionList = ({ cycle, studentId }) => {
  const cycleColors = {
    "Cycle 1" : 'text-orange-50',
    "Cycle 2" : 'text-cyan-50', 
    "Cycle 3" : 'text-green-50', 
    "Cycle 4" : 'text-blue-50', 
    "Cycle 5" : 'text-yellow-50', 
    "Cycle 6" : 'text-pink-50'
  }

  const sectionsAmount = cycle.sections.length
  const hasCycle = cycle.cycle_id !== null
  return (
    <React.Fragment>
      <td rowSpan={hasCycle ? sectionsAmount+1 : sectionsAmount} className={`align-top pt-2 px-2 ${cycle.cycle_id === null ? 'bg-danger-10 text-danger-60' : ''}`}>
        {!hasCycle && <span className='text-nowrap'>Missing</span>}
        {hasCycle && <span className={`py-1 text-nowrap rounded text-xs font-bold ${cycleColors[cycle.cycle_title.substring(0,7)]}`}>{cycle.cycle_title}</span>}
      </td>
      <FirstSectionList section={cycle.sections[0]} studentId={studentId} cycleId={cycle.cycle_id} />
    </React.Fragment>
  )
}
FirstCycleSectionList.propTypes = {
  cycle: PropTypes.object,
  studentId: PropTypes.number,
}
