/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import SubmissionFlag from './submission-flag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleQuestion, faClock } from '@fortawesome/free-regular-svg-icons'
import SoraIcon, { soraBoxOutline, soraFlagOutline, soraPencilEditOutline } from '@components/sora-icon'
import ReactTooltip from "react-tooltip"

const TaskSubmissionStatus = ({ taskSubmissionStatus, submissionStatus = '', onChangeStatus, disabled, task, temporal_status, submission_status, className }) => {

  return (
    <div className={`flex flex-row items-stretch gap-2 h-10 text-black ${className}`}>
      {task?.has_draft_submission &&
        <div className={`flex flex-row gap-1 justify-center items-center uppercase p-2 rounded-lg font-bold text-sm bg-gray-10`}>
          <SoraIcon icon={soraPencilEditOutline} className='w-3 h-3' />
          <span>
            Draft
          </span>
        </div>
      }
      {submission_status === 'submitted' &&
        <div className={`flex flex-row gap-1 justify-center items-center uppercase p-2 rounded-lg font-bold text-sm bg-blue-5`}
          data-tip
          data-for="submitted-tooltip">
          <SoraIcon icon={soraBoxOutline} className='w-4 h-4' />
          <span>
            Submitted
          </span>
          <ReactTooltip
            id="submitted-tooltip"
            aria-haspopup="true"
            className="normal-case"
          >
            <p>The submission was still not reviewed by an expert.</p>
          </ReactTooltip>
        </div>
      }
      {temporal_status === 'late' &&
        <>
          <div className={`flex flex-row gap-1 justify-center items-center uppercase p-2 rounded-lg font-bold text-sm bg-yellow-20`}
            data-tip
            data-for="late-tooltip"
          >
            <FontAwesomeIcon icon={faClock} />
            <span>
              Late
            </span>
            <ReactTooltip
              id="late-tooltip"
              aria-haspopup="true"
              className="normal-case"
            >
              <p className="my-1">Submissions done up to 48 hours after the task due date are still considered by experts. That's called the grace period.</p>
              <p className="my-1">The "late" tag means that you either submitted within the grace period, or there's still time do to so.</p>
              <p className="my-1">Only the first submission is considered for that logic, so any re-submissions won't change this status (flagged submissions are not considered).</p>
            </ReactTooltip>
          </div>

        </>
      }
      {temporal_status === 'missed' &&
        <div className={`flex flex-row gap-1 justify-center items-center uppercase p-2 rounded-lg font-bold text-sm bg-danger-20`}
          data-tip
          data-for="missed-tooltip"
        >
          <FontAwesomeIcon icon={faCircleQuestion} />
          <span>
            Missed
          </span>
          <ReactTooltip
            id="missed-tooltip"
            aria-haspopup="true"
            className="normal-case"
          >
            <p className="my-1">Submissions delayed for more than 48 hours from the task due date are considered missed and not reviewed by experts.</p>
            <p className="my-1">The "missed" tag means that you missed the grace period for that task.</p>
          </ReactTooltip>
        </div>
      }
      {submission_status === 'flagged' &&
        <div className={`relative flex flex-row gap-1 justify-center items-center  p-2 rounded-lg bg-danger-20 group`}
          data-tip
          data-for="flagged-tooltip">
          <SoraIcon icon={soraFlagOutline} className='w-4 h-4' />
          <span className="uppercase font-bold text-sm">
            Flagged
          </span>
          <ReactTooltip
            id="flagged-tooltip"
            aria-haspopup="true"
            className="normal-case"
          >
            <p>Please re-submit this task within the grace period (48 hours from due date) to receive feedback otherwise this will become a missed opportunity.</p>
          </ReactTooltip>
        </div>
      }
      {submission_status === 'reviewed' &&
        <div className={`flex flex-row gap-1 justify-center items-center uppercase p-2 rounded-lg font-bold text-sm bg-green-10`}
          data-tip
          data-for="reviewed-tooltip">
          <FontAwesomeIcon icon={faCircleCheck} />
          <span className="uppercase font-bold text-sm">
            Reviewed
          </span>
          <ReactTooltip
            id="reviewed-tooltip"
            aria-haspopup="true"
            className="normal-case"
          >
            <p>Your submission was already reviewed by an expert.</p>
          </ReactTooltip>
        </div>
      }
      {!disabled && onChangeStatus && <SubmissionFlag onChange={onChangeStatus} selected={submissionStatus} disabled={disabled} />}
    </div>
  )

}
TaskSubmissionStatus.propTypes = {
  taskSubmissionStatus: PropTypes.string,
  submissionStatus: PropTypes.string,
  onChangeStatus: PropTypes.func,
  disabled: PropTypes.bool,
}

export default TaskSubmissionStatus
