import axios from 'axios'

export async function loader({ params }) {
  const { data: taskDetailData } = await axios(`/tasks/${params.task_id}/detail`)
  const { data: taskSubmissionsListDataRaw } = await axios(`/task-submissions/task/${params.task_id}/student/${params.student_id}`)
  return {
    taskDetailData,
    taskSubmissionsListData: taskSubmissionsListDataRaw.task_submissions,
    noSubmissionAssessments: taskSubmissionsListDataRaw.no_submission_assessments
  }
}
