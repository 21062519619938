import React from 'react'
import PropTypes from 'prop-types'
import { SectionItem } from './section-item'

export const CycleSectionItem = ({ cycle, templateId }) => {
  const cycleColors = {
    "Cycle 1" : 'text-orange-50',
    "Cycle 2" : 'text-cyan-50', 
    "Cycle 3" : 'text-green-50', 
    "Cycle 4" : 'text-blue-50', 
    "Cycle 5" : 'text-yellow-50', 
    "Cycle 6" : 'text-pink-50'
  }

  const hasCycle = cycle.cycle_id !== null
  const sectionsAmount = cycle.data.length

  return (
    <>
      <td rowSpan={sectionsAmount} colSpan={hasCycle ? 1 : 4} className={`align-top p-2 ${hasCycle ? `pl-0  ${cycleColors[cycle.cycle_title.substring(0,7)]}` : 'bg-danger-10 text-danger-50'}`}>
          <span className={`text-nowrap rounded text-xs font-bold `}>{hasCycle ? cycle.cycle_title : 'No match'}</span>
      </td>
      <SectionItem section={cycle.data[0]} templateId={templateId} cycleId={cycle.cycle_id} />
    </>
  )
}
CycleSectionItem.propTypes = {
  cycle: PropTypes.object,
  templateId: PropTypes.number,
}
