import React from 'react'
import PropTypes from 'prop-types'
import { useFetcher } from 'react-router-dom'
import { Button, Icon } from '@design-system'
import useConfirmModal from '@hooks/useConfirmModal'

const RemoveProcess = ({ processId }) => {
  const fetcher = useFetcher()
  const confirm = useConfirmModal()
  const isLoading = fetcher.state !== 'idle'

  const handleRemove = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    const message = 'Are you sure you want to remove this process?'
    const accepted = await confirm(message)
    if (!accepted) return false

    const form = document.getElementById(`remove-process-form-${processId}`)
    fetcher.submit(form)
  }

  return (
    <fetcher.Form id={`remove-process-form-${processId}`} method="post" className="flex flex-col justify-center">
      <input type="hidden" name="_action" value="remove_process" />
      <input type="hidden" name="processId" value={processId} />
      <Button variant='outlined' color='danger' size='sm' loading={isLoading} disabled={isLoading} onClick={handleRemove}>
        <Icon name='trash-filled' size='xs' />
      </Button>
    </fetcher.Form>
  )
}
RemoveProcess.propTypes = {
  processId: PropTypes.number,
}

export default RemoveProcess
