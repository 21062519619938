import React, { useState } from 'react'
import { toast } from 'sonner'
import { useLoaderData, useParams, useRevalidator, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import _debounce from 'lodash/debounce'
import StudentOptionsItem from '@components/progress-report/student-options-item'
import Select from '@components/forms/select'
import useCycles from '@hooks/useCycles'
import { useThisPageTitle } from '@hooks/usePageTitle'
import { Switch } from '@headlessui/react'
import { Button } from '@designsystem'
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons'

const ReleaseProgressReport = () => {
  useThisPageTitle('Report Releasement')
  const { studentsProgresses, cohorts } = useLoaderData()
  const { revalidate: mutate } = useRevalidator()
  const { cycle_id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { selectedCycle } = useCycles()

  const [allStudentsChecked, checkAllStudents] = useState(false)
  const [publish, setPublish] = useState(true)
  const [publishing, setPublishing] = useState(false)
  const [studentIds, setStudentIds] = useState([])

  const cycleId = selectedCycle?.id || cycle_id
  const cohortsListOpts = [{ label: 'Any', value: '' }].concat(cohorts.map(c => ({ label: c.title, value: c.cohort_id })))
  const statusListOpts = [
    { label: 'Any', value: '' },
    { label: 'True', value: 'true' },
    { label: 'False ', value: 'false' },
  ]

  const sync = (studentIds = [], reports = {}) => {
    return axios.post('/pages/admin/workbench/report/progress/sync', { cycle_id: cycleId, student_ids: studentIds, reports })
  }

  const release = (report_type) => {
    return axios.post(`/pages/admin/workbench/report/progress/assessment/release`, { cycle_id: cycleId, report_type })
  }

  const filterStudentCohorts = (cohort_id) => {
    setStudentIds([])
    checkAllStudents(false)
    setSearchParams(prev => ({ ...Object.fromEntries(prev), cohort_id }))
  }

  const filterStatus = (status_type) => ({ value: status_value }) => {
    setStudentIds([])
    checkAllStudents(false)
    setSearchParams(prev => ({ ...Object.fromEntries(prev), [status_type]: status_value }))
  }

  const searchStudents = _debounce(({ target: { value: student = '' } }) => {
    setStudentIds([])
    checkAllStudents(false)
    setSearchParams(prev => ({ ...Object.fromEntries(prev), student }))
  }, 300)

  const toggleAll = () => {
    setStudentIds(
      !allStudentsChecked
        ? studentsProgresses.reduce((acc, curr) => [curr.student_id, ...acc], [])
        : []
    )
    checkAllStudents(!allStudentsChecked)
  }

  const handleMidCycle = ({ publish, ids }) => () => {
    setPublishing(true)
    sync(ids, { mid_cycle: publish })
      .then(() => mutate())
      .then(() => toast.success(`${publish ? 'Published' : 'Un-published'} Mid-cycle report from all selected students`))
      .catch(() => toast.error(`Unable to ${publish ? 'published' : 'un-published'}, please try again later.`))
      .finally(() => {
        setPublishing(false)
        checkAllStudents(false)
        setStudentIds([])
      })
  }

  const handleEndCycle = ({ publish, ids }) => async () => {
    setPublishing(true)
    sync(ids, { end_cycle: publish })
      .then(() => mutate())
      .then(() => toast.success(`${publish ? 'Published' : 'Un-published'} End-cycle report from all selected students`))
      .catch(() => toast.error(`Unable to ${publish ? 'published' : 'un-published'}, please try again later.`))
      .finally(() => {
        setPublishing(false)
        checkAllStudents(false)
        setStudentIds([])
      })
  }

  const releaseAssessment = (type) => async () => {
    release(type)
      .then(() => toast.success('Released to review progress reports'))
      .catch(() => toast.error('Unable to release the assessment, please try again later.'))
  }

  const updateStudent = (studentId, add) => {
    const list = add ? [studentId, ...studentIds] : studentIds.filter(x => Number(x) !== Number(studentId))
    setStudentIds(list)
    if (list.length === 0) checkAllStudents(false)
    if (list.length === studentsProgresses.length) checkAllStudents(true)
  }

  return (
    <section className="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 space-y-4 rounded">
      <div className="flex justify-between">
        <div className="flex flex-col space-y-3 xl:space-y-0 xl:flex-row xl:space-x-3 mt-auto">
          <Button onClick={toggleAll} variant="outlined" size="sm" startIcon={allStudentsChecked ? faCheckSquare : faSquare}>
            {!allStudentsChecked ? 'Check All' : 'Uncheck All'} ({studentIds.length}/{studentsProgresses.length})
          </Button>
        </div>
        <div className="flex flex-col space-y-3 xl:space-y-0 xl:flex-row xl:space-x-3 mt-auto">
          <fieldset className="flex border shadow p-2 ml-4 justify-around">
            <legend className="font-bold px-2 flex justify-between">Release Assessment</legend>
            {!publishing && <Button onClick={releaseAssessment('mid-cycle')} size="sm">Mid-Cycle</Button>}
            {publishing && <div className="py-1 text-center animate-pulse">Processing...</div>}
          </fieldset>
          <fieldset className="flex flex-row border shadow p-2 ml-4 w-64 justify-around">
            <legend className="font-bold px-2 flex flex-row justify-between">
              <Switch
                checked={publish} onChange={setPublish}
                className={`${publish ? 'bg-blue-50' : 'bg-gray-10'} relative inline-flex items-center h-6 rounded-full w-12 transition-colors ease-in-out duration-200`}
              >
                <span className="sr-only">Publish/Un-publish</span>
                <span className={`${publish ? 'translate-x-6' : 'translate-x-1'} inline-block w-4 h-4 bg-white rounded-full transform ring-0 transition ease-in-out duration-200`} />
              </Switch>
              <span className="ml-2">{publish ? 'Publish' : 'Un-publish'}</span>
            </legend>
            {!publishing &&
              <div className="flex space-x-2">
                <Button onClick={handleMidCycle({ publish, ids: studentIds })} size="sm" disabled={!studentIds.length}>
                  Mid-Cycle
                </Button>
                <Button onClick={handleEndCycle({ publish, ids: studentIds })} size="sm" disabled={!studentIds.length}>
                  End-Cycle
                </Button>
              </div>
            }
            {publishing &&
              <div className="py-1 text-center animate-pulse">
                Processing...
              </div>
            }
          </fieldset>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row lg:items-center space-x-2">
        <div className="flex flex-col w-full">
          <p className="text-sm text-gray-70">Student</p>
          <input
            className="w-full bg-white border-gray-20 rounded-lg px-4 h-[34px] border"
            type="text"
            aria-label="Search..."
            placeholder="Search..."
            defaultValue={searchParams.get('student')}
            onChange={searchStudents}
          />
        </div>
        <div className="flex flex-col w-full max-w-44">
          <p className="text-sm text-gray-70">Cohort</p>
          <Select
            placeholder="Select..."
            isClearable={false}
            isSearchable={false}
            options={cohortsListOpts}
            defaultValue={cohortsListOpts.find(c => c.value === Number(searchParams.get('cohort_id')))}
            onChange={(e) => {
              filterStudentCohorts(e.value)
            }}
          />
        </div>
        <div className="flex flex-col w-full max-w-44">
          <p className="text-sm text-gray-70">Mid-Cycle Assessed</p>
          <Select
            placeholder="Select..."
            isClearable={false}
            isSearchable={false}
            options={statusListOpts}
            defaultValue={statusListOpts.find(c => c.value === searchParams.get('has_report_assessment'))}
            onChange={filterStatus('has_report_assessment')}
          />
        </div>
        <div className="flex flex-col w-full max-w-44">
          <p className="text-sm text-gray-70">Mid-Cycle Published</p>
          <Select
            placeholder="Select..."
            isClearable={false}
            isSearchable={false}
            options={statusListOpts}
            defaultValue={statusListOpts.find(c => c.value === searchParams.get('mid_cycle'))}
            onChange={filterStatus('mid_cycle')}
          />
        </div>
        <div className="flex flex-col w-full max-w-44">
          <p className="text-sm text-gray-70">Final Task Assessed</p>
          <Select
            placeholder="Select..."
            isClearable={false}
            isSearchable={false}
            options={statusListOpts}
            defaultValue={statusListOpts.find(c => c.value === searchParams.get('has_final_assessment'))}
            onChange={filterStatus('has_final_assessment')}
          />
        </div>
        <div className="flex flex-col w-full max-w-44">
          <p className="text-sm text-gray-70">End-Cycle Published</p>
          <Select
            placeholder="Select..."
            isClearable={false}
            isSearchable={false}
            options={statusListOpts}
            defaultValue={statusListOpts.find(c => c.value === searchParams.get('end_cycle'))}
            onChange={filterStatus('end_cycle')}
          />
        </div>
      </div>
      {studentsProgresses.length === 0 &&
        <div className="text-center text-gray-50 w-full p-5 border">No students found with report progress assessed for
          this cycle</div>
      }
      {studentsProgresses.length > 0 &&
        <table className="table-fixed w-full bg-white">
          <tbody>
            {studentsProgresses.map(item => (
              <StudentOptionsItem
                key={`options_${item.student_id}`}
                item={item}
                onCheckedStudentId={updateStudent}
                checked={studentIds.includes(item.student_id)}
              />
            ))}
          </tbody>
        </table>
      }
    </section>
  )
}

export default ReleaseProgressReport
