import React from 'react'
import axios from 'axios'
import { Outlet, useLoaderData, useNavigation } from 'react-router-dom'
import { Button, Icon } from '@design-system'
import { useThisPageTitle } from '@hooks/usePageTitle'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'

const loader = async({ params }) => {
  const { processId } = params
  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}`)
  return result?.data
}

const Element = () => {
  const navigate = useNavigateWithCycle()
  const navigation = useNavigation()
  const { process } = useLoaderData()

  const isFormSubmitting = navigation.state !== 'idle'
  const { school_stage, group_title, variant_number } = process

  useThisPageTitle(`Journey Builder Workbench`)

  const handleGoBack = (event) => {
    event.preventDefault()
    navigate(`../?school_stage=${school_stage}`)
  }

  return (
    <div className='flex flex-col space-y-4 relative h-full'>
      <div className="flex flex-row space-x-3 items-center pb-4">
        <Button size='xs' variant='outlined' disabled={isFormSubmitting} onClick={handleGoBack}>
          <Icon name='cross' size='xs' />
        </Button>
        <div className='flex flex-col'>
          <h1 className="font-bold text-4xl">Journey Builder</h1>
          <span className='text-sm'>[{school_stage.toUpperCase()}] {group_title} - Variant #{variant_number}</span>
        </div>
      </div>
      <Outlet />
    </div>
  )
}

export const JourneyBuilderProcessRoute = {
  loader,
  Element,
}
