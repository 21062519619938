import React from 'react'
import axios from 'axios'
import { Form, useLoaderData, useNavigate, useNavigation, redirect, useSearchParams, useActionData } from 'react-router-dom'
import serializeFormData from '@utils/serializeFormData'
import { useThisPageTitle } from '@hooks/usePageTitle'
import { useToast } from '@hooks/useToast'
import RemoveTemplate from './remove-template'
import AddTemplate from './add-template'
import { Button, Icon } from '@design-system'

const loader = async ({ params }) => {
  const { processId } = params
  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/templates/review`)
  return result?.data
}

const action = async ({ params, request }) => {
  const { processId } = params
  const formData = await request.formData()
  const action = formData.get('_action')
  const serializedFormData = serializeFormData(formData)

  try {
    await axios.post(`/backoffice/workbench/journey-builder/processes/${processId}/templates/review`, { ...serializedFormData, action })
    return (action === 'next') ? redirect('../../assignments/students') : null
  } catch (error) {
    return {
      toast: { message: 'There was an error running Journey Builder. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const navigate = useNavigate()
  const navigation = useNavigation()
  const { templates, cycles } = useLoaderData()
  const [searchParams, setSearchParams] = useSearchParams()
  useToast(useActionData())
  useThisPageTitle(`Journey Builder Workbench`)

  const isFormSubmitting = navigation.state !== 'idle'

  const backAction = () => {
    navigate('../config')
  }

  const pickedTemplatesCategory = templates.picked.reduce((acc, tpl) => {
    if (tpl.category.toLowerCase() === 'stem') {
      acc.stem += 1
    } else if (tpl.category.toLowerCase() === 'humanities') {
      acc.humanities += 1
    }
    return acc
  }, { stem: 0, humanities: 0 })

  const sort_by = searchParams.get('sort_by')
  const sort_order = searchParams.get('sort_order')
  const sort_by_options = ['id', 'title', 'category', 'unit_impact', 'ability_impact', 'request_list_hits', 'score']
  const sort_order_options = ['asc', 'desc']

  if (sort_by_options.includes(sort_by) && sort_order_options.includes(sort_order)) {
    templates.available.sort((a, b) => {
      if (sort_order === 'asc') {
        return a[sort_by] - b[sort_by]
      } else {
        return b[sort_by] - a[sort_by]
      }
    })
  }

  const sort = (sortBy) => () => {
    setSearchParams({ sort_by: sortBy, sort_order: sort_order === 'asc' ? 'desc' : 'asc' })
  }

  return (
    <>
      <div className="flex-auto relative">
        <div className='overflow-y-auto w-full absolute top-0 bottom-20 text-sm space-y-4 px-2'>
          {isFormSubmitting && <div className='bg-gray-2 opacity-70 absolute top-0 bottom-0 right-0 left-0 flex flex-col justify-center items-center z-50'>
            <span className='font-bold text-xl animate-pulse'>Refreshing...</span>
          </div>}
          <div className='overflow-auto h-full text-sm relative'>
            <h3 className='text-xl underline py-3'>Picked Templates</h3>
            {(templates.picked.length === 0) && <div className='text-center'>No templates picked yet.</div>}
            {(templates.picked.length > 0) && 
              <table className='w-full'>
                <thead className='bg-screen-primary text-left sticky top-0'>
                  <tr>
                    <th className='py-1 px-3 w-10'></th>
                    <th className='py-1 px-3 w-20'>ID</th>
                    <th className='py-1 px-3'>Template</th>
                    <th className='py-1 px-3 w-[100px]'>Category</th>
                    <th className='py-1 px-3 w-[150px]'>Experts</th>
                    <th className='py-1 px-3 w-[300px]'>Units</th>
                    <th className='py-1 px-3 w-[130px]'>Requests Hits</th>
                    <th className='py-1 px-3 w-[240px]'>Allowed Cycles</th>
                  </tr>
                  <tr>
                    <th colSpan={8} className='h-px bg-alpha/5'></th>
                  </tr>
                </thead>
                <tbody>
                  {templates.picked.map(tpl => <RemoveTemplate key={`picked_template_${tpl.template_id}`} template={tpl} defaultCycles={cycles} />)}
                </tbody>
              </table>
            }
            <h3 className='text-xl underline py-3'>Templates to Pick</h3>
            <table className='w-full'>
              <thead className='bg-screen-primary text-left sticky top-0'>
                <tr>
                  <th className='py-1 px-3 w-10'></th>
                  <th className='py-1 px-3 w-20'>ID</th>
                  <th className='py-1 px-3'>Template</th>
                  <th className='py-1 px-3 w-[100px]'>Category</th>
                  <th className='py-1 px-3 w-[150px]'>Experts</th>
                  <th className='py-1 px-3 w-[300px]'>Units</th>
                  <th className={`py-1 px-3 w-20 cursor-pointer group`} onClick={sort('unit_impact')}>
                    <div className={`flex flex-row gap-2 items-center`}>
                      <span className='group-hover:underline'>Units Impact</span>
                      {sort_by === 'unit_impact' && <span><Icon name={sort_order === 'asc' ? 'chevron-up' : 'chevron-down'} /></span>}
                    </div>
                  </th>
                  <th className='py-1 px-3 w-20 cursor-pointer group' onClick={sort('ability_impact')}>
                    <div className={`flex flex-row gap-2 items-center`}>
                      <span className='group-hover:underline'>Abilities Impact</span>
                      {sort_by === 'ability_impact' && <span><Icon name={sort_order === 'asc' ? 'chevron-up' : 'chevron-down'} /></span>}
                    </div>
                  </th>
                  <th className='py-1 px-3 w-20 cursor-pointer group' onClick={sort('request_list_hits')}>
                    <div className={`flex flex-row gap-2 items-center`}>
                      <span className='group-hover:underline'>Requests Hits</span>
                      {sort_by === 'request_list_hits' && <span><Icon name={sort_order === 'asc' ? 'chevron-up' : 'chevron-down'} /></span>}
                    </div>
                  </th>
                </tr>
                <tr>
                  <th colSpan={9} className='h-px bg-alpha/5'></th>
                </tr>
              </thead>
              <tbody>
                {templates.available.map(tpl => <AddTemplate key={`available_template_${tpl.template_id}`} template={tpl} />)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Form method="post" id="journey-template-form" className='flex flex-row justify-end align-middle p-4 space-x-3 absolute bottom-0 left-0 right-0 rounded-xl border border-gray-30 bg-white z-70'>
        <input type="hidden" name='_action' value='next' />
        <div className='flex flex-row items-center text-lg'>
          <span className='font-bold'>Selected:</span>
          <span className='px-2'>{templates.picked.length} template(s)</span>
          <span>({pickedTemplatesCategory.stem} Stem | {pickedTemplatesCategory.humanities} Humanities)</span>
        </div>
        <div className='flex-auto'></div>
        <Button type='button' onClick={backAction} disabled={isFormSubmitting} variant='outlined'>Back</Button>
        <Button type='submit' disabled={isFormSubmitting} loading={isFormSubmitting}>Next</Button>
      </Form>
    </>
  )
}

export const JourneyBuilderTemplatesReviewRoute = {
  loader,
  action,
  Element,
}
