import axios from 'axios'

export async function loader({ params, request }) {
  const cycle_id = new URL(request.url).searchParams.get('cycle_id')
  const [{ data }, { data: progressData }] = await Promise.all([
    axios.get(`/pages/student/${params.student_id}/progress/cycle${cycle_id ? `?cycle_id=${cycle_id}` : ''}`),
    axios.get(`/individual/cycle-progress?student_id=${params.student_id}${cycle_id ? `&cycle_id=${cycle_id}` : ''}`)
  ])
  return {
    themes: data.themes,
    schoolStage: data.schoolStage,
    progress: progressData.result,
    userRole: data.userRole,
  }
}
