import React, { useEffect, useState } from 'react'
import { useFetcher } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Icon } from '@design-system'

export const AddExpert = ({ templateId }) => {
  const fetcher = useFetcher()
  const [experts, setExperts] = useState(undefined)
  const isLoading = fetcher.state !== 'idle' && Number(fetcher.formData?.get('template_id')) === Number(templateId)

  useEffect(() => {
    setExperts(fetcher.data?.experts)
  }, [fetcher.data])

  const handleAddTemplate = async (event) => {
    if (isNaN(event.target.value)) return false
    fetcher.submit(event.target.form)
  }

  const handleCancel = () => {
    delete fetcher.data?.experts
    setExperts(undefined)
  }

  return (
    <div className='relative'>
      {(experts) &&
        <div className='bg-white p-2 border drop-shadow absolute bottom-0 z-30'>
          <fetcher.Form method="post" className="flex flex-col gap-2">
            <input type="hidden" name="_action" value="assign_expert" />
            <input type="hidden" name="template_id" value={templateId} />
            <div>
              <Button variant='ghost' size="xs" onClick={handleCancel}>
                <Icon name="arrow-left" size='xs' />
              </Button>
            </div>
            <select name='expert_id' onChange={handleAddTemplate}>
              <option value=''>Select a new expert</option>
              {experts.map(expert => {
                return (
                  <option key={`template_${templateId}_expert_${expert.id}`} value={expert.id}>
                    {`(${expert.job_title}) ${expert.name}`}
                  </option>
                )
              })}
            </select>
          </fetcher.Form>
        </div>
      }
      <fetcher.Form method="get" className="flex flex-col justify-center">
        <input type="hidden" name="_action" value="get_experts" />
        <input type="hidden" name="template_id" value={templateId} />
        <Button type="submit" size="sm" disabled={isLoading} loading={isLoading}>Add new expert</Button>
      </fetcher.Form>
    </div>
  )
}
AddExpert.propTypes = {
  templateId: PropTypes.number,
}
