import React, { useState } from "react"
import { useLoaderData } from "react-router-dom"
import PropTypes from "prop-types"
import { Button } from "@designsystem"
import axios from "axios"

const loader = async ({ params }) => {
  const { processId } = params
  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/schedule/generated/experts`)
  return result?.data
}

const ToggleStudents = ({ total, list }) => {
  const [show, setShow] = useState(false)
  return (
    <>
      <div className='w-[50px] whitespace-nowrap'>
        <span>Total: {total}</span>

      </div>
      {total > 0 &&
        <>
          <div className="py-2">
            <Button size="xs" variant="outlined" onClick={() => setShow(!show)}>{show ? 'Hide' : 'Show'}</Button>
          </div>
          <div className={`flex flex-row flex-wrap gap-2 ${show ? 'block' : 'hidden'}`}>
            {list.map(std => (
              <div key={std} className="py-1 px-2 h-fit text-xs rounded bg-gray-20 text-black">
                {std}
              </div>
            ))}
          </div>
        </>
      }
    </>
  )
}
ToggleStudents.propTypes = {
  total: PropTypes.total,
  list: PropTypes.array,
}

const Element = () => {
  const { experts } = useLoaderData()

  const mountSection = (expertId, cycleId, item, expertIdx, isFirst = false) => {
    const isRedNotice = item.has_conflict ? 'bg-danger-10 text-danger-60' : 'bg-white'
    return (
      <React.Fragment key={`${expertIdx}_expert_${expertId}_cycle_${cycleId}_lb_${item.learning_block_id}`}>
        <div className={`flex flex-col ${isRedNotice} p-3 ${isFirst ? 'mt-4' : ''}`}>{item.learning_block_title}&nbsp;</div>
        <div className={`flex flex-row justify-between gap-2 ${isRedNotice} p-3 ${isFirst ? 'mt-4' : ''}`}>{item.template_title}&nbsp;</div>
        <div className={`flex flex-col ${isRedNotice} p-3 ${isFirst ? 'mt-4' : ''}`}>
          <ToggleStudents total={item.student_qty} list={item.students} />&nbsp;
        </div>
      </React.Fragment>
    )
  }

  const cycleRow = (expertId, cycle, idx, isFirst = false) => {
    return (
      <React.Fragment key={`expert_${expertId}_cycle_${cycle.id}`}>
        <div className={`flex flex-col bg-white p-3 ${isFirst ? 'mt-4' : ''}`} style={{ gridRow: `span ${cycle.data.length}` }}>
          <span>{cycle.cycle_title}</span>
        </div>
        {mountSection(expertId, cycle.cycle_id, cycle.data[0], idx, isFirst)}
        {((cycle.data.length) > 1) && cycle.data.slice(1).map(x => mountSection(expertId, cycle.cycle_id, x, idx))}
      </React.Fragment>
    )
  }

  const cycleRowEmpty = () => {
    return (
      <React.Fragment>
        <div className={`flex flex-col bg-white mt-4 p-3`}>
          <span>&nbsp;</span>
        </div>
        <div className={`flex flex-col bg-white mt-4 p-3`}>
          <span>&nbsp;</span>
        </div>
        <div className={`flex flex-col bg-white mt-4 p-3`}>
          <span>&nbsp;</span>
        </div>
        <div className={`flex flex-col bg-white mt-4 p-3`}>
          <span>&nbsp;</span>
        </div>
      </React.Fragment>
    )
  }

  const expertRow = (expert, idx) => {
    const maxRowSpan = expert.cycles.reduce((acc, curr) => (curr.data.length) + acc, 0)
    return (
      <React.Fragment key={`expert_${expert.id}`}>
        <div className='flex flex-col bg-white mt-4 p-3' style={{ gridRow: `span ${maxRowSpan}` }}>
          <span className='font-bold'>{expert.name}</span>
        </div>
        {((expert.cycles.length) === 0) && cycleRowEmpty()}
        {((expert.cycles.length) > 0) && cycleRow(expert.id, expert.cycles[0], idx, true)}
        {((expert.cycles.length) > 1) && expert.cycles.slice(1).map(x => cycleRow(expert.id, x, idx))}
      </React.Fragment>
    )
  }

  return (
    <div className='grid grid-cols-[200px_100px_200px_2fr_1fr] auto-cols-max w-full'>
      <div className='font-bold text-lg p-3 sticky top-0 bg-gray-5 border-b border-gray-30'>Expert</div>
      <div className='font-bold text-lg p-3 sticky top-0 bg-gray-5 border-b border-gray-30'>Cycle</div>
      <div className='font-bold text-lg p-3 sticky top-0 bg-gray-5 border-b border-gray-30'>Learning Block</div>
      <div className='font-bold text-lg p-3 sticky top-0 bg-gray-5 border-b border-gray-30'>Template</div>
      <div className='font-bold text-lg p-3 sticky top-0 bg-gray-5 border-b border-gray-30'>Students</div>
      {experts.map(expertRow)}
    </div>
  )
}

export const JourneyBuilderScheduleGeneratedExpertsRoute = {
  loader,
  Element,
}
