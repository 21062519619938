import React from 'react'
import axios from 'axios'
import { redirect, useLoaderData } from 'react-router-dom'
import serializeFormData from '@utils/serializeFormData'
import SchoolStageFilter from './school-stage-filter'
import { Button, Icon } from '@design-system'
import { ListProcess } from './list-process'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'

const loader = async({ params, request }) => {
  const url = new URL(request.url)
  const searchParams = url.searchParams
  const schoolStage = searchParams.get('school_stage')

  const result = await axios.get('/backoffice/workbench/journey-builder/processes/_index', { params: { school_stage: schoolStage } })
  return result?.data
}

const action = async ({ request }) => {
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const action = serializedFormData._action

  try {
    const result = await axios.post(`/backoffice/workbench/journey-builder/processes/_index`, { ...serializedFormData, action, processId: serializedFormData.processId })
    if (action === 'clone_process') return redirect(`./${result.data.id}`)
  } catch (error) {
    return {
      toast: { message: 'There was an error to work with this process. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }

  return null
}

const Element = () => {
  const navigate = useNavigateWithCycle()
  const { pastGroupProcesses, onGoingGroupProcesses } = useLoaderData()

  return (
    <div className='flex flex-col min-w-fit'>
      <div className='flex flex-row justify-between items-center pb-3'>
        <span className='font-bold text-4xl'>Journey Builder</span>
        <Button onClick={() => { navigate('./new') }} >
          <Icon name='plus' size='sm'/>
          <span>New process</span>          
        </Button>
      </div>
      <SchoolStageFilter />
      <div className='flex flex-col px-10'>
        <ListProcess title='Ongoing processes' type='ongoing' groupProcesses={onGoingGroupProcesses} />
        <ListProcess title='Past processes' type='past' groupProcesses={pastGroupProcesses} />
      </div>
    </div>
  )
}

export const JourneyBuilderProcessesIndexRoute = {
  loader,
  action,
  Element,
}
