import React from 'react'
import axios from 'axios'
import { useLoaderData } from 'react-router-dom'
import { RenderedSlate } from '@components/forms/slate-textarea'

export const loader = async ({ request }) => {
  const url = new URL(request.url)
  const searchParams = url.searchParams
  const result = await axios.get('/pages/admin/reports/materials', { params: searchParams })
  return result?.data
}

export default function MaterialsReportRoute() {
  const loaderData = useLoaderData() as { rows: Array<{ id: number, title: string, key_features_materials_and_tools_description: any }> }

  return (
    <div className="grid grid-cols-3 my-20 bg-white">
      <h4 className="font-bold text-xl border-b border-gray-20 p-4">Experience</h4>
      <h4 className="font-bold col-span-2 text-xl border-b border-gray-20 p-4">Tools and Materials</h4>
      {loaderData.rows.map((row) => {
        return (
          <React.Fragment key={row.id}>
            <div className="border-b border-gray-20 p-4">{row.title}</div>
            <div className="col-span-2 border-b border-gray-20 p-4">
              <RenderedSlate value={row.key_features_materials_and_tools_description} />
            </div>
          </React.Fragment>
        )
      })}
    </div>
  )
}

