import React from 'react'
import PropTypes from 'prop-types'

export const UnitList = ({ units }) => {
  return (
    <div className='flex flex-row flex-wrap gap-3'>
      {units.map(unit => (
        <div key={unit} className='rounded py-1 px-2 bg-green-10 font-bold text-xs text-black whitespace-nowrap'>{unit}</div>
      ))}
    </div>
  )
}
UnitList.propTypes = {
  units: PropTypes.array,
}
