import React, { useState, useRef, useEffect } from 'react'
import { Outlet, useParams, useOutletContext, useLoaderData, useLocation } from 'react-router-dom'
import useNavigateWithCycle, { NavigateCycle } from '@hooks/useNavigateWithCycle'
import TaskDetail from './submission/task-detail'
import TaskSubmissionList from './submission/task-submission-list'
import { Tab } from '@headlessui/react'
import StudentDetail from '@components/facilitate/student/student-detail'

const AssessmentSubmission = () => {
  const location = useLocation()
  const taskDetailRef = useRef(null)
  const navigate = useNavigateWithCycle()
  const { student_id, task_id, task_submission_id, theme_id } = useParams()
  const { showStudents } = useOutletContext()
  const [contextData, setContextData] = useState({})

  const { taskDetailData, taskSubmissionsListData, noSubmissionAssessments } = useLoaderData()

  const getTaskType = () => {
    return taskDetailData?.result?.type || 'default'
  }
  useEffect(() => {
    setContextData(() => {
      const regex = new RegExp(`auto_save_slate_\\d{1,}_${location.pathname}`)
      const keys = Object.keys(window.localStorage).filter((key) => regex.test(key))
      return keys.reduce((acc, curr) => {
        const item = JSON.parse(window.localStorage.getItem(curr))
        return { ...acc, [item.type]: [...(acc[item.type] || []), item.item] }
      }, {})
    })
  }, [location.pathname])

  const handleSelectedSubmission = (id) => {
    navigate(`./submissions/${id}`, { replace: true })
  }

  const setUnits = (units) => {
    delete contextData.units
    setContextData({ ...contextData, ...{ units } })
  }

  const setAbilities = (abilities) => {
    delete contextData.abilities
    setContextData({ ...contextData, ...{ abilities } })
  }

  const taskSubmissionId = !task_submission_id && taskSubmissionsListData && taskSubmissionsListData.length > 0
    ? taskSubmissionsListData[0].id
    : null


  const isFirstLoad = !location.pathname.split('/')[8]
  if (isFirstLoad) {
    if (taskSubmissionId) return <NavigateCycle to={`submissions/${taskSubmissionId}`} replace={true} />
    return <NavigateCycle to="submissions" replace={true} />
  }

  const taskType = getTaskType()

  const tab = location.pathname.split('/')[8]
  const tabs = ['submissions', 'expedition']
  let tabsTitle = ['Expedition']

  switch (taskType) {
    case 'report': {
      tabsTitle.unshift('Mid-Cycle Report')
      break
    }
    case 'final': {
      tabsTitle.unshift('End-Cycle Assessment')
      break
    }
    default: {
      tabsTitle.unshift('Task Feedback')
      break
    }
  }

  const handleOnChangeIndex = (index) => {
    const taskId = task_submission_id || taskSubmissionsListData?.[0]?.id
    if (index === 0) {
      if (taskId) return navigate(`./submissions/${taskId}`)
      return navigate('./submissions')
    }
    if (index === 1) {
      return navigate('./expedition')
    }
  }

  const selectedTab = tabs.indexOf(tab)

  return (
    <>
      <div className='flex flex-col flex-1 gap-2 min-w-[37.5rem]'>
        <div className='flex flex-col border border-gray-40 rounded-xl py-6 flex-auto bg-white'>
          <div className='px-4'>
            <TaskDetail task_id={task_id} ref={taskDetailRef} taskData={taskDetailData} />
          </div>
          <TaskSubmissionList
            data={taskSubmissionsListData}
            task_id={task_id}
            student_id={student_id}
            task_submission_id={task_submission_id}
            onSelectedSubmission={handleSelectedSubmission}
          />
        </div>
      </div>
      <div className={`flex flex-col bg-white border border-gray-40 rounded-xl py-6 px-4 min-w-[20rem] w-[27.5rem] ${showStudents ? '' : 'flex-1'}`}>
        <StudentDetail student_id={student_id} theme_id={theme_id} />
        <Tab.Group onChange={handleOnChangeIndex} selectedIndex={selectedTab !== -1 ? selectedTab : 0}>
          <Tab.List className="flex space-x-4">
            {tabsTitle.map((typeTitle) => (
              <Tab
                key={`tab_${typeTitle}`}
                className={({ selected }) =>
                  `pt-8 pb-3 focus:outline-none whitespace-nowrap ${selected
                    ? 'font-bold border-b-2 border-b-yellow-20'
                    : 'truncate ...'
                  }`
                }
              >
                {typeTitle}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="flex flex-col flex-auto">
            {tabs.map((key) => (
              <Tab.Panel
                key={`feedbacktabpanel_${key}`}
                className="flex flex-col flex-auto break-all"
              >
                <Outlet context={{ showStudents, noSubmissionAssessments, getTaskType, contextData, setContextData, setUnits, setAbilities }} />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  )
}

export default AssessmentSubmission
