import React from "react"
import { Outlet, useLoaderData, useLocation } from "react-router-dom"
import axios from "axios"
import { Tab } from "@headlessui/react"
import SoraLink from "@components/link"
import JourneyScheduleMetrics from "./journey-schedule-metrics"

const loader = async ({ params }) => {
  const { processId } = params
  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/schedule/generated`)
  return result?.data
}

const Element = () => {
  const { pathname } = useLocation()
  const { metrics } = useLoaderData()

  const tabs = [
    { label: 'Students', path: './students' },
    { label: 'Experts', path: './experts' },
    { label: 'Expeditions', path: './expeditions' },
    { label: 'Missing', path: './missing' },
  ]

  const currentIndex = tabs.findIndex(t => pathname.includes(t.path.slice(1)))

  return (
    <>
      <div className="flex flex-row">
        <Tab.Group selectedIndex={currentIndex >= 0 ? currentIndex : 0}>
          <Tab.List className='flex-auto'>
            {tabs.map(t => (
              <Tab
                key={t.label}
                className={({ selected }) => `p-3 text-xl truncate focus:outline-none border-b-2 border-transparent ${selected ? 'font-bold border-accent cursor-default' : 'hover:underline'}`}
              >
                <SoraLink to={t.path}>
                  {t.label}
                </SoraLink>
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
        <JourneyScheduleMetrics metrics={metrics} />
      </div>
      <div className="overflow-y-auto text-sm space-y-4 px-2">
        <Outlet />
      </div>
    </>
  )
}

export const JourneyBuilderScheduleGeneratedRoute = {
  loader,
  Element,
}
