import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import SelectUnits from '../forms/select-units'
import InputStars from '../forms/input-stars'

const UnitLevelFields = ({
  units,
  values: unitLevel,
  onChange,
  onRemove,
}) => {
  const handleUnitChange = (unit_id) =>
    onChange({
      ...unitLevel,
      unit_id,
    })

  const handleLevelChange = (level) =>
    onChange({
      ...unitLevel,
      level: Number(level),
    })

  const handleDelete = (event) => {
    event.preventDefault()
    onRemove()
  }

  return (
    <div className="grid grid-cols-4 items-center auto-rows-fr items-center gap-2 mb-4">
      <SelectUnits
        isMulti={false}
        units={units}
        selected_ids={[Number(unitLevel.unit_id)]}
        onChange={handleUnitChange}
      />
      <div
        type="text"
        className="flex flex-row text-sm items-center border-gray-10 rounded-lg py-1 w-full"
      >
        <InputStars
          defaultValue={unitLevel.level || 0}
          onChange={handleLevelChange}
          maximumNumberOfStars={4}
        />
      </div>
      <div>
        <button
          className="block bg-danger-50 text-white w-8 h-8 rounded font-bold px-2 float-right"
          onClick={handleDelete}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </div>
  )
}

UnitLevelFields.propTypes = {
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  units: PropTypes.any,
  values: PropTypes.shape({
    level: PropTypes.number,
    unit_id: PropTypes.any,
  }),
}
export default UnitLevelFields
