import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Button } from '@designsystem'
import Select from '@components/forms/select'

const EditableTaskAbility = ({ initialTaskAbility, availableAbilities, afterSubmit }) => {
  const [editing, setEditing] = useState(false)
  const [formValues, setFormValues] = useState(initialTaskAbility)

  const isNew = !initialTaskAbility.id

  if (!editing && isNew) {
    return <Button size="sm" variant="outlined" onClick={() => setEditing(true)}>+ Add Ability</Button>
  }

  if (!editing) {
    return <div className="bg-gray-10 border rounded p-2 text-xs space-y-2 font-bold" onClick={() => setEditing(true)}>{initialTaskAbility.ability?.title}</div>
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (isNew) {
      axios.post(`/tasks/${formValues.task_id}/abilities`, formValues)
        .then(() => {
          afterSubmit && afterSubmit()
          setEditing(false)
        })
        .catch(console.error)
    }
  }

  const handleDelete = e => {
    e.preventDefault()
    axios.delete(`/tasks/${initialTaskAbility.task_id}/abilities/${initialTaskAbility.ability_id}`)
      .then(() => {
        afterSubmit && afterSubmit()
      })
      .catch(console.error)
  }

  const abilitiesOpts = availableAbilities.map(aa => ({ value: aa.id, label: `[${aa.school_stage.toUpperCase()}] ${aa.title}` }))
  return <form className="flex flex-col justify-between bg-gray-10 border rounded p-2 text-sm h-[6.5rem]" onSubmit={handleSubmit}>
    <Select
      isMulti={false}
      isClearable={false}
      placeholder="Select an ability"
      onChange={e => setFormValues(prev => ({ ...prev, ability_id: Number(e.value) }))}
      options={abilitiesOpts}
      readOnlyNode={<span>{formValues?.ability?.title || ''}</span>}
      readOnly={!isNew}
    />
    <div className="flex space-x-2">
      {isNew && <Button size="xs" type="submit">Add this ability</Button>}
      <Button size="xs" variant="outlined" onClick={() => setEditing(false)}>Cancel</Button>
      {!isNew && <Button size="xs" variant="outlined" onClick={handleDelete}>Delete</Button>}
    </div>
  </form>
}

EditableTaskAbility.propTypes = {
  initialTaskAbility: PropTypes.shape({
    id: PropTypes.number,
    task_id: PropTypes.number,
    ability_id: PropTypes.number,
    ability: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
  availableAbilities: PropTypes.array,
  afterSubmit: PropTypes.func,
}

export default EditableTaskAbility
