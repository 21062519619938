import React from "react"
import { redirect, useActionData, useLoaderData } from "react-router-dom"
import axios from "axios"
import serializeFormData from "@utils/serializeFormData"
import { useToast } from "@hooks/useToast"
import { StudentSectionList } from "./student-section-list"

const loader = async ({ params, request }) => {
  const { processId } = params
  const searchParams = new URL(request.url).searchParams
  
  const action = searchParams.get('_action') || 'default'
  const studentId = searchParams.get('student_id')
  const cycleId = searchParams.get('cycle_id')

  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/schedule/review/students`, { params: { action, student_id: studentId, cycle_id: cycleId } })
  return result?.data
}

const action = async ({ params, request }) => {
  const { processId } = params
  const formData = await request.formData()
  const action = formData.get('_action')
  const serializedFormData = serializeFormData(formData)

  if (action === 'cancel') return null

  try {
    await axios.post(`/backoffice/workbench/journey-builder/processes/${processId}/schedule/review/students`, { ...serializedFormData, action })
    return (action === 'generate') ? redirect('../../generated/students') : null
  } catch (error) {
    return {
      toast: { message: 'There was an error running Journey Builder. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const { students } = useLoaderData()
  useToast(useActionData())

  return (
    <table className="w-full">
      <thead className='top-0 bg-screen-primary z-50 text-left sticky'>
        <tr>
          <th>Student</th>
          <th>Progress Projection</th>
          <th>Cycle</th>
          <th></th>
          <th>Learning Block</th>
          <th>Expert</th>
          <th>Template</th>
          <th>First Time</th>
          <th>New Units</th>
        </tr>
        <tr>
          <th colSpan={9} className="h-px bg-alpha/5" />
        </tr>
      </thead>
      <tbody>
        <StudentSectionList students={students} />
      </tbody>
    </table>
  )
}

export const JourneyBuilderScheduleReviewStudentsRoute = {
  action,
  loader,
  Element,
}
