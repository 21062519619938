import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import axios from 'axios'
import useConfirmModal from '@hooks/useConfirmModal'
import RubricDrawer from '@components/rubric-drawer'
import UnitAbilitySearchBar from '@components/facilitate/feedback/unit-ability-search-bar'
import AssessmentPickedList from './assessment-picked-list'

const UnitsAbilitiesAssessment = forwardRef(({
  apiUrl,
  selectedUnits = [],
  selectedAbilities = [],
  onUpdateSelectedUnits,
  onUpdateSelectedAbilities,
  disabled,
  compressed,
  saveItem,
  allowAddFeedback = false,
  noDataMessage,
  presetComments,
  onOpenPresetManager,
  refreshPresetComments,
  taskType,
}, ref) => {
  const { data, mutate } = useSWR(apiUrl ? apiUrl : null)

  const confirm = useConfirmModal()
  const drawerRef = useRef(null)

  useImperativeHandle(ref, () => ({
    refreshComponent: () => {
      mutate(apiUrl, axios.get(apiUrl).then(res => {
        const unitList = res.data.result?.units?.filter(u => u.picked) || []
        const abilityList = res.data.result?.abilities?.filter(a => a.picked) || []
        onUpdateSelectedUnits([...unitList])
        onUpdateSelectedAbilities([...abilityList])
      }))
    },
    getMissingItems: (unitIds, abilityIds, isFinalAssessment = false) => {
      /*
        For the !selectedUnitIds.includes(unit.id)/!selectedAbilityIds.includes(ability.id) part below,
        on the Final Task Assessment(isFinalAssessment), if the employee does not select a level for a unit/ability,
        it should fetch the preselected ones so that the backend receives an assessment with level 0 instead of no assessment.
      */
      const unitsMissing = data?.result?.units
        ?.filter(unit => unit.picked && (unit.flag === 'ontrack' || (isFinalAssessment && !selectedUnitIds.includes(unit.id))) && !unitIds.includes(unit.id))
        ?.map(u => { u.levelAssessed = u.faculty_level >= 0 ? u.faculty_level : null; return u }) || []
      const abilitiesMissing = data?.result?.abilities
        ?.filter(ability => ability.picked && (ability.flag === 'ontrack' || (isFinalAssessment && !selectedAbilityIds.includes(ability.id))) && !abilityIds.includes(ability.id))
        ?.map(a => { a.levelAssessed = a.faculty_level >= 0 ? a.faculty_level : null; return a }) || []

      return {
        units: unitsMissing,
        abilities: abilitiesMissing
      }
    }
  }))

  if (!data) return (<span>Loading</span>)

  const units = data.result?.units.sort((a, b) => String(a.topic_title + a.title).localeCompare(String(b.topic_title + b.title)))
  const selectedUnitIds = selectedUnits.map(u => u.id)
  const unitsPreSelected = data.result?.units?.filter(unit => unit.picked && !selectedUnitIds.includes(unit.id)) || []
  const unitsLastStateSelected = [...unitsPreSelected, ...selectedUnits]

  const abilities = data.result?.abilities.sort((a, b) => String(a.title).localeCompare(String(b.title)))
  const selectedAbilityIds = selectedAbilities.map(a => a.id)
  const abilitiesPreSelected = data.result?.abilities?.filter(ability => ability.picked && !selectedAbilityIds.includes(ability.id)) || []
  const abilitiesLastStateSelected = [...abilitiesPreSelected, ...selectedAbilities]

  const noUnitsAndAbilities = unitsLastStateSelected.length === 0 && abilitiesLastStateSelected.length === 0

  const handleSelected = (type, obj) => {
    obj.flag = 'ontrack'
    switch (type) {
      case 'unit':
        onUpdateSelectedUnits([...selectedUnits, ...[obj]])
        break
      case 'ability':
        onUpdateSelectedAbilities([...selectedAbilities, ...[obj]])
        break
    }
  }

  const handleRemoveItem = (type, obj) => async () => {
    if (await confirm('Are you sure you want to remove it?') === true) {
      switch (type) {
        case 'unit':
          onUpdateSelectedUnits(selectedUnits.filter(su => su.id !== obj.id))
          break
        case 'ability':
          onUpdateSelectedAbilities(selectedAbilities.filter(sa => sa.id !== obj.id))
          break
      }
    }
  }

  const handleChangeItem = (type, obj) => {
    switch (type) {
      case 'unit':
        onUpdateSelectedUnits([...selectedUnits.filter(u => u.id !== obj.id), ...[obj]])
        break
      case 'ability':
        onUpdateSelectedAbilities([...selectedAbilities.filter(a => a.id !== obj.id), ...[obj]])
        break
    }
  }

  const handleSaveItem = (type, obj) => {
    let payload = {}
    if (type === 'unit') payload.units = [obj]
    if (type === 'ability') payload.abilities = [obj]

    saveItem(payload).then(async () => {
      await mutate(apiUrl, axios.get(apiUrl).then(res => {
        if (type === 'unit') {
          const unitList = res.data.result?.units?.filter(u => u.id == obj.id) || []
          onUpdateSelectedUnits([...selectedUnits.filter(u => u.id !== obj.id), ...unitList])
        }
        if (type === 'ability') {
          const abilityList = res.data.result?.abilities?.filter(a => a.id == obj.id) || []
          onUpdateSelectedAbilities([...selectedAbilities.filter(a => a.id !== obj.id), ...abilityList])
        }
      }))
    })
  }

  const handleShowRubricsItem = (type, id) => {
    drawerRef.current.open(type, id)
  }

  return (
    <div className='flex flex-col gap-3'>
      <RubricDrawer ref={drawerRef} />
      {(!disabled && allowAddFeedback) &&
        <UnitAbilitySearchBar
          units={units}
          abilities={abilities}
          selectedUnits={unitsLastStateSelected}
          selectedAbilities={abilitiesLastStateSelected}
          onSelect={handleSelected}
        />
      }

      <div className='flex flex-col space-y-5'>
        <div>
          <AssessmentPickedList
            type='unit'
            list={unitsLastStateSelected}
            title={`Units (${unitsLastStateSelected?.length})`}
            onRemoveItem={handleRemoveItem}
            onChangeItem={handleChangeItem}
            onSaveItem={handleSaveItem}
            onShowRubricsItem={handleShowRubricsItem}
            onShowPresetCommentItem={onOpenPresetManager}
            disabled={disabled}
            compressed={compressed}
            allowAddFeedback={allowAddFeedback}
            presetComments={presetComments}
            refreshPresetComments={refreshPresetComments}
            taskType={taskType}
          />
        </div>
        <div>
          <AssessmentPickedList
            type='ability'
            list={abilitiesLastStateSelected}
            title={`Abilities (${abilitiesLastStateSelected?.length})`}
            onRemoveItem={handleRemoveItem}
            onChangeItem={handleChangeItem}
            onSaveItem={handleSaveItem}
            onShowRubricsItem={handleShowRubricsItem}
            onShowPresetCommentItem={onOpenPresetManager}
            disabled={disabled}
            compressed={compressed}
            allowAddFeedback={allowAddFeedback}
            presetComments={presetComments}
            refreshPresetComments={refreshPresetComments}
            taskType={taskType}
          />
        </div>
      </div>

      {noUnitsAndAbilities &&
        <div className='flex flex-col'>{noDataMessage}</div>
      }
    </div>
  )
})
UnitsAbilitiesAssessment.displayName = 'UnitsAbilitiesAssessment'
UnitsAbilitiesAssessment.propTypes = {
  apiUrl: PropTypes.string,
  selectedUnits: PropTypes.array,
  selectedAbilities: PropTypes.array,
  onUpdateSelectedUnits: PropTypes.func,
  onUpdateSelectedAbilities: PropTypes.func,
  disabled: PropTypes.bool,
  compressed: PropTypes.bool,
  saveItem: PropTypes.func,
  allowAddFeedback: PropTypes.bool,
  noDataMessage: PropTypes.any,
  presetComments: PropTypes.array,
  onOpenPresetManager: PropTypes.func,
  refreshPresetComments: PropTypes.func,
  taskType: PropTypes.string,
}

export default UnitsAbilitiesAssessment
