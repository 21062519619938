import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import SessionFields from './sessionFields'
import SelectUnits from '../forms/select-units'
import capitalize from '@utils/capitalize'
import add from 'date-fns/add'
import isBefore from 'date-fns/isBefore'
import isAfter from 'date-fns/isAfter'
import isEqual from 'date-fns/isEqual'
import DateTimePicker from '../forms/date-time-picker'
import Checkbox from '../forms/checkbox/index.jsx'
import SlateTextarea from '@components/forms/slate-textarea'
import MeetUnitLevels from './meetUnitLevels'
import UnitLevelFields from './unitLevelFields'
import useUserFeatureFlag from '@hooks/useUserFeatureFlag'
import CycleSelector from './cycleSelector'
import { Form } from 'react-router-dom'
import uniqueId from 'lodash/uniqueId'
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import { Button, TextField } from '@designsystem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Select from '@components/forms/select'
import debounce from 'lodash/debounce'
import Input from '@components/forms/input'
import { useFormPersistence } from '@hooks/useFormPersistence'

const EXPEDITION_TYPES = [
  'expedition',
  'track',
  'coworking',
  'learning_goal',
  'math_learning_goal',
  'language_learning_goal',
  'independent_study',
  'activity',
  'club',
]
const EXPEDITION_CATEGORIES = ['stem', 'humanities', 'electives']


export default function ExpeditionForm({
  initialExpedition,
  employees,
  units,
  cohorts,
  cycles,
  submitting,
  isHeartbeatFeatureFlagEnabled,
  isMsHeartbeatFeatureFlagEnabled,
  fieldErrors = {},
  savedFields = [],
}) {

  const videoSectionDescriptionRef = useRef()
  const keyFeaturesSectionDescriptionRef = useRef()
  const keyFeaturesCareerConnectionsDescriptionRef = useRef()
  const keyFeaturesEssentialQuestionsDescriptionRef = useRef()
  const keyFeaturesLearningObjectivesDescriptionRef = useRef()
  const keyFeaturesMaterialsAndToolsDescriptionRef = useRef()
  const keyFeaturesOptionalTopicDescriptionRef = useRef()
  const keyFeaturesPathsOfInquiryDescriptionRef = useRef()
  const keyFeaturesProjectCreationDescriptionRef = useRef()
  const formPersistenceRef = useFormPersistence()

  const { checkBetaFeature } = useUserFeatureFlag()
  const isRegistrationWaitlist = checkBetaFeature('waitlist')

  const initialEmployeesIds = (initialExpedition.employees || []).map(
    (e) => e.id
  )

  const [formValues, setFormValues] = useState({
    ...initialExpedition,
    prereqUnits: initialExpedition.units_levels?.filter(
      (skl) => skl.type === 'prereq'
    ),
    developUnits: initialExpedition.units_levels?.filter(
      (skl) => skl.type === 'develop'
    ),
    enable_heartbeat: !initialExpedition.id || initialExpedition.enable_heartbeat,
    sessions: initialExpedition.sessions,
    employees_ids: initialEmployeesIds,
  })

  const selectedCycle = cycles.find(
    (c) => c.id === Number(formValues.competition_interval_id)
  )
  const isRegistrationOpen = selectedCycle.open

  const handleChange = (event) => {
    const {
      target: { name, value, checked, type },
    } = event
    const resetMsSeats =
      name === 'cohort_id' && Number(value) === 1
        ? { middle_school_seats: 0 }
        : {}

    setFormValues((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
      ...resetMsSeats,
    }))
  }

  const handleUnitChange = (unit_id) => (unitFields) => {
    setFormValues((prevState) => {
      const nextUnitLevels = [...prevState.prereqUnits].map((skl) =>
        skl.unit_id === unit_id ? unitFields : skl
      )
      return {
        ...prevState,
        prereqUnits: nextUnitLevels,
      }
    })
  }

  const handleUnitRemove = (unit_id, type) => () => {
    setFormValues((prevState) => {
      const nextUnitLevels = [...prevState[type]].filter(
        (skl) => skl.unit_id !== unit_id
      )
      return {
        ...prevState,
        [type]: nextUnitLevels,
      }
    })
  }

  const handleAddUnit = (type) => (unit_id) => {
    setFormValues((prevState) => ({
      ...prevState,
      [type]: [
        ...(prevState[type] || []),
        {
          type,
          unit_id,
          level: 1,
        },
      ],
    }))
  }

  const handleSessionRemove = (key) => () => {
    setFormValues((prevState) => {
      let nextState = { ...prevState }
      const nextStateSessions = nextState?.sessions || []
      let nextSessions = [...nextStateSessions]
      nextState.sessions = nextSessions.filter(s => s.id !== key && s.key !== key)
      return nextState
    })
  }

  const handleSessionDuplicate = (key) => () => {
    setFormValues((prevState) => {
      let nextState = { ...prevState }
      const nextStateSessions = nextState?.sessions || []
      let nextSessions = [...nextStateSessions]
      const index = nextSessions.findIndex(s => s.id === key || s.key === key)

      const title = document.getElementsByName(`sessions[${key}][title]`)[0].value
      const rte_description = JSON.parse(document.getElementsByName(`sessions[${key}][rte_description]`)[0].value)
      const duration = document.getElementsByName(`sessions[${key}][duration]`)[0].value
      const attendance_type = document.getElementsByName(`sessions[${key}][attendance_type]`)[0].value
      const timestamptz = document.getElementsByName(`sessions[${key}][timestamptz]`)[0].value
      const conference_url = document.getElementsByName(`sessions[${key}][conference_url]`)[0].value

      const duplicated = {
        key: uniqueId(),
        title,
        rte_description,
        duration,
        timestamptz,
        expedition_id: initialExpedition.id,
        attendance_type,
        conference_url,
      }

      const expeditionTitle = (document.getElementsByName(`title`)[0].value + ' - ') || ''
      duplicated.title = `Session ${formValues.sessions.length + 1} - ${expeditionTitle}`
      nextSessions.splice(index, 1, nextSessions[index], duplicated)
      nextState.sessions = nextSessions
      return nextState
    })
  }

  const handleSessionRepeatWeekly = (key) => () => {
    const title = document.getElementsByName(`sessions[${key}][title]`)[0].value
    const rte_description = JSON.parse(document.getElementsByName(`sessions[${key}][rte_description]`)[0].value)
    const duration = document.getElementsByName(`sessions[${key}][duration]`)[0].value
    const attendance_type = document.getElementsByName(`sessions[${key}][attendance_type]`)[0]?.value
    const timestamptz = document.getElementsByName(`sessions[${key}][timestamptz]`)[0].value
    const conference_url = document.getElementsByName(`sessions[${key}][conference_url]`)[0].value || ''
    const theme_title = document.getElementsByName(`title`)[0].value || 'Unnamed'

    setFormValues((prevState) => {
      let nextState = { ...prevState }
      const template = {
        title,
        rte_description,
        duration,
        attendance_type,
        conference_url,
      }
      let repeatingDate = add(new Date(timestamptz), {
        weeks: 1,
      })
      let existingTimestamptzs = []
      for (let i = 0; i < 100; i++) {
        const iTimestamptz = document.getElementsByName(`sessions[${i}][timestamptz]`)?.[0]?.value
        if (!iTimestamptz) break
        existingTimestamptzs.push(new Date(iTimestamptz))
      }
      const startCycleDate = new Date(selectedCycle.start_timestamp)
      const endCycleDate = new Date(selectedCycle.end_timestamp)
      while (
        isBefore(repeatingDate, endCycleDate) &&
        isAfter(repeatingDate, startCycleDate)
      ) {
        const sessionAlreadyExists = existingTimestamptzs.some((etz) =>
          isEqual(etz, repeatingDate)
        )
        !sessionAlreadyExists &&
          nextState.sessions.push({
            ...template,
            key: uniqueId(),
            title: `Session ${nextState.sessions.length + 1} - ${theme_title}`,
            timestamptz: repeatingDate,
          })
        repeatingDate = add(repeatingDate, { weeks: 1 })
      }
      return nextState
    })
  }

  const handleAddSession = (event) => {
    let {
      target: { value },
    } = event
    if (value) {
      const key = uniqueId()

      const createdDate = zonedTimeToUtc(setHours(setMinutes(new Date(value), 0), 10), 'America/New_York')

      const defaultConferenceUrl = formValues.employees_ids
        .map((eid) => employees.find((e) => e.id === eid))
        .find((e) => e.conference_url)?.conference_url

      const theme_title = document.getElementsByName(`title`)[0].value || 'Unnamed'

      setFormValues((prevState) => ({
        ...prevState,
        sessions: [
          ...(prevState.sessions || []),
          {
            key,
            title: `Session ${formValues.sessions.length + 1} - ${theme_title}`,
            timestamptz: createdDate,
            conference_url: defaultConferenceUrl,
          },
        ],
      }))
    }
  }

  const searchFunction = useCallback(
    (options) =>
      debounce((value, callback) => {
        const searchedOptions = options
          .filter((option) =>
            option.label.toUpperCase().includes(value.toUpperCase())
          )
          .sort((a, b) =>
            String(a.label).trim().localeCompare(String(b.label).trim())
          )
        callback(searchedOptions)
      }, 500),
    []
  )

  const prereqUnitLevels = formValues.prereqUnits || []
  const prereqUnitIds = prereqUnitLevels.map((unit) => Number(unit.unit_id))

  const minDate = new Date(selectedCycle.start_timestamp)
  const maxDate = new Date(selectedCycle.end_timestamp)

  const addedSessionsCount = formValues.sessions.filter((s) => !s.id).length
  const removedSessionsCount = initialExpedition.sessions
    .map((s) => s.id)
    .filter((id) => !formValues.sessions.map((fs) => fs.id).includes(id)).length

  const isInvalidSession = (session) => {
    const timestamptz = document.getElementsByName(`sessions[${session.id || session.key}][timestamptz]`)[0]?.value
    return (
      new Date(timestamptz) > maxDate ||
      new Date(timestamptz) < minDate
    )
  }

  const formValid = formValues.type !== 'club'
    ? (formValues.title?.length > 0 &&
      !formValues.sessions.some(isInvalidSession) &&
      formValues.employees_ids.length > 0 &&
      validateSeats({
        seats: {
          year_10_seats: formValues.year_10_seats,
          year_11_seats: formValues.year_11_seats,
          year_12_seats: formValues.year_12_seats,
          year_9_seats: formValues.year_9_seats,
        },
        maxStudents: formValues.max_students,
      }))
    : (
      formValues.title?.length > 0 &&
      !formValues.sessions.some(isInvalidSession) &&
      formValues.max_students > 0
    )

  const selectedCohort = cohorts.find(
    (c) => c.id === Number(formValues.cohort_id)
  )

  const employeesOptions = employees
    .map(({ id, job_title, first_name, last_name }) => ({
      value: id,
      label: `${job_title}: ${first_name} ${last_name}`,
    }))

  const shouldShowEnableHeartbeatField = isHeartbeatFeatureFlagEnabled

  return (
    <Form
      className="min-w-250 flex flex-col space-y-4 shadow-md rounded bg-white p-6 mb-15"
      method="post"
      id="theme-form"
      ref={formPersistenceRef}
    >
      {initialExpedition.id && <input type="hidden" name="id" value={initialExpedition.id} />}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <label className="flex-1">
          <h3 className="ml-3 text-sm font-bold">Type:</h3>
          <select
            className={`border-gray-10 rounded-lg py-1 px-2 mt-0.5 w-full ${savedFields.includes('type') ? 'border !border-notice-50 !ring-notice-50' : ''}`}
            name="type"
            value={formValues.type}
            onChange={handleChange}
          >
            <option disabled hidden value="">
              -- please select a type --
            </option>
            {EXPEDITION_TYPES.map((type) => (
              <option key={type} value={type}>
                {type.split('_').map(capitalize).join(' ')}
              </option>
            ))}
          </select>
        </label>
        {formValues.type === 'expedition' && (
          <label className="flex-1">
            <h3 className="ml-3 text-sm font-bold">Category:</h3>
            <select
              placeholder="Pick category"
              className={`border-gray-10 rounded-lg py-1 px-2 mt-0.5 w-full ${savedFields.includes('category') ? 'border !border-notice-50 !ring-notice-50' : ''}`}
              name="category"
              value={formValues.category}
              onChange={handleChange}
            >
              {EXPEDITION_CATEGORIES.map((category) => (
                <option key={category} value={category}>
                  {category.split('_').map(capitalize).join(' ')}
                </option>
              ))}
            </select>
          </label>
        )}

        <label className="flex-1">
          <h3
            className="ml-3 text-sm font-bold"
            title="Select the cohort this experience would be available"
          >Campus:</h3>
          <select
            className={`border-gray-10 rounded-lg py-1 px-2 mt-0.5 w-full ${savedFields.includes('cohort_id') ? 'border !border-notice-50 !ring-notice-50' : ''}`}
            name="cohort_id"
            value={formValues.cohort_id}
            onChange={handleChange}
          >
            <option value="">-- all campuses --</option>
            {cohorts.map((cohort) => (
              <option key={cohort.id} value={cohort.id}>
                {cohort.title}
              </option>
            ))}
          </select>
        </label>
      </div>

      <div>
        <h3 className="ml-3 font-bold text-xl mb-2">Employees</h3>
        <Select
          options={employeesOptions}
          defaultValue={initialEmployeesIds.map(id => employeesOptions.find((option) => option.value === id))}
          isMulti
          className={savedFields.includes('employees_ids') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="employees_ids"
          placeholder="Type or select the employee"
          searchFunction={searchFunction(employeesOptions)}
          onChange={(employees) => {
            setFormValues((prevState) => {
              const newEmployees = employees.map(({ value }) => value)
              return {
                ...prevState,
                employees_ids: newEmployees,
              }
            })
          }}
        />
      </div>

      <div className="grid grid-cols-2 gap-2">
        <TextField
          className={savedFields.includes('image_url') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="image_url"
          label="Image URL"
          value={formValues.image_url || ''}
          onChange={handleChange}
          error={fieldErrors.image_url}
        />
        <TextField
          className={savedFields.includes('image_alt') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="image_alt"
          value={formValues.image_alt || ''}
          label="Image alt text"
          onChange={handleChange}
          error={fieldErrors.image_alt}
        />
      </div>

      className={savedFields.includes('type') ? '!bordertitle-notice-50 !ring-notice-50' : ''}
      <TextField label="Title" name="title" value={formValues.title || ''} onChange={handleChange} error={fieldErrors.title} />

      <label>
        <h3 className="ml-3 text-sm font-bold">Short description</h3>
        <Input
          className={savedFields.includes('short_description') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="short_description"
          id="template_short_description"
          placeholder=""
          defaultValue={initialExpedition?.short_description} />
      </label>
      <label>
        <h3 className="ml-3 text-sm font-bold">Video Section Title</h3>
        <Input
          className={savedFields.includes('video_section_title') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="video_section_title"
          id="template_video_section_title"
          placeholder="Expedition Introduction"
          defaultValue={initialExpedition?.video_section_title} />
      </label>
      <label
        htmlFor="template_video_section_description"
        onClick={() => videoSectionDescriptionRef.current.focus()}
      >
        <h3 className="ml-3 text-sm font-bold">Video Section Description</h3>
        <SlateTextarea
          value={initialExpedition?.video_section_description}
          className={savedFields.includes('video_section_description') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="video_section_description"
          id="template_video_section_description"
          ref={videoSectionDescriptionRef}
          aria-label="template_video_section_description"
          placeholder="Type description here"
        />
      </label>
      <label>
        <h3 className="ml-3 text-sm font-bold">Video Section Video Url</h3>
        <Input
          className={savedFields.includes('video_section_video_url') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="video_section_video_url"
          id="template_video_section_video_url"
          placeholder=""
          defaultValue={initialExpedition?.video_section_video_url} />
      </label>
      <label>
        <h3 className="ml-3 text-sm font-bold">Key Features Section Title</h3>
        <Input
          className={savedFields.includes('key_features_section_title') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="key_features_section_title"
          id="template_key_features_section_title"
          placeholder=""
          defaultValue={initialExpedition?.key_features_section_title} />
      </label>
      <label
        htmlFor="key_features_section_description"
        onClick={() => keyFeaturesSectionDescriptionRef.current.focus()}
      >
        <h3 className="ml-3 text-sm font-bold">Key Features Section Description</h3>
        <SlateTextarea
          className={savedFields.includes('key_features_section_descripti') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="key_features_section_description"
          id="template_key_features_section_description"
          aria-label="template_key_features_section_description"
          ref={keyFeaturesSectionDescriptionRef}
          placeholder="Type description here"
          value={initialExpedition?.key_features_section_description}
        />
      </label>
      <label>
        <h3 className="ml-3 text-sm font-bold">Key Features Career Connections Title</h3>
        <Input
          className={savedFields.includes('key_features_career_connection') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="key_features_career_connections_title"
          id="template_key_features_career_connections_title"
          placeholder="Career Connections"
          defaultValue={initialExpedition?.key_features_career_connections_title} />
      </label>
      <label
        htmlFor="key_features_career_connections_description"
        onClick={() => keyFeaturesCareerConnectionsDescriptionRef.current.focus()}
      >
        <h3 className="ml-3 text-sm font-bold">Key Features Career Connections Description</h3>
        <SlateTextarea
          className={savedFields.includes('key_features_career_connection') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="key_features_career_connections_description"
          id="template_key_features_career_connections_description"
          aria-label="template_key_features_career_connections_description"
          ref={keyFeaturesCareerConnectionsDescriptionRef}
          placeholder="Type description here"
          value={initialExpedition?.key_features_career_connections_description}
        />
      </label>
      <label>
        <h3 className="ml-3 text-sm font-bold">Key Features Essential Questions Title</h3>
        <Input
          className={savedFields.includes('key_features_essential_questio') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="key_features_essential_questions_title"
          id="template_key_features_essential_questions_title"
          placeholder="Essential Questions"
          defaultValue={initialExpedition?.key_features_essential_questions_title} />
      </label>
      <label
        htmlFor="key_features_essential_questions_description"
        onClick={() => keyFeaturesEssentialQuestionsDescriptionRef.current.focus()}
      >
        <h3 className="ml-3 text-sm font-bold">Key Features Essential Questions Description</h3>
        <SlateTextarea
          className={savedFields.includes('key_features_essential_questio') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="key_features_essential_questions_description"
          id="template_key_features_essential_questions_description"
          aria-label="template_key_features_essential_questions_description"
          ref={keyFeaturesEssentialQuestionsDescriptionRef}
          placeholder="Type description here"
          value={initialExpedition?.key_features_essential_questions_description}
        />
      </label>
      <label>
        <h3 className="ml-3 text-sm font-bold">Key Features Learning Objectives Title</h3>
        <Input
          className={savedFields.includes('key_features_learning_objectiv') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="key_features_learning_objectives_title"
          id="template_key_features_learning_objectives_title"
          placeholder="Learning Objectives"
          defaultValue={initialExpedition?.key_features_learning_objectives_title} />
      </label>
      <label
        htmlFor="key_features_learning_objectives_description"
        onClick={() => keyFeaturesLearningObjectivesDescriptionRef.current.focus()}
      >
        <h3 className="ml-3 text-sm font-bold">Key Features Learning Objectives Description</h3>
        <SlateTextarea
          className={savedFields.includes('key_features_learning_objectiv') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="key_features_learning_objectives_description"
          id="template_key_features_learning_objectives_description"
          aria-label="template_key_features_learning_objectives_description"
          ref={keyFeaturesLearningObjectivesDescriptionRef}
          placeholder="Type description here"
          value={initialExpedition?.key_features_learning_objectives_description}
        />
      </label>
      <label>
        <h3 className="ml-3 text-sm font-bold">Key Features Materials And Tools Title</h3>
        <Input
          className={savedFields.includes('key_features_materials_and_too') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="key_features_materials_and_tools_title"
          id="template_key_features_materials_and_tools_title"
          placeholder="Materials And Tools"
          defaultValue={initialExpedition?.key_features_materials_and_tools_title} />
      </label>
      <label
        htmlFor="key_features_materials_and_tools_description"
        onClick={() => keyFeaturesMaterialsAndToolsDescriptionRef.current.focus()}
      >
        <h3 className="ml-3 text-sm font-bold">Key Features Materials And Tools Description</h3>
        <SlateTextarea
          className={savedFields.includes('key_features_materials_and_too') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="key_features_materials_and_tools_description"
          id="template_key_features_materials_and_tools_description"
          aria-label="template_key_features_materials_and_tools_description"
          ref={keyFeaturesMaterialsAndToolsDescriptionRef}
          placeholder="Type description here"
          value={initialExpedition?.key_features_materials_and_tools_description}
        />
      </label>
      <label>
        <h3 className="ml-3 text-sm font-bold">Key Features Optional Topic Title</h3>
        <Input
          className={savedFields.includes('key_features_optional_topic_ti') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="key_features_optional_topic_title"
          id="template_key_features_optional_topic_title"
          placeholder=""
          defaultValue={initialExpedition?.key_features_optional_topic_title} />
      </label>
      <label
        htmlFor="key_features_optional_topic_description"
        onClick={() => keyFeaturesOptionalTopicDescriptionRef.current.focus()}
      >
        <h3 className="ml-3 text-sm font-bold">Key Features Optional Topic Description</h3>
        <SlateTextarea
          className={savedFields.includes('key_features_optional_topic_de') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="key_features_optional_topic_description"
          id="template_key_features_optional_topic_description"
          aria-label="template_key_features_optional_topic_description"
          ref={keyFeaturesOptionalTopicDescriptionRef}
          placeholder="Type description here"
          value={initialExpedition?.key_features_optional_topic_description}
        />
      </label>
      <label>
        <h3 className="ml-3 text-sm font-bold">Key Features Paths Of Inquiry Title</h3>
        <Input
          className={savedFields.includes('key_features_paths_of_inquiry_') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="key_features_paths_of_inquiry_title"
          id="template_key_features_paths_of_inquiry_title"
          placeholder="Paths Of Inquiry"
          defaultValue={initialExpedition?.key_features_paths_of_inquiry_title} />
      </label>
      <label
        htmlFor="key_features_paths_of_inquiry_description"
        onClick={() => keyFeaturesPathsOfInquiryDescriptionRef.current.focus()}
      >
        <h3 className="ml-3 text-sm font-bold">Key Features Paths Of Inquiry Description</h3>
        <SlateTextarea
          className={savedFields.includes('key_features_paths_of_inquiry_') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="key_features_paths_of_inquiry_description"
          id="template_key_features_paths_of_inquiry_description"
          aria-label="template_key_features_paths_of_inquiry_description"
          ref={keyFeaturesPathsOfInquiryDescriptionRef}
          placeholder="Type description here"
          value={initialExpedition?.key_features_paths_of_inquiry_description}
        />
      </label>
      <label>
        <h3 className="ml-3 text-sm font-bold">Key Features Project Creation Title</h3>
        <Input
          className={savedFields.includes('key_features_project_creation_') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="key_features_project_creation_title"
          id="template_key_features_project_creation_title"
          placeholder="Project Creation"
          defaultValue={initialExpedition?.key_features_project_creation_title} />
      </label>
      <label
        htmlFor="key_features_project_creation_description"
        onClick={() => keyFeaturesProjectCreationDescriptionRef.current.focus()}
      >
        <h3 className="ml-3 text-sm font-bold">Key Features Project Creation Description</h3>
        <SlateTextarea
          className={savedFields.includes('key_features_project_creation_') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="key_features_project_creation_description"
          id="template_key_features_project_creation_description"
          aria-label="template_key_features_project_creation_description"
          ref={keyFeaturesProjectCreationDescriptionRef}
          placeholder="Type description here"
          value={initialExpedition?.key_features_project_creation_description}
        />
      </label>
      <label>
        <h3 className="ml-3 text-sm font-bold">Quote Section Text</h3>
        <Input
          className={savedFields.includes('quote_section_text') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="quote_section_text"
          id="template_quote_section_text"
          placeholder=""
          defaultValue={initialExpedition?.quote_section_text} />
      </label>
      <label>
        <h3 className="ml-3 text-sm font-bold">Quote Section Author</h3>
        <Input
          className={savedFields.includes('quote_section_author') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="quote_section_author"
          id="template_quote_section_author"
          placeholder=""
          defaultValue={initialExpedition?.quote_section_author} />
      </label>
      <label>
        <h3 className="ml-3 text-sm font-bold">Quote Section Image Url</h3>
        <Input
          className={savedFields.includes('quote_section_image_url') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="quote_section_image_url"
          id="template_quote_section_image_url"
          placeholder=""
          defaultValue={initialExpedition?.quote_section_image_url} />
      </label>

      <label
        htmlFor="experience-description"
      >
        <h3 className="ml-3 text-sm font-bold">Description</h3>
        <SlateTextarea
          id={`experience-${formValues.id}-description`}
          className={savedFields.includes('rte_description') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="rte_description"
          value={formValues.rte_description}
          placeholder="Type your description here"
        />
      </label>
      <label
        htmlFor="experience-overview"
      >
        <h3 className="ml-3 text-sm font-bold">{formValues.type === 'activity' ? 'Project details' : 'Overview'}</h3>
        <SlateTextarea
          id={`experience-${formValues.id}-overview`}
          className={savedFields.includes('overview') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="overview"
          value={formValues.overview}
          placeholder="Type the overview here"
        />
      </label>
      <label
        htmlFor="experience-tools_and_materials"
      >
        <h3 className="ml-3 text-sm font-bold">Tools and Materials</h3>
        <SlateTextarea
          id={`experience-${formValues.id}-tools_and_materials`}
          className={savedFields.includes('tools_and_materials') ? 'border !border-notice-50 !ring-notice-50' : ''}
          name="tools_and_materials"
          value={formValues.tools_and_materials}
          placeholder="Add tools and materials here"
        />
      </label>
      <TextField
        label="Max Students"
        // readOnly={initialExpedition.id && isRegistrationOpen}
        type="number"
        className={savedFields.includes('max_students') ? 'border !border-notice-50 !ring-notice-50' : ''}
        name="max_students"
        min="0"
        value={formValues.max_students || ''}
        onChange={handleChange}
      />
      {isRegistrationWaitlist && (
        <label>
          <h3 className="font-bold ml-3">Seat Reservation</h3>
          <div
            className={`grid ${formValues.cohort_id === 2 ? 'grid-cols-5' : 'grid-cols-4'
              } gap-2`}
          >
            <TextField
              label="Year 12"
              type="number"
              min="0"
              readOnly={initialExpedition.id && isRegistrationOpen}
              className={savedFields.includes('year_12_seats') ? 'border !border-notice-50 !ring-notice-50' : ''}
              name="year_12_seats"
              value={formValues.year_12_seats || 0}
              onChange={handleChange}
            />
            <TextField
              label="Year 11"
              type="number"
              readOnly={initialExpedition.id && isRegistrationOpen}
              min="0"
              className={savedFields.includes('year_11_seats') ? 'border !border-notice-50 !ring-notice-50' : ''}
              name="year_11_seats"
              value={formValues.year_11_seats || 0}
              onChange={handleChange}
            />
            <TextField
              label="Year 10"
              type="number"
              readOnly={initialExpedition.id && isRegistrationOpen}
              min="0"
              className={savedFields.includes('year_10_seats') ? 'border !border-notice-50 !ring-notice-50' : ''}
              name="year_10_seats"
              value={formValues.year_10_seats || 0}
              onChange={handleChange}
            />
            <TextField
              label="Year 09"
              type="number"
              readOnly={initialExpedition.id && isRegistrationOpen}
              min="0"
              className={savedFields.includes('year_9_seats') ? 'border !border-notice-50 !ring-notice-50' : ''}
              name="year_9_seats"
              value={formValues.year_9_seats || 0}
              onChange={handleChange}
            />
            {Number(formValues.cohort_id) === 2 && (
              <TextField
                label="Middle School"
                type="number"
                readOnly={initialExpedition.id && isRegistrationOpen}
                min="0"
                className={savedFields.includes('middle_school_seats') ? 'border !border-notice-50 !ring-notice-50' : ''}
                name="middle_school_seats"
                value={formValues.middle_school_seats || 0}
                onChange={handleChange}
              />
            )}
          </div>
        </label>
      )}

      <CycleSelector
        cycles={cycles}
        className={savedFields.includes('competition_interval_id') ? 'border !border-notice-50 !ring-notice-50' : ''}
        name="competition_interval_id"
        value={formValues.competition_interval_id}
        required
        onChange={handleChange}
      />

      {shouldShowEnableHeartbeatField && (
        <div className="w-full border-y-1 py-2">
          <h3>Heartbeat</h3>
          <div className="text-sm w-full bg-blue-2 border rounded p-2 my-2">
            <p> <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-blue-50 w-4" />This option will enable heartbeat integration for this expedition.</p>
            <p className="ml-6">We will automatically create a channel for it and add all the registered students and employees to it.</p>
          </div>
          <label className="col-span-2">
            <input 
              type="hidden"
              name="enable_heartbeat"
              value={!formValues.enable_heartbeat && 'false'}
            />
            <h3 className="text-sm font-bold inline-block">
              Enable heartbeat:
            </h3>
            <Checkbox
              className={`ml-3 inline-block ${savedFields.includes('enable_heartbeat') ? 'border !border-notice-50 !ring-notice-50' : ''}`}
              name="enable_heartbeat"
              checked={!!formValues.enable_heartbeat}
              onChange={handleChange}
            />
            {!formValues.enable_heartbeat &&
              Boolean(formValues.heartbeat_channel_id || formValues.heartbeat_group_id) && (
                <span className="text-xs ml-3 font-bold text-yellow-40">
                  (!) The heartbeat {formValues.heartbeat_channel_id && 'channel '}{' '}
                  {formValues.heartbeat_group_id && 'and group '}
                  will be deleted after submission.
                </span>
              )}
          </label>
          {formValues.enable_heartbeat && (
            <div className="grid grid-cols-2 gap-2">
              <TextField
                label="Heartbeat Channel ID"
                className={savedFields.includes('heartbeat_channel_id') ? 'border !border-notice-50 !ring-notice-50' : ''}
                name="heartbeat_channel_id"
                value={formValues.heartbeat_channel_id}
                disabled
                placeholder={'-- created after submit --'}
                onChange={handleChange}
              />
              <TextField
                label="Heartbeat Group ID"
                className={savedFields.includes('heartbeat_group_id') ? 'border !border-notice-50 !ring-notice-50' : ''}
                name="heartbeat_group_id"
                disabled
                value={formValues.heartbeat_group_id}
                placeholder={'-- created after submit --'}
                onChange={handleChange}
              />
            </div>
          )}
        </div>
      )}

      <div>
        <h3 className="ml-3 font-bold text-xl mb-2">Units</h3>
        <h4 className="text-sm font-bold ml-3 mb-1">
          Prereq Units (
          <MeetUnitLevels
            unitLevels={prereqUnitLevels}
            cycle_id={Number(formValues.competition_interval_id)}
          />
          )
        </h4>
        {formValues.prereqUnits.map(({ unit_id, level }, index) => (
          <React.Fragment key={unit_id}>
            <input type="hidden" name={`units_levels[${index}][unit_id]`} value={unit_id} />
            <input type="hidden" name={`units_levels[${index}][level]`} value={level} />
            <input type="hidden" name={`units_levels[${index}][type]`} value='prereq' />
          </React.Fragment>
        ))}
        {prereqUnitLevels.map((unit) => (
          <UnitLevelFields
            key={unit.unit_id}
            units={units.filter(
              (u) =>
                Number(u.id) === Number(unit.unit_id) ||
                !prereqUnitIds.includes(u.id)
            )}
            values={unit}
            onChange={handleUnitChange(unit.unit_id)}
            onRemove={handleUnitRemove(unit.unit_id, 'prereqUnits')}
          />
        ))}
        <SelectUnits
          isMulti={false}
          units={units
            .filter((unit) => !prereqUnitIds.includes(unit.id))
            .filter(
              (unit) =>
                !selectedCohort?.school_stage ||
                unit.school_stage === selectedCohort?.school_stage ||
                unit.is_ms_elective
            )}
          selected_ids={[]}
          placeholder="-- please select a prereq unit to add --"
          onChange={handleAddUnit('prereqUnits')}
        />
      </div>

      <div>
        <h3 className="text-xl font-bold ml-3">
          Sessions
          {addedSessionsCount > 0 && (
            <sup className="text-green-50"> +{addedSessionsCount}</sup>
          )}
          {removedSessionsCount > 0 && (
            <sup className="text-danger-40"> -{removedSessionsCount}</sup>
          )}
        </h3>
        <ul>
          {(formValues.sessions || []).map((session) => {
            const key = session.id || session.key
            return (
              <li key={key}>
                <SessionFields
                  name={`sessions[${key}]`}
                  minDate={minDate}
                  maxDate={maxDate}
                  values={session}
                  onRemove={handleSessionRemove(key)}
                  onRepeatWeekly={handleSessionRepeatWeekly(key)}
                  onDuplicate={handleSessionDuplicate(key)}
                  showManualTimeInput={formValues.type === 'club'}
                />
              </li>
            )
          })}
        </ul>
        <label>
          Please select a date for the next session:
          <DateTimePicker
            minDate={minDate}
            maxDate={maxDate}
            format="P"
            placeholderText="-- please select a day to add a new session --"
            onChange={handleAddSession}
            className="px-2 mb-2 py-1 border rounded"
          />
        </label>
      </div>
      <div className="flex justify-end">
        <Button type="submit" disabled={!formValid} loading={submitting}>{submitting ? 'Submitting...' : 'Submit'}</Button>
      </div>
    </Form >
  )
}

ExpeditionForm.propTypes = {
  initialExpedition: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    short_description: PropTypes.string,
    video_section_title: PropTypes.string,
    video_section_description: PropTypes.string,
    video_section_video_url: PropTypes.string,
    key_features_section_title: PropTypes.string,
    key_features_section_description: PropTypes.string,
    key_features_career_connections_title: PropTypes.string,
    key_features_career_connections_description: PropTypes.string,
    key_features_essential_questions_title: PropTypes.string,
    key_features_essential_questions_description: PropTypes.string,
    key_features_learning_objectives_title: PropTypes.string,
    key_features_learning_objectives_description: PropTypes.string,
    key_features_materials_and_tools_title: PropTypes.string,
    key_features_materials_and_tools_description: PropTypes.string,
    key_features_optional_topic_title: PropTypes.string,
    key_features_optional_topic_description: PropTypes.string,
    key_features_paths_of_inquiry_title: PropTypes.string,
    key_features_paths_of_inquiry_description: PropTypes.string,
    key_features_project_creation_title: PropTypes.string,
    key_features_project_creation_description: PropTypes.string,
    quote_section_text: PropTypes.string,
    quote_section_author: PropTypes.string,
    quote_section_image_url: PropTypes.string,
    type: PropTypes.string,
    category: PropTypes.string,
    rte_description: PropTypes.array,
    overview: PropTypes.array,
    tools_and_materials: PropTypes.array,
    image_url: PropTypes.string,
    image_alt: PropTypes.string,
    max_students: PropTypes.number,
    cohort_id: PropTypes.number,
    year_12_seats: PropTypes.number,
    year_11_seats: PropTypes.number,
    year_10_seats: PropTypes.number,
    year_9_seats: PropTypes.number,
    middle_school_seats: PropTypes.number,
    competition_interval_id: PropTypes.number,
    enable_heartbeat: PropTypes.bool,
    heartbeat_channel_id: PropTypes.string,
    heartbeat_group_id: PropTypes.string,
    sessions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
    units_levels: PropTypes.arrayOf(
      PropTypes.shape({

      })
    ),
    employees: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    )
  }),
  employees: PropTypes.array,
  units: PropTypes.array,
  cohorts: PropTypes.array,
  cycles: PropTypes.array,
  submitting: PropTypes.bool,
  isHeartbeatFeatureFlagEnabled: PropTypes.bool,
  isMsHeartbeatFeatureFlagEnabled: PropTypes.bool,
  fieldErrors: PropTypes.object,
  savedFields: PropTypes.array,
}

const validateSeats = ({ seats, maxStudents }) =>
  Number(maxStudents) >=
  Object.values(seats).reduce(
    (acc, curr) => (curr ? Number(curr) + acc : acc),
    0
  )
