import React from 'react'
import PropTypes from 'prop-types'
import { UnitList } from './unit-list'
import { StudentTags } from './student-tags'
import { RemoveTemplateSection } from './remove-template-section'
import { AddSection } from './add-section'
import { EditableLearningBlock } from './editable-learning-block'
import { EditableTemplate } from './editable-template'

export const SectionItem = ({ section, cycleId, expertId }) => {
  if (section) {
    return (
      <React.Fragment key={`expert_${expertId}_cycle_${cycleId}_${section.learning_block_id}_${section.template_id}`}>
        <td className='p-2 pl-0 align-top'>
          <RemoveTemplateSection expertId={expertId} cycleId={cycleId} learningBlockId={section.learning_block_id} templateId={section.template_id} hasStudents={section.students.length > 0} readOnly={section.readonly || section.scheduled} />
        </td>
        <td className={`text-nowrap p-2 align-top ${section.has_conflict ? 'bg-danger-5' : ''}`}>
          <EditableLearningBlock expertId={expertId} cycleId={cycleId} learningBlockId={section.learning_block_id} title={section.learning_block_title} readOnly={section.readonly || section.scheduled} />
        </td>
        <td className='text-nowrap p-2 pl-0 align-top'>
          <EditableTemplate expertId={expertId} cycleId={cycleId} learningBlockId={section.learning_block_id} templateId={section.template_id} title={section.template_title} category={section.template_category} scheduled={section.scheduled} readOnly={section.readonly || section.scheduled} />
        </td>
        <td className='align-top'>
          <UnitList units={section.units} />
        </td>
        <td className='align-top'>
          <StudentTags students={section.students} />
        </td>
      </React.Fragment>
    )
  }

  return (<AddSection cycleId={cycleId} expertId={expertId} />)
}
SectionItem.propTypes = {
  section: PropTypes.object,
  cycleId: PropTypes.number,
  expertId: PropTypes.number,
}
