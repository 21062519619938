import React from "react"
import { useFetcher } from "react-router-dom"
import PropTypes from "prop-types"
import { Button, Icon } from "@design-system"

export const AddSection = ({ id, expertId, cycleId }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle'

  const handleCancel = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('_action', 'cancel')
    fetcher.submit(formData, { method: 'post' })
  }

  const sections = fetcher.data

  return (
    <td colSpan={5} className="px-2">
      <div className="relative">
        {(sections?.learning_blocks && sections?.templates) &&	
          <div className='bg-white p-2 border drop-shadow absolute top-0 z-30'>
            <div className="flex flex-col gap-2">
              <div>
                <Button variant='ghost' size="xs" onClick={handleCancel}>
                  <Icon name="arrow-left" size='xs' />
                </Button>
              </div>
              <fetcher.Form method="post" className="flex flex-row space-x-2">
                <input type="hidden" name="expert_id" value={expertId} />
                <input type="hidden" name="cycle_id" value={cycleId} />
                <input type="hidden" name="_action" value="create_section" />
                <select name="learning_block_id">
                  {sections.learning_blocks.map(lb => (
                    <option key={lb.id} value={lb.id}>{lb.title}</option>
                  ))}
                </select>
                <select name="template_id">
                  {sections.templates.map(tpl => (
                    <option key={tpl.template_id} value={tpl.template_id}>{tpl.title} {tpl.category ? `(${tpl.category})` : ''}</option>
                  ))}
                </select>
                <Button type="submit" >Add</Button>
              </fetcher.Form>
            </div>
          </div>
        }
        <fetcher.Form method="get" className="flex flex-col items-start pb-2">
          <input type="hidden" name="expert_id" value={expertId} />
          <input type="hidden" name="cycle_id" value={cycleId} />
          <input type="hidden" name="_action" value="get_sections" />
          <Button type="submit" variant="outlined" size="xs" disabled={isLoading} loading={isLoading}>
            Add new section
          </Button>
        </fetcher.Form>
      </div>
    </td>
  )
}
AddSection.propTypes = {
  id: PropTypes.number,
  expertId: PropTypes.number,
  cycleId: PropTypes.number,
}
