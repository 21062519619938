import React from 'react'
import PropTypes from 'prop-types'
import { useFetcher } from 'react-router-dom'
import { Button, Icon } from '@design-system'

export const RemoveTemplate = ({ studentId, templateId }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle'

  return (
    <fetcher.Form method="post" className="flex flex-col justify-center">
      <input type="hidden" name="_action" value="unassign_template" />
      <input type="hidden" name="student_id" value={studentId} />
      <input type="hidden" name="template_id" value={templateId} />
      <Button type="submit" variant='outlined' disabled={isLoading} loading={isLoading} className='h-6 w-6 p-1'>
        <Icon name='cross' className='text-xxs font-bold' />
      </Button>
    </fetcher.Form>
  )
}
RemoveTemplate.propTypes = {
  studentId: PropTypes.number,
  templateId: PropTypes.number,
}
