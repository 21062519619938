/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react'
import { useOutletContext, useParams, useLoaderData } from 'react-router-dom'
import useCurrentUser from '@hooks/useCurrentUser'
import useConfirmModal from '@hooks/useConfirmModal'
import CommentThread from '@components/comment-thread'
import { FeedbackForm } from './feedback-form'
import TaskSubmissionStatus from './feedback/task-submission-status'
import _get from 'lodash/get'
import { useEditor } from '@components/forms/short-slate-textarea'
import AiEndCycleReportDrawer from './ai-report/ai-end-cycle-report-drawer'

const EndCycleTab = ({ data, contextData, lastTypedMessage, setSetLastTypedMessage }) => {
  const commentRef = useRef()

  const currentUser = useCurrentUser()
  const confirm = useConfirmModal()
  const { task_submission_id, task_id, student_id, theme_id } = useParams()
  const { setUnits, setAbilities } = useOutletContext()

  // Ai related
  const [aiInsert] = useState()
  const [open, setOpen] = useState(false)
  const editor = useEditor()
  const loaderData = useLoaderData()

  const { task_submission = null } = data || {}

  const assessment = _get(task_submission, 'assessment', null)
  const initialSubmissionStatus = _get(assessment, 'submission_status', null)
  const isAssessed = _get(assessment, 'status', null) === 'assessed'

  const [submissionStatus, setSubmissionStatus] = useState(initialSubmissionStatus)

  const handleInsertFromAi = (slateMessage) => {
    editor.children = slateMessage
  }

  const handleSubmissionStatus = (option) => async () => {
    if (contextData.units?.length > 0 || contextData.abilities?.length > 0) {
      if (!(await confirm('You have changes if you continue you will discard them, are you sure you want to do this?')))
        return
    }
    setUnits([])
    setAbilities([])
    setSubmissionStatus(option)
  }

  return (
    <div className="flex flex-col flex-auto py-6 gap-6">
      <TaskSubmissionStatus
        taskSubmissionStatus={task_submission?.status}
        temporal_status={task_submission?.temporal_status}
        submission_status={task_submission?.submission_status}
        submissionStatus={submissionStatus}
        onChangeStatus={handleSubmissionStatus}
        disabled={isAssessed}
      />
      <CommentThread
        ref={commentRef}
        taskId={task_id}
        studentId={student_id}
        taskSubmissionId={task_submission_id}
        user={currentUser}
        className="border-t border-t-gray-30 border-b border-b-gray-30 py-3"
        placeholder={!isAssessed && submissionStatus?.includes('flag') ? 'Reason for flagging' : null}
        lastTypedMessage={lastTypedMessage}
        onChange={setSetLastTypedMessage}
        hideAiButton={!loaderData.taskSubmissionDetail.isAiMidCycleReportsEnabled}
        onClickAi={() => {
          setOpen(true)
        }}
        customEditor={editor}
      />
      <FeedbackForm
        task_submission_id={task_submission_id}
        task_id={task_id}
        theme_id={theme_id}
        student_id={student_id}
        assessment={assessment}
        contextData={contextData}
        commentRef={commentRef}
        submissionStatus={submissionStatus}
        setUnits={setUnits}
        setAbilities={setAbilities}
        isAssessed={isAssessed}
      />
      <div className="flex flex-col place-items-end">
        {loaderData.taskSubmissionDetail.isAiMidCycleReportsEnabled && (
          <AiEndCycleReportDrawer
            open={open}
            setOpen={setOpen}
            aiInsert={aiInsert}
            setAiInsert={handleInsertFromAi}
          ></AiEndCycleReportDrawer>
        )}
      </div>
    </div>
  )
}

export default EndCycleTab
