import React from 'react'
import PropTypes from 'prop-types'
import { StudentInfo } from './student-info'
import { FirstCycleSectionList } from './first-cycle-section-list'
import { SectionList } from './section-list'
import { CycleSectionList } from './cycle-section-list'
import { AddSection } from './add-section'

export const StudentSectionList = ({ students }) => {
  return (
    students.map(student => {
      const cyclesAmount = student.data.length
      const sectionsAmount = student.data.reduce((acc, curr) => acc + (curr.cycle_id !== null ? curr.sections.length + 1 : curr.sections.length), 0)
      return (
        <React.Fragment key={`student_${student.id}`}>
          <tr>
            <td colSpan={10}>&nbsp;</td>
          </tr>
          <tr className='bg-white'>
            <td rowSpan={sectionsAmount} className='px-2'>
              <StudentInfo id={student.id} name={student.name} campus={student.campus} />
            </td>
            <td rowSpan={sectionsAmount}  className='px-2'>
              <div className="flex flex-col">
                <span>{student.progress}%</span>
                <span className={`font-bold ${student.progress_improvement > 0 ? 'text-green-40' : ''}`}>+{student.progress_improvement}%</span>
              </div>
            </td>
            <FirstCycleSectionList cycle={student.data[0]} studentId={student.id} />
          </tr>
          {(student.data[0].sections.length > 1) &&
            <SectionList sections={student.data[0].sections.slice(1)} studentId={student.id} cycleId={student.data[0].cycle_id} />
          }
          {(student.data[0].sections.length > 0 && student.data[0].cycle_id !== null) &&
            <tr className='bg-white'>
              <AddSection cycleId={student.data[0].cycle_id} studentId={student.id} />
            </tr>
          }
          {(cyclesAmount > 1) && 
            <CycleSectionList cycles={student.data.slice(1)} studentId={student.id} />
          }
        </React.Fragment>
      )
    })
  )
}
StudentSectionList.propTypes = {
  students: PropTypes.array
}
