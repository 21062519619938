import React, { forwardRef, useEffect, useState, memo } from 'react'
import PropTypes from 'prop-types'
import Stars from '../../stars'
import { useCombinedRefs } from '../../../hooks/useCombinedRefs'

const InputStars = forwardRef(({
  maximumNumberOfStars,
  disabledStars,
  readOnly,
  readOnlyNode,
  className,
  onClickReadOnly,
  hasError,
  defaultValue,
  ...rest
}, ref) => {
  const innerRef = React.useRef(null)
  const combinedRef = useCombinedRefs(ref, innerRef)
  const [starsValue, setStarsValue] = useState(0)

  useEffect(() => {
    if (!readOnly) {
      combinedRef.current.value = defaultValue
    }
    setStarsValue(defaultValue)
  }, [readOnly, combinedRef, defaultValue])

  return readOnly ? <div className={`outline-none cursor-text ${className}`}
    {...rest}>{readOnlyNode ||
      Array.from({ length: maximumNumberOfStars }, (_, i) => i + 1).map(num => {
        const isLevelGreaterThanFilled = starsValue >= num
        const isLevelGreaterThanDisabled = disabledStars >= num
        return <button
          type="button"
          key={num}
          onClick={() => onClickReadOnly(num)}
          className={`focus:outline-none ${className}`}
          disabled={isLevelGreaterThanDisabled}>
          <Stars className={`text-danger-50 cursor-default
            ${isLevelGreaterThanDisabled ? 'opacity-70' : ''} 
            ${className}`}
            numFilled={isLevelGreaterThanFilled ? 1 : 0}
            maxStars={1} />
        </button>
      })}</div> : <div className={`inline-block ${hasError ? 'rounded border border-danger-40' : ''}`}>
    {Array.from({ length: maximumNumberOfStars }, (_, i) => i + 1).map(num => {
      const isLevelGreaterThanFilled = starsValue >= num
      const isLevelGreaterThanDisabled = disabledStars >= num
      return <button
        type="button"
        key={num}
        onClick={(e) => {
          combinedRef.current.value = num
          e.target = combinedRef.current
          e.target.value = num
          setStarsValue(num)
          rest.onChange(e.target.value)
        }}
        className={className}
        disabled={isLevelGreaterThanDisabled}
        id={`${rest.id}-btn-${num}`}>
        <Stars className={`text-danger-50 
          ${isLevelGreaterThanDisabled ? 'opacity-70 cursor-default' : 'hover:bg-gray-20'}
          ${className}`}
          numFilled={isLevelGreaterThanFilled ? 1 : 0}
          maxStars={1}
        />
      </button>
    })}
    <input ref={combinedRef} type="hidden" {...rest} />
  </div>
})

InputStars.displayName = 'InputStars'

InputStars.defaultProps = {
  className: '',
  onClickReadOnly: () => null,
  onChange: () => null,
  disabledStars: 0,
}

InputStars.propTypes = {
  maximumNumberOfStars: PropTypes.number.isRequired,
  disabledStars: PropTypes.number,
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  readOnlyNode: PropTypes.any,
  hasError: PropTypes.bool,
  defaultValue: PropTypes.number,
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClickReadOnly: PropTypes.func,
  onClick: PropTypes.func,
}

export default memo(InputStars)
