import React from "react"
import PropTypes from "prop-types"
import { Button, Icon, useFetcher } from "@design-system"

export const EditableTemplate = ({ expertId, cycleId, learningBlockId, templateId, title, category, scheduled = false, readOnly = false }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle'

  const handleCancel = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('_action', 'cancel')
    fetcher.submit(formData, { method: 'post' })
  }

  const categoryView = (title) => {
    switch (`${title}`.toLowerCase()) {
      case 'stem':
        return (<span className='rounded py-1 px-2 bg-green-5 font-bold text-xs text-black whitespace-nowrap'>Stem</span>)
      case 'humanities':
        return (<span className='rounded py-1 px-2 bg-turquoise-5 font-bold text-xs text-black whitespace-nowrap'>Humanities</span>)
      case 'math_learning_goal':
        return (<span className='rounded py-1 px-2 bg-purple-5 font-bold text-xs text-black whitespace-nowrap'>Math</span>)
      case 'electives':
        return (<span className='rounded py-1 px-2 bg-orange-5 font-bold text-xs text-black whitespace-nowrap'>Electives</span>)
      default:
        return null
    }
  }

  return (
    <div className="relative w-fit">
      {fetcher.data?.templates &&
        <div className='bg-white p-2 border drop-shadow absolute top-0 left-full ml-2 z-30'>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2 items-center">
              <Button variant='ghost' size="xs" onClick={handleCancel}>
                <Icon name="arrow-left" size='xs' />
              </Button>
              <span className="text-sm">Current: {title}</span>
            </div>
            <fetcher.Form method="post" className="flex flex-row space-x-2">
              <input type="hidden" name="_action" value="replace_template" />
              <input type="hidden" name="expert_id" value={expertId} />
              <input type="hidden" name="cycle_id" value={cycleId} />
              <input type="hidden" name="learning_block_id" value={learningBlockId} />
              <input type="hidden" name="current_id" value={templateId} />
              <select name="new_id">
                {fetcher.data?.templates?.map(tpl => (
                  <option key={tpl.id} value={tpl.id}>{tpl.title} {tpl.category ? `(${tpl.category})` : ''}</option>
                ))}
              </select>
              <Button type="submit" loading={isLoading} disabled={isLoading}>Replace</Button>
            </fetcher.Form>
          </div>
        </div>
      }
      <fetcher.Form method="get" className="flex flex-row items-center gap-2">
        <input type="hidden" name="_action" value="get_available_templates" />
        <input type="hidden" name="template_id" value={templateId} />
        <div className='flex flex-row gap-2 items-center'>
          <span>{title}</span>
          {categoryView(category)}
          {scheduled && <span className='px-2 py-1 bg-blue-10 text-blue-60 font-bold text-xs rounded'>Scheduled</span>}
          {!scheduled && <span className='px-2 py-1 bg-pink-10 text-pink-60 font-bold text-xs rounded'>New Section</span>}
        </div>
        {!readOnly && 
          <Button type="submit" variant='ghost' loading={isLoading} disabled={isLoading} className='h-6 w-6 p-1'>
            <Icon name="edit-2" size="xs" />
          </Button>
        }
      </fetcher.Form>
    </div>
  )
}
EditableTemplate.propTypes = {
  expertId: PropTypes.number,
  cycleId: PropTypes.number,
  learningBlockId: PropTypes.number,
  templateId: PropTypes.number,
  title: PropTypes.string,
  category: PropTypes.string,
  scheduled: PropTypes.bool,
  readOnly: PropTypes.bool,
}
