import React, { useRef, useEffect } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import CommentThread from '@components/comment-thread'
import useCurrentUser from '@hooks/useCurrentUser'
import useToggle from '@hooks/useToggle'
import { FeedbackForm } from '@components/facilitate/feedback-form'
import TaskSubmissionStatus from '@components/facilitate/feedback/task-submission-status'

const NoSubmissionTab = () => {
  const { theme_id, task_id, student_id } = useParams()
  const { noSubmissionAssessments, contextData, getTaskType, setUnits, setAbilities } = useOutletContext()
  const currentUser = useCurrentUser()
  const [assessAnyway, toggleAssessAnyway] = useToggle(false)
  const commentRef = useRef()
  const { id, status = '', submission_status = '' } = (noSubmissionAssessments || {})
  const hasAssessedNonSubmittedTask = !!id
  const isAssessed = status === 'assessed'
  const taskType = getTaskType()
  useEffect(() => {
    toggleAssessAnyway(false)
  }, [student_id])

  return (
    <div data-testid="no-submission-tab">
      <CommentThread
        ref={commentRef}
        taskId={task_id}
        studentId={student_id}
        taskSubmissionId={null}
        user={currentUser}
        className='border-t border-t-gray-30 border-b border-b-gray-30 py-3'
        placeholder='Type a message'
      />
      <>
        {
          !hasAssessedNonSubmittedTask && !assessAnyway
            ?
            <div className='flex-auto flex flex-col items-center justify-center mt-10'>
              <p className='text-center text-gray-60'>There is no submission from this student yet.</p>
              <br />
              {
                taskType === 'final' &&
                <p className='text-center underline hover:cursor-pointer text-gray-60' onClick={toggleAssessAnyway}>Assess anyway.</p>
              }
            </div>
            :
            <div className='flex flex-col flex-auto py-6 gap-6'>
              {
                hasAssessedNonSubmittedTask &&
                <TaskSubmissionStatus
                  taskSubmissionStatus={noSubmissionAssessments.status}
                  temporal_status={noSubmissionAssessments.temporal_status}
                  submission_status={noSubmissionAssessments.submission_status}
                  submissionStatus={submission_status}
                  disabled={isAssessed}
                />
              }
              <FeedbackForm
                task_id={task_id}
                theme_id={theme_id}
                student_id={student_id}
                setUnits={setUnits}
                setAbilities={setAbilities}
                assessment={noSubmissionAssessments}
                contextData={contextData}
                commentRef={commentRef}
                submissionStatus={submission_status}
                isAssessed={isAssessed}
              />
            </div>
        }
      </>
    </div>
  )
}

export default NoSubmissionTab
