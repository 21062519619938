import React from 'react'
import PropTypes from 'prop-types'
import Emoji from '@components/emoji'
import SoraIcon, { soraFlagOutline } from '@components/sora-icon'

const SubmissionFlag = ({ onChange, selected, disabled = false }) => {
  return (
    <div
      onClick={!disabled && onChange(selected === 'flag' ? 'ontrack' : 'flag')}
      className={`flex flex-col w-12 rounded-lg border border-2 border-transparent items-center justify-around ${selected === 'flag' ? 'bg-danger-20' : 'bg-gray-30'} ${!disabled ? `cursor-pointer ${selected === 'flag' ? 'hover:border-danger-60' : 'hover:border-gray-70'}` : ''}`}
    >
      {selected === 'flag' ? <Emoji name='flag' title='Flagged' /> : <SoraIcon icon={soraFlagOutline} className='text-gray-60 w-4 h-4' />}
    </div>
  )
}
SubmissionFlag.propTypes = {
  onChange: PropTypes.func,
  selected: PropTypes.string,
  disabled: PropTypes.bool,
}

export default SubmissionFlag
