import React from 'react'
import PropTypes from 'prop-types'

const MultipleChoiceOptions = ({ options = [], selectedOptions = [], onChange }) => {
  if (options.length === 0) return (<div className="flex flex-row space-x-4"><span>No cycles available for now</span></div>)
  return (
    <div className="flex flex-row space-x-4">
      {options.map((option, index) => (
        <div key={index} className="flex items-center">
          <div
            onClick={() => onChange(option.value)}
            className={`p-5 cursor-pointer text-lg text-black font-bold rounded ${selectedOptions.includes(option.value) ? 'bg-blue-5' : 'bg-white'}`}
          >
            <span>{option.label}</span>
          </div>
        </div>
      ))}
    </div>
  )
}
MultipleChoiceOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  })).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default MultipleChoiceOptions
