import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'

const customColor = {
  blue: 'text-blue-60 border-b-blue-60',
  red: 'text-danger-60 border-b-danger-60',
  yellow: 'text-yellow-20 border-b-yellow-20',
  green: 'text-green-90 border-b-green-90'
}

const selectedColor = {
  blue: 'bg-blue-60',
  red: 'bg-danger-60',
  yellow: 'bg-yellow-20',
  green: 'bg-green-90'
}

const StudentsSubmissionStatus = ({ showStudents, title, icon, color, list = [], listKey, selectedId }) => {
  const navigate = useNavigateWithCycle()
  const handleSelectStudent = (id) => () => {
    navigate(`${id}`)
  }

  const renderStudents = (students, key) => {
    if (!students) return
    return students.map(student => {
      const selected = student.id == selectedId
      return (
        <li
          key={`${key}_${student.id}`}
          className={`p-2 hover:bg-blue-5 rounded-lg cursor-pointer ${selected ? 'bg-blue-20 font-bold' : ''}`}
          onClick={handleSelectStudent(student.id)}
        >
          {student.name}
        </li>
      )
    })
  }

  const hasSelected = list?.filter(item => item.id === Number(selectedId)).length > 0 ?? false
  const listSize = list?.length ?? 0

  return (
    <>
      <div className={`px-1 py-1 flex gap-2 ${customColor[color]} ${showStudents ? 'border-b' : `${hasSelected ? `rounded-lg ${selectedColor[color]} !text-white` : ''}`}`}>
        <FontAwesomeIcon icon={icon} />
        <h1 className={`font-bold uppercase text-xs`}>{showStudents ? `${title} (${listSize})` : listSize}</h1>
      </div>
      {showStudents && (
        <ul className='flex flex-col gap-1'>
          {renderStudents(list, listKey)}
        </ul>
      )}
    </>
  )
}

StudentsSubmissionStatus.propTypes = {
  showStudents: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.any,
  color: PropTypes.string,
  list: PropTypes.array,
  listKey: PropTypes.string,
  selectedId: PropTypes.any
}

export default StudentsSubmissionStatus
