import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from '@design-system'
import { useFetcher } from 'react-router-dom'

export const VariantNote = ({ processId, defaultValue = '' }) => {
  const [editable, setEditable] = useState(false)
  const fetcher = useFetcher()

  const isLoading = fetcher.state === 'loading' && fetcher.formData.get('processId') === String(processId)

  const handleToggleEdit = (event) => {
    event.preventDefault()
    setEditable(!editable)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    fetcher.submit(event.target)
    setEditable(false)
  }

  if (editable) {
    return (
      <fetcher.Form method='post' className='flex flex-row gap-2 items-center' onSubmit={handleSubmit}>
        <input type="hidden" name="_action" value='update_variant_note' />
        <input type="hidden" name="processId" value={processId} />
        <input type="text" name="note" id="note" className='border border-alpha/10 rounded placeholder:text-alpha/40' placeholder='Add your note' defaultValue={defaultValue} />
        <Button variant='outlined' size='xs' onClick={handleToggleEdit} disabled={isLoading}>
          <Icon name='cross' size='xs' />
        </Button>
        <Button type='submit' size='xs' disabled={isLoading} loading={isLoading}>
          <Icon name='check' size='xs' />
        </Button>
      </fetcher.Form>
    )
  }

  return (
    <div className='flex flex-row gap-2 items-center'>
      <span className={`italic text-alpha/40`}>- "{defaultValue.length > 0 ? defaultValue : 'Add note'}"</span>
      <Button variant='ghost' size='xs' onClick={handleToggleEdit} loading={isLoading}>
        {!isLoading && <Icon name='edit-2' size='xs' />}
      </Button>
    </div>
  )
}
VariantNote.propTypes = {
  processId: PropTypes.number.isRequired,
  defaultValue: PropTypes.string
}
