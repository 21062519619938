import React from 'react'
import { Form, Outlet, redirect, useLocation, useNavigate, useNavigation } from 'react-router-dom'
import axios from 'axios'
import { Button } from '@design-system'
import serializeFormData from '@utils/serializeFormData'
import { Tab } from '@headlessui/react'
import SoraLink from '@components/link'


const action = async ({ params, request }) => {
  const { processId } = params
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const action = serializedFormData._action

  try {
    await axios.post(`/backoffice/workbench/journey-builder/processes/${processId}/assignments`, { ...serializedFormData, action })
    return action === 'next' ? redirect('../schedule/config') : null
  } catch (error) {
    return {
      toast: { message: 'There was an error running Journey Builder. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const navigate = useNavigate()
  const navigation = useNavigation()
  const { pathname } = useLocation()

  const isFormSubmitting = navigation.state !== 'idle'

  const backAction = () => {
    navigate('../templates/review')
  }

  const tabs = [
    { label: 'Students', path: './students' },
    { label: 'Expeditions', path: './expeditions' },
  ]

  const currentIndex = tabs.findIndex(t => pathname.includes(t.path.slice(1)))

  return (
    <>
      <div className="flex flex-row">
        <Tab.Group selectedIndex={currentIndex >= 0 ? currentIndex : 0}>
          <Tab.List className='flex-auto'>
            {tabs.map(t => (
              <Tab
                key={t.label}
                className={({ selected }) => `p-3 text-xl truncate focus:outline-none border-b-2 border-transparent ${selected ? 'font-bold border-accent cursor-default' : 'hover:underline'}`}
              >
                <SoraLink to={t.path}>
                  {t.label}
                </SoraLink>
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
      </div>
      <div className="flex-auto relative">
        <div className='text-sm space-y-4 px-2 overflow-y-scroll absolute top-0 bottom-20'>
          <Outlet />
        </div>
      </div>
      <div className='flex flex-row justify-end align-middle p-4 space-x-3 absolute bottom-0 left-0 right-0 rounded-xl border border-gray-30 bg-white'>
        <Button type='button' onClick={backAction} disabled={isFormSubmitting} variant='outlined'>Back</Button>
        <Form method="post" id="journey-builder-form">
          <input type="hidden" name="_action" value="next" />
          <Button type='submit' disabled={isFormSubmitting} loading={isFormSubmitting}>Next</Button>
        </Form>
      </div>
    </>
  )
}

export const JourneyBuilderAssignmentsRoute = {
  action,
  Element,
}
