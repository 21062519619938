import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useFetcher } from 'react-router-dom'
import { Button, Icon } from '@design-system'
import { Transition } from '@headlessui/react'

const CloneProcess = ({ processId, cloneableSteps }) => {
  const wrapperRef = useRef()
  const fetcher = useFetcher()
  const [show, setShow] = useState(false)

  const isLoading = fetcher.state !== 'idle' && fetcher.formData.get('processId') === String(processId)

  const handleToggleOptions = (event) => {
    event.preventDefault()
    setShow(!show)
  }

  const handleClickListener = (event) => {
    const clickedInside = (wrapperRef && wrapperRef.current.contains(event.target))
    if (clickedInside) return
    else setShow(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickListener)
    
    return () => {
      document.removeEventListener('mousedown', handleClickListener)
    }
  }, [])

  return (
    <fetcher.Form method="post" className="flex flex-col justify-center relative">
      <input type="hidden" name="_action" value="clone_process" />
      <input type="hidden" name="processId" value={processId} />
      <Button variant='outlined' size='sm' disabled={isLoading} loading={isLoading} onClick={handleToggleOptions}>
        <Icon name='copy-filled' size='xs' />
      </Button>
      <div ref={wrapperRef}>
        <Transition
          show={show} 
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className='flex flex-col absolute z-10 bg-white right-full top-0 mr-2 p-2 gap-2 shadow-lg border border-alpha/10'>
            <span>To which step?</span>
            <select name='stepTarget'>
              {cloneableSteps.map(step => (
                <option key={step.value} value={step.value}>{step.label}</option>
              ))}
            </select>
            <div className='flex flex-row gap-2 items-center justify-end'>
              <Button variant='outlined' size='sm' onClick={handleToggleOptions} disabled={isLoading}>
                <Icon name='cross' size='xs' />
              </Button>
              <Button size='xs' type='submit' loading={isLoading} disabled={isLoading} >
                <Icon name='check' size='xs' />
              </Button>
            </div>
          </div>
        </Transition>
      </div>
    </fetcher.Form>
  )
}
CloneProcess.propTypes = {
  processId: PropTypes.number,
  cloneableSteps: PropTypes.array,
}

export default CloneProcess
