import React from 'react'
import PropTypes from 'prop-types'
import { Card, Switch, useFetcher } from '@design-system'
import { format } from 'date-fns'

export const LearningBlocksList = ({ employeeId, learningBlocks = [] }) => {
  const fetcher = useFetcher()
  const urlParams = new URLSearchParams(document.location.search)
  const params = Object.fromEntries(urlParams)

  function dayOfWeekToDayName(dayOfWeek) {
    return ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'][dayOfWeek] || ''
  }

  const handleChange = (event) => {
    event.preventDefault()
    fetcher.submit(event.target.form)
  }

  return (
    <div className='grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3 justify-around'>
      {learningBlocks.map((learningBlock) => {
        const [scheduleStart, scheduleEnd] = learningBlock.schedules
        const todayYMD = format(new Date(), 'yyyy-MM-dd')
        const startTime = format(new Date(`${todayYMD}T${scheduleStart.time}`), 'HH:mm')
        const endTime = format(new Date(`${todayYMD}T${scheduleEnd.time}`), 'HH:mm')
        return (
          <Card key={`${params.cycle_id}_${employeeId}_${learningBlock.id}`} className='p-3'>
            <div className='flex flex-col gap-2 w-full'>
              <span className='text-sm font-bold'>{learningBlock.title}</span>
              <div className="flex flex-row">
                <span className="capitalize">{`${dayOfWeekToDayName(scheduleStart.day_of_the_week)}, ${startTime}`}</span>
                <span>-</span>
                <span className="capitalize">{`${dayOfWeekToDayName(scheduleEnd.day_of_the_week)}, ${endTime} (ET)`}</span>
              </div>
              <fetcher.Form method='post' className='flex flex-row justify-end' onChange={handleChange}>
                <input type="hidden" name="learningBlockId" value={learningBlock.id} />
                <input type="hidden" name="employeeId" value={employeeId} />
                <input type="hidden" name="cycleId" value={params.cycle_id} />
                <Switch name='available' defaultChecked={learningBlock.available} />
              </fetcher.Form>
            </div>
          </Card>
        )
      })}
    </div>
  )
}
LearningBlocksList.propTypes = {
  employeeId: PropTypes.number,
  learningBlocks: PropTypes.array
}
