import * as React from 'react'
import axios from 'axios'
import { Outlet, useLoaderData } from 'react-router-dom'
import { Card, Icon, Tabs, Typography } from '@design-system'

interface LoaderData {
  reviews: {
    total: number
    pendingExperienceLearningGoal: boolean
    pendingActivityIse: boolean
  }
  avgResponseTime: number
}

async function loader({ request }) {
  const url = new URL(request.url)
  const cycle_id = url.searchParams.get('cycle_id')
  const res = await axios.get(`/backoffice/facilitate?cycle_id=${cycle_id}`)
  return res.data
}

function Element() {
  const loaderData = useLoaderData() as LoaderData

  return (
    <>
      <header className="flex-col gap-4">
        <Typography variant="heading-3" weight="bold">Facilitate</Typography>
        <nav className="grid gap-4 grid-cols-1 md:grid-cols-2 my-8">
          <Card>
            <Card.Content>
              <div className="flex gap-3 items-center">
                <Icon name="pending-review" />
                <Typography variant="subheadline" weight="bold">Pending Review</Typography>
              </div>
              <Typography variant="heading-1" weight="bold">{loaderData.reviews.total ?? '-'}</Typography>
            </Card.Content>
          </Card>
          <Card >
            <Card.Content>
              <div className="flex gap-3 items-center">
                <Icon name="clock-check" />
                <Typography variant="subheadline" weight="bold">Average response time</Typography>
              </div>
              <Typography variant="heading-1" weight="bold">{loaderData.avgResponseTime ? `${loaderData.avgResponseTime}hs` : '-'}</Typography>
            </Card.Content>
          </Card>
        </nav>
      </header>
      <Typography variant="heading-4" weight="bold" className="mb-4">Your experiences</Typography>
      <Tabs>
        <Tabs.Item title={`Experiences/Learning Goals ${loaderData.reviews.pendingExperienceLearningGoal ? ' •' : ''}`} to="expeditions-learning-goals" />
        <Tabs.Item title={`Activities and ISEs ${loaderData.reviews.pendingActivityIse ? ' •' : ''}`} to="activities-ises" />
      </Tabs>
      <Outlet />
    </>
  )
}

export const FacilitateRoute = { Element, loader }