import React from 'react'
import PropTypes from 'prop-types'
import { StudentInfo } from './student-info'
import { RemoveTemplate } from './remove-template'
import { CyclesTemplate } from './cycles-templates'
import { UnitList } from './unit-list'
import { AbilityList } from './ability-list'
import { AddTemplate } from './add-template'

export const StudentList = ({ students, defaultCycles }) => {

  const categoryView = (title) => {
    switch (title.toLowerCase()) {
      case 'stem':
        return (<span className='rounded py-1 px-2 bg-green-5 font-bold text-xs whitespace-nowrap'>Stem</span>)
      case 'humanities':
        return (<span className='rounded py-1 px-2 bg-turquoise-5 font-bold text-xs whitespace-nowrap'>Humanities</span>)
      case 'math_learning_goal':
        return (<span className='rounded py-1 px-2 bg-purple-5 font-bold text-xs whitespace-nowrap'>Math</span>)
      case 'electives':
        return (<span className='rounded py-1 px-2 bg-orange-5 font-bold text-xs whitespace-nowrap'>Electives</span>)
    }
  }
  return (
    students.map(student => {
      const templatesLength = student.templates.length + 1
      if (templatesLength > 0) {
        return (
          <React.Fragment key={student.id}>
            <tr>
              <td colSpan={12}>&nbsp;</td>
            </tr>
            <tr className='bg-white'>
              <td className='p-2 align-top' rowSpan={templatesLength}>
                <StudentInfo id={student.id} name={student.name} campus={student.campus} />
              </td>
              <td className='p-2'>
                <RemoveTemplate studentId={student.id} templateId={student.templates[0].template_id} />
              </td>
              <td className='p-2'>
                <div className='flex flex-row gap-2'>
                  {student.templates[0].is_request_list && <span>⭐</span>}
                  <span>{student.templates[0].template_name}</span>
                  {student.templates[0].is_pathways &&
                    <div>
                      <span className='px-2 py-1 bg-yellow-10 text-yellow-70 text-xs rounded ml-1'>Pathways</span>
                    </div>
                  }
                </div>
              </td>
              <td className='p-2'>{categoryView(student.templates[0].template_category)}</td>
              <td className='p-2'>
                <CyclesTemplate id={student.templates[0].id} availableCycles={student.templates[0].cycles_available} selectedCycles={student.templates[0].cycles_selected} />
              </td>
              <td className='p-2'>{student.templates[0].is_first_time}</td>
              <td className='p-2'><UnitList units={student.templates[0].units} /></td>
              <td className='p-2'><AbilityList abilities={student.templates[0].abilities} /></td>
              <td className='p-2 align-top' rowSpan={templatesLength}>{student.request_hits}</td>
              <td className='p-2 align-top' rowSpan={templatesLength}>{student.unitsLeft}</td>
              <td className='p-2 align-top' rowSpan={templatesLength}>{student.graduationProgress}</td>
              <td className='p-2 align-top' rowSpan={templatesLength}>{student.cyclesLeft}</td>
            </tr>
            {student.templates.slice(1).map((template, idx) => (
              <tr key={`${student.id}_${idx}`} className='bg-white'>
                <td className='p-2'>
                  <RemoveTemplate studentId={student.id} templateId={template.template_id} />
                </td>
                <td className='p-2'>
                  <div className='flex flex-row gap-2'>
                    {template.is_request_list && <span>⭐</span>}
                    <span>{template.template_name}</span>
                    {template.is_pathways && 
                      <div>
                        <span className='px-2 py-1 bg-yellow-10 text-yellow-70 text-xs rounded ml-1'>Pathways</span>
                      </div>
                    }
                  </div>
                </td>
                <td className='p-2'>{categoryView(template.template_category)}</td>
                <td className='p-2'>
                  <CyclesTemplate id={template.id} availableCycles={template.cycles_available} selectedCycles={template.cycles_selected} />
                </td>
                <td className='p-2'>{template.is_first_time}</td>
                <td className='p-2'><UnitList units={template.units} /></td>
                <td className='p-2'><AbilityList abilities={template.abilities} /></td>
              </tr>
            ))}
            <tr className='bg-white'>
              <td className='p-2' colSpan={7}>
                <div className='flex flex-row'>
                  <AddTemplate studentId={student.id} />
                </div>
              </td>
            </tr>
          </React.Fragment>
        )
      } else {
        return (
          <tr key={student.id}>
            <td className='p-2'>
              <StudentInfo id={student.id} name={student.name} campus={student.campus} />
            </td>
            <td className='p-2'></td>
            <td className='p-2' colSpan={7}>Add</td>
            <td className='p-2'>{student.request_hits}</td>
            <td className='p-2'>{student.unitsLeft}</td>
            <td className='p-2'>{student.graduationProgress}</td>
            <td className='p-2'>{student.cyclesLeft}</td>
          </tr>
        )
      }
    })
  )
}
StudentList.propTypes = {
  students: PropTypes.array,
  defaultCycles: PropTypes.array,
}
