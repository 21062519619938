import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { ActionFunctionArgs, useLoaderData, useNavigate } from 'react-router-dom'
import { unstable_Dialog as Dialog, useFetcher, Typography, Button, Icon } from '@design-system'
import { TextField } from '@designsystem'
import Datepicker from '@components/forms/datepicker'
import { LoaderData } from './types'

async function loader({ params }) {
  const result = await axios.get(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}/deadline-extension`
  )
  return result.data
}

async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData()
  const res = await axios.post(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}/deadline-extension`,
    formData
  )

  return { status: res.status, ...res.data }
}

function Element() {
  const fetcher = useFetcher()
  const navigate = useNavigate()
  const loaderData = useLoaderData() as LoaderData

  const [formState, setFormState] = useState({
    dueDate: '',
    reason: '',
  })
  const handleInputChange = function (e) {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
    if (fetcher.data?.status === 200 && fetcher.formData.get('_action') === 'create_deadline_extension') {
      navigate('../')
    }
  }, [fetcher.data])

  return (
    <Dialog
      open={true}
      onOpenChange={(value) => {
        navigate('../')
      }}
    >
      <Dialog.Content className="px-0 max-w-[700px]">
        <Dialog.Header className="flex items-center justify-center  border-b border-neutral-200 pb-4">
          <Typography variant="heading-5" weight="bold">
            Deadline extension
          </Typography>
        </Dialog.Header>

        <fetcher.Form method="POST" id="createDeadlineExtensionForm">
          <div className="flex flex-col px-14 pt-8 gap-6">
            <Typography variant="body">
              Extended deadline for{' '}
              <Typography className="inline" variant="body" weight="bold">
                {loaderData.title}
              </Typography>
            </Typography>

            <div className="flex flex-col gap-2">
              <Typography variant="body" weight="bold">
                Current due date
              </Typography>
              <div className="flex gap-2">
                <Icon name="calendar" />
                <Typography variant="body">{loaderData.currentDueDate}</Typography>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <Typography variant="body" weight="bold">
                New due date
              </Typography>

              <div className="w-1/2">
                <Datepicker
                  // @ts-ignore - cant import type from JSX
                  name="dueDate"
                  required
                  className=" border-2 border-gray-30"
                  placeholder="Select a new due date"
                  min={new Date().toJSON().slice(0, 10)}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <Typography variant="body" weight="bold">
                Reason
              </Typography>
              <TextField
                placeholder="Share a reason for extension"
                name="reason"
                required
                trim
                onChange={handleInputChange}
              />
            </div>

            <div className="flex flex-col bg-gray-10  rounded-lg justify-center gap-2.5 p-4">
              <Typography variant="body">
                Reminder: students see tasks as due the day before the actual due date.
              </Typography>
              <Typography variant="body">
                Other students will not be affected by this change and are still going to see the original due date.
              </Typography>
            </div>

            <div className="flex justify-end gap-4 pt-4">
              <Button type="button" variant="outlined" onClick={() => navigate('../')}>
                Cancel
              </Button>
              <Button
                type="submit"
                name="_action"
                value="create_deadline_extension"
                form="createDeadlineExtensionForm"
                disabled={formState.dueDate === '' || formState.reason === ''}
              >
                Confirm extension
              </Button>
            </div>
          </div>
        </fetcher.Form>
      </Dialog.Content>
    </Dialog>
  )
}
export const FacilitateExperienceTaskStudentDeadlineExtensionRoute = {
  Element,
  action,
  loader,
}
