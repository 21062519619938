import React, { Fragment } from 'react'
import axios from 'axios'
import { useLoaderData, useNavigation, redirect } from 'react-router-dom'
import _refiner from 'refiner-js'
import * as Sentry from '@sentry/react'
import Intercom from '@intercom/messenger-js-sdk'
import { Logo, DropdownMenu } from '@design-system'
import SoraLink from '@components/link'
import { TopNav } from './top-nav'
import { MenuMobile } from './menu-mobile'
import { NavButtonDrawer, NavButtonAside } from './nav-button'
import { Aside } from './aside'
import { MainOutlet } from './main-outlet'
import { CycleSelector } from './cycle-selector'
import { Notifications } from './notifications'
import { ProfileMenu } from './profile-menu'
import posthog from 'posthog-js'

async function loader({ params, request }) {
  try {
    const searchParams = new URL(request.url).searchParams
    const { student_id } = params
    const cycle_id = searchParams.get('cycle_id')
    const nonNullParams = Object.fromEntries(Object.entries({ cycle_id, student_id }).filter(([_, v]) => v !== null && v !== undefined))
    const downSearchParams = new URLSearchParams(nonNullParams)
    const { data } = await axios.get(`/pages/index?${downSearchParams.toString()}`)
    const returnTo = searchParams.get('return_to')

    if (returnTo) {
      await logout()
      return redirect(`/signin?${searchParams.toString()}`)
    }

    try {
      _refiner('identifyUser', {
        id: data.userId,
        email: data.email,
        name: data.name,
        is_student: data.userRole === 'student',
        is_parent: data.userRole === 'guardian',
        is_employee: data.userRole !== 'student' && data.userRole !== 'guardian',
        is_beta: data.userIsBeta,
      })
    } catch (error) {
      console.error(error)
    }

    try {
      posthog.identify(
        data.userId,
        { email: data.name, name: data.email, role: data.userRole }
      )
    } catch (error) {
      console.error(error)
    }

    if (data.intercomAppId) {
      Intercom({
        app_id: data.intercomAppId,
        user_id: data.userId, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        user_hash: data.intercomUserHash, // IMPORTANT: Replace "user.hash" with the variable you use to capture the user's hashed email
        name: data.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: data.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        created_at: data.userCreatedAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
        role: data.intercomRole,
        hide_default_launcher: true,
      });
    }

    try {
      Sentry.setUser({
        email: data.email,
        id: data.userId,
        name: data.name,
        role: data.userRole,
        is_beta: data.userIsBeta,
      })
    } catch (error) {
      console.error(error)
    }

    if (['student', 'guardian'].includes(data.userRole)) {
      const { data } = await axios.get(`/pages/wishlist/current-cross-app-session`)
      const queryParams = new URLSearchParams(data.user)

      await logout()

      return redirect(`${data.flutterUrl}/sso?${queryParams.toString()}`)
    }

    return data
  } catch (e) {
    if (e.response?.status === 401) {
      const searchParams = new URL(request.url).searchParams
      _refiner('resetUser')
      return redirect(`/signin?${searchParams.toString()}`)
    }
  }
}

async function action({ request }) {
  const formData = await request.formData()
  const _action = formData.get('_action')
  if (_action === 'signout') {
    await logout()
    _refiner('resetUser')
    return redirect('/signin')
  }
  return null
}

function logout() {
  return axios({
    method: 'delete',
    url: '/auth/logout/web',
    baseURL: '/',
  })
}

const Element = () => {
  const {
    selectedCycleId,
    cycles,
    userId,
    userRole,
    profileImgUrl,
    name,
    email,
    links,
    quickLinks,
  } = useLoaderData()
  const navigation = useNavigation()
  const nextPath = navigation.location ? navigation.location.pathname + navigation.location.search : ""
  const isNextOnMenu = links.map(lk => lk.path).includes(nextPath)
  return (
    <div className="flex flex-col grow">
      <TopNav>
        <MenuMobile>
          {links.map((link) => (
            <Fragment key={`mobile_link_${link.title}`}>
              <NavButtonDrawer {...link} />
            </Fragment>
          ))}
        </MenuMobile>
        <SoraLink
          data-cy='main-sora-logo'
          data-testid='logo-link'
          to="/"
        >
          <Logo data-testid='logo' />
        </SoraLink>
        <TopNav.Ul>
          <CycleSelector cycles={cycles} selectedCycleId={selectedCycleId} className="hidden sm:block" />
          <Notifications userId={userId} userRole={userRole} />
          <ProfileMenu name={name} imgUrl={profileImgUrl}>
            <ProfileMenu.User name={name} email={email} imgUrl={profileImgUrl} />
            <DropdownMenu.Separator />
            <div className="sm:hidden flex p-2">
              <CycleSelector cycles={cycles} selectedCycleId={selectedCycleId} className="w-full" />
            </div>
            {quickLinks.map((link) => (
              <Fragment key={`quick_link_${link.title}`}>
                <ProfileMenu.Link {...link} />
              </Fragment>
            ))}
            <DropdownMenu.Separator />
            <ProfileMenu.SupportButton />
            <ProfileMenu.DarkThemeToggle />
            <ProfileMenu.Logout />
          </ProfileMenu>
        </TopNav.Ul>
      </TopNav>
      <div className="flex grow max-w-full">
        <Aside>
          {links.map((link) => (
            <Fragment key={`link_${link.title}`}>
              <NavButtonAside {...link} />
            </Fragment>
          ))}
        </Aside>
        <MainOutlet shouldLoad={isNextOnMenu} />
      </div>
    </div>
  )
}

export const RootAuthenticatedRoute = {
  loader,
  action,
  Element
}
