import React, { useRef, useState, useEffect } from 'react'
import { Form, useLoaderData, useActionData, useNavigation, useSubmit } from 'react-router-dom'
import { toast } from 'sonner'
import axios from 'axios'
import { Button } from '@designsystem'
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons'
import SoraIcon, { soraCloudUploadOutline, soraClose } from '@components/sora-icon'

export async function loader({ request }) {
  const cycle_id = new URL(request.url).searchParams.get('cycle_id')
  const { data } = await axios.get(`/pages/admin/workbench/rosetta-stone/cycle-selected?cycle_id=${cycle_id}`)
  return data
}

export default function WorkbenchRosettaStoneRoute() {
  const loaderData = useLoaderData()
  const navigation = useNavigation()

  const [syncRosettaBtnDisabled, setSyncRosettaBtnDisabled] = useState(false)
  const [mustIgnoreArchivedThemesRosettaSync, setMustIgnoreArchivedThemesRosettaSync] = useState(false)
  const [mustIgnoreStudentsLeftSoraRosettaSync, setMustIgnoreStudentsLeftSoraRosettaSync] = useState(false)

  const [syncRosettaStudentsBtnDisabled, setSyncRosettaStudentsBtnDisabled] = useState(false)
  const [parsedCSVData, setParsedCSVData] = useState([])

  const inputFileRef = useRef(null)
  const submit = useSubmit()
  const actionData = useActionData()
  const csvErrors = actionData?.csvErrors

  const isUploadEnrolmentCSV =
    navigation.state === 'submitting' && navigation.formData?.get('formType') === 'uploadRosettaStudentsCSV'

  useEffect(() => {
    if (!isUploadEnrolmentCSV) {
      inputFileRef.current?.form.reset()
    }
  }, [isUploadEnrolmentCSV])

  useEffect(() => {
    if (actionData?.parsedCSVData) {
      setParsedCSVData(actionData?.parsedCSVData)
    }
  }, [actionData?.parsedCSVData])

  useEffect(() => {
    if (csvErrors) {
      toast.error('Invalid CSV file, please try again.')
    }
  }, [csvErrors])

  const toggleIgnoreArchivedThemes = () => {
    setMustIgnoreArchivedThemesRosettaSync(!mustIgnoreArchivedThemesRosettaSync)
  }

  const toggleIgnoreStudentsLeftSora = () => {
    setMustIgnoreStudentsLeftSoraRosettaSync(!mustIgnoreStudentsLeftSoraRosettaSync)
  }

  const syncRosettaStone = async () => {
    try {
      setSyncRosettaBtnDisabled(true)
      toast('Rosetta Stone sync is loading, please wait!')
      await axios.post('/rosetta-sync', {
        cycle_id: loaderData.cycle_id,
        ignore_archived_themes: mustIgnoreArchivedThemesRosettaSync,
        ignore_students_left_sora: mustIgnoreStudentsLeftSoraRosettaSync,
      })
      toast.success('Rosetta Stone data has been successfully synced!')
    } catch (e) {
      const errorMessage = e.response.data.message
      toast.error(errorMessage || 'Error on sync Rosetta Stone data, please try again later.')
    }

    setSyncRosettaBtnDisabled(false)
  }

  const syncRosettaStoneStudents = async () => {
    try {
      setSyncRosettaStudentsBtnDisabled(true)
      toast('Rosetta Stone Students sync is loading, please wait!')
      await axios.post('/rosetta-students-sync', {
        rosettaStudentList: parsedCSVData,
      })
      toast.success('Rosetta Stone data has been successfully synced!')
      setParsedCSVData([])
    } catch (e) {
      const errorMessage = e.response.data.message
      toast.error(errorMessage || 'Error on sync Rosetta Stone data, please try again later.')
    }

    setSyncRosettaStudentsBtnDisabled(false)
  }

  return (
    <div className="flex flex-col pt-3 gap-16">
      <div>
        <span className="bg-yellow-5 p-3 border border-yellow-30 rounded">
          Sync with Rosetta Stone for <b>{`${loaderData.title}`}</b>
        </span>
        <div className="flex items-center gap-4 mt-5">
          <Button onClick={syncRosettaStone} loading={syncRosettaBtnDisabled}>
            {syncRosettaBtnDisabled ? 'Syncing...' : 'Start Rosetta Sync'}
          </Button>
          <Button
            onClick={toggleIgnoreArchivedThemes}
            variant="outlined"
            startIcon={mustIgnoreArchivedThemesRosettaSync ? faCheckSquare : faSquare}
          >
            Ignore Archived Experiences
          </Button>
          <Button
            onClick={toggleIgnoreStudentsLeftSora}
            variant="outlined"
            startIcon={mustIgnoreStudentsLeftSoraRosettaSync ? faCheckSquare : faSquare}
          >
            Ignore the students who left Sora
          </Button>
        </div>
      </div>
      <div>
        <span className="bg-yellow-5 p-3 border border-yellow-30 rounded">Sync Students with Rosetta Stone</span>
        <div className="flex items-center gap-4 mt-5">
          <Button
            onClick={syncRosettaStoneStudents}
            loading={syncRosettaStudentsBtnDisabled}
            disabled={parsedCSVData.length === 0}
          >
            {syncRosettaStudentsBtnDisabled ? 'Syncing...' : 'Start Student Rosetta Sync'}
          </Button>
          <div className="flex items-center justify-between border rounded-xl p-5">
            <div className="flex flex-col whitespace-nowrap mr-3">
              <span>
                {parsedCSVData.length > 0 ? 'CSV file uploaded!' : 'Add the CSV file with Rosetta students here!'}
              </span>
            </div>
            <div className="my-auto flex space-x-2 whitespace-nowrap">
              <Form method="post" encType="multipart/form-data">
                <input type="hidden" name="formType" value="uploadRosettaStudentsCSV" />
                <input
                  className="hidden"
                  ref={inputFileRef}
                  onChange={(e) => submit(e.target.form)}
                  type="file"
                  name="rosetta_students_csv"
                  multiple={false}
                  accept=".csv"
                />
              </Form>
              {parsedCSVData.length > 0 ? (
                <button
                  className="flex px-4 py-1 rounded-lg bg-gray-10"
                  onClick={(e) => {
                    e.preventDefault()
                    setParsedCSVData([])
                  }}
                >
                  <SoraIcon icon={soraClose} className="mr-3" />
                  CANCEL CSV
                </button>
              ) : (
                <button
                  className="flex px-4 py-1 rounded-lg bg-gray-10"
                  onClick={(e) => {
                    e.preventDefault()
                    inputFileRef.current.click()
                  }}
                >
                  <SoraIcon icon={soraCloudUploadOutline} className="mr-3" />
                  UPLOAD CSV
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
