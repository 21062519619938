import { SoraNavLink } from '@components/link'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { SubmenuPortal } from '@routes/index'
import useCurrentUser from '@hooks/useCurrentUser'
import { NavigateCycle } from '@hooks/useNavigateWithCycle'

export default function ExpeditionIdRoute() {
  const currentUser = useCurrentUser()
  if (!currentUser) return null
  if (currentUser.role === 'student') return <NavigateCycle to={`/student/${currentUser.id}/home`} />
  if (currentUser.role === 'guardian') return <NavigateCycle to={`/student/${currentUser.students_ids[0]}/home`} />

  return <>
    <SubmenuPortal>
      <div className="grid grid-cols-12 text-center items-end h-full w-[800px] text-xs font-bold uppercase">
        <SoraNavLink to='edit' className={active => `col-span-2 border-b-2 py-1 ${active ? 'border-accent text-accent' : 'border-transparent'}`}>
          Edit
        </SoraNavLink>
        <SoraNavLink to='tasks' className={active => `col-span-2 border-b-2 py-1 ${active ? 'border-accent text-accent' : 'border-transparent'}`}>
          Tasks
        </SoraNavLink>
        <SoraNavLink to='registrations' className={active => `col-span-2 border-b-2 py-1 ${active ? 'border-accent text-accent' : 'border-transparent'}`}>
          Registrations
        </SoraNavLink>
        <SoraNavLink to='attendances' className={active => `col-span-2 border-b-2 py-1 ${active ? 'border-accent text-accent' : 'border-transparent'}`}>
          Attendances
        </SoraNavLink>
      </div>
    </SubmenuPortal>
    <Outlet />
  </>
}
