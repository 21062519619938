import React, { useState } from 'react'
import { Outlet, useLocation, useOutletContext, useLoaderData, useParams } from 'react-router-dom'
import useUserFeatureFlag from '@hooks/useUserFeatureFlag'
import EndCycleTab from './end-cycle-tab'
import MidCycleTab from './mid-cycle-tab'
import TaskFeedbackTab from './task-feedback-tab'
import NoSubmissionTab from '@components/facilitate/no-submission-tab'

const TAB_INDEX = 10

const AssessmentFeedback = () => {
  const location = useLocation()
  const { showStudents, getTaskType, contextData, setContextData } = useOutletContext()
  const { checkBetaFeature } = useUserFeatureFlag()
  const isSoraScore = checkBetaFeature('sora_score')
  const { task_submission_id } = useParams()

  const loaderData = useLoaderData()
  const taskSubmissionDetail = loaderData.taskSubmissionDetail

  const [lastTypedMessage, setSetLastTypedMessage] = useState(null)

  const taskType = getTaskType()

  const tab = location.pathname.split('/')[TAB_INDEX]

  const DEVELOPMENT_FEEDBACK_SUBROUTES = ['intro', 'form', 'done']

  const checkDevFeedbackSubroutes = ({ isSoraScore, shouldInclude, tab }) => {
    if (!tab) return false
    return isSoraScore ? DEVELOPMENT_FEEDBACK_SUBROUTES.includes(tab) === shouldInclude : true
  }

  if (!task_submission_id || !taskSubmissionDetail) {
    return <NoSubmissionTab />
  }

  return checkDevFeedbackSubroutes({ isSoraScore, tab, shouldInclude: true }) ? (
    <Outlet context={{ showStudents, lastTypedMessage, setSetLastTypedMessage, contextData, setContextData }} />
  ) : (
    <>
      {taskType === 'final' && (
        <EndCycleTab
          data={taskSubmissionDetail}
          contextData={contextData}
          lastTypedMessage={lastTypedMessage}
          setSetLastTypedMessage={setSetLastTypedMessage}
        />
      )}
      {taskType === 'report' && (
        <MidCycleTab
          data={taskSubmissionDetail}
          lastTypedMessage={lastTypedMessage}
          setSetLastTypedMessage={setSetLastTypedMessage}
        />
      )}
      {taskType !== 'final' && taskType !== 'report' && (
        <TaskFeedbackTab
          data={taskSubmissionDetail}
          showStudents={showStudents}
          contextData={contextData}
          setContextData={setContextData}
          lastTypedMessage={lastTypedMessage}
          setSetLastTypedMessage={setSetLastTypedMessage}
        />
      )}
    </>
  )
}

export default AssessmentFeedback
