import React from 'react'
import PropTypes from 'prop-types'

const ExpertList = ({ experts }) => {
  return (
    <div className='flex flex-row flex-wrap gap-3'>
      {experts?.map(expert => (
        <div key={expert} className={`rounded py-1 px-2 bg-alpha/10 font-bold text-xs whitespace-nowrap ${expert ? '' : 'text-danger-50'}`}>
          {expert ? expert : 'Missing Expert'}
        </div>
      ))}
    </div>
  )
}
ExpertList.propTypes = {
  experts: PropTypes.array,
}

export default ExpertList
