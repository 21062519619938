import React from 'react'
import PropTypes from 'prop-types'

const RedDot = ({ show = false }) => {
  if (!show) return (<></>)
  return (
    <span className="flex h-2 w-2 relative mr-3">
      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-danger-30 opacity-75"></span>
      <span className="relative inline-flex rounded-full h-2 w-2 bg-danger-30"></span>
    </span>
  )
}

RedDot.propTypes = {
  show: PropTypes.bool
}

export default RedDot
