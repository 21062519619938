import React from 'react'
import { SubmenuPortal } from '../..'
import { Outlet, useLoaderData } from 'react-router'
import { SoraNavLink } from '@components/link'
import axios from 'axios'

export async function loader({ params }) {
  const { data } = await axios.get(`/pages/student/${params.student_id}/progress`)
  return data
}

export default function PaceAndProgressRoute() {
  const loaderData = useLoaderData()

  const shouldShowGraduationTab = loaderData.shouldShowGraduationTab
  const schoolStage = loaderData.schoolStage

  return (
    <div className="flex-1">
      <div className='group-[.flutter]/flutter:hidden'>
        <SubmenuPortal>
          <div className="grid grid-cols-5 items-end text-center h-full w-[32rem] relative mb-3">
            <SoraNavLink to="cycle" className={active => `w-full border-b-2 py-1.5 text-xs font-bold uppercase 
                ${active ? 'border-accent text-accent' : 'border-transparent'
              }`}>
              Cycle
            </SoraNavLink>
            {shouldShowGraduationTab && (
              <SoraNavLink to="graduation" className={active => `w-full border-b-2 py-1.5 text-xs font-bold uppercase 
                ${active ? 'border-accent text-accent' : 'border-transparent'
                }`}>
                {schoolStage === 'hs' ? 'Graduation' : 'Year'}
              </SoraNavLink>
            )}
          </div>
        </SubmenuPortal>
      </div>
      <Outlet />
    </div>
  )
}
