
import React from 'react'
import axios from 'axios'
import { redirect, useActionData, useLoaderData, useNavigation } from 'react-router-dom'

import { useThisPageTitle } from '@hooks/usePageTitle'
import ExpeditionForm from '@components/expedition-form'
import serializeFormData from '@utils/serializeFormData'
import { useToast } from '@hooks/useToast'
import { DataPersistenceBanner } from '@components/data-persistence-banner'
import { REMOTE_STATE_KEY, getDiffsFromCommonKeys, getLocalStorageData } from '@utils/formPersistence'

export default function EditExpeditionRoute() {
  const loaderData = useLoaderData()
  const navigation = useNavigation()
  const actionData = useActionData()

  const employees = loaderData.employees
  const cohorts = loaderData.cohorts
  const units = loaderData.units
  const cycles = loaderData.cycles
  const expedition = loaderData.theme
  const savedFields = loaderData.locallySavedFields
  const isLocalStorageData = loaderData.isLocalStorageData
  const isSavedRemoteStateDirty = loaderData.isSavedRemoteStateDirty

  useThisPageTitle(`${expedition?.title || 'Expedition'} - Editing`, { backTo: '/v2/employee/expeditions/edit' })
  useToast(actionData)

  return <div className="flex flex-col gap-6">
    {isSavedRemoteStateDirty ? (
      <DataPersistenceBanner
        variation='remoteDirty'
        onKeepChanges={() => {
          window.localStorage.setItem(`${REMOTE_STATE_KEY}_${location.pathname}`, JSON.stringify(loaderData.remoteState))
          window.location.reload()
        }}
        onUpdateVersion={() => {
          window.localStorage.removeItem(`${REMOTE_STATE_KEY}_${location.pathname}`)
          window.localStorage.removeItem(location.pathname)
          window.location.reload()
        }}
      />
    ) : isLocalStorageData ? (
      <DataPersistenceBanner
        variation='localData'
        onDiscardChanges={() => {
          window.localStorage.removeItem(`${REMOTE_STATE_KEY}_${location.pathname}`)
          window.localStorage.removeItem(location.pathname)
          window.location.reload()
        }}
      />
    ) : null}
    <ExpeditionForm
      cohorts={cohorts}
      employees={employees}
      cycles={cycles}
      units={units}
      initialExpedition={expedition}
      submitting={navigation.state === 'submitting'}
      fieldErrors={actionData?.error}
      isHeartbeatFeatureFlagEnabled={loaderData.isHeartbeatFeatureFlagEnabled}
      isMsHeartbeatFeatureFlagEnabled={loaderData.isMsHeartbeatFeatureFlagEnabled}
      savedFields={savedFields}
    />
  </div>
}

export const loader = async ({ request, params }) => {
  const url = new URL(request.url)
  const cycle_id = url.searchParams.get("cycle_id")
  try {
    const { data } = await axios.get(`/pages/employee/expeditions/${params.id}/edit${cycle_id ? `?cycle_id=${cycle_id}` : ''}`)
    const remoteState = data.theme
    const localState = getLocalStorageData(url.pathname)
    const savedRemoteState = JSON.parse(window.localStorage.getItem(`${REMOTE_STATE_KEY}_${url.pathname}`)) || {}
    const defaultThemeData = localState ?? remoteState
    const isSavedRemoteStateDirty = getDiffsFromCommonKeys(remoteState, savedRemoteState).length > 0
    const isLocalStorageData = !!localState
    const locallySavedFields = isLocalStorageData ? getDiffsFromCommonKeys(localState || {}, savedRemoteState || {}) : []

    return {
      theme: defaultThemeData,
      employees: data.employees,
      isSavedRemoteStateDirty,
      isLocalStorageData,
      cohorts: data.cohorts,
      units: data.units,
      cycles: data.cycles,
      selectedCycleId: data.selectedCycleId,
      isHeartbeatFeatureFlagEnabled: data.isHeartbeatFeatureFlagEnabled,
      isMsHeartbeatFeatureFlagEnabled: data.isMsHeartbeatFeatureFlagEnabled,
      locallySavedFields,
    }
  } catch (e) {
    console.error(e)
    return redirect('/')
  }
}

export const action = async ({ request }) => {
  const formData = await request.formData()
  let serializedData = serializeFormData(formData)
  serializedData.employees_ids = formData.getAll('employees_ids').join(',')

  serializedData.enable_heartbeat = serializedData.enable_heartbeat === 'on' ? true : 
    serializedData.enable_heartbeat === 'false' ? false :
    undefined
  
  const typeParam = serializedData.type.includes('goal') ? 'goal' : serializedData.type
  try {
    await axios.post(`/pages/employee/expeditions/${serializedData.id}/edit`, serializedData)
    return redirect(`/employee/expeditions/edit?cycle_id=${serializedData.competition_interval_id}&type=${typeParam}`)
  } catch (err) {
    return {
      toast: {
        message: err?.response?.data?.errors?.details?.map(det => det.message).join('. ') || 'There was an error while editing this expedition.',
        appearance: 'error'
      }
    }
  }
}
