import React from 'react'
import { Form, redirect, useLoaderData, useNavigation } from 'react-router-dom'
import axios from 'axios'
import { Button } from '@designsystem'
import serializeFormData from '@utils/serializeFormData'
import StudentSelectOptions from './student-select-options'
import StudentSelectSingle from './student-select-single'

const loader = async ({ params }) => {
  const { processId } = params
  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/students`)
  return result?.data
}

const action = async ({ params, request }) => {
  const { processId } = params
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const action = serializedFormData._action

  try {
    await axios.post(`/backoffice/workbench/journey-builder/processes/${processId}/students`, { ...serializedFormData, action })
    return action === 'next' ? redirect('../templates/config') : null
  } catch (error) {
    return {
      toast: { message: 'There was an error running Journey Builder. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const navigation = useNavigation()
  const { students, is_all_selected } = useLoaderData()

  const isFormSubmitting = navigation.state !== 'idle'

  return (
    <>
      <div className='flex-auto relative'>
        <div className='overflow-y-auto w-full absolute top-0 bottom-20'>
          <table className='w-full'>
            <thead className='bg-screen-primary text-left sticky top-0 drop-shadow'>
              <tr>
                <th className='py-1 w-[60px]'>
                  <StudentSelectOptions isAllSelected={is_all_selected} />  
                </th>
                <th className='py-1 px-3 w-[60px]'>ID</th>
                <th className='py-1 px-3 w-[160px]'>Preferred Name</th>
                <th className='py-1 px-3'>Name</th>
                <th className='py-1 px-3'>Email</th>
              </tr>
              <tr>
                <th colSpan={5} className='h-px bg-alpha/10'></th>
              </tr>
            </thead>
            <tbody>
              {students.map(student => (
                <tr key={student.id}>
                  <td className='py-1 px-3'>
                    <StudentSelectSingle checked={student.checked} studentId={student.id} />
                  </td>
                  <td className='py-1 px-3'>{student.id}</td>
                  <td className='py-1 px-3'>{student.preferred_name}</td>
                  <td className='py-1 px-3'>
                    {student.full_name}
                    {student.is_new && <span className='text-xs font-bold text-green-50 bg-green-10 rounded px-2 ml-2'>New</span>}
                  </td>
                  <td className='py-1 px-3'>{student.sora_email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className='flex flex-row justify-end align-middle p-4 space-x-3 absolute bottom-0 left-0 right-0 rounded-xl border border-gray-30 bg-white'>
        <Form method="post" id="journey-builder-form">
          <input type="hidden" name="_action" value="next" />
          <Button type='submit' disabled={isFormSubmitting} >Next</Button>
        </Form>
      </div>
    </>
  )
}

export const JourneyBuilderStudentsRoute = {
  loader,
  action,
  Element,
}
