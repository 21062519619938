import React from 'react'
import PropTypes from 'prop-types'
import EditableActivityUnitLevel from '@components/editable-activity/editable-activity-unit-level'
import axios from 'axios'

const EditableTaskUnitLevel = ({ initialUnitLevel, availableUnits, afterSubmit }) => {
  const isNew = !initialUnitLevel?.id

  const handleSubmit = data => {
    if (isNew) {
      return axios.post(`/tasks/${initialUnitLevel.task_id}/units_levels`, { ...initialUnitLevel, ...data })
        .then(res => {
          afterSubmit && afterSubmit()
          return res
        })
        .catch(console.error)
    } else {
      return axios.patch(`/tasks/${initialUnitLevel.task_id}/units_levels/${initialUnitLevel.id}`, { ...initialUnitLevel, ...data })
        .then(res => {
          afterSubmit && afterSubmit()
          return res
        })
        .catch(console.error)
    }
  }

  const handleDelete = () => {
    return axios.delete(`/tasks/${initialUnitLevel.task_id}/units_levels/${initialUnitLevel.id}`)
      .then(() => {
        afterSubmit && afterSubmit()
      })
      .catch(console.error)
  }

  return <EditableActivityUnitLevel initialUnitLevel={initialUnitLevel.id && initialUnitLevel} availableUnits={availableUnits}
    onDelete={handleDelete} onSubmit={handleSubmit} />
}

EditableTaskUnitLevel.propTypes = {
  initialUnitLevel: PropTypes.shape({
    id: PropTypes.number,
    task_id: PropTypes.number,
  }),
  availableUnits: PropTypes.array,
  afterSubmit: PropTypes.func,
}

export default EditableTaskUnitLevel
