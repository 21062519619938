import React from 'react'
import axios from 'axios'
import { Outlet, useLoaderData, useLocation, useParams } from 'react-router-dom'
import { NavigateCycle } from '@hooks/useNavigateWithCycle'

// ! THIS ROUTE EXISTS BECAUSE FLUTTER STUDENT MIRROR FEATURE CANNOT HANDLE ROUTES THAT HAVE THE useCurrentUser hook 
// ! IN THEM DUE TO THE WAY IT HANDLES AUTHENTICATION
export async function loader({ params }) {
  const { data } = await axios.get(`/pages/student/${params.student_id}`)
  return data
}

export default function StudentRoutingNew() {
  const { student_id } = useParams()
  const location = useLocation()
  const currentUser = useLoaderData()

  if (
    currentUser && ['student', 'guardian'].includes(currentUser?.role) &&
    (
      ['direct', 'me'].includes(student_id) ||
      (currentUser?.role === 'student' && Number(currentUser?.id) !== Number(student_id)) ||
      (currentUser?.role === 'guardian' && !currentUser?.students_ids.map(id => Number(id)).includes(Number(student_id)))
    )
  ) {
    const studentId = currentUser?.role === 'student' ? currentUser?.id : currentUser?.students_ids[0]
    const newPath = location.pathname.replace(student_id, studentId)
    return <NavigateCycle to={`${newPath}${location.search}`} replace={true} />
  }

  return <Outlet />
}

