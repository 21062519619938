import React from 'react'
import axios from "axios"
import serializeFormData from '@utils/serializeFormData'
import { useLoaderData } from 'react-router'
import { Icon, Button, Typography } from '@design-system'
import { Link, useSearchParams } from 'react-router-dom'

async function loader({ params }) {
  const result = await axios.get(`/backoffice/houses/${params.houseId}/attendances/${params.eventId}/zoom-report`)
  return result.data
}

async function action({ params, request }) {
  try {
    const formData = await request.formData()
    const serializedFormData = serializeFormData(formData)
    const response = await axios.post(`/backoffice/houses/${params.houseId}/attendances/${params.eventId}/zoom-report`, serializedFormData)
    return response.data
  } catch (error) {
    const data = error.response?.data
    return data || {
      toast: {
        message: 'Something went wrong. Please try again later.',
        appearance: 'error',
      }
    }
  }
}

function Element() {
  const loaderData = useLoaderData()
  const [searchParams] = useSearchParams()
  return <div className="fixed top-0 right-0 bottom-0 min-w-fit-content z-100 bg-white px-10 border border-r h-screen overflow-scroll">
    <div className="flex flex-row items-center gap-4 justify-between pb-8 py-10 sticky top-0 bg-white">
      <Typography variant="heading-1">
        Zoom Report
      </Typography>
      <Button asChild variant="outlined" color="soft">
        <Link to={'..?' + searchParams.toString()}>
          <Icon name="cross" />
        </Link>
      </Button>
    </div>
    <table className="table-auto">
      <thead className="sticky top-24 bg-white">
        <tr>
          <th>
            <Typography variant="heading-6" className="pb-4 text-left">
              Participant
            </Typography>
          </th>
          <th>
            <Typography variant="heading-6" className="pl-6 pb-4 text-right">
              Joined At
            </Typography>
          </th>
          <th>
            <Typography variant="heading-6" className="pl-6 pb-4 text-right">
              Total Time
            </Typography>
          </th>
        </tr>
      </thead>
      <tbody className="h-full ">
        {loaderData.participantsList.map((participant) => (
          <tr key={participant.name}>
            <td className="flex items-center">
              <Typography variant="body" className="py-2">
                {participant.name}
              </Typography>
              {participant.isVerified && (
                <Icon name="shield-check-filled" size='xs' className="text-success-50 ml-2" />
              )}
            </td>
            <td>
              <Typography variant="body" className="text-right py-2">
                {new Date(participant.joinedAt).toLocaleTimeString()}
              </Typography>
            </td>
            <td>
              <Typography variant="body" className="text-right py-2">
                {participant.time}
              </Typography>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
}

export const HouseAttendanceEventZoomReportRoute = {
  loader,
  action,
  Element,
}
