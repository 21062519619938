import React, { useEffect, useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { Transition } from '@headlessui/react'
import { useFetcher, useLoaderData } from 'react-router-dom'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { useToast } from '@hooks/useToast'
import capitalize from '@utils/capitalize'
import { Typography, Button } from '@design-system'
import useConfirmModal from '@hooks/useConfirmModal'
import NothingHere from '../../../../components/error-boundary/nothing-here.svg'

async function loader({ params }) {
  const { data } = await axios.get(`/pages/employee/students/${params.student_id}/withdrawal`)
  return data
}

async function action({ request, params }) {
  const studentId = params.student_id
  const formData = await request.formData()
  const cycleId = new URL(request.url).searchParams.get('cycle_id')
  if (formData.get('_confirm') === 'false') {
    switch (formData.get('action')) {
      case 'remove_experiences': {
        const experiencesResponse = await axios.get(`/pages/employee/students/${studentId}/withdrawal/remove_experiences${cycleId ? `?cycle_id=${cycleId}` : ''}`)
        return {
          action: 'remove_experiences',
          confirmModal: {
            title: 'Are you sure?',
            subtitle: <div className="flex flex-col gap-2">
              <p>This will remove the student from all experiences.</p>
              {experiencesResponse.data?.experiencesByCycle && Object.entries(experiencesResponse.data?.experiencesByCycle).map(([cycle, experiences]) => (
                <div key={cycle}>
                  <h3 className="font-bold">{cycle}</h3>
                  <ul className="list-disc list-inside">{experiences.map((exp) => <li key={exp.id}>{exp.title} - ({capitalize(exp.type).replaceAll('_', ' ')})</li>)}</ul>
                </div>
              ))}
            </div>,
            confirmLabel: 'Remove',
            cancelLabel: 'Cancel'
          }
        }
      }
      case 'remove_house_events': {
        const houseResponse = await axios.get(`/pages/employee/students/${studentId}/withdrawal/remove_house_events`)
        return {
          action: 'remove_house_events',
          confirmModal: {
            title: 'Are you sure?',
            subtitle: `This will remove the student from all standups and checkpoints from ${houseResponse.data?.houseTitle || 'their house'}.`,
            confirmLabel: 'Remove',
            cancelLabel: 'Cancel'
          }
        }
      }
      case 'remove_platforms': {
        return {
          action: 'remove_platforms',
          confirmModal: {
            title: 'Are you sure?',
            subtitle: <div>
              <p>This will automatically revoke the student's access from:</p>
              <ul className="ml-2 list-disc list-inside">
                <li>Zoom</li>
                <li>Google</li>
                <li>Rosetta Stone</li>
                <li>Heartbeat (Guardians will also be removed)</li>
              </ul>
              <p> Also, don't forget to remove the student from platforms that are not in this automation, such as:</p>
              <ul className="ml-2 list-disc list-inside">
                <li>IXL</li>
                <li>Discord</li>
                <li>Canva</li>
              </ul>
              <p className="italic font-semibold">We recommend a double check on the automated platforms to assure student access was removed.</p>
            </div>,
            confirmLabel: 'Revoke',
            cancelLabel: 'Cancel'
          }
        }
      }
    }
  }
  try {
    const response = await axios.post(`/pages/employee/students/${studentId}/withdrawal${cycleId ? `?cycle_id=${cycleId}` : ''}`, {
      action: formData.get('action')
    })
    return response.data
  } catch (error) {
    return {
      toast: {
        appearance: 'error',
        message: error?.response?.data?.message || 'Something went wrong while executing this action.'
      }
    }
  }
}

function Element() {
  const loaderData = useLoaderData()
  const removePlatformsFetcher = useFetcher()
  const removeExperiencesFetcher = useFetcher()
  const removeHouseEventsFetcher = useFetcher()
  const banners = removePlatformsFetcher.data?.banners
  const canExecuteWithdrawal = loaderData.canExecuteWithdrawal

  if (!canExecuteWithdrawal) {
    return (
      <div className="h-screen w-full items-center flex flex-col gap-3 justify-center">
        <img className="ml-auto mr-auto h-52" loading="lazy" src={NothingHere} alt="Desert with cactus drawing" />
        <Typography variant="heading-2">Can't display withdrawal actions</Typography>
        <Typography variant="subheadline">Student should have a leave date set in order to execute withdrawal actions.</Typography>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-1">
        <Typography variant="heading-2" weight="bold">Student Withdrawal</Typography>
        <Typography variant="body">Hey, if you're seeing this screen, it means this student has a leave date set, and therefore, is about to depart or has already left Sora.</Typography>
        <Typography variant="body">The 3 buttons below allow you to execute with easiness, 3 individual pieces of the withdrawal process:</Typography>
      </div>
      <div>
        <Typography variant="body">1. Removing the Student from Experiences.</Typography>
        <Typography variant="body">2. Removing the Student from House Events.</Typography>
        <Typography variant="body">3. Revoking Student's access to Zoom, Google, Rosetta Stone and Heartbeat accounts.</Typography>
      </div>
      <div className="flex gap-4">
        <ConfirmationForm fetcher={removeExperiencesFetcher} method="POST" actionValue="remove_experiences" buttonTitle="Remove from Experiences" />
        <ConfirmationForm fetcher={removeHouseEventsFetcher} method="POST" actionValue="remove_house_events" buttonTitle="Remove from House Events" />
        <ConfirmationForm fetcher={removePlatformsFetcher} method="POST" actionValue="remove_platforms" buttonTitle="Remove from Platforms" />
      </div>
      {banners ? banners.map((banner) => (
        <Banner key={banner.title} title={banner.title} message={banner.message} />
      )) : null}
    </div>
  )
}

function Banner({ title, message }) {
  const [dismissBanner, setDismissBanner] = useState(false)

  return !dismissBanner && (
    <Transition
      appear
      show={!dismissBanner}
      enter="transition-opacity duration-250"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-250"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="p-4 bg-warning-10 flex flex-col rounded-lg border border-warning-20 justify-start w-fit gap-4">
        <div className="justify-between flex items-center">
          <Typography variant="heading-4" weight="bold">{title}</Typography>
          <Button as="span" startIcon={faClose} size="xs" onClick={() => setDismissBanner(true)} variant="nude" />
        </div>
        <Typography variant="body">{message}</Typography>
      </div>
    </Transition>
  )
}

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}

/**
 * Had to change the implementation slightly here, because it was not working properly with the original implementation.
 * The useRef hook was always referencing the latest form of the dom, instead of the form it was originially assigned to.
 * I tried using forwardRef and also useCallback as a ref, but none of them worked.
 */
const ConfirmationForm = ({ fetcher, buttonTitle, actionValue, ...rest }) => {
  const confirm = useConfirmModal()
  const fetcherData = fetcher.data
  useToast(fetcherData)

  useEffect(() => {
    if (fetcherData?.confirmModal) {
      confirm(fetcherData.confirmModal).then((confirmed) => {
        if (confirmed) {
          fetcher.formData.set('_confirm', 'true')
          fetcher.submit(fetcher.formData, { method: rest.method })
        } else {
          fetcher.formData.set('_confirm', 'false')
        }
      })
    }
  }, [fetcherData?.confirmModal])

  return (
    <fetcher.Form {...rest}>
      <input type="hidden" name="_confirm" value={'false'} />
      <Button name="action" value={actionValue} loading={fetcher.state === 'submitting'} type="submit">{buttonTitle}</Button>
    </fetcher.Form>
  )
}

ConfirmationForm.displayName = 'ConfirmationForm'
ConfirmationForm.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  actionValue: PropTypes.string.isRequired,
  fetcher: PropTypes.object.isRequired,
}

export const EmployeeStudentWithdrawalRoute = {
  Element,
  action,
  loader,
}
