import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SoraIcon, { soraBoxOutline, soraFlagOutline } from '@components/sora-icon'
import { faClock, faQuestionCircle, faCircleCheck } from '@fortawesome/free-regular-svg-icons'

const getIconForSubmission = (status, temporal_status, submission_status) => {
  if (temporal_status === 'late') return (<span className='text-yellow-20 text-lg'><FontAwesomeIcon icon={faClock} /></span>)
  if (temporal_status === 'missed') return (<span className='text-danger-50 text-lg'><FontAwesomeIcon icon={faQuestionCircle} /></span>)

  if (submission_status === 'flagged') return (<span className='text-danger-50'><SoraIcon icon={soraFlagOutline} className='w-4 h-4' /></span>)
  if (submission_status === 'reviewed') return (<span className='text-green-90 text-lg'><FontAwesomeIcon icon={faCircleCheck} /></span>)

  return (<span className='text-blue-50'><SoraIcon icon={soraBoxOutline} className='w-4 h-4' /></span>)
}

export default getIconForSubmission
