import PropTypes from 'prop-types'
import React from 'react'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import format from 'date-fns/format'
import PreWorkCard from './pre-work-card.jsx'
import { RenderedSlate } from '@components/forms/slate-textarea/index.jsx'

const ExpeditionRow = ({ session, title, zoomLink, isClosestDay, openSession, setOpenSession, timeStampTZ }) => {
  const openedSession = openSession === session.id
  return (
    <>
      <div
        onClick={() => openedSession ? setOpenSession(null) : setOpenSession(session.id)}
        className={
          `${openedSession
            ? 'bg-white shadow-md rounded-b-none'
            : 'bg-gray-20  mb-4'} flex justify-between items-center text-1xl h-10 p-2 px-4 rounded-md`
        }>
        <div>
          <FontAwesomeIcon
            icon={openedSession ? faChevronDown : faChevronRight}
            className="pt-1 text-lg lg:text-xl cursor-pointer text-gray-70" />
          <span className="font-bold text-gray-70 ml-6">{openedSession ? 'Pre-work' : session.title}</span>
        </div>
        <div>
          {!openedSession && <span className="font-bold mr-10 text-gray-70">{new Date(session.timestamptz).toLocaleDateString([], { day: 'numeric', month: 'numeric' })}</span>}
          <span className="font-bold text-gray-50">{title}</span>
        </div>
      </div>
      {openedSession && (
        <div className="bg-white mb-4 shadow-md rounded-md rounded-t-none p-4" >
          <div className="mb-10">
            {session.preWork?.map(task => {
              return (
                <PreWorkCard key={task.id} task={task} timeStampTZ={timeStampTZ} />
              )
            })
            }
          </div>
          <div className="flex justify-between items-center">
            <h3 className="font-bold text-2xl text-gray-70 uppercase">{session.title}</h3>
            <div className="flex items-center">
              {isClosestDay && <a href={zoomLink} target="_blank" rel="noreferrer" className="bg-green-50 hover:bg-green-40 rounded-full font-bold text-1xl text-white py-2 px-6 uppercase">Join</a>}
              <span className="font-bold ml-6 text-gray-70 text-2xl">{format(new Date(session.timestamptz), 'M/d')}</span>
            </div>
          </div>
          <h5 className="uppercase font-bold text-xs">{new Date(session.timestamptz).toLocaleTimeString('en-US', {
            weekday: 'long',
            hour: '2-digit',
            minute: '2-digit',
          })}</h5>
          {session.rte_description && <div className="mt-4"><RenderedSlate value={session.rte_description} /></div>}
        </div>
      )}

    </>
  )
}

ExpeditionRow.propTypes = {
  isClosestDay: PropTypes.bool,
  closestDate: PropTypes.any,
  openSession: PropTypes.any,
  session: PropTypes.shape({
    description: PropTypes.any,
    rte_description: PropTypes.any,
    id: PropTypes.any,
    preWork: PropTypes.array,
    timestamptz: PropTypes.any,
    title: PropTypes.any
  }),
  setOpenSession: PropTypes.func,
  timeStampTZ: PropTypes.any,
  title: PropTypes.any,
  zoomLink: PropTypes.any
}

export default ExpeditionRow
