import axios from 'axios'
import { redirect } from 'react-router-dom'

export const loader = async ({ request, params }) => {
  const url = new URL(request.url)
  const cycle_id = url.searchParams.get("cycle_id")

  try {
    const { data: { theme, students } } = await axios.get(`/pages/employee/expeditions/${params.id}/registrations`)
    return { theme, students }
  } catch (e) {
    console.error(e)
    return redirect(`/${cycle_id ? `?cycle_id=${cycle_id}` : ''}`)
  }
}
