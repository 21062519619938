import React, { useState } from 'react'
import { Outlet, useLoaderData, useParams } from 'react-router-dom'
import { NavigateCycle } from '@hooks/useNavigateWithCycle'
import StudentList from '@components/facilitate/student/student-list'
import axios from 'axios'

export const loader = async ({ params }) => {
  const { data } = await axios.get(`/tasks/${params.task_id}/submission/students`)
  return data
}

export default function AssessmentTask() {
  const [showStudents, setShowStudents] = useState(true)
  const { student_id } = useParams()

  const loaderData = useLoaderData()
  const studentListData = loaderData.result

  const { unassessed, flagged, not_submitted, assessed } = studentListData
  const allStudents = [...(unassessed || []), ...(flagged || []), ...(not_submitted || []), ...(assessed || [])]
  const firstId = allStudents[0]?.id

  if (!student_id) {
    return <NavigateCycle to={`${firstId}`} replace={true} />
  }

  const handleToggleShowStudents = (e) => {
    e.stopPropagation()
    setShowStudents(current => !current)
  }

  return (
    <>
      {student_id !== '0' &&
        <StudentList data={studentListData} selectedStudentId={student_id} showStudents={showStudents} handleToggleShowStudents={handleToggleShowStudents} />
      }
      <Outlet context={{ showStudents }} />
    </>
  )
}
