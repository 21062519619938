import axios from 'axios'

const loader = async ({ request }) => {
  const searchParams = new URL(request.url).searchParams
  const cycle_id = searchParams.get('cycle_id')
  const { data } = await axios.get(`pages/employee/expeditions/swap-requests${cycle_id ? `?cycle_id=${cycle_id}` : ''}`)
  return data
}

export default loader
