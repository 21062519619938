import React from 'react'
import PropTypes from 'prop-types'
import { RemoveExpert } from './remove-expert'
import { CyclesExperts } from './cycles-experts'
import { StudentsTag } from './students-tag'
import { UnitList } from './unit-list'
import { AddExpert } from './add-expert'

export const ExpeditionList = ({ processId, templates, experts }) => {

  const categoryView = (title) => {
    switch (title.toLowerCase()) {
      case 'stem':
        return (<span className='rounded py-1 px-2 bg-green-5 font-bold text-xs whitespace-nowrap'>Stem</span>)
      case 'humanities':
        return (<span className='rounded py-1 px-2 bg-turquoise-5 font-bold text-xs whitespace-nowrap'>Humanities</span>)
      case 'math_learning_goal':
        return (<span className='rounded py-1 px-2 bg-purple-5 font-bold text-xs whitespace-nowrap'>Math</span>)
      case 'electives':
        return (<span className='rounded py-1 px-2 bg-orange-5 font-bold text-xs whitespace-nowrap'>Electives</span>)
    }
  }
  return (
    templates.map(template => {
      const expertsLength = template.experts.length + 1
      if (expertsLength > 1) {
        return (
          <React.Fragment key={template.id}>
            <tr>
              <td colSpan={7}>&nbsp;</td>
            </tr>
            <tr className='bg-white align-top h-10'>
              <td className='p-2 align-top' rowSpan={expertsLength}>
                <div className='flex flex-col'>
                  <span>{template.title} #{template.id}</span>
                </div>
                {categoryView(template.category)}
              </td>
              <td className='p-2'>
                <RemoveExpert assignmentId={template.experts[0]?.journey_assignment_experts_id} />
              </td>
              <td className='p-2'>
                <span className='text-nowrap'>({template.experts[0]?.job_title}) {template.experts[0]?.name}</span>
              </td>
              <td className='p-2'>
                <CyclesExperts id={template.experts[0]?.journey_assignment_experts_id} selectedCycles={template.experts[0]?.cycles} />
              </td>
              <td className='p-2 align-top' rowSpan={expertsLength}>{template.request_list_hits}</td>
              <td className='p-2 align-top' rowSpan={expertsLength}><UnitList units={template.units} /></td>
              <td className='p-2 align-top' rowSpan={expertsLength}>
                <StudentsTag students={template.students} />
              </td>
            </tr>
            {template.experts.slice(1).map((expert, idx) => (
              <tr key={`template_${template.id}_expert_${expert.id}_${idx}`} className='bg-white align-top h-10'>
                <td className='p-2'>
                  <RemoveExpert assignmentId={expert.journey_assignment_experts_id} />
                </td>
                <td className='p-2'>
                  <span className='text-nowrap'>({expert.job_title}) {expert.name}</span>
                </td>
                <td className='p-2'>
                  <CyclesExperts id={expert.journey_assignment_experts_id} selectedCycles={expert.cycles} />
                </td>
              </tr>
            ))}
            <tr className='bg-white align-top'>
              <td className='p-2' colSpan={3}>
                <div className='flex flex-row'>
                  <AddExpert templateId={template.id} />
                </div>
              </td>
            </tr>
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment key={template.id}>
            <tr>
              <td colSpan={7}>&nbsp;</td>
            </tr>
            <tr key={template.id} className='bg-white'>
              <td className='p-2'>
                {template.title}
              </td>
              <td className='p-2' colSpan={3}>
                <div className='flex flex-row'>
                  <AddExpert templateId={template.id} />
                </div>
              </td>
              <td className='p-2'>{template.request_list_hits}</td>
              <td className='p-2'><UnitList units={template.units} /></td>
              <td className='p-2'><StudentsTag students={template.students} /></td>
            </tr>
          </React.Fragment>
        )
      }
    })
  )
}
ExpeditionList.propTypes = {
  students: PropTypes.array,
  defaultCycles: PropTypes.array,
}
