import React from 'react'
import PropTypes from 'prop-types'
import { useFetcher } from 'react-router-dom'
import { Button, Icon } from '@design-system'

export const RemoveExpert = ({ assignmentId }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle' && Number(fetcher.formData?.get('journey_assignment_experts_id')) === Number(assignmentId)

  return (
    <fetcher.Form method="post" className="flex flex-col justify-center">
      <input type="hidden" name="_action" value="unassign_expert" />
      <input type="hidden" name="journey_assignment_experts_id" value={assignmentId} />
      <Button type="submit" variant='outlined' disabled={isLoading} loading={isLoading} className='h-6 w-6 p-1'>
        <Icon name='cross' className='text-xxs font-bold' />
      </Button>
    </fetcher.Form>
  )
}
RemoveExpert.propTypes = {
  assignmentId: PropTypes.number,
}
