import React from 'react'
import { useLoaderData, Navigate } from 'react-router-dom'
import axios from 'axios'

const loader = async({ params }) => {
  const { processId } = params
  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/_index`)
  return result?.data
}

const Element = () => {
  const { process } = useLoaderData()

  switch (process?.current_step) {
    case 'student_selection':
      return <Navigate to="./students" replace={true}/>
    case 'setup':
      return <Navigate to="./templates/config" replace={true}/>
    case 'template_selection':
      return <Navigate to="./templates/review" replace={true}/>
    case 'assignment':
      return <Navigate to="./assignments/students" replace={true}/>
    case 'schedule_params':
      return <Navigate to="./schedule/config" replace={true}/>
    case 'schedule_review':
      return <Navigate to="./schedule/review/students" replace={true}/>
    case 'generated':
      return <Navigate to="./schedule/generated/students" replace={true}/>
    default:
      return <Navigate to="../" replace={true}/>
  }
}

export const JourneyBuilderProcessIndexRoute = {
  loader,
  Element,
}
