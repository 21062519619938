import Papa from 'papaparse'

export const action = async ({ request }) => {
  const formData = await request.formData()
  const formType = formData.get('formType')

  if (formType === 'uploadRosettaStudentsCSV') {
    const file = formData.get('rosetta_students_csv')

    if (!file?.size) {
      return {
        errors: {
          rosetta_students_csv: 'Invalid CSV file',
        },
      }
    }

    try {
      const { parsedCSVData, rawCSVData } = await asyncPapaParse(file)
      return {
        parsedCSVData,
        rawCSVData,
      }
    } catch (csvErrors) {
      return {
        csvErrors,
      }
    }
  }
}

export function parseCSVData(csvData) {
  const parsedRow = []
  csvData.forEach((row) => {
    if (row.Language && row.Email) {
      parsedRow.push({ email: row.Email, language: row.Language })
    }
  })

  return parsedRow
}

function asyncPapaParse(file) {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const { data, errors } = results
        if (errors?.length) return reject(errors)
        let parsedCSVData = null
        try {
          parsedCSVData = parseCSVData(data)
          if (!parsedCSVData?.length) return reject([{ row: -1, message: 'CSV is empty' }])
        } catch (e) {
          return reject([
            {
              row: -1,
              message: 'Error while parsing CSV: ' + e?.message || '',
            },
          ])
        }
        return resolve({
          parsedCSVData,
          rawCSVData: data,
        })
      },
    })
  })
}
