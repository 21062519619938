import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useFetcher } from 'react-router-dom'
import { Icon } from '@design-system'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu } from '@headlessui/react'
import useConfirmModal from '@hooks/useConfirmModal'

const StudentSelectOptions = ({ isAllSelected = false }) => {
  const fetcher = useFetcher()
  const confirm = useConfirmModal()
  const isLoading = fetcher.state !== 'idle'

  const selectAll = (event) => {
    fetcher.submit({ _action: 'select-all' }, { method: "post" })
  }

  const clearAll = (event) => {
    fetcher.submit({ _action: 'clear-all' }, { method: "post" })
  }

  const doAction = (action) => async (event) => {
    event.preventDefault()
    const result = await confirm({
      title: 'Do you want to clear the selected ones?',
      subtitle: 'This option will deselect previously selected items.',
      confirmLabel: 'Yes',
      cancelLabel: 'No, I want to merge',
    })

    fetcher.submit({ _action: action, clean: result }, { method: "post" })
  }

  return (
    <fetcher.Form method='post'>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex flex-row items-center rounded-md bg-white border border-gray-40 px-3 py-2 gap-1 focus:outline-none focus-visible:ring-2" disabled={isLoading}>
            {isLoading && <FontAwesomeIcon spin={true} className="flex text-gray-50" icon={faSpinner} />}
            {!isLoading &&
              <>
                <input type="checkbox" className='pointer-events-none' defaultChecked={isAllSelected} />
                <Icon name='chevron-down' size='xs' />
              </>
            }
          </Menu.Button>
        </div>
        <Menu.Items className="absolute left-0 mt-2 flex flex-col divide-y rounded-md bg-white shadow-lg border border-gray-40 focus:outline-none">
          <div className="px-1 py-1 ">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-gray-70 text-white' : 'text-gray-900'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={selectAll}
                >
                  Select All
                </button>
              )}
            </Menu.Item>
          </div>
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-gray-70 text-white' : 'text-gray-900'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm whitespace-nowrap`}
                  onClick={doAction('select-returning-students')}
                >
                  Select Returning Students
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-gray-70 text-white' : 'text-gray-900'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm whitespace-nowrap`}
                  onClick={doAction('select-new-students')}
                >
                  Select New Students
                </button>
              )}
            </Menu.Item>
          </div>
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-gray-70 text-white' : 'text-gray-900'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={clearAll}
                >
                  Clear All
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
    </fetcher.Form>
  )
}
StudentSelectOptions.propTypes = {
  isAllSelected: PropTypes.bool,
}

export default StudentSelectOptions
