import PropTypes from 'prop-types'
import React from 'react'
import useSWR from 'swr'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SessionItem = ({ student_id, setOpenSession, openSession, sessionId, timeStampTZ, title, attendableId }) => {
  const { data: { result: attendanceStatus } = { result: null } } = useSWR(`/attendances/student?attendable_id=${attendableId}&student_id=${student_id}`)
  let attendanceStyling = new Date(timeStampTZ) > new Date() ? 'bg-gray-80 xl:w-[13rem]' : 'bg-blue-90 xl:w-[13rem]'
  attendanceStyling = openSession === sessionId ? 'bg-green-50 xl:w-58' : attendanceStyling

  return (
    <div onClick={() => setOpenSession(sessionId)}
      className={`${attendanceStyling} rounded-1xl h-12 my-2 xl:m-2 flex first:mt-0 relative w-full`}>
      <div className={`${!attendanceStatus?.absent ? 'bg-green-50' : 'bg-danger-40'} rounded-xl h-3 w-3 bg-green-50 flex justify-center items-center absolute top-2 right-2`}>
        {!attendanceStatus?.absent && <FontAwesomeIcon icon={faCheck} className="text-white p-0.5" />}
      </div>
      <span className="font-bold text-white p-3 px-4">{title} - {new Date(timeStampTZ).toLocaleDateString([], { day: 'numeric', month: 'numeric' })}</span>
    </div>
  )
}

SessionItem.propTypes = {
  student_id: PropTypes.number,
  attendableId: PropTypes.number,
  openSession: PropTypes.any,
  sessionId: PropTypes.any,
  setOpenSession: PropTypes.func,
  timeStampTZ: PropTypes.any,
  title: PropTypes.any
}

export default SessionItem
