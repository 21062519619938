import React, { useEffect, useState } from 'react'
import { useFetcher } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Icon } from '@design-system'

export const AddTemplate = ({ studentId }) => {
  const fetcher = useFetcher()
  const [templates, setTemplates] = useState(undefined)
  const isLoading = fetcher.state !== 'idle' && Number(fetcher.formData?.get('student_id')) === Number(studentId)

  useEffect(() => {
    setTemplates(fetcher.data?.templates)
  }, [fetcher.data])

  const handleAddTemplate = async (event) => {
    if (isNaN(event.target.value)) return false
    fetcher.submit(event.target.form)
  }

  const handleCancel = () => {
    delete fetcher.data?.templates
    setTemplates(undefined)
  }

  return (
    <div className='relative'>
      {(templates) &&
        <div className='bg-white p-2 border drop-shadow absolute bottom-0 z-30'>
          <fetcher.Form method="post" className="flex flex-col gap-2">
            <input type="hidden" name="_action" value="assign_template" />
            <input type="hidden" name="student_id" value={studentId} />
            <div>
              <Button variant='ghost' size="xs" onClick={handleCancel}>
                <Icon name="arrow-left" size='xs' />
              </Button>
            </div>
            <select name='template_id' onChange={handleAddTemplate}>
              <option value=''>Select a new template</option>
              {templates.map(template => {
                const requestedLabel = `${template.requested ? '⭐ ' : ''}`
                const templateLabel = `${template.title} ${template.category ? `(${template.category})` : ''}`
                const unitAndAbilityLabel = `(New units: ${template.unit_impact} | New abilities: ${template.ability_impact})`
                return (
                  <option key={`student_${studentId}_template_${template.id}`} value={template.id}>
                    {`${requestedLabel}${templateLabel} ${unitAndAbilityLabel}`}
                  </option>
                )
              })}
            </select>
          </fetcher.Form>
        </div>
      }
      <fetcher.Form method="get" className="flex flex-col justify-center">
        <input type="hidden" name="_action" value="get_student_templates" />
        <input type="hidden" name="student_id" value={studentId} />
        <Button type="submit" size="sm" disabled={isLoading} loading={isLoading}>Add new template</Button>
      </fetcher.Form>
    </div>
  )
}
AddTemplate.propTypes = {
  studentId: PropTypes.number,
}
