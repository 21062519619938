import axios from 'axios'
import SoraLink from '@components/link'
import RedDot from '@components/red-dot'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCircleInfo, faSpinner } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import React from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Button } from '@designsystem'

export function TasksList({ theme_id, tasks, onConvertToFinal, hasTasksDetailsRedesignFeatureEnabled }) {
  const onClickConvertToFinal = async (task_id) => {
    try {
      await axios.put(`/experiences/${theme_id}/assessment/tasks/${task_id}`)
    } catch (e) {
      console.error(e)
    }
    onConvertToFinal()
  }
  const hasFinalTask = tasks?.length > 0 && tasks.some((t) => t.type === 'final')
  return (
    <div className="p2">
      <ul className="w-full flex flex-col rounded-xl bg-white border border-gray-40 p-3">
        {tasks.map((task, idx, arr) => {
          const isLastTask = idx === arr.length - 1
          const formattedDueAt = new Date(task.due_at).toLocaleDateString([], { day: 'numeric', month: 'numeric' })
          return (
            <li
              key={`facilitate_${theme_id}_tasks_${task.id}`}
              className="border-b border-b-gray-40 last:border-none last:pb-0 first:pt-1"
              data-testid="task-item"
            >
              <div className="flex flex-row items-center">
                {hasTasksDetailsRedesignFeatureEnabled && (
                  <Button size="xs" variant="outlined">
                    <SoraLink to={`/facilitate/experiences/${theme_id}/tasks/${task.id}`}>Open in 3.0 version</SoraLink>
                  </Button>
                )}

                <SoraLink
                  to={`/employee/facilitate/assessment/${theme_id}/task/${task.id}${task.is_readonly ? '/0' : ''}`}
                  className="flex flex-1 flex-row cursor-pointer items-center p-2 hover:bg-gray-10"
                >
                  <div className="flex flex-row flex-1 items-center w-full">
                    <RedDot show={task.pending && !task.is_readonly} />
                    <span data-testid={`task-item-${idx}-title`}>{task.title}</span>
                    <span>
                      {task.is_readonly && (
                        <FontAwesomeIcon
                          title="Read-only task. No submissions are accepted."
                          className="ml-1"
                          icon={faCircleInfo}
                        />
                      )}
                    </span>
                    {isLastTask && !hasFinalTask && (
                      <LastTaskNotFinalBtn onClick={() => onClickConvertToFinal(task.id)} />
                    )}
                  </div>
                  {!task.is_readonly && (
                    <div className="flex flex-row justify-between flex-wrap text-xs font-bold text-white space-x-2 ml-auto">
                      <div className="w-32 py-1 text-blue-70 text-center">Unassessed: {task.unassessed}</div>
                      <div className="w-24 py-1 text-danger-50 text-center">Flagged: {task.flagged}</div>
                      <div className="w-32 py-1 text-gray-40 text-center">No Submission: {task.no_submission}</div>
                      <div className="w-24 py-1 text-turquoise-50 text-center">Assessed: {task.assessed}</div>
                    </div>
                  )}
                  <div className="w-full max-w-28 text-right">{formattedDueAt}</div>
                </SoraLink>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
TasksList.propTypes = {
  theme_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tasks: PropTypes.array,
  onConvertToFinal: PropTypes.func,
  hasTasksDetailsRedesignFeatureEnabled: PropTypes.bool,
}

export function LastTaskNotFinalBtn({ onClick }) {
  const [isShowing, setIsShowing] = React.useState(false)
  const [btnToShow, setBtnToShow] = React.useState('confirm')
  return (
    <Popover
      className="flex relative hover:cursor-pointer"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      {({ open }) => (
        <>
          <Popover.Button>
            <FontAwesomeIcon
              title="Last task is not final!"
              className="ml-1 text-danger-50"
              icon={faCircleInfo}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setIsShowing((isShowing) => !isShowing)
              }}
              data-testid="missing-final-task-btn"
            />
          </Popover.Button>
          <Transition
            show={isShowing}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Panel className="flex items-center absolute z-10 bg-white rounded-lg px-2 ml-1 border py-px -top-[1px] space-x-1">
              {btnToShow === 'confirm' && (
                <span
                  className="text-blue-70 font-bold whitespace-nowrap hover:cursor-default"
                  data-testid="convert-task-label"
                >
                  Convert to Final?
                </span>
              )}
              <FontAwesomeIcon
                className={`p-px rounded-full ${btnToShow === 'confirm' ? 'text-turquoise-50 border border-turquoise-50' : 'text-blue-70'
                  }`}
                icon={btnToShow === 'confirm' ? faCheck : faSpinner}
                onClick={async (e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setBtnToShow('load')
                  await onClick()
                  setTimeout(() => setIsShowing((isShowing) => !isShowing), 2000)
                }}
                spin={btnToShow === 'load'}
                data-testid="convert-task-btn"
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
LastTaskNotFinalBtn.propTypes = {
  onClick: PropTypes.func,
}
