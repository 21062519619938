import React from 'react'
import PropTypes from 'prop-types'
import { RenderedSlate, isBlankValue } from '@components/forms/slate-textarea'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import Emoji from '@components/emoji'
import getIconForSubmission from '@utils/getIconForSubmission'
import getTitleForSubmission from '@utils/getTitleForSubmission'

const TaskSubmissionList = ({ data, task_submission_id, onSelectedSubmission }) => {

  const handleSelectSubmission = (id) => () => {
    onSelectedSubmission(id)
  }

  const taskSubmissions = data.map((submission, idx) => {
    return (
      <li key={`task_submission_${submission.id}`} className='flex flex-col hover:bg-gray-10 px-4'>
        <div className={`flex flex-col border-b ${idx === data.length - 1 ? 'border-b-transparent' : 'border-b-gray-40'} ${task_submission_id && submission.id != task_submission_id ? 'opacity-60' : ''}`}>
          <div className='flex flex-row gap-2 justify-center items-center py-4 cursor-pointer hover:opacity-80' onClick={handleSelectSubmission(submission.id)}>
            <div className='self-start'>
              {getIconForSubmission(submission.status, submission.temporal_status, submission.submission_status)}
            </div>
            <div className='flex-auto flex flex-col'>
              <div className='flex flex-row items-center'>
                <span className={`text-xl flex-auto ${submission.id == task_submission_id ? 'font-bold' : ''}`}>Submission {submission.position}</span>
                <div className='flex-auto'></div>
                <div className='flex flex-row text-xs items-center gap-6'>
                  <>
                    {getTitleForSubmission(submission.status, submission.temporal_status, submission.submission_status)}
                    {submission.done_at && <span> - Submitted {new Date(submission.done_at).toLocaleDateString([], { dateStyle: 'medium' })}</span>}
                  </>

                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
              {submission?.total_comments > 0 &&
                <div className='flex flex-row items-center gap-2'>
                  <Emoji name='chat' title='Comments' size={3} />
                  <span className='text-xs'>{submission.total_comments}</span>
                </div>
              }
            </div>
          </div>
          {(submission.id == task_submission_id && !isBlankValue(submission.body)) &&
            <div className='border border-gray-40 rounded-xl p-2 mb-2 bg-white'>
              <RenderedSlate value={submission.body} />
            </div>
          }
        </div>
      </li>
    )
  })

  return (
    <ul className='flex flex-col pt-6'>
      {taskSubmissions}
    </ul>
  )
}
TaskSubmissionList.propTypes = {
  data: PropTypes.array,
  task_id: PropTypes.string,
  student_id: PropTypes.string,
  task_submission_id: PropTypes.string,
  onSelectedSubmission: PropTypes.func,
  onNoSelectedSubmission: PropTypes.func,
}

export default TaskSubmissionList
