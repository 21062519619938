import React from 'react'
import { useLoaderData } from 'react-router-dom'
import axios from 'axios'
import serializeFormData from '@utils/serializeFormData'
import { RefreshTemplateButton } from './refresh-template-button'
import { StudentList } from './student-list'

const STUDENTS_PER_PAGE = 20

const loader = async ({ params, request }) => {
  const { processId } = params
  const searchParams = new URL(request.url).searchParams
  const action =  searchParams.get('_action') || 'default'
  const studentId = searchParams.get('student_id')
  const assignmentId = searchParams.get('assignment_id')
  const studentFilter = searchParams.get('student_filter')
  const template = searchParams.get('template')
  const limit = searchParams.get('limit') || STUDENTS_PER_PAGE

  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/assignments/students`, { params: { action, student_id: studentId, assignment_id: assignmentId, student_filter: studentFilter, template, limit }})
  return result?.data
}

const action = async ({ params, request }) => {
  const { processId } = params
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const action = serializedFormData._action

  try {
    await axios.post(`/backoffice/workbench/journey-builder/processes/${processId}/assignments/students`, { ...serializedFormData, action })
    return null
  } catch (error) {
    return {
      toast: { message: 'There was an error running Journey Builder. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const { data, metrics, cycles } = useLoaderData()

  return (
    <div className='flex flex-col gap-3'>
      <div className='flex flex-row justify-end'>
        <div className='flex-flex-col space-y-3'>
          <div className='flex flex-row justify-end'>
            <RefreshTemplateButton />
          </div>
          <div className='flex flex-row gap-3'>
            <div>
              <div className="flex flex-col p-3 space-y-2 bg-white font-bold max-w-[250px]">
                <span>Template Request Hits</span>
                <span className="text-xl">{metrics.request_hits.hits} / {metrics.request_hits.total} ({metrics.request_hits.percent}%)</span>
              </div>
            </div>
            <div>
              <div className="flex flex-col p-3 space-y-2 bg-white font-bold max-w-[250px]">
                <span>Unique students with 1+ request list hits</span>
                <span className="text-xl">{metrics.student_request_hits.at_least_one} / {metrics.student_request_hits.total}</span>
              </div>
            </div>
            <div>
              <div className="flex flex-col p-3 space-y-2 bg-white font-bold max-w-[250px]">
                <span>Unique students with 50%+ request list hits</span>
                <span className="text-xl">{metrics.student_request_hits.half_full} / {metrics.student_request_hits.total}</span>
              </div>
            </div>
          </div>
        </div>
      </div>      
      <div>
        <table>
          <thead className='sticky top-0 bg-screen-primary z-50'>
            <tr>
              <th>Student</th>
              <th></th>
              <th>Templates</th>
              <th>Category</th>
              <th>Allowed Cycles</th>
              <th>First time</th>
              <th>New Units</th>
              <th>New Abilities</th>
              <th>Total Request Hits</th>
              <th>Units needed to graduate</th>
              <th>Projected Graduation Progress</th>
              <th>Cycles Left</th>
            </tr>
            <tr>
              <th colSpan={12} className='h-px bg-alpha/5' />
            </tr>
          </thead>
          <tbody className='text-black'>
            <StudentList students={data} defaultCycles={cycles} />
          </tbody>
        </table>
      </div>
    </div>
  )
}

export const JourneyBuilderAssignmentsStudentsRoute = {
  loader,
  action,
  Element,
}
