import React, { useState } from 'react'
import axios from 'axios'
import { Form, useLoaderData, useSearchParams, redirect } from 'react-router-dom'
import { RadioGroup } from '@headlessui/react'
import { Button } from '@designsystem'
import serializeFormData from '@utils/serializeFormData'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import SoraLink from '@components/link'
import MultipleChoiceOptions from './multiple-choice-options'

const loader = async ({ request }) => {
  const urlParams = new URL(request.url).searchParams
  const school_stage = urlParams.get('school_stage')
  const searchParams = new URLSearchParams()
  if (school_stage) searchParams.append('school_stage', school_stage)
  const result = await axios.get('/backoffice/workbench/journey-builder/processes/new', { params: searchParams })

  return result?.data
}

const action = async ({ request }) => {
  const formData = await request.formData()
  const { school_stage, cycle_ids } = serializeFormData(formData)
  try {
    const result = await axios.post('/backoffice/workbench/journey-builder/processes/new', { school_stage, cycle_ids: cycle_ids.split(',').map(Number) })
    const { id: processId } = result.data
    return redirect(`../${processId}`)
  } catch (error) {
    return {
      toast: { message: 'There was an error trying to create a new process. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const { cycles } = useLoaderData()
  const [selectedSchoolStage, setSelectedSchoolStage] = useState('ms')
  const [selectedCyclesIds, setSelectedCyclesIds] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()

  const onSchoolStageChange = (value) => {
    setSelectedSchoolStage(value)
    const params = new URLSearchParams(searchParams)
    params.delete('school_stage')
    params.append('school_stage', value)
    setSearchParams(params)
  }

  const onCycleChange = (value) => {
    if (selectedCyclesIds.includes(value)) {
      setSelectedCyclesIds(selectedCyclesIds.filter((cycleId) => cycleId !== value))
    } else {
      setSelectedCyclesIds([...selectedCyclesIds, value])
    }
  }

  return (
    <div className='flex flex-col'>
      <div className="flex flex-row space-x-3 items-start pb-4">
        <Button type="button" as={SoraLink} to="../" variant="outlined" startIcon={faXmark} size="xs" />
      </div>
      <div className='flex flex-col items-center'>
        <div className='flex flex-col space-y-5'>
          <span className='text-center text-xl font-bold'>Journey Builder</span>
          <span className='text-center text-2xl font-bold'>Select which school stage you are generating</span>
          <div className='flex flex-row justify-center py-3'>
            <RadioGroup value={selectedSchoolStage} onChange={onSchoolStageChange} className='flex flex-row space-x-3'>
              <RadioGroup.Option value="ms" className='cursor-pointer'>
                {({ checked }) => (
                  <div className={`p-5 text-lg text-black font-bold rounded ${checked ? 'bg-blue-5' : 'bg-white'}`}>
                    <span>Mid School</span>
                  </div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="hs" className='cursor-pointer '>
                {({ checked }) => (
                  <div className={`p-5 text-lg text-black font-bold rounded ${checked ? 'bg-blue-5' : 'bg-white'}`}>
                    <span>High School</span>
                  </div>
                )}
              </RadioGroup.Option>
            </RadioGroup>
          </div>
          <span className='text-center text-2xl font-bold'>Select which cycle you are generating</span>
          <div className='flex flex-row justify-center py-3'>
            <MultipleChoiceOptions options={cycles} selectedOptions={selectedCyclesIds} onChange={onCycleChange} />
          </div>
          <div className='flex flex-col items-center'>
            <Form id='journey-processes-new' method='post'>
              <input type='hidden' name='school_stage' value={selectedSchoolStage} />
              <input type='hidden' name='cycle_ids' value={selectedCyclesIds.join(',')} />
              <Button type='submit' disabled={cycles?.length === 0}>Start</Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export const JourneyBuilderProcessNewRoute = {
  loader,
  action,
  Element,
}
