import React, { useState } from 'react'
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  PaginationState,
} from '@tanstack/react-table'
import type { RegistrationChangesData } from './types'
import useClipboard from '@hooks/useClipboard'
import { toast } from 'sonner'

export const RegistrationChangesReportTable = ({ data }: { data: RegistrationChangesData[] }) => {
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 25 })
  const [, copy] = useClipboard()

  const handleCopy = v => {
    try {
      copy(v)
      toast.success('Content copied!', { duration: 1000 })
    } catch (e) {
      console.error(e)
    }
  }

  const columnHelper = createColumnHelper<RegistrationChangesData>()
  const columns = [
    columnHelper.group({
      header: 'Student',
      columns: [
        columnHelper.accessor('student_id', {
          header: 'ID',
          cell: (c) => c.getValue(),
        }),
        columnHelper.accessor('student_name', {
          header: 'Name',
          cell: (c) => c.getValue(),
        }),
        columnHelper.accessor('student_email', {
          header: 'Email',
          cell: (c) => c.getValue(),
        }),
        columnHelper.accessor('campus', {
          header: 'Campus',
          cell: (c) => c.getValue(),
        }),
      ],
    }),
    columnHelper.group({
      header: 'Experience',
      columns: [
        columnHelper.accessor('experience', {
          header: 'Title',
          cell: (c) => c.getValue(),
        }),
        columnHelper.accessor('experience_type', {
          header: 'Type',
          cell: (c) => c.getValue(),
        }),
        columnHelper.accessor('learning_block', {
          header: 'Learning Block',
          cell: (c) => c.getValue(),
        }),
      ],
    }),
    columnHelper.group({
      header: 'Registration Change',
      columns: [
        columnHelper.accessor('notes', {
          header: 'Notes',
          cell: (c) => c.getValue(),
        }),
        columnHelper.accessor('category', {
          header: 'Category',
          cell: (c) => c.getValue(),
        }),
        columnHelper.accessor('source', {
          header: 'Source',
          cell: (c) => c.getValue(),
        }),
        columnHelper.accessor('user_name', {
          header: 'Action User',
          cell: (c) => c.getValue(),
        }),
        columnHelper.accessor('timestamp', {
          header: 'Date',
          cell: (c) => new Date(c.getValue()).toLocaleDateString('en-US', { day: '2-digit', month: 'short' }),
        }),
      ],
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  return (
    <>
      <table className="w-full border bg-white text-[0.875rem]" data-testid="registration-changes-report-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="text-left border-b">
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan} className={`px-3 py-2 ${!header.placeholderId && header.subHeaders.length && 'border-r'}`}>
                  {header.isPlaceholder ? null : (
                    <div className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''} onClick={header.column.getToggleSortingHandler()}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {!table.getRowModel().rows.length && <tr className="border-b hover:bg-gray-10 text-center" data-testid="registration-changes-report-table-row"><td colSpan={table.getLeafHeaders().length}>No registration changes found...</td></tr>}
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="border-b hover:bg-gray-10" data-testid="registration-changes-report-table-row">
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="pl-2 last:pr-2 py-1 group">
                  <div className="hover:cursor-pointer" onClick={() => handleCopy(cell.getContext().getValue())}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex flex-row items-center justify-end py-2 gap-3">
        <button className="border rounded p-1" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
          {'<<'}
        </button>
        <button className="border rounded p-1" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          {'<'}
        </button>
        <button className="border rounded p-1" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          {'>'}
        </button>
        <button className="border rounded p-1" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}>
          {'>>'}
        </button>
        <span className="flex items-center gap-1">
          Page <strong>{table.getState().pagination.pageIndex + 1} of {table.getPageCount()}</strong>
        </span>
        <span className="flex items-center gap-1">
          <p>| Go to page:</p>
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => { table.setPageIndex(e.target.value ? Number(e.target.value) - 1 : 0) }}
            className="border p-1 rounded w-16 h-10"
          />
        </span>
        <select value={table.getState().pagination.pageSize} onChange={(e) => { table.setPageSize(Number(e.target.value)) }} className="h-10 rounded">
          {[25, 50, 100, data.length].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize === data.length ? 'All' : pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}
