import React from 'react'
import PropTypes from 'prop-types'
import { Form, useSubmit } from 'react-router-dom'
import { Button } from '@design-system'
import SoraLink from '@components/link'
import useConfirmModal from '@hooks/useConfirmModal'
import TemplateCard from '@components/template-card'
import capitalize from '@utils/capitalize'

export default function CardWrapper({ user, template, onOpenModal, onApproveStudy = null }) {
  const submit = useSubmit()
  const confirm = useConfirmModal()

  const handleDeleteSubmit = async e => {
    e.preventDefault()
    const formEl = e.currentTarget
    const confirmed = await confirm(`Are you sure you want to delete ${template.title}?`)
    if (confirmed) {
      submit(formEl)
    }
  }

  return (
    <TemplateCard>
      <TemplateCard.Header
        title={`[${template.school_stage.toUpperCase()}] ${template.title}`}
        subtitle={`created by: ${template.creator_name}`}
        tags={(
          <div className="space-x-2">
            {template.is_deleted === true && <small className='rounded py-1 px-2 bg-yellow-40 text-white font-bold text-xs whitespace-nowrap'>Archived</small>}
            {template.is_draft === true && <small className='rounded py-1 px-2 bg-gray-70 text-white font-bold text-xs whitespace-nowrap'>Draft</small>}
            <small className='rounded py-1 px-2 bg-cyan-40 text-white font-bold text-xs whitespace-nowrap'>{template.type.split('_').filter(word => word !== 'template').map(capitalize).join(' ')}</small>
          </div>
        )}>
        {template.editableByUser &&
          <TemplateCard.Popover>
            <SoraLink
              to={template.editUrl}
            >
              <TemplateCard.PopoverItem title='edit template' label='Edit' />
            </SoraLink>
            {!template.is_deleted && (
              <Form method='post' onSubmit={handleDeleteSubmit}>
                <input type='hidden' name='_action' value='deleteTemplate' />
                <input type='hidden' name='template_id' value={template.id} />
                <button>
                  <TemplateCard.PopoverItem title='Archive template' label='Archive' />
                </button>
              </Form>
            )}
            {template.is_deleted && (
              <Form method='post'>
                <input type='hidden' name='_action' value='unarchiveTemplate' />
                <input type='hidden' name='template_id' value={template.id} />
                <button>
                  <TemplateCard.PopoverItem title='Unarchive template' label='Unarchive' />
                </button>
              </Form>
            )}
          </TemplateCard.Popover>
        }
      </TemplateCard.Header>
      <TemplateCard.Body
        title={template.title}
        description={template.rte_description}
        units={template.units}
        abilities={template.abilities}
        descriptionAction={() => window.open(template.previewUrl, '_blank')} />
      <TemplateCard.Footer>
        {template.is_study_approved !== false ?
          (
            <div className="flex flex-row space-x-2 items-center">
              <Button
                type="button"
                variant="outlined"
                onClick={() => {
                  window.open(template.previewUrl, '_blank')
                }}
              >
                View
              </Button>
              {user.role === 'admin' && (
                <Button title="Pick Template" asChild>
                  <SoraLink to={`/template/${template.id}/use-picked-template`}>
                    Pick Template
                  </SoraLink>
                </Button>
              )}
            </div>
          ) : !template.is_deleted && (
            <div className="flex flex-row space-x-2 items-center">
              <Form method="post" onSubmit={handleDeleteSubmit}>
                <input type="hidden" name="_action" value="deleteTemplate" />
                <input type="hidden" name="template_id" value={template.id} />
                <Button
                  type="submit"
                  color="danger"
                  title='Delete template'
                >
                  Decline
                </Button>
              </Form>
              <Form method="post">
                <input type="hidden" name="_action" value="approveStudy" />
                <input type="hidden" name="template_id" value={template.id} />
                <Button
                  type="submit"
                  title="Approve template"
                >
                  Approve
                </Button>
              </Form>
            </div>
          )}
      </TemplateCard.Footer>
    </TemplateCard>
  )
}

CardWrapper.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    previewUrl: PropTypes.string,
    school_stage: PropTypes.string,
    is_redesigned_template: PropTypes.bool,
    creator_name: PropTypes.string,
    creator_id: PropTypes.number,
    rte_description: PropTypes.any,
    is_deleted: PropTypes.bool,
    is_draft: PropTypes.bool,
    type: PropTypes.string,
    units: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        id: PropTypes.number,
      })
    ),
    abilities: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        id: PropTypes.number,
      })
    ),
    is_study_approved: PropTypes.bool,
    editUrl: PropTypes.string,
    editableByUser: PropTypes.bool,
  }),
  user: PropTypes.shape({
    beta: PropTypes.bool,
    role: PropTypes.string,
    id: PropTypes.number,
    hasEditTemplateLibraryPermission: PropTypes.bool,
  }),
  onOpenModal: PropTypes.func,
  onApproveStudy: PropTypes.func,
}
