import React from 'react'
import startOfDay from 'date-fns/startOfDay'
import { Button } from '@design-system'

interface ParentsMessageButtonProps {
  startAt: string,
  smsSentAt: string,
  absencesCount: number,
}

export function ParentsMessageButton({ absencesCount, smsSentAt, startAt }: ParentsMessageButtonProps) {
  const eventStarted = new Date(Date.now()) > startOfDay(new Date(startAt))
  const numericParentsLabel = `${absencesCount} parent${absencesCount === 1 ? '' : 's'}`
  const disabled = !!(!eventStarted || smsSentAt || absencesCount === 0)

  return (
    <Button size='sm' disabled={disabled} type="submit">
      {!eventStarted ? 'Can\'t message parents before event day'
        : smsSentAt ? `${numericParentsLabel} notified at ${new Date(smsSentAt).toLocaleTimeString([], { day: 'numeric', month: 'short' })}`
          : absencesCount === 0 ? 'No absents, Yay!'
            : `Notify ${numericParentsLabel}`
      }
    </Button>
  )
}
