import axios from 'axios'
import React from 'react'
import { redirect, useLoaderData, useNavigate } from 'react-router-dom'
import serializeFormData from '@utils/serializeFormData'
import { Button, useFetcher, Combobox, Typography, unstable_Dialog as Dialog } from '@design-system'
import { TextField } from '@designsystem'

async function loader() {
  const { data } = await axios.get(`/backoffice/houses/new`)
  return data
}

async function action({ request }) {
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  try {
    const { data } = await axios.post(`/backoffice/houses/new`, serializedFormData)
    return data
  } catch (error) {
    return {
      toast: {
        appearance: 'error',
        message: error.response.data.error.message
      },
      errors: error.response.data.errors,
    }
  }
}

function Element() {
  const loaderData = useLoaderData()
  const fetcher = useFetcher()
  const isSubmitting = fetcher.state === 'submitting'
  const navigate = useNavigate()

  return (
    <Dialog
      open={true}
      onOpenChange={(value) => {
        navigate('../')
      }}
    >
      <Dialog.Content className="px-0 max-w-[700px]">
        <Dialog.Header className="flex border-b border-neutral-200 pb-4 px-4">
          <Typography variant="heading-5" weight="bold">
            New House
          </Typography>
        </Dialog.Header>
        <fetcher.Form noValidate method="post" className="flex flex-col gap-4 px-4 pt-4">
          <TextField required label="Title" name="title" error={fetcher.data?.errors?.find(err => err.fieldName === 'title')?.message} defaultValue='' />
          <Combobox
            required
            label="Campus"
            name="cohortId"
            placeholder="Type or select the house's campus"
            error={fetcher.data?.errors?.find(err => err.fieldName === 'cohortId')?.message}
            defaultValue=''>
            {loaderData.cohorts.map(op =>
              <Combobox.Item key={op.id} value={op.id}>{op.title}</Combobox.Item>
            )}
          </Combobox>
          <Combobox
            required
            label="Standup Time"
            name="defaultStandupTime"
            placeholder="Select the house default standup time"
            defaultValue="">
            <Combobox.Item value="10:00:00-0500">10:00 am EST (EST/CST Recommended)</Combobox.Item>
            <Combobox.Item value="13:20:00-0500">01:20 pm EST (MST/PST Recommended)</Combobox.Item>
          </Combobox>
          <div className="self-end">
            <Button type="submit" loading={isSubmitting}>
              {isSubmitting ? 'Saving...' : 'Save House'}
            </Button>
          </div>
        </fetcher.Form>
      </Dialog.Content>
    </Dialog>
  )
}

export const HouseNewRoute = {
  Element,
  loader,
  action,
}
