import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faPencilAlt, faCalendarDay, faTasks, faDoorOpen, faDoorClosed, faTrash, faBoxOpen, faBox, faCopy, faPaste, faPlay, faPause, faRepeat } from '@fortawesome/free-solid-svg-icons'
import { getCategoryColor } from '@utils/categoryColors'
import capitalize from '@utils/capitalize'
import SoraLink from '@components/link'
import { Popover, Switch, Transition } from '@headlessui/react'
import useToggle from '@hooks/useToggle.js'
import copyDirectLinkToDashboard from '@utils/copyDirectLinkToDashboard'
import useCurrentUser from '@hooks/useCurrentUser'
import useConfirmModal from '@hooks/useConfirmModal'
import CycleSelector from '@components/expedition-form/cycleSelector'
import useUserPermissions from '@hooks/useUserPermissions'


const ExpeditionTableRow = ({ exp, onPublish, onDraft, onArchive, onUnarchive, onDelete, onDuplicate, onSync, onReset, canEdit, availableCycles, isNewExperienceFormEnabled }) => {

  const { role } = useCurrentUser()
  const confirm = useConfirmModal()
  const { hasPermission } = useUserPermissions()

  const [syncing, setSyncing] = useState(false)
  const [archiving, setArchiving] = useState(false)
  const [unarchiving, setUnarchiving] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [duplicating, setDuplicating] = useState(false)
  const [reseting, setReseting] = useState(false)
  const [dupRegisters, toggleDupRegisters] = useToggle(false)
  const [dupSessions, toggleDupSessions] = useToggle(false)
  const [targetCycleId, setTargetCycleId] = useState('')
  const [publishing, setPublishing] = useState(false)
  const [unpublishing, setUnpublishing] = useState(false)

  const handlePublish = async () => {
    if (await confirm('You\'re about to publish this Expedition. This will make it possible to synchronize the experience and share it with students. Are you sure?')) {
      setPublishing(true)
      onPublish(exp.id)
        .finally(() => setPublishing(false))
    }
  }

  const handleUnpublish = async () => {
    if (await confirm('You\'re about to publish this Expedition. This makes unavailable to synchronizing and sharing the experience with students. Are you sure?')) {
      setUnpublishing(true)
      onDraft(exp.id)
        .finally(() => setUnpublishing(false))
    }
  }

  const handleArchive = async () => {
    if (await confirm('You\'re about to archive this Expedition. This will close the heartbeat channel. Are you sure?')) {
      setArchiving(true)
      onArchive(exp.id)
        .finally(() => setArchiving(false))
    }
  }

  const handleUnarchive = async () => {
    if (await confirm('You\'re about to unarchive this Expedition. This will open it\'s heartbeat channel. Are you sure?')) {
      setUnarchiving(true)
      onUnarchive(exp.id)
        .finally(() => setUnarchiving(false))
    }
  }

  const handleSync = () => {
    setSyncing(true)
    onSync(exp.id)
      .finally(() => setSyncing(false))
  }

  const handleDelete = () => {
    setDeleting(true)
    onDelete(exp.id)
      .finally(() => setDeleting(false))
  }

  const handleDuplicate = () => {
    setDuplicating(true)
    onDuplicate(exp, { dupRegisters, dupSessions, targetCycleId })
      .finally(() => setDuplicating(false))
  }

  const handleReset = () => {
    setReseting(true)
    onReset(exp.id)
      .finally(() => setReseting(false))
  }

  const categoryColor = getCategoryColor(exp.category).bgDark

  return <tr key={exp.id} className="dark:bg-black">
    <td className="border px-4 py-2">
      <div className="flex space-x-2 justify-between items-center">
        <div className="flex flex-col space-y-1 items-start">
          <SoraLink to={exp.preview_url} target='_blank'>
            <span className="font-bold">{exp.title}</span>
          </SoraLink>
          {exp.experts.map((expertName) =>
            <small key={expertName} className={`rounded text-xs whitespace-nowrap`}>
              {expertName}
            </small>
          )}
        </div>
        <div className="flex flex-col space-y-1 items-end">
          {exp.is_draft && (
            <small className={`rounded py-1 px-2 bg-gray-40 font-bold text-xs whitespace-nowrap`}>
              Draft
            </small>
          )}
          <small className={`rounded py-1 px-2 ${categoryColor} font-bold text-xs whitespace-nowrap`}>
            {exp.category.split('_').map(capitalize).join(' ')} {exp.is_draft}
          </small>

          {exp.learning_block && (
            <small className={`rounded py-1 px-2 bg-gray-10 font-bold text-xs whitespace-nowrap`}>
              {exp.learning_block}
            </small>
          )}
        </div>
      </div>
    </td>
    <td className="border px-4 py-2 w-60">
      <div className="flex items-center justify-around">
        {exp.cohort_title || 'All'}
      </div>
    </td>
    <td className="border px-4 py-2 text-center w-40">
      <div className="flex items-center justify-around">
        <FontAwesomeIcon className="text-gray-50" icon={exp.open ? faDoorOpen : faDoorClosed} title={exp.open ? 'Open for students to register' : 'Closed for students to register'} />
        <SoraLink to={isNewExperienceFormEnabled && exp.is_redesigned_experience ? `../../../NEW_EXPERIENCE/${exp.id}/registrations`:`../${exp.id}/registrations`} className="hover:underline text-blue-70">
          {exp.registered_students}/{exp.max_students === 1000 ? '∞' : exp.max_students}
        </SoraLink>
        {onSync && hasPermission('sync_expeditions') && (
          <button disabled={syncing} onClick={handleSync} className={`${syncing ? 'bg-gray-40' : 'bg-blue-70'} text-white rounded font-bold py-1 px-2 ml-2`}>
            <FontAwesomeIcon icon={faSync} className={syncing ? 'animate-spin' : ''} />
          </button>
        )}
      </div>
    </td>
    <td className="border px-4 py-2">
      <div className="flex items-center text-center justify-center flex-wrap space-x-2">
        <SoraLink title="Edit" to={isNewExperienceFormEnabled && exp.is_redesigned_experience ? `../../../NEW_experience/${exp.id}/edit` : `../${exp.id}/edit`} disabled={!canEdit} className="block bg-blue-30 text-white rounded font-bold w-8 h-8 leading-8">
          <FontAwesomeIcon icon={faPencilAlt} />
        </SoraLink>
        <SoraLink title="Attendances" to={isNewExperienceFormEnabled && exp.is_redesigned_experience ? `../../../NEW_EXPERIENCE/${exp.id}/attendances`:`../${exp.id}/attendances`} disabled={exp.count_sessions === 0} style={{ pointerEvents: exp.count_sessions === 0 ? 'none' : 'auto' }}
          className={`block bg-green-40 text-white rounded font-bold w-8 h-8 leading-8 ${exp.count_sessions === 0 ? 'opacity-50' : ''}`}>
          <FontAwesomeIcon icon={faCalendarDay} />
        </SoraLink>
        <SoraLink title="Tasks" to={isNewExperienceFormEnabled && exp.is_redesigned_experience ? `../../../NEW_EXPERIENCE/${exp.id}/tasks`:`../${exp.id}/tasks`} className="block relative bg-yellow-40 text-white rounded font-bold w-8 h-8 leading-8">
          <FontAwesomeIcon icon={faTasks} />
          {exp.count_invalid_tasks > 0 && <small className="bg-danger-40 rounded-full h-4 w-4 text-tiny flex items-center justify-center absolute -right-1 -top-1">{exp.count_invalid_tasks}</small>}
        </SoraLink>
        {onDuplicate && (
          <Popover className="relative">
            <Popover.Button title="Duplicate" disabled={duplicating} className={`${duplicating ? 'bg-gray-40' : 'bg-blue-70'} text-white rounded font-bold w-8 h-8 leading-8`}>
              <FontAwesomeIcon icon={faCopy} className={duplicating ? 'animate-bounce' : ''} />
            </Popover.Button>
            <Transition
              className="absolute z-10 right-0"
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Popover.Panel>
                <div className="bg-white px-4 py-3 border mt-1 rounded shadow-xl space-y-2 flex flex-col">
                  <Switch.Group>
                    <div className={`flex text-xs items-center justify-between ${exp.count_sessions > 0 ? 'opacity-100' : 'opacity-50'}`}>
                      <Switch.Label className="mr-4 whitespace-nowrap">Copy Sessions</Switch.Label>
                      <Switch
                        disabled={exp.count_sessions === 0}
                        checked={dupSessions}
                        onChange={toggleDupSessions}
                        className={`${dupSessions ? 'bg-green-50' : 'bg-gray-40'}
                          relative inline-flex flex-shrink-0 h-[16px] w-[30px] border-2 border-transparent rounded-full cursor-pointer 
                          transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75
                        `}
                      >
                        <span
                          aria-hidden="true"
                          className={`${dupSessions ? 'translate-x-3.5' : 'translate-x-0'}
                            pointer-events-none inline-block h-[12px] w-[12px] rounded-full bg-white 
                            shadow-lg transform ring-0 transition ease-in-out duration-200
                          `}
                        />
                      </Switch>
                    </div>
                  </Switch.Group>
                  <Switch.Group>
                    <div className={`flex text-xs items-center justify-between ${exp.registered_students > 0 ? 'opacity-100' : 'opacity-50'}`}>
                      <Switch.Label className="mr-4 whitespace-nowrap">Copy Registrations</Switch.Label>
                      <Switch
                        disabled={exp.registered_students === 0}
                        checked={dupRegisters}
                        onChange={toggleDupRegisters}
                        className={`${dupRegisters ? 'bg-green-50' : 'bg-gray-40'}
                          relative inline-flex flex-shrink-0 h-[16px] w-[30px] border-2 border-transparent rounded-full cursor-pointer 
                          transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75
                        `}
                      >
                        <span
                          aria-hidden="true"
                          className={`${dupRegisters ? 'translate-x-3.5' : 'translate-x-0'}
                            pointer-events-none inline-block h-[12px] w-[12px] rounded-full bg-white 
                            shadow-lg transform ring-0 transition ease-in-out duration-200
                          `}
                        />
                      </Switch>
                    </div>
                  </Switch.Group>
                  <div className="text-xxs">
                    <CycleSelector cycles={availableCycles} value={targetCycleId} onChange={e => setTargetCycleId(e.target.value)} required noLabel />
                  </div>
                  <button disabled={!targetCycleId} onClick={handleDuplicate} className="bg-blue-70 text-white text-xs rounded font-bold px-2 py-1 self-end disabled:opacity-50">Duplicate</button>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        )}
        {(onReset && exp.has_template) && (
          <button title="Re-sync Template Info" disabled={reseting} onClick={handleReset}
            className={`${reseting ? 'bg-gray-40' : 'bg-slate-700'} text-white rounded font-bold w-8 h-8 leading-8`}>
            <FontAwesomeIcon icon={faRepeat} className={reseting ? 'animate-bounce' : ''} />
          </button>
        )}
        {(onPublish && role === 'admin' && !exp.archived && exp.is_draft) && (
          <button title="Publish" disabled={publishing} onClick={handlePublish}
            className={`${publishing ? 'bg-gray-40' : 'bg-green-70'} text-white rounded font-bold w-8 h-8 leading-8`}>
            <FontAwesomeIcon icon={faPlay} className={publishing ? 'animate-bounce' : ''} />
          </button>
        )}
        {(onDraft && role === 'admin' && !exp.archived && !exp.is_draft) && (
          <button title="Un-publish" disabled={unpublishing} onClick={handleUnpublish}
            className={`${unpublishing ? 'bg-gray-40' : 'bg-yellow-50'} text-white rounded font-bold w-8 h-8 leading-8`}>
            <FontAwesomeIcon icon={faPause} className={unpublishing ? 'animate-bounce' : ''} />
          </button>
        )}
        {onArchive && role === 'admin' && !exp.archived && (
          <button title="Archive" disabled={archiving} onClick={handleArchive}
            className={`${archiving ? 'bg-gray-40' : 'bg-danger-50'} text-white rounded font-bold w-8 h-8 leading-8`}>
            <FontAwesomeIcon icon={faBox} className={archiving ? 'animate-bounce' : ''} />
          </button>
        )}
        {onUnarchive && role === 'admin' && exp.archived && (
          <button title="Unarchive" disabled={unarchiving} onClick={handleUnarchive}
            className={`${unarchiving ? 'bg-gray-40' : 'bg-blue-70'} text-white rounded font-bold w-8 h-8 leading-8`}>
            <FontAwesomeIcon icon={faBoxOpen} className={unarchiving ? 'animate-bounce' : ''} />
          </button>
        )}
        {onDelete && role === 'admin' && exp.archived && (
          <button title="Delete" disabled={deleting} onClick={handleDelete}
            className={`${deleting ? 'bg-gray-40' : 'bg-danger-50'} text-white rounded font-bold w-8 h-8 leading-8`}>
            <FontAwesomeIcon icon={faTrash} className={deleting ? 'animate-bounce' : ''} />
          </button>
        )}
        <button
          title="Copy Student Direct Link"
          onClick={async () => await copyDirectLinkToDashboard(`/expeditions/${exp.id}`)}
          className="bg-gray-60 text-white rounded font-bold w-8 h-8 leading-8 ml-auto">
          <FontAwesomeIcon icon={faPaste} />
        </button>
      </div>
    </td>
  </tr>
}

ExpeditionTableRow.propTypes = {
  canEdit: PropTypes.any,
  exp: PropTypes.shape({
    preview_url: PropTypes.string,
    archived: PropTypes.bool,
    is_draft: PropTypes.bool,
    category: PropTypes.string,
    id: PropTypes.number,
    max_students: PropTypes.number,
    open: PropTypes.any,
    sessions: PropTypes.array,
    students: PropTypes.array,
    employees: PropTypes.array,
    title: PropTypes.any,
    count_invalid_tasks: PropTypes.number,
    registered_students: PropTypes.number,
    count_sessions: PropTypes.number,
    cohort_title: PropTypes.string,
    learning_block: PropTypes.string,
    experts: PropTypes.arrayOf(PropTypes.string),
    has_template: PropTypes.bool,
    is_redesigned_experience: PropTypes.bool,
  }),
  availableCycles: PropTypes.array,
  onArchive: PropTypes.func,
  onDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
  onSync: PropTypes.func,
  onUnarchive: PropTypes.func,
  onPublish: PropTypes.func,
  onDraft: PropTypes.func,
  onReset: PropTypes.func,
  isNewExperienceFormEnabled: PropTypes.bool,
}

export default ExpeditionTableRow
