import React from 'react'

import Expedition from '@components/expedition'
import useToggle from '@hooks/useToggle'
import MyOtherSeparator from '@components/my-other-separator'
import { useThisPageTitle } from '@hooks/usePageTitle'
import SearchBar from '@components/searchbar'
import SoraLink from '@components/link'
import useQuery from '@hooks/useQuery'
import useCurrentUser from '@hooks/useCurrentUser'
import { Button } from '@designsystem'
import axios from 'axios'
import { useLoaderData } from 'react-router'

export async function loader({ request }) {
  const searchParams = new URL(request.url).searchParams
  const cycle_id = searchParams.get('cycle_id')
  const with_archiveds = searchParams.get('with_archiveds')
  const search = searchParams.get('search')
  const cohort_ids = searchParams.get('cohort_ids')
  const query = {}
  if (cycle_id) {
    query.cycle_id = cycle_id
  }
  if (with_archiveds) {
    query.with_archiveds = with_archiveds
  }
  if (search) {
    query.search = search
  }
  if (cohort_ids && cohort_ids !== 'all') {
    query.cohort_ids = cohort_ids
  }
  const queryString = new URLSearchParams(query)
  const { data } = await axios.get(`/pages/employee/expeditions?${queryString.toString()}`)
  return data
}

const ExpeditionsList = () => {
  const [archivedsOpen, toggleArchivedsOpen] = useToggle()
  const [queryParams, setQueryParams] = useQuery()
  const currentUser = useCurrentUser()
  const loaderData = useLoaderData()

  const toggleCohortView = () => {
    if (queryParams?.cohort_ids === 'all')
      return setQueryParams({ ...queryParams, cohort_ids: currentUser.cohorts.join(',') })

    return setQueryParams({ ...queryParams, cohort_ids: 'all' })
  }

  const expeditions = loaderData.themes
  const archivedsCount = loaderData.archivedsCount

  useThisPageTitle('Viewing Experiences')

  const myExpeditions = expeditions.filter(
    (exp) => exp.is_own
  )
  const othersExpeditions = expeditions.filter(
    (exp) => !exp.is_own
  )
  const archivedExpeditions = expeditions.filter((exp) => exp.archived)

  return (
    <div id="expedition-management" data-cy="management-table">
      <header className="mb-4">
        <div className="w-full space-x-4">
          <Button
            as={SoraLink}
            variant="nude"
            color="cta"
            size="sm"
            to='/v2/employee/expeditions/edit'
            id="change-view-btn"
          >
            Change to edit
          </Button>
          {!!currentUser?.cohorts?.length && <Button
            onClick={toggleCohortView}
            variant="nude"
            color="cta"
            size="sm"
          >{queryParams?.cohort_ids === 'all'
            ? 'Show my cohorts'
            : 'Show all cohorts'}
          </Button>}
        </div>
        <SearchBar
          placeholder="Search by title, description, employee or student..."
          className="mt-4 w-full bg-white border-none shadow rounded-full px-4 py-2"
        />
      </header >
      <ul className="mb-4" id="my-own-expeditions">
        {myExpeditions.map((expedition) => (
          <li className="mb-2" key={expedition.id}>
            <Expedition readOnly expedition={expedition} />
          </li>
        ))}
      </ul>
      <MyOtherSeparator />
      <ul className="mt-4" id="other-expeditions">
        {othersExpeditions.map((expedition) => (
          <li className="mb-2" key={expedition.id}>
            <Expedition readOnly expedition={expedition} />
          </li>
        ))}
      </ul>
      <footer className="flex flex-col items-center">
        <button
          onClick={toggleArchivedsOpen}
          className="mt-8 justify-self-start hover:underline text-sm text-gray-60"
        >
          {archivedsOpen ? 'Hide' : 'Show'} {archivedsCount} Archiveds...
        </button>
        <ul
          hidden={!archivedsOpen}
          className="mt-4"
          id="archived-expeditions"
        >
          {archivedExpeditions.map((expedition) => (
            <li className="mb-2" key={expedition.id}>
              <Expedition readOnly expedition={expedition} />
            </li>
          ))}
        </ul>
      </footer>
    </div >
  )
}

export default ExpeditionsList
