import React, { Suspense } from 'react'
import { Await, useLoaderData, useSearchParams } from 'react-router-dom'
import { RadioGroup } from '@headlessui/react'
import { SummarizedExpeditionAttendances } from './summarized'
import { AggregatedExpeditionAttendances } from './aggregated'

export default function ExpeditionAttendancesRoute() {
  const loaderData = useLoaderData()
  const [searchParams, setSearchParams] = useSearchParams()
  const currentView = searchParams.get('v') || 'summarized'
  const isAggregatedView = currentView === 'aggregated'
  function handleChangeView(v) {
    setSearchParams((params) => ({ ...Object.fromEntries(params.entries()), v }))
  }

  return (
    <>
      <div className="flex items-center space-x-3 pt-1 mb-4">
        <p className="font-bold">View:</p>
        <RadioGroup value={currentView} onChange={handleChangeView}>
          <div className="flex items-center space-x-2">
            <RadioGroup.Option
              value="summarized"
              className={({ active, checked }) => `
                ${active ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300' : ''}
                ${checked ? 'bg-sky-900/75 text-white' : 'bg-white'}
                relative flex cursor-pointer rounded-lg px-3 py-px shadow-md focus:outline-none
             `}
            >
              {({ checked }) => (
                <RadioGroup.Description as="span" className={`text-sm text-center min-w-20 ${checked ? 'text-sky-100' : 'text-gray-50'}`}>
                  <span>Default</span>
                </RadioGroup.Description>
              )}
            </RadioGroup.Option>
            <RadioGroup.Option
              value="aggregated"
              className={({ active, checked }) => `
                ${active ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300' : ''}
                ${checked ? 'bg-sky-900/75 text-white' : 'bg-white'}
                relative flex cursor-pointer rounded-lg px-3 py-px shadow-md focus:outline-none
             `}
            >
              {({ checked }) => (
                <RadioGroup.Description as="span" className={`text-sm text-center min-w-20 ${checked ? 'text-sky-100' : 'text-gray-50'}`}>
                  <span>Aggregated</span>
                </RadioGroup.Description>
              )}
            </RadioGroup.Option>
          </div>
        </RadioGroup>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <Await resolve={loaderData.result}>
          {(data) => (
            isAggregatedView
              ? <AggregatedExpeditionAttendances data={data} />
              : <SummarizedExpeditionAttendances expedition={data} />
          )}
        </Await>
      </Suspense>
    </>
  )
}
