import React from 'react'
import { Icon } from '@design-system'
import { Button } from '@design-system'

type RemoteDirtyProps = {
  onKeepChanges: () => void
  onUpdateVersion: () => void
}

type LocalDataProps = {
  onDiscardChanges: () => void
}

type DataPersistenceBannerProps =
  | ({ variation: 'remoteDirty' } & RemoteDirtyProps)
  | ({ variation: 'localData' } & LocalDataProps)

export function DataPersistenceBanner(props: DataPersistenceBannerProps) {
  return (
    <div className="rounded-lg flex flex-col w-fit gap-1 bg-notice-5 border border-notice-50 px-6 py-4">
      <div className="flex gap-2 items-baseline leading-loose">
        <Icon name="alert-triangle" size="md" />
        <h5 className="font-bold text-xl">Work in Progress Saved</h5>
      </div>
      {props.variation === 'remoteDirty' ? (
        <div className="ml-7">
          <p>Looks like there have been updates to the data since you started editing. Would you like to refresh to the latest version?</p>
          <p className="font-semibold italic">Please note: this will overwrite any changes you've made.</p>
          <div className="flex gap-3 justify-end items-center">
            <Button variant="outlined" onClick={props.onKeepChanges}>
              Keep my changes
            </Button>
            <Button onClick={props.onUpdateVersion}>
              Update to latest version
            </Button>
          </div>
        </div>
      ) : (
        <p>We've recovered your recent unsaved changes. To discard your changes and return to the original version, please
          <b className="text-blue-40 cursor-pointer hover:underline" onClick={props.onDiscardChanges}> click here</b>.
        </p>
      )}
    </div>
  )

}
