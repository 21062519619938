import PropTypes from 'prop-types'
import React from 'react'
import AiReportDrawer from '../ai-report-drawer'

const AiEndCycleReportDrawer = ({ open, setOpen, aiInsert, setAiInsert }) => {
  return (
    <AiReportDrawer
      open={open}
      setOpen={setOpen}
      aiInsert={aiInsert}
      setAiInsert={setAiInsert}
      title="End-cycle report feedback"
      endpointPath="/sora-ai/reports/end-cycle-draw"
    ></AiReportDrawer>
  )
}

AiEndCycleReportDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  aiInsert: PropTypes.string,
  setAiInsert: PropTypes.func,
  title: PropTypes.string,
  endpointPath: PropTypes.string,
}

export default AiEndCycleReportDrawer
