import SoraLink from '@components/link'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useLoaderData, ActionFunctionArgs, useOutletContext, useSearchParams, Outlet } from 'react-router-dom'
import {
  Button,
  DropdownMenu,
  Icon,
  Typography,
  unstable_Dialog as Dialog,
  unstable_Checkbox as Checkbox,
  useFetcher,
  EmptyState,
} from '@design-system'
import { TextField } from '@designsystem'
import { FilterPresetCommentArrayArgs, LoaderResponse, PresetComment } from './types'
import { cloneDeep } from 'lodash'
import SlateTextarea, { BlockButton, LinkButton, MarkButton } from '@components/forms/slate-textarea'
import EmptyCommentsMascot from './empty-comments-mascot.svg'

async function loader({ params }) {
  const result = await axios.get(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}/comment-bank`
  )
  return result.data
}

async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData()
  const res = await axios.post(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}/comment-bank`,
    formData
  )
  return { status: res.status, ...res.data }
}

function Element() {
  const [searchInputValue, setSearchInputValue] = useState<string>('')

  return (
    <div className="flex flex-col w-[460px] overflow-hidden bg-white border-x">
      <PageHeader setSearchInputValue={setSearchInputValue} />
      <PageBody searchInputValue={searchInputValue} />
      <PageFooter />
    </div>
  )
}

function PageHeader({ setSearchInputValue }: { setSearchInputValue: { (value: string): void } }) {
  return (
    <>
      <div className="flex flex-row justify-between pt-4 px-6 items-center">
        <Typography variant="heading-5" weight="bold">
          Comments
        </Typography>
        <SoraLink className="flex-none" to={'..'} as={Link}>
          <Button variant="outlined" color="soft" size="xs">
            <Icon name="cross" />
          </Button>
        </SoraLink>
      </div>
      <div className="flex flex-row justify-between pt-4 px-6 items-center">
        <TextField
          placeholder="Search comment"
          fullWidth
          startAdornment={<Icon name="search" />}
          onChange={(event) => setSearchInputValue(event.target.value)}
        />
      </div>
    </>
  )
}

function PageBody({ searchInputValue }: { searchInputValue: string }) {
  const loaderData = useLoaderData() as LoaderResponse
  const [setSlateCurrentValue] = useOutletContext<Array<any>>()

  const experiencePresetComments = filterPresetCommentArray({
    commentArray: loaderData.experience,
    filterText: searchInputValue,
  })
  const userPresetComments = filterPresetCommentArray({
    commentArray: loaderData.general,
    filterText: searchInputValue,
  })

  return (
    <div className="flex flex-col flex-1 overflow-auto pt-6 px-6 border-b border-neutral-200 gap-2">
      {experiencePresetComments.length > 0 && (
        <div>
          <Typography variant="subheadline" weight="bold">
            Expedition comments
          </Typography>
          <Typography variant="callout">Comments created for this expedition</Typography>
        </div>
      )}
      {experiencePresetComments.map((el) => (
        <div
          key={el.id}
          className="flex p-4 bg-stone-50 rounded-lg items-center justify-between hover:bg-stone-100"
        >
          <div
            className="flex-col w-full truncate pr-4 cursor-pointer"
            onClick={(_) => {
              setSlateCurrentValue(cloneDeep(el.body))
            }}>
            <Typography variant="body" weight="bold" className="truncate">
              {el.title}
            </Typography>

            <Typography variant="body" className="truncate">
              {el.textBody}
            </Typography>
          </div>
          <CommentDropdownMenu commentId={el.id} title={el.title} body={el.body} type={el.type} />
        </div>
      ))}

      {userPresetComments.length > 0 && (
        <div className="pt-4">
          <Typography variant="subheadline" weight="bold">
            General comments
          </Typography>
          <Typography variant="callout">Comments you've created and are available to all your expeditions</Typography>
        </div>
      )}

      {userPresetComments.map((el) => (
        <div
          key={el.id}
          className="flex p-4 bg-stone-50 rounded-lg items-center justify-between hover:bg-stone-100"
        >
          <div
            className="flex-col w-full truncate pr-4 cursor-pointer"
            onClick={(_) => {
              setSlateCurrentValue(cloneDeep(el.body))
            }}>
            <Typography variant="body" weight="bold" className="truncate">
              {el.title}
            </Typography>

            <Typography variant="body" className="truncate">
              {el.textBody}
            </Typography>
          </div>
          <CommentDropdownMenu commentId={el.id} title={el.title} body={el.body} type={el.type} />
        </div>
      ))}

      {loaderData.experience.length === 0 && loaderData.general.length === 0 && (
        <EmptyState direction="column">
          <EmptyState.Illustration>
            <img src={EmptyCommentsMascot} alt="No comments mascot" />
          </EmptyState.Illustration>
          <EmptyState.Title>No saved comments</EmptyState.Title>
          <EmptyState.Description>You don't have any saved comments</EmptyState.Description>
        </EmptyState>
      )}
      <Outlet />
    </div>
  )
}

function PageFooter() {
  const fetcher = useFetcher()
  const [params] = useSearchParams()
  const [open, setOpen] = useState(params.has('create'))

  useEffect(() => {
    if (fetcher.data?.status === 200 && fetcher.formData.get('_action') === 'create_preset_comment') {
      setOpen(false)
    }
  }, [fetcher.data])

  return (
    <div className="flex justify-center">
      <Button size="lg" className="m-6" variant="outlined" asChild>
        <SoraLink to={'./preset-comment/create'} as={Link}>
          <Icon name="plus"></Icon>
          Create new comment
        </SoraLink>
      </Button>
    </div>
  )
}

interface CommentDropdownMenuProps {
  commentId: number
  title: string
  body: Array<any>
  type: 'user' | 'experience'
}

function CommentDropdownMenu({ commentId, title, body, type }: CommentDropdownMenuProps) {
  const fetcher = useFetcher()
  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    if (fetcher.data?.status === 200 && fetcher.formData.get('_action') === 'edit_preset_comment') {
      setDialogOpen(false)
    }
  }, [fetcher.data])

  return (
    <>
      <DropdownMenu>
        <DropdownMenu.Trigger asChild>
          <Button className={`self-center`} variant="ghost" size="sm" color="soft">
            <Icon name="more-vert-filled" />
          </Button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content>
          <DropdownMenu.Item
            className="hover:bg-neutral-100 flex items-center gap-2"
            onClick={(_) => {
              setDialogOpen(true)
            }}
          >
            <Icon name="edit-3" />
            <Typography variant="callout">Edit Comment</Typography>
          </DropdownMenu.Item>

          <fetcher.Form method="POST" id="deleteCommentFromBankForm">
            <input type="hidden" name="commentId" value={commentId} />
            <DropdownMenu.Item className="text-danger hover:bg-neutral-100">
              <button
                className="flex items-center gap-2"
                type="submit"
                name="_action"
                value="delete_preset_comment"
                form="deleteCommentFromBankForm"
              >
                <Icon name="trash-2" color="danger" />
                <Typography variant="callout" color="danger">
                  Delete Comment
                </Typography>
              </button>
            </DropdownMenu.Item>
          </fetcher.Form>
        </DropdownMenu.Content>
      </DropdownMenu >
      <Dialog
        open={dialogOpen}
        onOpenChange={(value) => {
          setDialogOpen(value)
        }}
      >
        <Dialog.Content className="px-0 max-w-[700px]">
          <Dialog.Header className="flex items-center justify-center  border-b border-neutral-200 pb-4">
            <Typography variant="heading-5" weight="bold">
              Edit comment
            </Typography>
          </Dialog.Header>

          <fetcher.Form method="POST" id="createPresetCommentForm">
            <input type="hidden" name="commentId" value={commentId} />
            <div className="flex flex-col px-14 pt-8 gap-6">
              <TextField placeholder="Comment Title" name="title" required trim defaultValue={title} />

              <SlateTextarea
                // @ts-ignore slate component is not typed so TS will throw an error
                className="h-32"
                name="body"
                value={body}
                customTopBar={<>
                  <div className="flex items-center border-b-[1px] border-neutral-300  sticky top-0 bg-white z-5 h-9 py-1">
                    <MarkButton
                      disabled={false}
                      format="bold"
                      icon={<Icon className="leading-['inherit']" name="text-bold" size="md" />} />
                    <MarkButton
                      disabled={false}
                      format="italic"
                      icon={<Icon className="leading-['inherit']" name="text-italic" size="md" />} />
                    <BlockButton
                      disabled={false}
                      format="numbered-list"
                      icon={<Icon className="leading-['inherit']" name="list-ordered" size="md" />} />
                    <BlockButton
                      disabled={false}
                      format="bulleted-list"
                      icon={<Icon className="leading-['inherit']" name="list-unordered" size="md" />} />
                    <LinkButton disabled={false} />
                    <MarkButton
                      disabled={false}
                      format="strikethrough"
                      icon={<Icon className="leading-['inherit']" name="text-strikethrough" size="md" />} />
                    <MarkButton
                      disabled={false}
                      format="underline"
                      icon={<Icon className="leading-['inherit']" name="text-underline" size="md" />} />
                  </div>
                </>} />
              <Typography variant="callout">
                This comment will be saved in your comment bank and you can reuse across students and assignments
                related to this{' '}
                <Typography className="inline" variant="callout" weight="bold">
                  same expedition instance.
                </Typography>
              </Typography>
              <div className="flex gap-2">
                <Checkbox
                  id="all_experiences_checkbox"
                  className=""
                  name="allExperiences"
                  defaultChecked={type === 'user' ? true : false} />
                <Typography variant="body" asChild>
                  <label htmlFor="all_experiences_checkbox">Make comment available across all my expeditions</label>
                </Typography>
              </div>
              <div className="flex justify-end">
                <Button type="submit" name="_action" value="edit_preset_comment" >
                  Save changes
                </Button>
              </div>
            </div>
          </fetcher.Form>
        </Dialog.Content>
      </Dialog>
    </>
  )
}

/**
 * Filters an array of comment objects based on a filter text.
 *
 * @param {FilterPresetCommentArrayArgs} params - The parameters for filtering the comment array.
 * @returns {Comment[]} The filtered array of comment objects.
 */
function filterPresetCommentArray({ commentArray, filterText }: FilterPresetCommentArrayArgs): PresetComment[] {
  if (!commentArray || !Array.isArray(commentArray) || typeof filterText !== 'string') {
    return []
  }

  const lowerCaseFilterText = filterText.toLowerCase()

  return commentArray.filter((comment) => {
    return comment.title.toLowerCase().includes(lowerCaseFilterText)
  })
}

export const FacilitateExperienceTaskStudentCommentBankRoute = {
  loader,
  action,
  Element,
}
