import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RenderedSlate } from '@components/forms/slate-textarea'
import { TextField, Button } from '@designsystem'
import { useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import Avatar from '@components/avatar'
import axios from 'axios'
import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import Slideover from '@components/slideover'
import SoraIcon, { soraSparkles, soraSendPaperPlane } from '@components/sora-icon'
import useCurrentUser from '@hooks/useCurrentUser'
import useSWR from 'swr'

const AiReportDrawer = ({ open, setOpen, aiInsert, setAiInsert, title, endpointPath }) => {
  const { student_id, theme_id } = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [messages, setMessages] = useState()
  const [textFieldValue, setTextFieldValue] = useState('')
  const currentUser = useCurrentUser()


  // Used to lock chat and prevent new messages to be sent
  const [isSendingMessage, setIsSendingMessage] = useState(false)

  // Used to scroll to bottom of the chat
  const bottomEl = useRef(null)

  const urlPath = endpointPath ?? `/sora-ai/reports/mid-cycle-draw`

  const { isValidating, mutate } = useSWR(open ? `${urlPath}?student_id=${student_id}&theme_id=${theme_id}` : null, {
    revalidateOnFocus: false,
    onSuccess: (data) => {
      setMessages(data.messages.data)
      // Scroll if it is the first loading
      if (!messages) bottomEl?.current?.scrollIntoView() // Hard scrolling
    },
    onError: (err, key) => {
      toast.error('Failed to get initial report from Ai, please refresh & try again.')
    },
  })

  const handleSendMessage = () => async () => {
    try {
      setIsSendingMessage(true)
      const res = await axios.post(`${urlPath}`, {
        student_id,
        theme_id,
        new_message: textFieldValue,
      })
      setMessages(res.data.messages.data)
      setTextFieldValue('')
      setIsSendingMessage(false)
      bottomEl?.current?.scrollIntoView({ behavior: 'smooth' })
    } catch (err) {
      setIsSendingMessage(false)
      toast.error('Failed to get message from Ai. Please refresh & try again.')
    }
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setTextFieldValue(value)
  }

  const handleKeyDown = async (event) => {
    // I a special key is pressed we use the key
    if (event.ctrlKey || event.metaKey || event.altKey || event.shiftKey) return
    // If we press enter, then send the message
    if (event.key === 'Enter') {
      await handleSendMessage()()
      return true
    }
  }

  const handleClearChat = async () => {
    try {
      setIsSendingMessage(true)
      await axios.delete(`${urlPath}?student_id=${student_id}&theme_id=${theme_id}`)
      setMessages([])
      mutate() // revalidate SWR
      setIsSendingMessage(false)
      bottomEl?.current?.scrollIntoView({ behavior: 'smooth' })
    } catch (err) {
      setIsSendingMessage(false)
      toast.error('Failed to get restart Ai chat. Please refresh & try again.')
    }
  }

  const shouldDisplayLoading = isValidating && (!messages || messages.length == 0)

  return (
    <Slideover
      open={open}
      title={title ?? <span className="ml-5">{'Mid-cycle report feedback'}</span>}
      // Navigate to previous route if setOpen not defined (when component is defined as a subroute)
      setOpen={setOpen ? setOpen : () => navigate('../?' + searchParams.toString())}
      maxWidth="max-w-[40vw]"
    >
      {/* LOADING WIDGET */}
      {shouldDisplayLoading &&
        <div key="loading-widget" className="flex flex-col justify-center grow">
          <FontAwesomeIcon icon={faSpinner} className="text-gray-70" spinPulse size="2x" fontWeight={100} />
          <p className="text-center text-gray-70	text-lg pt-6">We are generating<br /> the inital chat</p>
        </div>}

      <section className="mx-0">
        <div className="flex flex-col mb-20">
          {/* EVERY ROW IS A MESSAGE */}
          {messages &&
            messages.map((message) => {
              if (isUserMessage(message)) {
                return (
                  <div key={message.id} className="flex flex-row">
                    {/* AVATAR */}
                    <Avatar
                      className="mx-5 h-6 min-w-6 w-6"
                      classNameImg="rounded-full bg-gray-40"
                      name={currentUser.name}
                      imgUrl={currentUser.profileImgUrl}
                    />
                    <div className={`flex flex-col w-full pxl-8 bg-blue-5 rounded-b-lg rounded-r-lg`}>
                      {/* MESSAGE */}
                      <RenderedSlate className="my-2 mx-5 break-all" value={message.contentMarkdown} />
                    </div>
                  </div>
                )
              } else {
                return (
                  <div key={message.id} className="flex flex-row">
                    {/* AVATAR */}
                    <SoraIcon icon={soraSparkles} className="mx-5 mt-5" />
                    <div className={`flex flex-col w-full`}>
                      {/* MESSAGE */}
                      <RenderedSlate className="mt-5 break-all" value={message.contentMarkdown} />

                      {/* INSERT BUTTON */}
                      <div className="mt-2 mb-7">
                        <Button
                          onClick={() => {
                            setAiInsert(message.contentMarkdown)
                            setOpen(false)
                          }}
                          loading={false}
                        >
                          Insert
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              }
            })}
          {/* Used for scrolling to bottom */}
          <div ref={bottomEl}></div>
        </div>

        {/* Use relative if you want the text field scrolable too*/}
        {/* TEXT INPUT SECTION */}
        <div className="flex flex-col absolute bottom-0 left-0 right-0 bg-white border-t">
          <div className="my-4 mx-10">
            <TextField
              onKeyDown={handleKeyDown}
              disabled={isValidating || isSendingMessage}
              name="ai_instructions"
              placeholder="Tell AI what to do next..."
              onChange={handleChange}
              value={textFieldValue}
              startAdornment={
                isValidating || isSendingMessage ? (
                  <TextField.Adornment as="div" className="rounded-full p-2">
                    <FontAwesomeIcon icon={faSpinner} className="" spinPulse />
                  </TextField.Adornment>
                ) : (
                  <TextField.Adornment as="div" className="rounded-full p-1">
                    <SoraIcon icon={soraSparkles} className="fill-current" />
                  </TextField.Adornment>
                )
              }
              endAdornment={
                <TextField.Adornment
                  as="div"
                  className={`rounded-full ${isValidating || isSendingMessage ? '' : 'cursor-pointer hover:bg-gray-10'
                    } p-1`}
                  onClick={isValidating || isSendingMessage ? null : handleSendMessage()}
                >
                  <SoraIcon icon={soraSendPaperPlane} className="" />
                </TextField.Adornment>
              }
            />
            <div className="flex flex-row text-xs font-thin mt-1	place-content-center">
              <div>Want to start over?</div>
              <a className="underline cursor-pointer text-violet-500" onClick={handleClearChat}>
                &nbsp;Reset report
              </a>
            </div>
          </div>
        </div>
      </section>
    </Slideover>
  )
}

AiReportDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  aiInsert: PropTypes.string,
  setAiInsert: PropTypes.func,
  title: PropTypes.string,
  endpointPath: PropTypes.string,
}

const isUserMessage = (message) => {
  return message ? message.role === 'user' : false
}

export default AiReportDrawer
