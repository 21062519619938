import React from 'react'
import { Outlet, redirect, useNavigation, useSearchParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Transition } from '@headlessui/react'
import axios from 'axios'
import _refiner from 'refiner-js'
import * as Sentry from '@sentry/react'
import useTheme from '@hooks/useTheme'

export async function loader({ params, request }) {
  try {
    

    const searchParams = new URL(request.url).searchParams
    const cycle_id = searchParams.get('cycle_id')
    const token = searchParams.get('token')
    const force_login = searchParams.get('force_login')
    const student_id = params.student_id ?? searchParams.get('student_id')
    const nonNullParams = Object.fromEntries(Object.entries({ cycle_id, force_login, student_id, token }).filter(([_, v]) => v !== null && v !== undefined))
    const downSearchParams = new URLSearchParams(nonNullParams)
    const { data } = await axios.get(`/pages/index?${downSearchParams.toString()}`)
    
    try {
      _refiner('identifyUser', {
        id: data.userId,
        email: data.userEmail,
        name: data.userName,
        is_student: data.userRole === 'student',
        is_parent: data.userRole === 'guardian',
        is_employee: data.userRole !== 'student' && data.userRole !== 'guardian',
        is_beta: data.userIsBeta,
      })  
    } catch (error) {
      console.error(error)
    }
    try {
      Sentry.setUser({
        email: data.userEmail,
        id: data.userId,
        name: data.userName,
        role: data.userRole,
        is_beta: data.userIsBeta,
      })  
    } catch (error) {
      console.error(error)
    }

    return data
  } catch (e) {
    if (e.response?.status === 401) {
      _refiner('resetUser')
      return redirect('/signin')
    }
  }
}

export default function IndexRoute() {
  const navigation = useNavigation()
  const [theme, _, setTheme] = useTheme(true)
  const [searchParams] = useSearchParams()
  const showSpinner = navigation.state === 'loading'
  if (searchParams.get('theme') && (theme as any) !== searchParams.get('theme')) {
    setTheme(searchParams.get('theme') as any)
  }
  return (
    <div className="group/flutter flutter">
      <div className="flex flex-row h-full w-full">
        <main id="container" className="relative flex-1 h-full">
          <div className="max-w-screen-2xl mx-auto px-4">
            <Transition
              show={!showSpinner}
              enter="transition-opacity duration-600"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-600"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Outlet />
            </Transition>
            <Transition
              show={showSpinner}
              enter="transition-opacity duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0 hidden"
              className="fixed top-0 left-0 w-full h-full flex justify-center items-center"
            >
              <FontAwesomeIcon icon={faSpinner} spin className="text-black dark:text-white text-3xl" />
            </Transition>
          </div>
        </main>
      </div>
    </div>
  )
}
