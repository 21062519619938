import React from 'react'
import PropTypes from 'prop-types'

export const StudentInfo = ({ id, name, campus }) => {
  return (
    <div className='flex flex-col'>
      <span className='font-bold'>[{id}] {name}</span>
      <span>{campus}</span>
    </div>
  )
}
StudentInfo.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  campus: PropTypes.string,
}
