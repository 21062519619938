import axios from 'axios'

export const loader = async ({ request: { url } }) => {
  const { search } = new URL(url)
  const [{ data: studentsProgresses }, { data: { result: cohorts } }] = await Promise.all([
    axios.get(`/pages/admin/workbench/report/progress/students${search}`),
    axios.get('/cohorts')
  ])
  return {
    studentsProgresses,
    cohorts: cohorts.filter(c => c.cohort_id !== 4 && c.cohort_id !== 5)
  }
}
