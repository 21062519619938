import React from 'react'
import PropTypes from 'prop-types'
import { useFetcher } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const StudentSelectSingle = ({ checked = false, studentId }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle'

  const toggle = (event) => {
    event.preventDefault()
    fetcher.submit({ _action: checked ? 'unselect' : 'select', student_id: studentId }, { method: "post" })
  }

  return (
    <fetcher.Form>
      {isLoading ? <FontAwesomeIcon spin={true} className="flex text-gray-50" icon={faSpinner} /> : <input type="checkbox" name="selected" className='cursor-pointer' checked={checked} onChange={toggle} />}
    </fetcher.Form>
  )
}
StudentSelectSingle.propTypes = {
  checked: PropTypes.bool,
  studentId: PropTypes.number,
}

export default StudentSelectSingle
