import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from '@design-system'

export const StudentTags = ({ students }) => {
  const [show, setShow] = useState(false)
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex flex-row gap-2 items-center'>
        <span className='py-2'>Total: {students.length}</span>
        {(students.length > 0) &&
          <Button size='xs' variant='ghost' onClick={() => {setShow(!show)}}>
            <Icon name={show ? 'eye-slash' : 'eye'} size='xs' />
          </Button>
        }
      </div>
      {show && 
        <div className='flex flex-row flex-wrap gap-3'>
          {students.map(student => (
            <div key={student.id} className={`rounded py-1 px-2 font-bold text-xs whitespace-nowrap ${student.new ? 'bg-green-5 text-green-60' : 'bg-alpha/15 text-black'}`}>{student.name}</div>
          ))}
        </div>
      }
    </div>
  )
}
StudentTags.propTypes = {
  students: PropTypes.array,
}
