import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from '@design-system'

export const UnitList = ({ units }) => {
  const [show, setShow] = useState(false)
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex flex-row gap-2 items-center'>
        <span className='py-2'>Total: {units.length}</span>
        {(units.length > 0) &&
          <Button size='xs' variant='ghost' onClick={() => {setShow(!show)}}>
            <Icon name={show ? 'eye-slash' : 'eye'} size='xs' />
          </Button>
        }
      </div>
      {show &&
        <div className='flex flex-row flex-wrap gap-3'>
          {units.map(unit => (
            <div key={unit} className='rounded py-1 px-2 bg-green-10 font-bold text-xs text-black whitespace-nowrap'>{unit}</div>
          ))}
        </div>
      }
    </div>
  )
}
UnitList.propTypes = {
  units: PropTypes.array,
}
