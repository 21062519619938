/* eslint-disable react/prop-types */
import React from 'react'
import HsPanel from '../../../pages/pace-and-progress/graduation/hs-panel'
import MsPanel from '../../../pages/pace-and-progress/graduation/ms-panel'
import BottomSection from '../../../pages/pace-and-progress/graduation/bottom-section'
import { useLoaderData, useRevalidator } from 'react-router-dom'
import axios from 'axios'

export const loader = async ({ params, request }) => {
  const url = new URL(request.url)
  const cycleId = url.searchParams?.get('cycle_id')
  const { data } = await axios.get(`/pages/student/${params.student_id}/progress/graduation?cycle_id=${cycleId}`)
  return data
}

export default function StudentGraduationRoute() {
  const loaderData = useLoaderData()
  const revalidator = useRevalidator()
  const mutate = revalidator.revalidate

  return (
    <>
      {loaderData.schoolStage === 'hs' ? <HsPanel data={loaderData} /> : <MsPanel data={loaderData} />}
      <BottomSection data={loaderData} mutate={mutate} />
    </>
  )
}
