import React from 'react'
import axios from 'axios'
import { Form, useLoaderData, useNavigation, redirect, useActionData, useNavigate } from 'react-router-dom'
import { Button } from '@design-system'
import serializeFormData from '@utils/serializeFormData'
import { useToast } from '@hooks/useToast'

const loader = async ({ params }) => {
  const { processId } = params
  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/templates/config`)
  return result?.data
}

const action = async ({ params, request }) => {
  const { processId } = params

  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)

  try {
    await axios.post(`/backoffice/workbench/journey-builder/processes/${processId}/templates/config`, { setup_params: serializedFormData })
    return redirect('../review')
  } catch (error) {
    return {
      toast: { message: 'There was an error running Journey Builder. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const navigate = useNavigate()
  const { process } = useLoaderData()
  const navigation = useNavigation()
  useToast(useActionData())
  
  const isFormSubmitting = navigation.state !== 'idle'

  const backAction = () => {
    navigate('../../students')
  }
  
  return (
    <Form method="post" id="journey-builder-form" className='flex flex-col h-full relative  pb-20'>
      <div className='overflow-y-auto text-sm space-y-4 px-2'>
        <input type="hidden" name="school_stage" value={process.school_stage} />
        <div className='flex flex-col space-y-4 py-4'>
          <div>
            <label>Force 1 STEM and 1 Humanities?</label>
            <select name='force_1_stem_1_humanities' defaultValue={process?.force_1_stem_1_humanities ? '1' : '0'} >
              <option value='0'>No</option>
              <option value='1'>Yes</option>
            </select>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end align-middle p-4 space-x-3 absolute bottom-0 left-0 right-0 rounded-xl border border-gray-30 bg-white">
        <Button type='button' onClick={backAction} disabled={isFormSubmitting} variant='outlined'>Back</Button>
        <Button type='submit' disabled={isFormSubmitting} loading={isFormSubmitting}>Next</Button>
      </div>
    </Form>
  )
}

export const JourneyBuilderTemplatesConfigRoute = {
  loader,
  action,
  Element,
}
