import axios from 'axios'
import { defer } from 'react-router-dom'

export const loader = async ({ params, request }) => {
  const fetchData = async () => {
    const v = new URL(request.url).searchParams.get('v')
    try {
      const { data } = await axios.get(v === 'detailed' ? `/experiences/${params.id}/assessment/students` : `/experiences/${params.id}/assessment/tasks`)
      return data
    } catch (e) {
      console.error(e)
      return []
    }
  }

  return defer({ result: fetchData(params.id, new URL(request.url).searchParams.get('v')) })
}
