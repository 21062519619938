import React, { ComponentProps, ReactNode } from 'react'
import { Pill } from '@design-system'

export type PillTaskVariant = 'assessed' | 'unassessed' | 'pending' | 'missed' | 'flagged' | 'extended-deadline' | 'late'

interface PillAllowedProps extends Pick<ComponentProps<typeof Pill>, 'rounded' | 'variant' | 'color' | 'disabled'> { }

interface VariantProps extends PillAllowedProps {
  childrenFn: (props: Omit<PillTaskProps, 'variant'>) => ReactNode
}

const defaultProps: VariantProps = {
  childrenFn: ({ variant }: PillTaskProps) => <Pill.Value>{variant}</Pill.Value>,
}

const variants: Record<PillTaskVariant, VariantProps> = {
  assessed: {
    childrenFn: () => <Pill.Value>Assessed</Pill.Value>,
    color: 'success-30'
  },
  unassessed: {
    childrenFn: () => <Pill.Value>Unassessed</Pill.Value>,
    color: 'cyan-5'
  },
  pending: {
    childrenFn: () => <Pill.Value>Pending submission</Pill.Value>,
    variant: 'outlined',
    disabled: true,
  },
  missed: {
    childrenFn: () => <Pill.Value>Missed</Pill.Value>,
    color: 'danger-5'
  },
  flagged: {
    childrenFn: () => <Pill.Icon name="flag-2-filled" />,
    variant: 'outlined',
    color: 'danger-50',
  },
  'extended-deadline': {
    childrenFn: () => <Pill.Value>Extended deadline</Pill.Value>,
    variant: 'outlined',
  },
  late: {
    childrenFn: () => <Pill.Value>Late</Pill.Value>,
    variant: 'outlined',
    color: 'danger-50'
  },
}

interface PillTaskProps {
  variant: PillTaskVariant
}

/**
 * PillTask display a pill with task status
 */
export const PillTask = (props: PillTaskProps) => {
  const { childrenFn, ...pillProps } = variants[props.variant] ?? defaultProps
  return (
    <Pill {...pillProps}>
      {childrenFn({ ...props })}
    </Pill>
  )
}
