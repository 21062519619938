import React, { PropsWithChildren } from 'react'
import { unstable_Dialog as Dialog, Button, Icon } from '@design-system'

export const MenuMobile = ({ children }: PropsWithChildren) => (
  <Dialog>
    <Dialog.Trigger asChild>
      <Button data-testid="trigger-menu" variant="ghost" className="-ml-2 mr-2 !ring-0 xl:hidden">
        <Icon name="menu" />
      </Button>
    </Dialog.Trigger>
    <Dialog.Content side="left" className="py-12 px-2">
      <nav>
        <ul>
          {children}
        </ul>
      </nav>
    </Dialog.Content>
  </Dialog >
)

