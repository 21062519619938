import PropTypes from 'prop-types'
import React from 'react'

import Student from '../student'
import SessionsInfo from '../sessions-info'
import { getCategoryColor } from '../../utils/categoryColors'
import SoraLink from '@components/link/index.jsx'
import { RenderedSlate } from '@components/forms/slate-textarea'
import useCurrentUser from '@hooks/useCurrentUser'
import { useParams } from 'react-router-dom'
import OpportunitiesList from '@components/opportunities-list'

const Expedition = ({ expedition }) => {
  const { student_id } = useParams()
  const currentUser = useCurrentUser()

  const learnedUnits = expedition.tasks
    ? Object.values(
      expedition.tasks
        .flatMap((t) => t.units)
        .reduce((acc, con) => {
          return {
            ...acc,
            [con.unit_id]: {
              ...con,
              count: acc[con.unit_id] ? acc[con.unit_id].count + 1 : 1,
            },
          }
        }, {})
    ).map((unit) => ({
      ...unit,
      id: unit?.unit_id,
      title: unit?.unit?.title,
      level: unit?.unit?.level,
    }))
    : expedition.learned_units || []

  const learnedAbilities = expedition.tasks
    ? Object.values(
      expedition.tasks
        .flatMap((t) => t.abilities)
        .reduce((acc, ab) => {
          return {
            ...acc,
            [ab.ability_id]: {
              ...ab,
              count: acc[ab.ability_id] ? acc[ab.ability_id].count + 1 : 1,
            },
          }
        }, {})
    ).map((ability) => ({
      ...ability,
      id: ability?.ability_id,
      title: ability?.ability?.title,
    }))
    : expedition.learned_abilities_v2

  const maxStudents = expedition.max_students
  const currentStudents = expedition.students?.length || expedition.registered_students

  const categoryColor = getCategoryColor(expedition.category)

  const students = expedition.students

  const expeditionTitle =
    currentUser.demo && student_id && expedition ? (
      <SoraLink className="text-blue-70" to={`/student/${student_id}/expedition/${expedition.id}`}>
        {expedition.title}
      </SoraLink>
    ) : (
      <SoraLink
        to={`/expeditions/${expedition.id}`}
        className={`font-bold text-2xl ${categoryColor.text} hover:underline`}
      >
        {expedition.title}
      </SoraLink>
    )

  return (
    <div className="flex shadow-md rounded bg-white">
      <div
        className={`flex-none py-4 px-2 ${categoryColor.bgDark} rounded-l font-bold font-mono uppercase`}
        style={{ writingMode: 'vertical-lr', textOrientation: 'upright' }}
      >
        {expedition.category}
      </div>
      <div className="flex-grow flex flex-wrap lg:flex-nowrap">
        <div className="flex-grow px-4 py-4">
          <header className="flex justify-between items-center">
            {expeditionTitle}
            <h5 className="font-bold text-xl whitespace-nowrap text-gray-50">
              {expedition.employees.map((e) => `${e.first_name} ${e.last_name}`).join(', ')}
            </h5>
          </header>
          {expedition.cohort_title && (
            <div className={`inline-block`}>
              <p className="inline-block line-clamp-2">
                <strong>Cohort: {expedition.cohort_title}</strong>
              </p>
            </div>
          )}
          <div className={`inline-block ${expedition.cohort_title && 'pl-20'}`}>
            <SessionsInfo sessions={expedition.sessions} />
          </div>

          <div className="my-4">
            {expedition.rte_description?.length > 0 && (
              <>
                <h4 className="font-bold text-lg mb-1">Description</h4>
                <RenderedSlate value={expedition.rte_description} />
              </>
            )}
            {expedition.overview?.length > 0 && (
              <>
                <h4 className="font-bold text-lg mb-1">Overview</h4>
                <RenderedSlate value={expedition.overview} />
              </>
            )}
            {expedition.tools_and_materials?.length > 0 && (
              <>
                <h4 className="font-bold text-lg mb-1">Tools and Materials</h4>
                <RenderedSlate value={expedition.tools_and_materials} />
              </>
            )}
          </div>
          {learnedUnits.concat(learnedAbilities).length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-gray-10 px-4 py-2 rounded">
              <div>
                <h4 className="font-bold text-md text-gray-50 mb-3">Units you will learn</h4>
                <OpportunitiesList opportunities={learnedUnits} />
              </div>
              <div>
                <h4 className="font-bold text-md text-gray-50 mb-3">Abilities you will practice</h4>
                <OpportunitiesList opportunities={learnedAbilities} />
              </div>
            </div>
          )}
        </div>
        <div className="flex-none w-full lg:w-1/4 px-4 py-4 flex flex-col">
          <h3 className="font-bold text-md text-gray-50">
            Students ({currentStudents}/{maxStudents})
          </h3>
          <div className="px-4 py-2 bg-gray-10 flex-grow flex flex-col justify-between rounded">
            <ul className="bg-gray-10 flex flex-col space-y-2 mb-4">
              {students
                .filter((student) => student.name)
                .map((student) => (
                  <li key={student.id}>
                    <Student student={student} />
                  </li>
                ))}
              {students.filter((student) => !student.name).length > 0 && (
                <li className="text-sm leading-6 text-gray-50 font-bold">
                  + {students.filter((student) => !student.name).length} others...
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

Expedition.propTypes = {
  currentUser: PropTypes.object,
  expedition: PropTypes.shape({
    active: PropTypes.any,
    category: PropTypes.any,
    description: PropTypes.any,
    rte_description: PropTypes.any,
    overview: PropTypes.any,
    tools_and_materials: PropTypes.any,
    id: PropTypes.any,
    max_students: PropTypes.any,
    open: PropTypes.any,
    sessions: PropTypes.array,
    units_levels: PropTypes.array,
    students: PropTypes.array,
    title: PropTypes.any,
    type: PropTypes.string,
    tasks: PropTypes.array,
    employees: PropTypes.array,
    learned_units: PropTypes.array,
    learned_abilities_v2: PropTypes.array,
    registered_students: PropTypes.string,
    cohort_title: PropTypes.string,
  }),
  onRegister: PropTypes.func,
  onUnregister: PropTypes.func,
  readOnly: PropTypes.any,
}

export default Expedition
