export type NotificationType = 'SCHOOL' | 'ACADEMIC' | 'PRODUCT' | 'REPORTS_AND_INSIGHTS'

export type Notification = {
  id: number,
  body: string,
  read: boolean,
  created_at: string,
  cta_text?: null | string,
  icon_alt?: null | string,
  icon_url?: null | string,
  image_alt?: null | string,
  image_url?: null | string,
  redirect_url: string,
  title: string,
  type: NotificationType,
  category: string,
  content?: Array<unknown>
}

export enum NotificationTabs {
  ALL = 'all',
  ACADEMIC = 'academic',
  REPORTS = 'reports',
  PRODUCT = 'product',
  SCHOOL = 'school',
}

export const NotificationTabsText: Record<NotificationTabs, string> = {
  [NotificationTabs.ALL]: 'All Notifications',
  [NotificationTabs.ACADEMIC]: 'Academic Notifications',
  [NotificationTabs.REPORTS]: 'Reports and Insights',
  [NotificationTabs.PRODUCT]: 'Product Notifications',
  [NotificationTabs.SCHOOL]: 'School Notifications',
} as const

export type PreferenceItem = {
  title: string,
  email: boolean,
  mobile: boolean,
  web: boolean,
}

export type NotificationPreferences = Record<NotificationType, Record<string, PreferenceItem>>
