import PropTypes from 'prop-types'
import React from 'react'
import {
  faCheck,
  faEllipsisH,
  faFileSignature,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import { faReadme } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SoraLink from '@components/link/index.jsx'

const PreWorkCard = ({ task, timeStampTZ }) => {
  const { student_id } = useParams()
  const { id, status, is_readonly, title } = task
  const submissionStatus = taskSubmissionStatus({
    status,
    is_readonly,
    title,
    endDate: timeStampTZ,
  })
  return (
    <div
      className="w-full md:w-96 font-bold py-3 px-4 bg-gray-20 rounded-md flex justify-between"
      key={id}
    >
      {(is_readonly && new Date() > new Date(timeStampTZ)) || !student_id ? (
        <span className="text-gray-70">{title}</span>
      ) : (
        <SoraLink to={`/student/${student_id}/tasks/${id}`} className="text-blue-80">
          {title}
        </SoraLink>
      )}
      {submissionStatus && (
        <div
          className={`${submissionStatus.style} h-7 w-7 rounded-full text-center`}
          title={submissionStatus.title}
        >
          <FontAwesomeIcon
            icon={submissionStatus.icon}
            className="text-white align-middle"
          />
        </div>
      )}
    </div>
  )
}

PreWorkCard.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.any,
    status: PropTypes.string,
    submissions: PropTypes.array,
    title: PropTypes.string,
    is_readonly: PropTypes.bool,
    due_at: PropTypes.any,
  }),
  timeStampTZ: PropTypes.any,
}

export default PreWorkCard

const taskSubmissionStatus = ({ status, is_readonly, title, endDate }) => {
  const dueDate = new Date(endDate)

  if (is_readonly)
    return {
      icon: faReadme,
      style: 'bg-blue-40',
      title,
    }

  if (status === 'no_submission' && dueDate > new Date(Date.now()))
    return {
      icon: faFileSignature,
      style: 'bg-white',
      title: 'Click here for submitting!',
    }
  if (status === 'no_submission' && dueDate < new Date(Date.now()))
    return {
      icon: faTimes,
      style: 'bg-danger-40',
      title: 'You missed the deadline for submitting this task!',
    }
  if (status === 'unassessed')
    return {
      icon: faEllipsisH,
      style: 'bg-yellow-40',
      title: 'Currently being reviewed by the faculty...',
    }
  if (status === 'assessed')
    return {
      icon: faCheck,
      style: 'bg-green-50',
      title: 'Approved, nice one! Check the review notes by clicking here!',
    }

  return null
}
