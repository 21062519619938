import React from 'react'
import { Icon, Spinner, Typography, unstable_Popover as Popover, Button, unstable_Tooltip as Tooltip, useFetcher } from '@design-system'
import { format } from 'date-fns'

enum CategoryEnumLabels {
  task_not_submitted = 'Missed Tasks',
  unexcused_absence = 'Unexcused Absences',
  attendance_increased = 'Attendance Increased',
  task_submission_increased = 'Task Submission Increased',
}

export const PopoverDetails = ({ houseReportItemId }) => {
  const fetcher = useFetcher()
  const handleOpen = () => {
    fetcher.submit({ _loader: 'details', houseReportItemId }, { method: "get" })
  }
  return (
    <Popover>
      <Popover.Trigger asChild >
        <Button variant="ghost" size="xs" className="p-0 h-auto w-auto" onClick={handleOpen}>
          <Tooltip content="Show report details" >
            {fetcher.state === 'loading' ? <Spinner size="xs" /> : <Icon name="info-filled" size="xs" />}
          </Tooltip>
        </Button>
      </Popover.Trigger>
      <Popover.Content>
        {fetcher.data?.category && (
          <>
            <Typography variant="footnote" color="tertiary" weight="bold" className="mb-4">{CategoryEnumLabels[fetcher.data?.category]}</Typography>
            {fetcher.data?.category === 'task_not_submitted' && (
              <MissedTasks items={fetcher.data?.items} />
            )}
            {fetcher.data?.category === 'unexcused_absence' && (
              <UnexcusedAbsences groupedItems={fetcher.data?.groupedItems} />
            )}
            {['attendance_increased', 'task_submission_increased'].includes(fetcher.data?.category) && (
              <PercentageIncreased {...fetcher.data} />
            )}
          </>
        )}
      </Popover.Content>
    </Popover>
  )
}

const MissedTasks = ({ items }) => (
  <ul className="space-y-6">
    {items.map(({ id, taskTitle, themeTitle, dueAt, deadlineExtensionDueAt }) => (
      <li key={id} className="space-y-2">
        <Typography variant="callout" weight="bold">{taskTitle}</Typography>
        <Typography variant="callout">Experience: {themeTitle}</Typography>
        <Typography variant="callout">Due Date: {format(new Date(dueAt), 'MMM dd')}</Typography>
        {deadlineExtensionDueAt && <Typography variant="callout">Deadline Extension: {format(new Date(deadlineExtensionDueAt), 'MMM dd')}</Typography>}
      </li>
    ))}
  </ul>
)

const UnexcusedAbsences = ({ groupedItems }) => (
  <ul className="space-y-6">
    {groupedItems.map(({ date, items }) => (
      <li className="space-y-2" key={date}>
        <Typography variant="footnote" weight="bold">{format(new Date(date), 'EEEE, MMM dd')}</Typography>
        <ul className="space-y-2">
          {items.map(({ id, title }) => (
            <li key={id}>
              <Typography variant="callout">{title}</Typography>
            </li>
          ))}
        </ul>
      </li>
    ))}
  </ul>
)

const PercentageIncreased = ({ previousPercentage, currentPercentage, improvementPercentage }) => (
  <ul className="space-y-2">
    <li className="flex gap-1">
      <Typography variant="footnote" className="grow text-right" asChild><span>Previous Period:</span></Typography>
      <Typography variant="footnote" weight="bold" asChild><span>{(previousPercentage * 100).toLocaleString()}%</span></Typography>
    </li>
    <li className="flex gap-1">
      <Typography variant="footnote" className="grow text-right" asChild><span>Current Period:</span></Typography>
      <Typography variant="footnote" weight="bold" asChild><span>{(currentPercentage * 100).toLocaleString()}%</span></Typography>
    </li>
    <li className="flex justify-end border-t border-gray-10 dark:border-gray-90 pt-1.5">
      <Typography variant="footnote" weight="bold" color="success" asChild><span>+{(improvementPercentage * 100).toLocaleString()}%</span></Typography>
    </li>
  </ul>
)
