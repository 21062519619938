import React from "react"
import { Form, redirect, useActionData, useLoaderData, useNavigation } from "react-router-dom"
import { Select, TextField } from "@designsystem"
import axios from "axios"
import serializeFormData from "@utils/serializeFormData"
import { useToast } from "@hooks/useToast"
import { Button } from "@design-system"
import useNavigateWithCycle from "@hooks/useNavigateWithCycle"

const loader = async ({ params }) => {
  const { processId } = params
  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/schedule/config`)
  return result?.data
}

const action = async ({ params, request }) => {
  const { processId } = params
  
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)

  try {
    await axios.post(`/backoffice/workbench/journey-builder/processes/${processId}/schedule/config`, { schedule_params: serializedFormData })
    return redirect('../review/students')
  } catch (error) {
    return {
      toast: { message: 'There was an error running Journey Builder. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const navigate = useNavigateWithCycle()
  const navigation = useNavigation()
  const { process } = useLoaderData()
  useToast(useActionData())

  const isFormSubmitting = navigation.state !== 'idle'

  const backAction = (event) => {
    event.preventDefault()
    navigate('../../assignments/students')
  }

  return (
    <Form method="post" id="journey-builder-form" className="flex flex-col space-y-4 h-full relative pb-20">
      <div className="overflow-y-auto text-sm space-y-4 py-4">
        <TextField
          label='Max Unique Expeditions Per Expert'
          type='number'
          id='max_unique_expeditions_per_expert'
          name='max_unique_expeditions_per_expert'
          defaultValue={process?.max_unique_expeditions_per_expert || 3}
        />
        <TextField
          label='Max Expeditions Per Expert'
          type='number'
          id='max_expeditions_per_expert'
          name='max_expeditions_per_expert'
          defaultValue={process?.max_expeditions_per_expert || 5}
        />
        <TextField
          label='Max Students Per Expedition'
          type='number'
          id='max_students_per_expedition'
          name='max_students_per_expedition'
          defaultValue={process?.max_students_per_expedition || 15}
        />
        <TextField
          label='Max Students Per Learning Goal'
          type='number'
          id='max_students_per_learning_goal'
          name='max_students_per_learning_goal'
          defaultValue={process?.max_students_per_learning_goal || 20}
        />
        <TextField
          label='Min Students Per Section (Learning Goal or Expedition)'
          type='number'
          id='min_section_size'
          name='min_section_size'
          defaultValue={process?.min_section_size || 5}
        />
        <div>
          <label>Prioritize sections on edge learning blocks?</label>
          <Select
            name='prioritize_edge_learning_blocks'
            defaultSelected={process?.prioritize_edge_learning_blocks ? 1 : 0}
            options={[
              { label: 'No', value: 0},
              { label: 'Yes', value: 1}
            ]}
          />
        </div>
        <div>
          <label>Force 1 stem and 1 humanity per cycle? (This option may decrease the probability of allocating)</label>
          <Select
            name='force_1_stem_1_humanities'
            defaultSelected={process?.schedule_force_1_stem_1_humanities ? 1 : 0}
            options={[
              { label: 'No', value: 0},
              { label: 'Yes', value: 1}
            ]}
          />
        </div>
      </div>
      <div className="flex flex-row justify-end align-middle p-4 space-x-3 absolute bottom-0 left-0 right-0 rounded-xl border border-gray-30 bg-white">
        <Button type="button" variant="outlined" disabled={isFormSubmitting} onClick={backAction}>
          Back
        </Button>
        <Button type="submit" disabled={isFormSubmitting} loading={isFormSubmitting}>
          Next
        </Button>
      </div>
    </Form>
  )
}

export const JourneyBuilderScheduleConfigRoute = {
  action,
  loader,
  Element,
}
