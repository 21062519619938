import React, { useEffect } from 'react'
import axios from 'axios'
import { Link, Outlet, useLoaderData, useSearchParams, } from 'react-router-dom'
import { ParentsMessageButton } from './parent-message-button'
import { Icon, Typography, useFetcher, Pill, Combobox, Button, unstable_Tooltip as Tooltip } from '@design-system'
import serializeFormData from '@utils/serializeFormData'

type Event = {
  id: number,
  attendable_id: number,
  start_at: string,
  end_at: string,
  sms_sent_at?: string,
  conference_url: string,
  absences_count: number,
}

type Attendance = {
  id: number,
  studentName: string,
  status: string,
  cameraOn: boolean,
  participated: boolean,
  notifiedAt: string,
}

type LoaderData = {
  event: Event,
  hasMatchingGcalEvent: boolean,
  hasMatchingZoomEvent: boolean,
  isWaitingZoomEvent: boolean,
  attendances: Array<Attendance>,
  isEditingEnabled: boolean,
}

async function loader({ params }) {
  const result = await axios.get(`/backoffice/houses/${params.houseId}/attendances/${params.eventId}`)
  return result.data
}

async function action({ params, request }) {
  try {
    const formData = await request.formData()
    const serializedFormData = serializeFormData(formData)
    const response = await axios.post(`/backoffice/houses/${params.houseId}/attendances/${params.eventId}`, serializedFormData)
    return response.data
  } catch (error) {
    const data = error.response?.data
    return data || {
      toast: {
        message: 'Something went wrong. Please try again later.',
        appearance: 'error',
      }
    }
  }
}

const STATUS_BG_COLORS = {
  'Present': 'success-30',
  'Unexcused Absent': 'danger-5',
  'Excused Absent': undefined,
  'Late': 'yellow-20',
}

function Element() {
  const fetcher = useFetcher()

  const {
    event: selectedEvent,
    isWaitingZoomEvent,
    hasMatchingZoomEvent,
    attendances,
    isEditingEnabled,
  } = useLoaderData() as LoaderData
  const [searchParams] = useSearchParams()
  const [isEditing, setIsEditing] = React.useState(false)

  useEffect(() => {
    if (fetcher.state === 'idle') {
      setIsEditing(false)
    }
  }, [fetcher.state])

  const handleToggleEditingStatus = () => {
    if (isEditingEnabled) {
      setIsEditing(!isEditing)
    }
  }

  const showNotificationBox = selectedEvent && new Date(selectedEvent.start_at) < new Date() && !selectedEvent.sms_sent_at && selectedEvent.absences_count > 0
  const tooltip = "This event hasn't happened yet"

  const isFailedToConnectWithZoom = !hasMatchingZoomEvent && !isWaitingZoomEvent

  return (
    <div className="flex flex-col gap-8">

      <div className="flex flex-col gap-4">
        <Typography variant="subheadline" weight="bold" className="my-4">
          Standup
        </Typography>

        <div className="flex items-center gap-2">
          <Icon size='sm' name='clock' />
          <Typography>
            {new Date(selectedEvent.start_at).toLocaleTimeString([])} to {new Date(selectedEvent.end_at).toLocaleTimeString([], { timeZoneName: 'short' })}
          </Typography>
        </div>

        <div className="flex items-center gap-2">
          <Icon size='sm' name='link-2' />
          <a href={selectedEvent.conference_url} target='_blank' rel="noreferrer noopener"
            className="text-md hover:underline flex flex-row items-center gap-2 text-accent font-bold">
            {selectedEvent.conference_url}
          </a>
        </div>

        <div className="flex items-center gap-2">
          <Icon size='sm' name='video-camera' className={isFailedToConnectWithZoom && 'text-danger'} />
          <Typography color={isFailedToConnectWithZoom ? 'danger' : undefined}>
            {hasMatchingZoomEvent
              ? (
                <>
                  Connected with Zoom Attendance. <Link
                    to={'zoom-report?' + searchParams.toString()}
                    className="hover:underline text-accent font-bold">
                    View Report
                  </Link>
                </>
              ) : isWaitingZoomEvent
                ? 'Waiting for meeting to start on Zoom'
                : 'Failed to connect with Zoom'
            }
          </Typography>
        </div>
      </div>

      {
        showNotificationBox && (
          <fetcher.Form className="border border-blue-30 bg-blue-2 dark:bg-blue-90 dark:border-blue-90 rounded" method="post">
            <input type="hidden" name="_action" value="send_sms" />
            <div className="flex flex-row justify-between items-center m-4">
              <div className="flex flex-col gap-1">
                <Typography variant='body' weight='bold'>
                  Notify parents of absent students
                </Typography>
                <div className="flex items-center gap-1">
                  <Icon size="xs" name="alert-triangle" />
                  <Typography variant="footnote">
                    You can only send out the notification once per attendance sheet. Make sure you've collected all the absences before sending!
                  </Typography>
                </div>
              </div>
              {new Date(selectedEvent.start_at) < new Date() && <ParentsMessageButton
                startAt={selectedEvent.start_at}
                smsSentAt={selectedEvent.sms_sent_at}
                absencesCount={selectedEvent.absences_count}
              />}
            </div>
          </fetcher.Form>
        )
      }

      <fetcher.Form method="post" className="bg-white dark:bg-gray-100 border dark:border-gray-90 rounded">
        <table className="table-fixed w-full text-center">
          <thead>
            <tr>
              <th className="p-3 text-left border-b dark:border-gray-90" colSpan={3}>
                <Typography variant='heading-6' weight='bold'>
                  Attendance
                </Typography>
              </th>
              <th className="p-3 text-right border-b dark:border-gray-90" colSpan={1}>
                {!isEditing ? (
                  tooltip ? (
                    <Tooltip content={tooltip}>
                      <Button type="button" size='sm' variant='outlined' disabled={!isEditingEnabled} onClick={handleToggleEditingStatus}>
                        Edit
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button type="button" size='sm' variant='outlined' disabled={!isEditingEnabled} onClick={handleToggleEditingStatus}>
                      Edit
                    </Button>
                  )
                ) : (
                  <div className="flex flex-row gap-2 justify-end">
                    <Button type="button" size='sm' variant='outlined' onClick={handleToggleEditingStatus}>
                      Cancel
                    </Button>
                    <Button type="submit" size='sm' name="_action" value="overwrite_attendances">
                      Save
                    </Button>
                  </div>
                )}
              </th>
            </tr>
            <tr>
              <th className="p-3 border-b dark:border-gray-90 text-left">Student name</th>
              <th className="p-3 border-b dark:border-gray-90 text-left">Status</th>
              <th className="p-3 border-b dark:border-gray-90">Camera On</th>
              <th className="p-3 border-b dark:border-gray-90">Participated</th>
            </tr>
          </thead>
          <tbody>
            {attendances.map(attendance => (
              <tr key={attendance.id} className="hover:bg-gray-5 dark:hover:bg-gray-98">
                <td>
                  <input type="hidden" name={`attendances[${attendance.id}][id]`} defaultValue={attendance.id} />
                  <div className="p-3 text-left">
                    <Typography>{attendance.studentName}</Typography>
                    {attendance.notifiedAt && (
                      <div className="text-xxs text-danger-60 -bottom-3">
                        Parents notified in {new Date(attendance.notifiedAt).toLocaleString([], {
                          month: 'short',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        })}
                      </div>
                    )}
                  </div>
                </td>
                <td>
                  <div className="p-3 flex flex-row gap-2">
                    {isEditing && attendance.status !== 'Excused Absent' ? (
                      <Combobox
                        name={`attendances[${attendance.id}][status]`}
                        defaultValue={attendance.status}>
                        <Combobox.Item value="Present">Present</Combobox.Item>
                        <Combobox.Item value="Present, Late">Present, Late</Combobox.Item>
                        <Combobox.Item value="Unexcused Absent">Unexcused Absent</Combobox.Item>
                      </Combobox>
                    ) : attendance.status.split(',').map(st => {
                      return <Pill key={st} color={STATUS_BG_COLORS[st.trim()]}>
                        <Pill.Value>{st}</Pill.Value>
                      </Pill>
                    })}
                  </div>
                </td>
                <td>
                  <div className="p-3">
                    {isEditing && attendance.status !== 'Excused Absent' ? (
                      <input type="checkbox"
                        name={`attendances[${attendance.id}][cameraOn]`}
                        disabled={!isEditing}
                        defaultChecked={attendance.cameraOn}
                      />
                    ) : (
                      attendance.cameraOn ? (
                        <Icon name="check" className="text-success-60" />
                      ) : (
                        <Icon name="cross" className="text-danger" />
                      )
                    )}
                  </div>
                </td>
                <td>
                  <div className="p-3">
                    {isEditing && attendance.status !== 'Excused Absent' ? (
                      <input type="checkbox"
                        name={`attendances[${attendance.id}][participated]`}
                        disabled={!isEditing}
                        defaultChecked={attendance.participated}
                      />
                    ) : (
                      attendance.participated ? (
                        <Icon name="check" className="text-success-60" />
                      ) : (
                        <Icon name="cross" className="text-danger" />
                      )
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </fetcher.Form>
      <Outlet />
    </div >
  )
}

function ManualMarkerCheckbox({ attendanceId, disabled, _action, field, defaultChecked, tooltip }) {
  const fetcher = useFetcher()
  return <fetcher.Form method="post">
    <input type="hidden" name="_action" defaultValue={_action} />
    <input type="hidden" name="attendanceId" defaultValue={attendanceId} />
    {tooltip ? (
      <Tooltip content={tooltip}>
        <input type="checkbox" name={field} disabled={disabled}
          defaultChecked={defaultChecked} onChange={e => fetcher.submit(e.target.form)}
          className={fetcher.data?.toast.appearance === 'error' ? "text-danger" : ""}
        />
      </Tooltip>
    ) : (

      <input type="checkbox" name={field} disabled={disabled}
        defaultChecked={defaultChecked} onChange={e => fetcher.submit(e.target.form)}
        className={fetcher.data?.toast.appearance === 'error' ? "text-danger" : ""}
      />
    )}
  </fetcher.Form>
}

export const HouseAttendanceEventRoute = {
  loader,
  action,
  Element,
}
