import add from 'date-fns/add'
import isMonday from 'date-fns/isMonday'
import isFriday from 'date-fns/isFriday'
import isThursday from 'date-fns/isThursday'
import isTuesday from 'date-fns/isTuesday'

const WEEK_SIZE = 7

export const EXPEDITION_TYPES = [
  { value: 'expedition', label: 'Expedition' },
  { value: 'track', label: 'Track' },
  { value: 'coworking', label: 'Coworking' },
  { value: 'learning_goal', label: 'Learning Goal' },
  { value: 'math_learning_goal', label: 'Math Learning Goal' },
  { value: 'language_learning_goal', label: 'Language Learning Goal' },
  { value: 'independent_study', label: 'Independent Study' },
  { value: 'activity', label: 'Activity' },
]

export const EXPEDITION_CATEGORIES = [
  { value: 'stem', label: 'STEM' },
  { value: 'humanities', label: 'Humanities' },
  { value: 'electives', label: 'Elective' },
]

const findNextDay = (targetDay, minDate) => {
  const isDayOfWeek = {
    monday: isMonday,
    tuesday: isTuesday,
    thursday: isThursday,
    friday: isFriday,
  }
  const checkIfItIsDay = isDayOfWeek[targetDay]
  let helper = minDate
  for (let i = 0; i < WEEK_SIZE; i++) {
    if (checkIfItIsDay(helper)) {
      return helper
    }
    helper = add(helper, { days: 1 })
  }
}

const addTime = ({ date, hours, minutes = 0 }) => {
  date.setHours(hours)
  date.setMinutes(minutes)
  return date
}

export const generateSessionTimesOptions = (minDate) => {
  if (!minDate) return []
  const nextMonday = findNextDay('monday', minDate)
  const nextThursday = findNextDay('thursday', minDate)
  const nextTuesday = findNextDay('tuesday', minDate)
  const nextFriday = findNextDay('friday', minDate)
  return [
    {
      value: [
        addTime({
          hours: 10,
          minutes: 15,
          date: new Date(nextMonday.getTime()),
        }),
        addTime({
          hours: 10,
          minutes: 15,
          date: new Date(nextThursday.getTime()),
        }),
      ],
      label: 'Mon/Thu (10:15am - 11:15am) EST',
    },
    {
      value: [
        addTime({ hours: 10, date: new Date(nextMonday.getTime()) }),
        addTime({ hours: 10, date: new Date(nextThursday.getTime()) }),
      ],
      label: 'Mon/Thu (10:00am - 11:00am) EST',
    },
    {
      value: [
        addTime({
          hours: 11,
          date: new Date(nextMonday.getTime()),
          minutes: 15,
        }),
        addTime({
          hours: 11,
          date: new Date(nextThursday.getTime()),
          minutes: 15,
        }),
      ],
      label: 'Mon/Thu (11:15am - 12:15pm) EST',
    },
    {
      value: [
        addTime({
          hours: 12,
          date: new Date(nextMonday.getTime()),
          minutes: 45,
        }),
        addTime({
          hours: 12,
          date: new Date(nextThursday.getTime()),
          minutes: 45,
        }),
      ],
      label: 'Mon/Thu (12:45pm - 1:45pm) EST',
    },
    {
      value: [
        addTime({
          hours: 13,
          date: new Date(nextMonday.getTime()),
          minutes: 45,
        }),
        addTime({
          hours: 13,
          date: new Date(nextThursday.getTime()),
          minutes: 45,
        }),
      ],
      label: 'Mon/Thu (1:45pm - 2:45pm) EST',
    },
    {
      value: [
        addTime({
          hours: 15,
          date: new Date(nextMonday.getTime()),
          minutes: 15,
        }),
        addTime({
          hours: 15,
          date: new Date(nextThursday.getTime()),
          minutes: 15,
        }),
      ],
      label: 'Mon/Thu (3:15pm - 4:15pm) EST',
    },
    {
      value: [
        addTime({ hours: 10, date: new Date(nextTuesday.getTime()) }),
        addTime({ hours: 10, date: new Date(nextFriday.getTime()) }),
      ],
      label: 'Tue/Fri (10:00am - 11:00am) EST',
    },
    {
      value: [
        addTime({
          hours: 10,
          minutes: 15,
          date: new Date(nextTuesday.getTime()),
        }),
        addTime({
          hours: 10,
          minutes: 15,
          date: new Date(nextFriday.getTime()),
        }),
      ],
      label: 'Tue/Fri (10:15am - 11:15am) EST',
    },
    {
      value: [
        addTime({
          hours: 11,
          date: new Date(nextTuesday.getTime()),
          minutes: 15,
        }),
        addTime({
          hours: 11,
          date: new Date(nextFriday.getTime()),
          minutes: 15,
        }),
      ],
      label: 'Tue/Fri (11:15am - 12:15pm) EST',
    },
    {
      value: [
        addTime({
          hours: 12,
          date: new Date(nextTuesday.getTime()),
          minutes: 45,
        }),
        addTime({
          hours: 12,
          date: new Date(nextFriday.getTime()),
          minutes: 45,
        }),
      ],
      label: 'Tue/Fri (12:45pm - 1:45pm) EST',
    },
    {
      value: [
        addTime({
          hours: 13,
          date: new Date(nextTuesday.getTime()),
          minutes: 45,
        }),
        addTime({
          hours: 13,
          date: new Date(nextFriday.getTime()),
          minutes: 45,
        }),
      ],
      label: 'Tue/Fri (1:45pm - 2:45pm) EST',
    },
    {
      value: [
        addTime({
          hours: 15,
          date: new Date(nextTuesday.getTime()),
          minutes: 15,
        }),
        addTime({
          hours: 15,
          date: new Date(nextFriday.getTime()),
          minutes: 15,
        }),
      ],
      label: 'Tue/Fri (3:15pm - 4:15pm) EST',
    },
    {
      value: [
        addTime({
          date: new Date(nextMonday.getTime()),
          hours: 10,
          minutes: 15,
        }),
        addTime({
          date: new Date(nextTuesday.getTime()),
          hours: 10,
          minutes: 15,
        }),
        addTime({
          date: new Date(nextThursday.getTime()),
          hours: 10,
          minutes: 15,
        }),
        addTime({
          date: new Date(nextFriday.getTime()),
          hours: 10,
          minutes: 15,
        }),
      ],
      label: 'Mon/Tue/Thu/Fri (10:15am - 11:15am) EST',
    },

    {
      value: [
        addTime({
          date: new Date(nextMonday.getTime()),
          hours: 11,
          minutes: 15,
        }),
        addTime({
          date: new Date(nextTuesday.getTime()),
          hours: 11,
          minutes: 15,
        }),
        addTime({
          date: new Date(nextThursday.getTime()),
          hours: 11,
          minutes: 15,
        }),
        addTime({
          date: new Date(nextFriday.getTime()),
          hours: 11,
          minutes: 15,
        }),
      ],
      label: 'Mon/Tue/Thu/Fri (11:15am - 12:15pm) EST',
    },
    {
      value: [
        addTime({
          date: new Date(nextMonday.getTime()),
          hours: 12,
          minutes: 45,
        }),
        addTime({
          date: new Date(nextTuesday.getTime()),
          hours: 12,
          minutes: 45,
        }),
        addTime({
          date: new Date(nextThursday.getTime()),
          hours: 12,
          minutes: 45,
        }),
        addTime({
          date: new Date(nextFriday.getTime()),
          hours: 12,
          minutes: 45,
        }),
      ],
      label: 'Mon/Tue/Thu/Fri (12:45pm - 1:45pm) EST',
    },
    {
      value: [
        addTime({
          date: new Date(nextMonday.getTime()),
          hours: 13,
          minutes: 45,
        }),
        addTime({
          date: new Date(nextTuesday.getTime()),
          hours: 13,
          minutes: 45,
        }),
        addTime({
          date: new Date(nextThursday.getTime()),
          hours: 13,
          minutes: 45,
        }),
        addTime({
          date: new Date(nextFriday.getTime()),
          hours: 13,
          minutes: 45,
        }),
      ],
      label: 'Mon/Tue/Thu/Fri (1:45pm - 2:45pm) EST',
    },
  ]
}

const zeroPad = (num) => {
  const n = Math.abs(num)
  if (String(n).length === 2) return String(n)
  const zeros = Math.max(0, 2 - Math.floor(n).toString().length)
  let zeroString = Math.pow(10, zeros).toString().slice(1)
  if (num < 0) {
    zeroString = '-' + zeroString
  }

  return zeroString + n
}

export const parseDuration = (duration) => {
  if (!duration) return '00:00:00'
  if (typeof duration === 'object') {
    const { hours = '00', minutes = '00' } = duration
    return [zeroPad(hours), zeroPad(minutes), '00'].join(':')
  }
  if (typeof duration === 'string') {
    const [hours = '00', minutes = '00'] = duration.split(':')
    return [zeroPad(hours), zeroPad(minutes), '00'].join(':')
  }
}

export const isInvalidSession = (session, minDate, maxDate) => {
  return (
    new Date(session.timestamptz) > maxDate ||
    new Date(session.timestamptz) < minDate
  )
}

// TODO - this can be optimized by iterating over only the specific days of the week we're looking for
export const generateSessionTimes = ({ sessionTimes, cycleStart, desiredSessions }) => {
  const sessions = []
  const pushedSessions = []
  let day = cycleStart
  const infoOfSessionTimes = sessionTimes.map((sessionTimes) => ({ weekDay: sessionTimes.getDay(), hour: sessionTimes.getHours(), minute: sessionTimes.getMinutes() }))

  const cycleStartSessionTime = infoOfSessionTimes.find(({ weekDay }) => weekDay === day.getDay())
  if (day.getHours() > cycleStartSessionTime.hour || (day.getHours() === cycleStartSessionTime.hour && day.getMinutes() > cycleStartSessionTime.minute)) {
    day = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1)
  }
  
  while (sessions.length < desiredSessions) {
    const isSameWeekDay = infoOfSessionTimes.some(({ weekDay }) => weekDay === day.getDay())
    if (isSameWeekDay) {
      sessions.push(day)
    }
    day = new Date(day.getTime() + 86400000)
  }

  const parsedSessions = sessions.map((session) => {
    const { hour, minute } = infoOfSessionTimes.find(({ weekDay }) => weekDay === session.getDay())
    session.setHours(hour)
    session.setMinutes(minute)
    return session
  }).sort((a, b) => a - b)

  return {
    generatedSessionsTimesArray: parsedSessions,
    pushedSessions,
  }
}

export function hasDST(date = new Date()) {
  const january = new Date(
    date.getFullYear(),
    0,
    1,
  ).getTimezoneOffset()
  const july = new Date(
    date.getFullYear(),
    6,
    1,
  ).getTimezoneOffset()

  return Math.max(january, july) !== date.getTimezoneOffset()
}
