import axios from 'axios'

export const action = async ({ request, params }) => {
  const formData = await request.formData()
  const jsonData = Object.fromEntries(formData)

  if (jsonData.mode === 'add') {
    try {
      await axios.post(`/expeditions/${params.id}/registrations-with-waitlist`, {
        student_id: jsonData.student_id,
        section_id: jsonData.section_id,
        notes: jsonData.notes,
        should_ignore_schedule_conflicts: jsonData.should_ignore_schedule_conflicts === 'true',
      })
      return { ok: true }
    } catch (error) {
      return { ok: false, error }
    }
  }

  if (jsonData.mode === 'ban') {
    try {
      await axios.delete(`/expeditions/${params.id}/registrations?student_id=${jsonData.student_id}`)
      return { ok: true }
    } catch (error) {
      return { ok: false, error }
    }
  }
}
