/* eslint-disable react/prop-types */
import useQuery from '@hooks/useQuery'
import { useParams, Outlet, useLoaderData } from 'react-router-dom'
import React, { useState } from 'react'
import useSWR from 'swr'
import _groupBy from 'lodash/groupBy'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleRight,
  faAngleDown,
  faArrowUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons'
import useCycles from '@hooks/useCycles'
import useClipboard from '@hooks/useClipboard'
import useToggle from '@hooks/useToggle'
import Popover from '@pages/pace-and-progress/popover'
import LearnAboutProgressCard from '@pages/pace-and-progress/learn-about-progress-card'
import CycleSlideover from '@pages/pace-and-progress/cycle/cycle-slideover'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'
import TargetDataVisual from '@pages/pace-and-progress/cycle/targetDataVisual'
import TaskSubmissionStatus from '@components/facilitate/feedback/task-submission-status'
import { PortalModal } from '@components/modal'

// https://github.com/soraschools/home/pull/1115/commits/467f51faceecb1813e4fdd87e7dccc638caa9f41
// removed partially working expedition section, can use this commit to get stuff back

export default function ProgressCycleRoute() {
  const loaderData = useLoaderData()
  const userRole = loaderData.userRole
  const themes = loaderData.themes
  const progress = loaderData.progress
  const schoolStage = loaderData.schoolStage

  const [_status] = useClipboard()
  const { student_id } = useParams()
  const { cycles, currentCycle } = useCycles()
  const [queryParams, _setParams] = useQuery()
  let { cycle_id = currentCycle?.id } = queryParams

  const selectedCycle = cycles?.find((cycle) => cycle.id === Number(cycle_id))

  const {
    totalTasks,
    submittedTasks,
    assessmentFlagged,
    missedTasks,
    taskSubmittedOnTime,
  } = progress.tasks

  const sessionMetrics = progress.attendance.sessions

  const [sliderOpen, setSliderOpen] = useState(false)
  const [sliderData, setSliderData] = useState({
    title: '',
    counter: 0,
    units: [],
    abilities: [],
  })
  const [missedTasksModalOpen, setMissedTasksModalOpen] = useToggle(false)
  const [flaggedTasksModalOpen, setFlaggedTasksModalOpen] = useToggle(false)

  const toggleSlider = () => {
    if (sliderOpen) {
      setSliderOpen(false)
      setTimeout(() => {
        setSliderOpen(true)
      }, 500)
    } else {
      setSliderOpen(true)
    }
  }

  const changeSliderData = ({ title = '', counter = 0, units = [], abilities = [] }) => {
    setSliderData({
      title,
      counter,
      units,
      abilities,
    })
  }

  return (
    <div className="pb-12">
      {/* outlet for task slider */}
      <Outlet />
      <h1 className="group-[.flutter]/flutter:hidden font-bold text-4xl whitesPaceInCyclePage-nowrap my-4">
        Cycle Pace & Progress
      </h1>
      <LearnAboutProgressCard type={schoolStage} page="cycle" />
      <h2 className='text-xl font-bold mb-4 mt-6'>Units and abilities</h2>
      <TargetDataVisual />

      <div className="mt-4 grid grid-cols-1 lg:grid-cols-5 gap-2">
        <StatCard
          title="TOTAL UNIT DEMONSTRATIONS"
          num={progress.units.total.demos}
          denom={progress.units.total.expected}
          onClick={(progress.units.total.demos && progress.units.total.demos_items.length) ? () => {
            changeSliderData({
              title: 'TOTAL UNITS DEMONSTRATIONS',
              counter: progress.units.total.demos,
              units: progress.units.total.demos_items,
            })
            toggleSlider()
          } : undefined}
        >
          <div className="w-[220px] text-sm">
            In <strong>{selectedCycle?.title}</strong>, you have registered for
            Expeditions that will help you demonstrate{' '}
            <strong>{progress.units.total.expected} Units.</strong>
          </div>
        </StatCard>
        <StatCard
          title="NEW UNITS DEMONSTRATIONS"
          num={progress.units.new?.demos}
          denom={progress.units.new?.expected}
          onClick={(progress.units.new?.demos && progress.units.new?.demos_items.length) ? () => {
            changeSliderData({
              title: 'NEW UNITS DEMONSTRATIONS',
              counter: progress.units.new.demos,
              units: progress.units.new.demos_items,
            })
            toggleSlider()
          } : undefined}
        >
          <div className="w-[220px] text-sm">
            In <strong>{selectedCycle?.title}</strong>, you have registered for
            Expeditions that will help you demonstrate{' '}
            <strong>{progress.units.new?.expected} new Units.</strong>
          </div>
        </StatCard>
        <StatCard
          title="TOTAL ABILITIES OPPORTUNITIES"
          num={progress.abilities.total.demos}
          denom={progress.abilities.total.expected}
          onClick={(progress.abilities.total.demos && progress.abilities.total.demos_items.length) ? () => {
            changeSliderData({
              title: 'TOTAL ABILITIES OPPORTUNITIES',
              counter: progress.abilities.total.demos,
              abilities: progress.abilities.total.demos_items,
            })
            toggleSlider()
          } : undefined}
        >
          <div className="w-[220px] text-sm">
            In <strong>{selectedCycle?.title}</strong>, you have registered for
            Expeditions that will help you demonstrate{' '}
            <strong>{progress.abilities.total.expected} Abilities.</strong>
          </div>
        </StatCard>
        <StatCard
          title="NEW ABILITIES OPPORTUNITIES"
          num={progress.abilities.new?.demos}
          denom={progress.abilities.new?.expected}
          onClick={(progress.abilities.new?.demos && progress.abilities.new?.demos_items.length) ? () => {
            changeSliderData({
              title: 'NEW ABILITIES DEMONSTRATIONS',
              counter: progress.abilities.new.demos,
              abilities: progress.abilities.new.demos_items
            })
            toggleSlider()
          } : undefined}
        >
          <div className="w-[220px] text-sm">
            In <strong>{selectedCycle?.title}</strong>, you have registered for
            Expeditions that will help you demonstrate{' '}
            <strong>{progress.abilities.new?.expected} new Abilities.</strong>
          </div>
        </StatCard>
        <div className="rounded-lg p-4 flex flex-col justify-between bg-white dark:bg-gray-95 border-gray-30 dark:border-gray-90 border">
          <div className="flex flex-row">
            <span className="text-sm block flex-1 grow">
              EXPEDITIONS ATTENDANCE
            </span>
            <Popover className="ml-1">
              <div className="w-[220px] text-sm">
                <p>% of attended sessions in expeditions.</p>
                <p>
                  House events are <strong>not</strong> considered.
                </p>
              </div>
            </Popover>
          </div>
          {sessionMetrics.presentPercentage === 0 ? (
            <p className="text-sm">
              No attendance data collected yet for this cycle.
            </p>
          ) : (
            <h2 className="text-sm items-end">
              <span className="text-4xl font-bold leading-[3rem]">
                {sessionMetrics.presentPercentage}%{` `}
              </span>
              <span className="text-sm text-gray-70 dark:text-gray-50">Attended</span>
            </h2>
          )}
        </div>
      </div>

      <h2 className='text-xl font-bold mb-4 mt-8'>Tasks overview</h2>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-2">
        <StatCard
          color="blue"
          title="SUBMITTED"
          num={submittedTasks}
          denom={totalTasks}
        >
          <div className="w-[120px] text-sm">
            <p>
              Number of tasks you've submitted so far, out of the total {totalTasks} of tasks
              for all experiences this cycle. Read-only tasks are&nbsp;
              <strong>not</strong> considered.
            </p>
          </div>
        </StatCard>
        <StatCard
          color="red"
          title="MISSED"
          num={missedTasks}
          onClick={setMissedTasksModalOpen}
        >
          <div className="w-[220px] text-sm">
            <p>
              Number of submissions that were late by 2 or more days, or totally
              missed.
            </p>
          </div>
        </StatCard>
        <StatCard
          color="red"
          title="FLAGGED"
          num={assessmentFlagged}
          onClick={setFlaggedTasksModalOpen}
        >
          <div className="w-[220px] text-sm">
            <p>
              Number of submissions that can't be assessed by an expert. Considers
              the latest submission of each task.
            </p>
          </div>
        </StatCard>
        <StatCard
          title="ON TIME"
          num={`${taskSubmittedOnTime || 0}`}
          denom={totalTasks || 0}
        >
          <div className="w-[220px] text-sm">
            <p>Total amount of <strong>original</strong> submissions submitted by the original task due date.</p>
            <p>Resubmissions are <strong>not</strong> considered.</p>
          </div>
        </StatCard>
      </div>

      <h2 className='text-xl font-bold mb-4 mt-6'>Experiences</h2>
      <ul className="space-y-2">
        {themes.map((theme, idx) => {
          return <ThemeItemNewStatuses
            key={`theme-${idx}`}
            idx={idx}
            theme={theme}
            student_id={student_id}
            userIsStudent={userRole === 'student' || userRole === 'guardian'}
          />
        })}
      </ul>

      <CycleSlideover
        sliderOpen={sliderOpen}
        sliderData={sliderData}
        selectedCycle={selectedCycle}
        setSliderOpen={setSliderOpen}
      />
      <TaskSubmissionStatusModal
        cycle_id={cycle_id}
        student_id={student_id}
        onClose={setMissedTasksModalOpen}
        isOpen={missedTasksModalOpen}
        status='missed'
      />
      <TaskSubmissionStatusModal
        cycle_id={cycle_id}
        student_id={student_id}
        onClose={setFlaggedTasksModalOpen}
        isOpen={flaggedTasksModalOpen}
        status='flagged'
      />
    </div>
  )
}

const formatDueDate = (value) => {
  const date = new Date(value)
  return `${date.toLocaleString([], {
    month: 'long',
  })} ${date.toLocaleString([], {
    day: 'numeric',
  })}, ${date.toLocaleString([], { year: 'numeric' })}`
}

const ThemeItemNewStatuses = ({ theme, student_id, userIsStudent, idx }) => {
  const navigate = useNavigateWithCycle()
  const [open, setOpen] = useState(false)

  const getOnClickEvent = (task) => {
    if (task.type === 'practice') return () => navigate(`tasks/${task.id}/submission/practice`)
    if (!userIsStudent) return () => navigate(`/employee/facilitate/assessment/${theme.id}/task/${task.id}/${student_id}`)
    if (task.submission_status) return () => navigate(`tasks/${task.id}/submission/review`)
    return () => navigate(`tasks/${task.id}/submission/review/draft`)
  }

  const getButton = (task) => {
    return <button
      disabled={task.is_readonly}
      className={`rounded-full px-4 py-2 text-lg hover:bg-gray-10
        ${task.is_readonly ? 'cursor-not-allowed text-gray-50' : ''}
      `}
      title={task.is_readonly ? 'Read-only task' : null}
      onClick={getOnClickEvent(task)}
    >
      <FontAwesomeIcon size="xs" icon={faArrowUpRightFromSquare} />
    </button >
  }

  return (
    <li className="border border-gray-30 dark:border-gray-90 rounded-lg p-4 bg-white dark:bg-gray-95">
      <div className="flex flex-row gap-4">
        <div className="bg-green-50 min-h-[80px] w-[100px] min-w-[100px] flex items-center justify-center rounded">
          <span className="font-bold text-5xl text-white rounded-lg">
            {idx < 9 && 0}
            {idx + 1}
          </span>
        </div>
        <div className='flex flex-col lg:flex-row grow items-start lg:items-center gap-2'>
          <div className="grow flex-1">
            <h3 className="text-lg font-semibold">{theme.title}</h3>
            <div className="flex items-center text-sm space-x-1">
              <p>{theme.employees.map(e => e.name).join(', ')}</p>
              {theme.learning_block_title && <p>| {theme.learning_block_title}</p>}
            </div>
          </div>
          <div className='flex items-center w-full lg:w-auto justify-between lg:justify-end space-x-3'>
            {theme.abilities?.length > 0 && (
              <div className="rounded-full bg-gray-10 dark:bg-gray-100 px-4 py-1">
                {theme.abilities.length} abilities
              </div>
            )}
            {theme.units?.length > 0 && (
              <div className="rounded-full bg-gray-10 dark:bg-gray-100 px-4 py-1">
                {theme.units.length} units
              </div>
            )}
            <div className="rounded-full bg-gray-10 dark:bg-gray-100 px-4 py-1">
              {theme.tasks.length} tasks
            </div>
            <button className="rounded-full w-10 h-10 flex items-center justify-center cursor-pointer hover:bg-gray-10" onClick={() => setOpen(!open)}>
              <FontAwesomeIcon icon={open ? faAngleDown : faAngleRight} />
            </button>
          </div>
        </div>
      </div>
      {open && (
        <div className="divide-y divide-gray-30 dark:divide-gray-90">
          <div className="flex flex-col py-4 space-y-3">
            {theme.units?.length > 0 && (
              <div className="flex items-center space-x-2 flex-wrap">
                <p className="font-bold">Units:</p>
                {theme.units.map((u, i) => (
                  <p
                    key={`${i}_${u.title}`}
                    className={`px-2 py-1 border-2 rounded-lg text-sm bg-white ${u.is_new ? 'border-green-20' : ''}`}
                    title={u.is_new ? "You're improving this new unit!" : "You've already completed this unit!"}
                  >
                    {u.title}
                  </p>
                ))}
              </div>
            )}
            {theme.abilities?.length > 0 && (
              <div className="flex items-center space-x-2 flex-wrap">
                <p className="font-bold">Abilities:</p>
                {theme.abilities.map((a, i) => (
                  <p
                    key={`${i}_${a.title}`}
                    className={`px-2 py-1 border-2 rounded-lg text-sm bg-white ${a.is_new ? 'border-green-20' : ''}`}
                    title={a.is_new ? "You're improving this new ability!" : "You've already completed this ability!"}
                  >
                    {a.title}
                  </p>
                ))}
              </div>
            )}
          </div>
          {theme.tasks.map((task) => {
            return (
              <div
                key={`${theme.id}-${task.id}-idx`}
                className="pl-4 grid grid-cols-18 flex-col py-3 lg:flex-row lg:items-center"
              >
                <p className="col-span-11 font-bold text-lg">{task.title}</p>
                <div className="col-span-2 flex flex-col">
                  <span className="text-sm">Due date</span>
                  <span>{formatDueDate(task.due_at)}</span>
                </div>
                <div className="col-span-4 flex justify-end">
                  <TaskSubmissionStatus
                    task={task}
                    temporal_status={task.temporal_status}
                    submission_status={task.submission_status}
                  />
                </div>
                <div className='col-span-1 group-[.flutter]/flutter:hidden flex justify-end'>
                  {getButton(task)}
                </div>
              </div>
            )
          })}
        </div>
      )}
    </li>
  )
}

const StatCard = ({ onClick, title, num, denom, children, color = 'white' }) => {
  return (
    <div
      className={`rounded-lg px-4 py-6 flex flex-col justify-between ${onClick && 'cursor-pointer'
        } bg-white dark:bg-gray-95 border border-gray-30 dark:border-gray-90`}
      onClick={onClick}
    >
      <div className="flex flex-row mb-2 ">
        <span className="text-sm block flex-1 grow">{title}</span>
        <Popover className="ml-1">{children}</Popover>
      </div>
      <div className="h-full flex flex-row items-end">
        <span className={`text-5xl font-bold leading-[2.5rem] ${color === 'red' && Number(num) > 0 && 'text-danger-40'}`}>
          {num}
        </span>
        {!!denom && (
          <span className="text-lg text-gray-70 dark:text-gray-50">
            / {denom}
          </span>
        )}
      </div>
    </div>
  )
}

const TaskSubmissionStatusModal = ({ cycle_id, student_id, status, isOpen, onClose }) => {
  const { data } = useSWR(() => `/pages/student/${student_id}/progress/cycle/tasks/${status}?cycle_id=${cycle_id}`)
  const groupedTasks = _groupBy(data?.results || [], t => t.theme_title)
  return (
    <PortalModal
      isOpen={isOpen}
      onClose={onClose}
      className="w-[50rem]"
    >
      <div className="p-8 space-y-8">
        <h1 className="font-bold text-xl capitalize">{status} tasks</h1>
        <div className="flex flex-col space-y-5">
          {Object.entries(groupedTasks).map(([theme_title, tasks], i) => (
            <div className="flex flex-col space-y-1" key={i}>
              <p className="font-bold">{theme_title}</p>
              <ul className="list-disc">
                {tasks.map((t, j) => <li key={j} className="ml-4">{t.task_title}</li>)}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </PortalModal>
  )
}
TaskSubmissionStatusModal.propTypes = {
  cycle_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  student_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.oneOf(['missed', 'flagged']),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
