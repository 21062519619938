import React, { useEffect } from 'react'
import { toast } from 'sonner'
import SoraLink from '@components/link'
import { useLoaderData } from 'react-router-dom'
import axios from 'axios'
import { TextField } from '@designsystem'
import { Button, Logo } from '@design-system'
import useQuery from '@hooks/useQuery'

export const loader = async ({ request }) => {
  const url = new URL(request.url)
  const error = url.searchParams.get('error')
  if (error) url.searchParams.delete('error')
  window.history.replaceState({}, document.title, url.href)
  if (error?.length > 0) return { error }

  try {
    const { data } = await axios.get('/auth/validate', { baseURL: '/' })
    if (data.valid) window.location.href = '/'
  } catch (err) {
    return { error: err.message }
  }

  return {}
}

export default function GuardianSigninRoute() {
  const loaderData = useLoaderData()

  const [query] = useQuery()

  useEffect(() => {
    if (loaderData?.error) toast.error(loaderData.error)
  }, [loaderData])

  return (
    <div className="h-full flex items-center justify-center">
      <form action="/auth/signin" method="post" className="max-w-md w-full sm:border border-gray-30 dark:border-gray-85 md:rounded-xl flex flex-col items-center gap-8 p-8 bg-white dark:bg-gray-100 sm:dark:bg-gray-95">
        <a href="https://soraschools.com/" target="_blank" rel="noreferrer">
          <Logo data-cy="login-page-sora-logo" size="lg" />
        </a>
        <p>Log in to continue as a Guardian</p>
        <div className="w-full flex flex-col gap-3">
          <input type="hidden" name="return_to" value={query?.return_to ? encodeURI(query.return_to) : ''} />
          <TextField autoFocus placeholder="Email" data-cy="login-email-input" type="email" name="email" />
          <TextField placeholder="Password" data-cy="login-password-input" type="password" name="password" />
          <SoraLink className="text-sm hover:underline font-bold" to="/password/recover">Forgot or don&apos;t have your password?</SoraLink>
        </div>
        <Button type="submit" data-cy="login-btn" className="w-full">Continue</Button>
        <Button asChild variant="ghost">
          <SoraLink to="/signin">Back to student or faculty sign in</SoraLink>
        </Button>
      </form>
    </div>
  )
}
