import React from 'react'
import PropTypes from 'prop-types'
import { SectionList } from './section-list'
import { CycleSectionItem } from './cycle-section-item'

export const CycleSectionList = ({ cycles, templateId }) => {

  return (
    cycles.map(cycle => {
      const sectionsAmount = cycle.data.length
      
      return (
        <React.Fragment key={`expert_${templateId}_cycle_${cycle.cycle_id}`}>
          <tr className="bg-white dark:bg-black">
            <CycleSectionItem cycle={cycle} templateId={templateId} />
          </tr>
          {sectionsAmount > 1 &&
            <SectionList sections={cycle.data.slice(1)} templateId={templateId} cycleId={cycle.cycle_id} />
          }
        </React.Fragment>
      )

    })
  )
}
CycleSectionList.propTypes = {
  cycles: PropTypes.array,
  templateId: PropTypes.number,
}
