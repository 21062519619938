import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import Avatar from '@components/avatar'
import SoraLink from '@components/link'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const StudentDetail = ({ student_id, theme_id }) => {
  const { data } = useSWR(student_id && theme_id ? `/students/${student_id}/theme/${theme_id}/assessment` : null)

  if (!data || !data?.student) return <></>

  return (
    <div className='flex flex-row'>
      <div className='flex-1 flex flex-row items-center'>
        <Avatar name={data.student.name} imgUrl={data.student.profile_image_url} className='w-10 h-10' classNameImg='rounded-lg' />
        <div className='flex flex-col px-2'>
          <SoraLink className='flex items-center group space-x-1' to={`/employee/students/${student_id}/contact`} target='_blank'>
            <p className='group-hover:font-semibold text-blue-80'>{data.student.name}</p>
            <FontAwesomeIcon className='hidden group-hover:flex' size="sm" icon={faExternalLinkAlt} />
          </SoraLink>
          <div className='flex flex-row gap-2'>
            <p className='whitespace-nowrap'>Grade {data.student.grade}</p>
            <p>{data.student.pronouns?.length > 0 ? `(${data.student.pronouns})` : ''}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
StudentDetail.propTypes = {
  student_id: PropTypes.string,
  theme_id: PropTypes.string,
  showMetrics: PropTypes.bool
}

export default StudentDetail
