
import React from 'react'
import PropTypes from 'prop-types'

const SessionsInfo = ({ sessions }) => {
  const sessionsInfo = Array.from(new Set(sessions.map(session =>
    new Date(session.timestamptz).toLocaleTimeString('en-US', {
      weekday: 'short',
      hour: '2-digit',
      minute: '2-digit',
    })
  ))).join(' / ')
  return <p title={sessionsInfo} className="line-clamp-2">
    {sessionsInfo}
  </p>
}

SessionsInfo.propTypes = {
  sessions: PropTypes.array
}

export default SessionsInfo
