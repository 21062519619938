import * as React from 'react'
import axios from 'axios'
import { Tab } from '@headlessui/react'
import { Outlet, useLoaderData, useLocation, useParams } from 'react-router-dom'
import { cn, Typography } from '@design-system'
import SoraLink from '@components/link'

type LoaderData = Awaited<ReturnType<typeof loader>>

async function loader({ params }) {
  const experienceId = params?.experienceId
  const experienceRepresentativeParam = experienceId === 'edit' ? '' : experienceId + '/'

  const { data } = await axios.get(`/backoffice/experiences/${experienceRepresentativeParam}title`)
  return {
    experienceTitle: data.title
  }
}

function Element() {
  const params = useParams()
  const location = useLocation()
  const id = params?.id
  const experienceId = params?.experienceId
  const [pageTitle, setPageTitle] = React.useState('')

  const loaderData = useLoaderData() as LoaderData
  const experienceTitle = loaderData?.experienceTitle

  React.useEffect(() => {
    setPageTitle(experienceTitle)
  }, [])

  React.useEffect(() => {
    const titleInput = document.querySelector('input[name="title"]') as HTMLInputElement

    const handleTitleChange = (e) => {
      if (e)
        setPageTitle(e.target.value)
    }

    titleInput?.addEventListener('input', handleTitleChange)
    return () => {
      titleInput?.removeEventListener('input', handleTitleChange)
    }
  }, [])

  const experienceRepresentativeParam = experienceId || id
  return experienceRepresentativeParam ? (
    <Tab.Group>
      <div className="mr-72 -mt-10 bg-gray-2 dark:bg-gray-100 right-0 left-60 z-10 w-screen h-28 flex flex-col gap-3 fixed">
        <Tab.List className="flex space-x-10 border-b border-b-gray-30 w-full pt-4">
          <Tab
            as={SoraLink}
            to={`./${experienceRepresentativeParam}/edit`}
            className={`py-2 ${location.pathname.includes('edit') ? 'border-b-2 font-bold border-b-accent ' : ''}`}
          >
            Edit
          </Tab>
          <Tab
            as={SoraLink}
            to={`./${experienceRepresentativeParam}/tasks`}
            className={`py-2 ${location.pathname.includes('tasks') ? 'border-b-2 font-bold border-b-accent ' : ''}`}
          >
            Tasks
          </Tab>
          <Tab
            as={SoraLink}
            to={`./${experienceRepresentativeParam}/registrations`}
            className={`py-2 ${location.pathname.includes('registrations') ? 'border-b-2 font-bold border-b-accent ' : ''}`}
          >
            Registrations
          </Tab>
          <Tab
            as={SoraLink}
            to={`./${experienceRepresentativeParam}/attendances`}
            className={`py-2 ${location.pathname.includes('attendances') ? 'border-b-2 font-bold border-b-accent ' : ''}`}
          >
            Attendances
          </Tab>
        </Tab.List>
        <div className="max-w-[80vw]">
          <Typography variant="heading-4" weight="bold" className="text-ellipsis line-clamp-1">{pageTitle}</Typography>
        </div>
      </div>
      <Tab.Panels>
        <Tab.Panel className="mt-16">
          <Outlet />
        </Tab.Panel>
        <Tab.Panel className="mx-36 mt-20">
          <Outlet />
        </Tab.Panel>
        <Tab.Panel className="mx-36 mt-20">
          <Outlet />
        </Tab.Panel>
        <Tab.Panel className="mx-36 mt-20">
          <Outlet />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  )
    : <Outlet />
}

export const NEW_ExperienceRoute = {
  Element: Element,
  loader: loader,
}
