import React from 'react'
import PropTypes from 'prop-types'
import { SectionItem } from './section-item'

export const SectionList = ({ sections, cycleId, templateId }) => {
  return (
    sections.map(section => {
      return (
        <tr key={`expert_${templateId}_cycle_${cycleId}_section__${section.learning_block_id}_${section.employee_id}`} className='bg-white dark:bg-black'>
          <SectionItem section={section} cycleId={cycleId} templateId={templateId} />
        </tr>
      )
    })
  )
}



SectionList.propTypes = {
  sections: PropTypes.array,
  cycleId: PropTypes.number,
  templateId: PropTypes.number,
}
