import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useRevalidator } from 'react-router'
import Select from 'react-select'
import { toast } from 'sonner'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import isWithinInterval from 'date-fns/isWithinInterval'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCancel,
  faChevronDown,
  faChevronRight,
  faPen,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons'

import useFocusFormField from '@hooks/useFocusFormField'
import { useConfirmBeforeLeave } from '@hooks/useConfirmBeforeLeave'
import useToggle from '@hooks/useToggle'
import useClipboard from '@hooks/useClipboard'
import useConfirmModal from '@hooks/useConfirmModal'
import useTaskRelations from '@hooks/useTaskRelations'

import Checkbox from '@components/forms/checkbox'
import Input from '@components/forms/input'
import DateTimePicker from '@components/forms/date-time-picker'
import SlateTextarea from '@components/forms/slate-textarea'

import EditableTaskAbility from '@pages/expedition/tasks/editable-task-ability'
import EditableTaskUnitLevel from '@pages/expedition/tasks/editable-task-unit-level'

import getBrowserTimezoneAbbreviation from '@utils/getTimezoneAbbreviation'
import { Button } from '@designsystem'

function buildDefaultValues(task) {
  return {
    id: task.id,
    title: task.title,
    due_at: task.due_at,
    is_draft: !!task.is_draft,
    is_readonly: !!task.is_readonly,
    is_practice: !!task.is_practice,
    is_finalreview: task.type === 'final',
    body: task.body,
    theme_id: task.theme_id,
    theme_session_id: task.theme_session_id
  }
}

function EditableTask({
  task,
  sessions = [],
  minDate,
  maxDate,
  allThemeAbilitiesV2,
  allThemeUnits,
  isAdding,
  onRemove,
  onUpdate,
  onCreate,
  onCancel,
  isPlaylabEnabled,
}) {
  const { taskRelations } = useTaskRelations({ task_id: task.id })
  const revalidator = useRevalidator()
  const mutate = revalidator.revalidate

  const [isSubmitting, setIsSubmitting] = useState(false)
  const confirm = useConfirmModal()
  const [, copy] = useClipboard()


  const [isEditing, toggleIsEditing] = useToggle(isAdding)
  useConfirmBeforeLeave(isEditing)
  const focusFormField = useFocusFormField(null)

  const { register, watch, errors, control, handleSubmit, reset } = useForm({
    defaultValues: buildDefaultValues(task, sessions),
  })

  const onDeleteTask = async (e) => {
    e.stopPropagation()
    if (
      await confirm(
        'After deleting the task will be lost forever! Are you sure about this? '
      )
    ) {
      if (
        (Number(taskRelations.submissions) === 0 && Number(taskRelations.comments) === 0) ||
        await confirm(
          `The are ${taskRelations.submissions} student submissions ${Number(taskRelations.comments) ? `and ${taskRelations.comments} comments` : ''} in here! Are you REALLY sure about this?`
        )
      ) {
        if (
          Number(taskRelations.assessments) === 0 ||
          await confirm(
            `${taskRelations.assessments} of them are even reviewed! Are you REALLY REEEAAALLY sure about this?`
          )
        ) {
          await onRemove(task.id)
        }
        return false
      }
    }
  }

  const handleSubmitFn = async (data) => {
    setIsSubmitting(true)
    try {
      data.theme_session_id = data?.theme_session_id?.value || data?.theme_session_id || null
      data.type = data.is_practice ? 'practice' : data.is_finalreview ? 'final' : 'default'
      if (data.id) {
        await onUpdate(data)
      } else {
        await onCreate(data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  const dueAt = watch('due_at')
  const sessionId = watch('theme_session_id')
  const hasRelatedSession = !!sessionId
  const isRelatedToLastSession = hasRelatedSession && sessions.at(-1)?.id === sessionId

  const availableUnits = allThemeUnits.filter((unit) => {
    return !task.units.some((taskUnits) => unit.id === taskUnits.unit_id)
  })

  const availableAbilitiesV2 = allThemeAbilitiesV2.filter((ab) => {
    return !task.abilities_v2.some((tab) => ab.id === tab.ability_id)
  })

  const isValidTask = (!dueAt && hasRelatedSession) || (dueAt && isWithinInterval(new Date(dueAt), {
    start: new Date(minDate),
    end: new Date((new Date(maxDate)).getTime() + 1000),
  }))

  const sessionOpts = [{ value: null, label: 'None' }, ...sessions.map(s => ({ value: s.id, label: s.title }))]

  return (
    <div className="text-xs py-2 bg-white shadow-md rounded opacity-100">
      <form
        className="grid grid-cols-18 gap-2 space-y-3 lg:space-y-0 items-center px-6"
        onSubmit={handleSubmit(handleSubmitFn)}
      >
        {task.id && <input type="hidden" name="id" ref={register} />}
        <input type="hidden" name="theme_id" ref={register} />
        <FontAwesomeIcon
          icon={isEditing ? faChevronDown : faChevronRight}
          className="col-span-1 hidden lg:block text-lg lg:text-xl cursor-pointer text-gray-70"
          onClick={toggleIsEditing}
        />
        <div className="col-span-4 uppercase text-gray-70 font-bold">
          <Input
            ref={register({ required: true })}
            placeholder="Type here the `title`"
            rows="1"
            name="title"
            id={`${task.id}-title`}
            className="text-xs font-bold bg-white pl-1"
            hasError={!!errors.title}
            autoFocus={false}
            readOnly={!isEditing}
            readOnlyNode={<span>{task.title}</span>}
            maxLength={70}
          />
        </div>
        <div className="col-span-7 grid grid-cols-14 items-center uppercase text-gray-70 lg:text-center font-bold">
          <span className="col-span-3 text-xs" title="Related tasks will have their due date linked to the start of the next session after the selected one">related to:</span>
          <div className="col-span-5 text-left">
            <Controller
              name="theme_session_id"
              control={control}
              render={({ value, onChange }) => {
                if (!isEditing) {
                  if (hasRelatedSession) {
                    return (
                      <p className="truncate">
                        {sessionOpts.find(opt => Number(opt.value) === Number(value)).label}
                      </p>
                    )
                  }
                  return <span>None</span>
                }
                return (
                  <Select
                    styles={{
                      control: s => ({ ...s, minHeight: '30px', height: '30px' }),
                      indicatorsContainer: s => ({ ...s, height: '30px' }),
                      menu: s => ({ ...s, zIndex: 9999 }),
                    }}
                    isSearchable={false}
                    options={sessionOpts}
                    onChange={(v) => onChange(v.value)}
                    value={sessionOpts.find(c => c.value === value)}
                  />
                )
              }}
            />
          </div>
          <span className="col-span-2 text-xs">due at:</span>
          <div className="col-span-4 text-left">
            <Controller
              name='due_at'
              control={control}
              render={({ onChange, value, ...field }) => {
                const dueAt = `${value ? format(new Date(value), 'M/d/yy p') : ''} (${getBrowserTimezoneAbbreviation()})`
                if (!isEditing || hasRelatedSession) {
                  return (
                    <p className="truncate" title={dueAt}>
                      {hasRelatedSession ? (isRelatedToLastSession ? 'Before Last Session' : 'Before Next Session') : dueAt}
                    </p>
                  )
                }
                return (
                  <DateTimePicker
                    {...field}
                    required
                    readOnlyNode={dueAt}
                    readOnly={!isEditing}
                    placeholder='Select a date...'
                    id={`${task.id}-due_at`}
                    value={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    customInput={(
                      <div className="flex flex-col relative">
                        <input
                          className="border rounded-lg px-2 py-1"
                          value={dueAt}>
                        </input>
                        {!isValidTask && <p className={`text-xxs absolute -bottom-3 text-danger-50`}>Invalid date</p>}
                      </div>
                    )}
                    filterTime={(time) => {
                      const selectedDate = new Date(time)
                      return (
                        maxDate.getTime() >= selectedDate.getTime() &&
                        minDate.getTime() <= selectedDate.getTime()
                      )
                    }}
                    onChange={(e) => {
                      if (e.target.value) onChange(e.target.value)
                    }}
                    onClickReadOnly={() => {
                      toggleIsEditing()
                      focusFormField({ focusedField: `${task.id}-due_at` })
                    }}
                    timeIntervals={15}
                    injectTimes={[
                      setHours(setMinutes(new Date(value), 59), 18),
                      setHours(setMinutes(new Date(value), 59), 19),
                      setHours(setMinutes(new Date(value), 59), 20),
                      setHours(setMinutes(new Date(value), 59), 21),
                      setHours(setMinutes(new Date(value), 59), 22),
                      setHours(setMinutes(new Date(value), 59), 23),
                    ]}
                  />
                )
              }}
            />
          </div>
        </div>
        <div className="col-span-4 flex flex-row justify-center items-center gap-3">
          <div className="flex flex-col items-center content-center uppercase text-gray-70 space-y-1">
            <span
              className="text-gray-70 font-bold"
              title="Tasks marked as drafts do not appear in the student's task list!"
            >
              Draft
            </span>
            <Checkbox
              ref={register}
              name="is_draft"
              id={`${task.id}-is_draft}`}
              disabled={!isEditing}
              className="rounded disabled:opacity-30"
            />
          </div>
          <div className="flex flex-col items-center content-center uppercase text-gray-70 space-y-1">
            <span className="text-gray-70 font-bold">
              Read Only
            </span>
            <Checkbox
              ref={register}
              name="is_readonly"
              id={`${task.id}-is_readonly`}
              disabled={!isEditing}
              className="rounded disabled:opacity-30"
            />
          </div>
          <div className="flex flex-col items-center content-center uppercase text-gray-70 space-y-1">
            <span className="text-gray-70 font-bold">
              Final Review
            </span>
            <Checkbox
              ref={register}
              name="is_finalreview"
              id={`${task.id}-is_finalreview`}
              disabled={!isEditing}
              className="rounded disabled:opacity-30"
            />
          </div>
          {isPlaylabEnabled && (
            <div className="flex flex-col items-center content-center uppercase text-gray-70 space-y-1 lg:space-x-0">
              <span
                className="text-gray-70 font-bold"
                title="This task won't expect a submission, but will allow the student to discuss the subject with an AI!"
              >
                Practice
              </span>
              <Checkbox
                ref={register}
                name="is_practice"
                id={`${task.id}-is_practice`}
                disabled={!isEditing}
                className="rounded disabled:opacity-30"
              />
            </div>
          )}
        </div>
        <div className={`col-span-2 text-gray-70 text-lg lg:text-center flex flex-row gap-3 justify-end items-center ${!task.id ? 'invisible' : ''}`}>
          <div
            className='rounded-full text-sm hover:bg-blue-60 hover:text-white text-blue-60 px-3 py-2 cursor-pointer'
            onClick={() => {
              toast.success('Task URL copied!')
              copy(`/tasks?task_id=${task.id}`)
            }}
          >
            <FontAwesomeIcon icon={faCopy} />
          </div>
          {!isAdding && (
            <div
              className="rounded-full text-sm hover:bg-danger-60 hover:text-white text-danger-60 px-3 py-2 cursor-pointer"
              onClick={onDeleteTask}
            >
              <FontAwesomeIcon icon={faTrash} />
            </div>
          )}
          <div
            className='rounded-full text-sm px-3 py-2 hover:bg-gray-70 hover:text-white cursor-pointer'
            onClick={(e) => {
              if (isEditing) {
                toggleIsEditing()
                onCancel()
              } else {
                toggleIsEditing(!isEditing)
              }
            }}
          >
            <FontAwesomeIcon icon={isEditing ? faCancel : faPen} />
          </div>
        </div>
        {isEditing && (
          <div className="col-span-18">
            <h3 className="text-xs uppercase py-2 text-gray-70 hidden lg:block font-bold">
              Description
            </h3>
            <Controller
              as={SlateTextarea}
              id={`task:${task.id}-body`}
              name="body"
              value={task.body}
              className="text-xs -z-1"
              placeholder="Type here your task description"
              control={control}
            />
          </div>
        )}
        {isEditing && (
          <div className="col-span-18 flex gap-4 py-4 justify-end">
            {!isSubmitting && (
              <Button
                variant="outlined"
                color="cta"
                size="sm"
                onClick={(e) => {
                  reset()
                  toggleIsEditing()
                  onCancel()
                }}
              >
                Cancel
              </Button>
            )}
            <Button
              color="cta"
              size="sm"
              type="submit"
              disabled={!isValidTask || isSubmitting}
            >
              {isSubmitting ? 'Submitting, please wait...' : 'Save'}
            </Button>
          </div>
        )}
      </form>
      {isEditing && !isAdding && (
        <div className="grid grid-cols-2 gap-12 p-6">
          <div>
            <h3 className="uppercase text-xs font-bold py-1">Units</h3>
            <ul className="space-y-2 mb-2">
              {task.units
                .map((unit) => {
                  return (
                    <li key={unit.id}>
                      <EditableTaskUnitLevel
                        initialUnitLevel={unit}
                        availableUnits={availableUnits}
                        afterSubmit={mutate}
                      />
                    </li>
                  )
                })}
            </ul>
            <EditableTaskUnitLevel
              initialUnitLevel={{ task_id: task.id }}
              availableUnits={availableUnits}
              afterSubmit={mutate}
            />
          </div>
          <div>
            <h3 className="uppercase text-xs font-bold py-1">Abilities</h3>
            <ul className="space-y-2 mb-2">
              {task.abilities_v2
                .map((abl) => {
                  return (
                    <li key={abl.id} className="mb-2">
                      <EditableTaskAbility
                        initialTaskAbility={abl}
                        availableAbilities={availableAbilitiesV2}
                        afterSubmit={mutate}
                      />
                    </li>
                  )
                })}
            </ul>
            <EditableTaskAbility
              initialTaskAbility={{ task_id: task.id }}
              availableAbilities={availableAbilitiesV2}
              afterSubmit={mutate}
            />
          </div>
        </div>
      )}
    </div>
  )
}

EditableTask.defaultProps = {
  isAdding: false,
  onCancel: () => null,
}

EditableTask.propTypes = {
  task: PropTypes.shape(() => ({
    id: PropTypes.number,
    title: PropTypes.string,
    due_at: PropTypes.string,
    is_draft: PropTypes.bool,
  })).isRequired,
  events: PropTypes.array,
  sessions: PropTypes.array,
  isAdding: PropTypes.bool,
  isPlaylabEnabled: PropTypes.bool,
  toggleIsDraft: PropTypes.func,
  expedition: PropTypes.object,
  onRemove: PropTypes.func,
  onUpdate: PropTypes.func,
  onCreate: PropTypes.func,
  onCancel: PropTypes.func,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  allThemeAbilitiesV2: PropTypes.any,
  allThemeUnits: PropTypes.any,
}

export default EditableTask
