import axios from 'axios'

export async function loader({ params }) {
  if (params.task_submission_id) {
    const { data: taskSubmissionDetail } = await axios(`/task-submissions/${params.task_submission_id}/detail`)
    return { taskSubmissionDetail }
  }

  return { taskSubmissionDetail: null }
}
