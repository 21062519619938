import React from 'react'
import PropTypes from 'prop-types'
import { UnitList } from './unit-list'
import { SectionList } from './section-list'
import { CycleSectionList } from './cycle-section-list'
import { CycleSectionItem } from './cycle-section-item'

export const ExpeditionList = ({ expeditions }) => {
  return (
    expeditions.map(expedition => {
      const cyclesAmount = expedition.cycles?.length || 0
      const sectionsAmount = expedition.cycles?.reduce((acc, curr) => acc + (curr.cycle_id ? curr.data?.length : 1), 0) || 0
      const firstCycle = expedition.cycles?.[0]
      return (
        <React.Fragment key={`expedition_${expedition.id}`}>
          <tr>
            <td colSpan={6}>&nbsp;</td>
          </tr>
          <tr className='bg-white dark:bg-black'>
            <td rowSpan={sectionsAmount} className='p-2 align-top'>
              <span className='font-bold'>{expedition.title}</span>
            </td>
            <td rowSpan={sectionsAmount} className='p-2 align-top'>
              <UnitList units={expedition.units} />
            </td>
            <CycleSectionItem cycle={firstCycle} templateId={expedition.id} />
          </tr>
          {(firstCycle?.data.length > 1) &&
            <SectionList sections={firstCycle.data.slice(1)} templateId={expedition.id} cycleId={firstCycle.cycle_id} />
          }
          {(cyclesAmount > 1) && 
            <CycleSectionList cycles={expedition.cycles.slice(1)} templateId={expedition.id} />
          }
        </React.Fragment>
      )
    })
  )
}
ExpeditionList.propTypes = {
  expeditions: PropTypes.array
}
