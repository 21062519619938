import React from 'react';
import { faX, faCheck, faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function SessionStatusItem({ present, excused }: ISessionStatusItem) {
  const status = excused ? 'excused' : present ? 'present' : 'absent'
  const iconProps = {
    present: { className: 'text-green-40 border-green-40', icon: faCheck },
    excused: { className: 'text-blue-40 border-blue-40', icon: faMinus },
    absent: { className: 'text-danger-50 border-danger-50', icon: faX },
  }[status]
  return (
    <FontAwesomeIcon
      className={`align-middle border-[1.5px] rounded-lg w-3 h-3 p-1 ${iconProps.className}`}
      icon={iconProps.icon}
      data-testid={`${status}-attendance-icon`}
    />
  )
}

export function AggregatedExpeditionAttendances({ data }: IAggregatedExpeditionAttendances) {
  if (!Array.isArray(data)) return null
  return (
    <div className="flex flex-col space-y-4 text-sm pt-2 pb-8">
      <div className="flex items-center font-bold">
        <SessionStatusItem present={true} />
        <p className="ml-1 mr-5">Present</p>
        <SessionStatusItem present={false} />
        <p className="ml-1 mr-5">Absent</p>
        <SessionStatusItem excused={true} />
        <p className="ml-1">Excused</p>
      </div>
      <div className="w-full text-sm bg-white border rounded-lg overflow-x-auto">
        <table className="w-full" data-testid="assess-student-attendances-table">
          <thead>
            <tr className="border-b">
              <th className="text-left pl-3">Student Name</th>
              {data[0].sessions.map(s => (
                <th className="text-center p-3" key={s.timestampz}>
                  {new Date(s.timestampz).toLocaleTimeString('en-US', {
                    weekday: 'short',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                  })}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map(student => (
              <tr key={student.name} className="border-b last:border-none" data-testid="student-attendance-row">
                <td className="pl-3">{student.name}</td>
                {student.sessions.map(session => (
                  <td className="py-3 text-center" key={`${student.name}-${session.timestampz}`}>
                    <SessionStatusItem present={session.present} excused={session.excused} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export interface IAggregatedExpeditionAttendances {
  data: {
    name: string;
    sessions: {
      timestampz: string;
      present: boolean;
      excused: boolean;
    }[]
  }[]
}

interface ISessionStatusItem {
  present?: boolean;
  excused?: boolean;
}
