import React from 'react'
import PropTypes from 'prop-types'
import { Combobox, useFetcher } from '@design-system'
import { useSearchParams } from 'react-router-dom'

export const EmployeeSelector = ({ employees = [], defaultValue }) => {
  const fetcher = useFetcher()

  const [_, setSearchParams] = useSearchParams()

  const isLoading = fetcher.state !== 'idle'

  const handleChange = (event) => {
    event.preventDefault()
    const urlParams = new URLSearchParams(document.location.search)
    const currentParams = Object.fromEntries(urlParams)
    setSearchParams({ ...currentParams, employeeId: event.target.value })

    fetcher.submit({ ...currentParams, employeeId: event.target.value }, { method: 'get' })
  }

  return (
    <fetcher.Form>
      <Combobox name='employeeId' placeholder='Select an employee' search={<Combobox.Search placeholder="Search a employee" />} defaultValue={defaultValue} loading={isLoading} onChange={handleChange}>
        {employees.map(employee => (
          <Combobox.Item key={`${employee.id}`} value={employee.id} >
            {employee.name} - {employee.email}
          </Combobox.Item>
        ))}
      </Combobox>
    </fetcher.Form>
  )
}
EmployeeSelector.propTypes = {
  employees: PropTypes.array,
  defaultValue: PropTypes.number,
}
