import React from 'react'
import PropTypes from 'prop-types'

export const AbilityList = ({ abilities }) => {
  return (
    <div className='flex flex-row flex-wrap gap-3'>
      {abilities.map(ability => (
        <div key={ability} className='rounded py-1 px-2 bg-blue-10 font-bold text-xs whitespace-nowrap'>{ability}</div>
      ))}
    </div>
  )
}
AbilityList.propTypes = {
  abilities: PropTypes.array,
}
