import React, { useState } from 'react'
import axios from 'axios'

import useForm from '@hooks/useForm'
import { toast } from 'sonner'
import SoraLink from '@components/link'
import useCurrentUser from '@hooks/useCurrentUser'
import { Navigate } from 'react-router-dom'
import { TextField } from '@designsystem'
import { Button, Logo } from '@design-system'

const RecoverPassword = () => {

  const [formValues, handleChange] = useForm({
    email: ''
  })


  const [submitting, setSubmitting] = useState(false)

  const currentUser = useCurrentUser()

  if (currentUser)
    return <Navigate to="/" />

  const handleClick = () => {
    setSubmitting(true)
    axios({
      method: 'patch',
      url: '/password',
      data: {
        email: formValues.email
      },
      baseURL: '/api'
    }).then(response => {
      toast.success(response.data.message)
    }).catch(error => {
      toast.error(error.message)
    }).finally(() => {
      setSubmitting(false)
    })
  }

  return (
    <div className="h-full flex items-center justify-center">
      <div className="max-w-md w-full sm:border border-gray-30 dark:border-gray-85 md:rounded-xl flex flex-col items-center gap-8 p-8 bg-white dark:bg-gray-100 sm:dark:bg-gray-95">
        <a href="https://soraschools.com/" target="_blank" rel="noreferrer">
          <Logo data-cy="login-page-sora-logo" size="lg" />
        </a>
        <p className="text-center">Continue to receive a secure link to create a new password</p>
        <div className="w-full flex flex-col gap-3">
          <TextField autoFocus placeholder="Email" data-cy="login-email-input" type="email" name="email" value={formValues.email} onChange={handleChange} />
        </div>
        <Button loading={submitting} onClick={handleClick} data-cy="login-btn" className="w-full">Send link</Button>
        <SoraLink className="hover:underline font-bold" to="/guardian/signin">Back to guardian sign in</SoraLink>
      </div>
    </div>
  )
}
export default RecoverPassword
