import React from "react"
import PropTypes from "prop-types"
import { Button, Icon, useFetcher } from "@design-system"

export const EditableLearningBlock = ({ expertId, cycleId, learningBlockId, title, readOnly = false }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle' && 
    fetcher.formData?.get('cycle_id') === String(cycleId) && 
    fetcher.formData?.get('expert_id') === String(expertId) && 
    fetcher.formData?.get('current_id') === String(learningBlockId)
  
  const handleCancel = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('_action', 'cancel')
    fetcher.submit(formData, { method: 'post' })
  }  

  return (
    <div className="relative w-fit">
      {fetcher.data?.learning_blocks &&
        <div className='bg-white p-2 border drop-shadow absolute top-0 left-full ml-2 z-30'>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2 items-center">
              <Button variant='ghost' size="xs" onClick={handleCancel}>
                <Icon name="arrow-left" size='xs' />
              </Button>
              <span className="text-sm">Current: {title}</span>
            </div>
            <fetcher.Form method="post" className="flex flex-row space-x-2">
              <input type="hidden" name="_action" value="replace_learning_block" />
              <input type="hidden" name="expert_id" value={expertId} />
              <input type="hidden" name="cycle_id" value={cycleId} />
              <input type="hidden" name="current_id" value={learningBlockId} />
              <select name="new_id">
                {fetcher.data?.learning_blocks?.map(lb => (
                  <option key={lb.id} value={lb.id}>{lb.title}</option>
                ))}
              </select>
              <Button type="submit" loading={isLoading} disabled={isLoading}>Replace</Button>
            </fetcher.Form>
          </div>
        </div>
      }
      <fetcher.Form method="get" className="flex flex-row items-center gap-2">
        <input type="hidden" name="_action" value="get_available_learning_blocks" />
        <input type="hidden" name="expert_id" value={expertId} />
        <input type="hidden" name="cycle_id" value={cycleId} />
        <span>{title}</span>
        {!readOnly &&
          <Button type="submit" variant='ghost' loading={isLoading} disabled={isLoading} className='h-6 w-6 p-1'>
            <Icon name="edit-2" size="xs" />
          </Button>
        }
      </fetcher.Form>
    </div>
  )
}
EditableLearningBlock.propTypes = {
  expertId: PropTypes.number,
  cycleId: PropTypes.number,
  learningBlockId: PropTypes.number,
  title: PropTypes.string,
  readOnly: PropTypes.bool,
}
