import React, { Fragment, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { renderToString } from 'react-dom/server'
import Select from 'react-select'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import '@pages/attendance/override.css'
import enUsLocale from 'date-fns/locale/en-US'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faPlus, faInfo } from '@fortawesome/free-solid-svg-icons'
import joinFirstLastName from '@utils/joinFirstLastName'
import useCycles from '@hooks/useCycles'
import { Popover, Transition } from '@headlessui/react'
import { getCategoryColor } from '@utils/categoryColors'
import capitalize from '@utils/capitalize'
import endOfDay from 'date-fns/endOfDay'
import sub from 'date-fns/sub'
import getDaysInMonth from 'date-fns/getDaysInMonth'
import isBefore from 'date-fns/isBefore'
import isSameMonth from 'date-fns/isSameMonth'
import startOfDay from 'date-fns/startOfDay'
import add from 'date-fns/add'
import isAfter from 'date-fns/isAfter'
import isSameDay from 'date-fns/isSameDay'
import intervalToDuration from 'date-fns/intervalToDuration'
import { Button } from '@designsystem'
import SoraIcon, { soraCheck } from '@components/sora-icon'

const locales = {
  'en-US': enUsLocale,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const getCalendarDaysElements = async (ref, tries = 0) => {
  if (tries > 100) return []

  const els = ref.querySelectorAll('.rbc-day-bg')
  if (els.length) return els

  await new Promise(resolve => setTimeout(resolve, 50))
  return await getCalendarDaysElements(ref, ++tries)
}

const getTotalAttendancesForDay = (attendances, ymd) => {
  if (!attendances?.[ymd]) return {}

  if (attendances[ymd].checkpoint && attendances[ymd].standup) { // HouseEvents
    return {
      totalExcused: [...attendances[ymd].checkpoint.excused, ...attendances[ymd].standup.excused].length,
      totalAbsent: [...attendances[ymd].checkpoint.absent, ...attendances[ymd].standup.absent].length,
      totalTardy: [...attendances[ymd].checkpoint.tardy, ...attendances[ymd].standup.tardy].length,
    }
  }

  return { // ThemeSessions
    totalExcused: attendances[ymd].excused.length,
    totalAbsent: attendances[ymd].absent.length,
    totalTardy: attendances[ymd].tardy.length,
  }
}

const CalendarDayAttendance = ({ numAtt, nameAtt }) => (
  <div className="px-1 flex items-center space-x-1">
    <div className={`h-2 rounded-full ${nameAtt === 'tardy' ? 'bg-yellow-30' : nameAtt === 'absent' ? 'bg-danger-30' : 'bg-cyan-30'} w-2`} />
    <span className="text-xs">{numAtt}</span>
    <span className="hidden lg:inline text-xs">{nameAtt}</span>
  </div>
)
CalendarDayAttendance.propTypes = {
  numAtt: PropTypes.number,
  nameAtt: PropTypes.string,
}

const ExcusesCalendar = ({ onSelectSlot, onSelectEvent, onClickAddAbsence, excuses, attendances, sessions, isLoading = false, showAbsenceBtn = true, studentCounselor = null, defaultDate = new Date() }) => {
  const [events, setEvents] = useState([])
  const [selectedDate, setSelectedDate] = useState(new Date(defaultDate))
  const [calendarView, setCalendarView] = useState('month')
  const { minDate, maxDate } = useCycles()
  const bigCalendarRef = useRef()

  const isLeftArrowDisabled = () => {
    const days = calendarView === 'month' ? getDaysInMonth(selectedDate) : 1
    const subbed = endOfDay(sub(selectedDate, { days }))
    return isBefore(subbed, new Date(minDate)) && !(calendarView === 'month' && isSameMonth(subbed, new Date(minDate)))
  }

  const isRightArrowDisabled = () => {
    const days = calendarView === 'month' ? getDaysInMonth(selectedDate) : 1
    const added = startOfDay(add(selectedDate, { days }))
    return isAfter(added, new Date(maxDate)) && !(calendarView === 'month' && isSameMonth(added, new Date(maxDate)))
  }

  const nextDate = () => {
    setSelectedDate(prev => add(
      prev,
      calendarView === 'month' ? { months: 1 } : { days: 1 }
    ))
  }
  const previousDate = () => {
    setSelectedDate(prev => sub(
      prev,
      calendarView === 'month' ? { months: 1 } : { days: 1 }
    ))
  }

  const changeView = ({ value }) => {
    setCalendarView(value)
  }

  useEffect(() => {
    const dailyExcuses = (excuses || [])
      .reduce((acc, exc) => {
        const ymd = format(new Date(exc.start_at), 'yyyy-MM-dd')
        if (!acc[ymd]) {
          acc[ymd] = []
        }
        acc[ymd].push(exc)
        return acc
      }, {})

    const getExcuseProps = (exc) => ({
      start: new Date(exc.start_at),
      end: new Date(exc.end_at),
      id: exc.id,
      recurring_id: exc.recurring_info?.id,
      recurring_days: exc.recurring_info?.days,
      recurring_period_start: new Date(exc.recurring_info?.start_at),
      recurring_period_end: new Date(exc.recurring_info?.end_at),
      reason: exc.reason || '',
      approved: exc.approved || false,
      student_id: exc.student_id,
      additional_comments: exc.additional_comments || '',
      title: exc?.title ? exc.title : exc.student.name + ' - ' + (exc.reason || 'Absence'),
    })

    const calendarExcuses = Object.values(dailyExcuses)
      .flatMap(excusesInDay => {
        if (calendarView === 'month' && excusesInDay.length > 1) {
          const [firstExcuse] = excusesInDay
          return {
            start: new Date(firstExcuse.start_at),
            end: new Date(firstExcuse.end_at),
            title: excusesInDay.length + ' excuses',
            excuses: excusesInDay.map(getExcuseProps),
            event_type: 'excuse'
          }
        }
        if (calendarView === 'day' || excusesInDay.length === 1) {
          return excusesInDay.map(exc => {
            const duration = intervalToDuration({ start: new Date(exc.start_at), end: new Date(exc.end_at) })
            const allDay = duration.days || duration.hours >= 23
            return {
              ...getExcuseProps(exc),
              event_type: 'excuse',
              allDay
            }
          })
        }
      })

    const calendarSessions = (sessions && calendarView === 'day' ? sessions : [])
      .map(sess => ({
        start: new Date(sess.session_start || sess.start_at),
        end: new Date(sess.session_end || sess.end_at),
        title: sess.theme_title || capitalize(sess.kind),
        session_type: sess.type || '',
        event_type: 'session',
      }))

    setEvents([...calendarExcuses, ...calendarSessions])
  }, [excuses, sessions, calendarView])

  useEffect(() => {
    if (calendarView === 'month' && bigCalendarRef.current) {
      (async () => {
        const dayBg = await getCalendarDaysElements(bigCalendarRef.current)
        dayBg.forEach(el => {
          const { classList } = el
          const daySelector = [...classList].find(cl => cl.includes('day:'))
          if (daySelector) {
            const [, ymd] = daySelector.split(':')
            const { totalTardy = 0, totalAbsent = 0, totalExcused = 0 } = getTotalAttendancesForDay(attendances, ymd)
            el.innerHTML = renderToString(
              <div className="flex flex-col w-full h-full relative">
                {
                  (!attendances?.[ymd] || totalAbsent || totalExcused)
                    ? null
                    : <div className="flex items-center h-full justify-center text-green-30"> <SoraIcon icon={soraCheck} className='text-lg' /></div>
                }
                <div className="absolute bottom-0 flex flex-col px-2 py-2">
                  {
                    totalTardy ?
                      <CalendarDayAttendance numAtt={totalTardy} nameAtt="tardy" />
                      : ''
                  }
                  {
                    totalAbsent ?
                      <CalendarDayAttendance numAtt={totalAbsent} nameAtt="absent" />
                      : ''
                  }
                  {
                    totalExcused ?
                      <CalendarDayAttendance numAtt={totalExcused} nameAtt="excused" />
                      : ''
                  }
                </div>
              </div>
            )
          }
          const startNextCycle = [...classList].find(cl => cl.includes('startNextCycle'))
          const endPreviousCycle = [...classList].find(cl => cl.includes('endPreviousCycle'))
          if (startNextCycle || endPreviousCycle) {
            el.innerHTML = renderToString(
              <div className="flex items-center w-full h-full justify-center text-center text-sm text-gray-70">
                {
                  startNextCycle ?
                    <>Start of the <br />next cycle</> :
                    endPreviousCycle ?
                      <>End of the <br />previous cycle</> : ''
                }
              </div>
            )
          }
        })
      })()
    }
  }, [selectedDate, attendances, calendarView])

  return (
    <>
      <div className="flex mb-3">
        <div className="flex space-x-1 items-center">
          <Button
            type="button"
            size="sm"
            variant="nude"
            onClick={previousDate}
            disabled={isLeftArrowDisabled()}
            startIcon={faChevronLeft}
            tooltip="Previous"
          />
          <Button
            type="button"
            size="sm"
            variant="nude"
            onClick={nextDate}
            disabled={isRightArrowDisabled()}
            startIcon={faChevronRight}
            tooltip="Next"
          />
          <span className="hidden md:inline font-bold text-2xl text-gray-70">
            {format(selectedDate, calendarView === 'month' ? 'MMMM yyyy' : 'MMMM yyyy, do')}
          </span>
          <span className="md:hidden font-bold text-base text-gray-70">
            {format(selectedDate, calendarView === 'month' ? 'MMM yy' : 'MMM yy, do')}
          </span>
        </div>
        <div className="flex items-center ml-auto space-x-3">
          {
            showAbsenceBtn &&
            <>
              <Popover className="relative">
                {() => (
                  <>
                    <Popover.Button>
                      <div className="border-2 h-5 rounded-full text-xs px-1.5 text-blue-60 border-blue-60 items-center flex">
                        <FontAwesomeIcon size="xs" icon={faInfo} />
                      </div>
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 max-w-sm px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 lg:max-w-3xl">
                        <div className="overflow-hidden min-w-96 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative bg-white p-7">
                            <div className="text-gray-70 text-lg font-bold mb-5">Will your student be absent for more than 5 days?</div>
                            <div className="flex flex-col space-y-5">
                              <div className="word-wrap text-sm">
                                If you know your student will be absent for more than 5 days, their advisor will need to approve this length of absence.
                                Once added, you will receive a follow-up email from your student’s advisor.
                              </div>
                              <div className="word-wrap text-sm">
                                Alternatively, or if you have an important / emergency situation that you would like to discuss, please contact directly your student’s advisor, {studentCounselor?.email ? joinFirstLastName(studentCounselor) + '.' : ''}
                              </div>
                              <span className="italic text-sm">{studentCounselor?.email ? studentCounselor.email : 'Please select a student to see their counselor information'}</span>
                              {
                                studentCounselor?.email ?
                                  <button onClick={() => { location.href = `mailto:${studentCounselor.email}` }} className="flex justify-start max-w-min whitespace-nowrap px-3 py-1 bg-blue-60 rounded-lg">
                                    <span className="text-white text-sm">SEND MESSAGE</span>
                                  </button>
                                  : null
                              }
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              <button onClick={onClickAddAbsence} className="flex items-center bg-blue-60 rounded-full px-2 py-1 md:px-3 md:py-1.5 text-white text-xs">
                <FontAwesomeIcon icon={faPlus} />
                <div className="ml-1 lg:text-sm hidden md:inline">ADD ABSENCE</div>
              </button>
            </>
          }
          <Select
            styles={{
              control: (base) => ({
                ...base,
                border: '1px solid #DADCE0',
              }),
            }}
            className="min-w-20 md:min-w-24 z-5 text-sm"
            defaultValue={{ value: 'month', label: 'Month' }}
            isSearchable={false}
            onChange={changeView}
            options={[
              { value: 'month', label: 'Month' },
              { value: 'day', label: 'Day' }
            ]}
          />
        </div>
      </div>
      <div className={`${calendarView === 'day' && 'overflow-y-scroll'} max-h-150 ${isLoading ? 'opacity-40' : ''}`} ref={bigCalendarRef}>
        <Calendar
          showMultiDayTimes
          selectable
          date={selectedDate}
          onNavigate={console.log}
          localizer={localizer}
          events={events}
          view={calendarView}
          defaultView="month"
          formats={{ weekdayFormat: (date) => localizer.format(date, 'iii') }}
          onView={console.log}
          views={['month', 'day']}
          step={15}
          onSelectEvent={onSelectEvent}
          onSelectSlot={onSelectSlot}
          dayLayoutAlgorithm="no-overlap"
          toolbar={false}
          eventPropGetter={(event) => {
            if (event.event_type === 'session' && event.session_type) return { style: { backgroundColor: getCategoryColor(event.session_type).hex } }
          }}
          dayPropGetter={(date) => {
            const shouldGrayBg = isBefore(endOfDay(date), new Date(minDate)) || isAfter(startOfDay(date), new Date(maxDate))
            const isStartNextCycle = isSameDay(date, add(new Date(maxDate), { days: 1 }))
            const isEndPreviousCycle = isSameDay(date, sub(new Date(minDate), { days: 1 }))
            return {
              className: `day:${format(date, 'yyyy-MM-dd')} ${shouldGrayBg ? 'bg-gray-20' : ''} ${isStartNextCycle ? 'startNextCycle' : ''} ${isEndPreviousCycle ? 'endPreviousCycle' : ''}`
            }
          }}
        />
      </div>
    </>
  )
}
ExcusesCalendar.propTypes = {
  onSelectSlot: PropTypes.func,
  onSelectEvent: PropTypes.func,
  onClickAddAbsence: PropTypes.func,
  excuses: PropTypes.array,
  attendances: PropTypes.object,
  sessions: PropTypes.array,
  isLoading: PropTypes.bool,
  showAbsenceBtn: PropTypes.bool,
  studentCounselor: PropTypes.object,
  defaultDate: PropTypes.object,
}

export default ExcusesCalendar
