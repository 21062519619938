import React from 'react'
import PropTypes from 'prop-types'
import AssessmentPickedItem from '@components/facilitate/assessment/assessment-picked-item'

const AssessmentPickedList = ({
  list = [],
  title,
  type,
  onRemoveItem,
  onChangeItem,
  onSaveItem,
  onShowRubricsItem,
  onShowPresetCommentItem,
  disabled,
  compressed,
  allowAddFeedback,
  presetComments,
  refreshPresetComments,
}) => {
  if (list.length === 0) return null

  const items = list.sort((a, b) => String(a.topic_title + a.title).localeCompare(String(b.topic_title + b.title))).map(item => {
    return (
      <AssessmentPickedItem
        key={`final:${type}:selected:${item.id}`}
        type={type}
        item={item}
        onRemove={onRemoveItem(type, item)}
        onChange={onChangeItem}
        onSave={onSaveItem}
        onShowRubrics={onShowRubricsItem}
        onShowPresetComment={onShowPresetCommentItem}
        disabled={disabled}
        compressed={compressed}
        allowAddFeedback={allowAddFeedback}
        presetComments={presetComments}
        refreshPresetComments={refreshPresetComments}
      />
    )
  })

  return (
    <>
      <h1 className='font-bold text-lg py-2'>{title}</h1>
      <ul className='flex flex-col'>{items}</ul>
    </>
  )
}
AssessmentPickedList.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  type: PropTypes.string,
  onRemoveItem: PropTypes.func,
  onChangeItem: PropTypes.func,
  onSaveItem: PropTypes.func,
  onShowRubricsItem: PropTypes.func,
  onShowPresetCommentItem: PropTypes.func,
  disabled: PropTypes.bool,
  compressed: PropTypes.bool,
  allowAddFeedback: PropTypes.bool,
  presetComments: PropTypes.array,
  refreshPresetComments: PropTypes.func,
}

export default AssessmentPickedList