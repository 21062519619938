/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import useCurrentUser from '@hooks/useCurrentUser'
import { useSWRConfig } from 'swr'
import format from 'date-fns/format'
import SlateTextarea, { RenderedSlate } from '@components/forms/slate-textarea'
import _isEqual from 'lodash/isEqual'
import { tzAgnosticDate } from '@utils/tzAgnosticDate'

import useHouses from '@hooks/useHouses'
import useHouseAdvisors from '@hooks/useHouseAdvisors'
import useStudentEmergencyContact from '@hooks/useStudentEmergencyContact'

import axios from 'axios'
import { toast } from 'sonner'
import useUserPermissions from '@hooks/useUserPermissions'
import { Button, TextField } from '@designsystem'
import { useLoaderData, useParams, useRevalidator } from 'react-router'
import useTimelineEvents from '@hooks/useTimelineEvents'
import HouseCell from '@pages/students/house-cell'
import { PortalModal } from '@components/modal'
import StudentProfileTimeline from '@pages/student-profile/timeline'

export default function EmployeeStudentContactRoute() {
  const { student, programs, developmentalStages, canEditStudentRecoveryEmail, isStudentUpdateProtoEnabled, canSuspendStudent, isSuspensionFeatureEnabled } = useLoaderData()
  const guardians = student.guardian

  const revalidator = useRevalidator()
  const mutateStudent = revalidator.revalidate

  const currentUser = useCurrentUser()
  const { student_id } = useParams()
  const { mutate: mutateTimeline } = useTimelineEvents(student_id)

  const { houses } = useHouses()
  const { advisors } = useHouseAdvisors()

  const { emergencyContact, mutate: mutateEmergencyContact } = useStudentEmergencyContact(student_id)

  const [guardian1 = {}, guardian2 = {}] = guardians.sort((a, b) => b.is_primary - a.is_primary)

  const { hasPermission } = useUserPermissions()
  const canEditStudentData = hasPermission('edit_student_data')
  const canEditStudentHouse = hasPermission('edit_student_house')

  const getDiff = (origO1, origO2) => {
    const initialValue = {}
    const o1 = { ...origO1 }
    const o2 = { ...origO2 }
    o1.profile_information = Array.isArray(o1.profile_information) ? o1.profile_information : JSON.parse(o1.profile_information || "[]")
    o2.profile_information = Array.isArray(o2.profile_information) ? o2.profile_information : JSON.parse(o2.profile_information || "[]")
    if (!_isEqual(o1.profile_information, o2.profile_information)) {
      o1.profile_information = (o2.profile_information || [])
        .map(e => e.children)
        .flat()
        .map(e => e?.text)
        .filter(Boolean)
        .join(' ')
    }
    return Object.keys(o2).reduce((diff, key) => {
      if (o1[key] == o2[key]) return diff
      if (_isEqual(o1[key], o2[key])) return diff
      if (!o1[key] && !o2[key]) return diff

      if (
        [
          'birth_date',
          'ms_start_date',
          'ms_graduation_date',
          'start_date',
          'hs_start_date',
          'hs_graduation_date',
          'graduation_date',
        ].includes(key)
      ) {
        let b1 = format(tzAgnosticDate(o1[key]), 'yyyy-MM-dd')
        if (b1 === o2[key]) return diff
      }

      // check expected graduation
      if (['expected_graduation', 'ms_expected_graduation', 'hs_expected_graduation'].includes(key)) {
        if (new Date(o1[key]).getFullYear() == o2[key]) return diff
      }

      if (['ms_start_date', 'hs_start_date'].includes(key)) {
        if (new Date(o1[key]).getFullYear() == o2[key]) return diff
      }

      if (key === 'house_id') {
        if (o1[key] == o2[key]) return diff

        const title = houses.find((h) => h.id == o2[key])?.title
        return {
          ...diff,
          house_title: title,
          [key]: o2[key],
        }
      }

      if (
        [
          'is_primary',
          'is_vice_president',
          'is_president',
          'is_foundation',
        ].includes(key)
      ) {
        if (o1[key] === (o2[key] === 'on')) return diff
      }

      return {
        ...diff,
        [key]: o2[key],
      }
    }, initialValue)
  }

  const studentOnSubmit = async (e) => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const formProps = Object.fromEntries(formData)

    if (e.target.id === 'learning-details-form')
      formProps.is_foundation = formProps.is_foundation === 'on' ? 'on' : 'off'

    if (formProps.profile_information) {
      formProps.profile_information = Array.isArray(formProps.profile_information) ? formProps.profile_information : JSON.parse(formProps.profile_information)
    }

    const url = isStudentUpdateProtoEnabled ? `/api/students/${student.id}/v2` : `/api/students/${student.id}`
    await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...formProps,
      }),
    })

    await mutateStudent()

    const diff = getDiff(student, formProps)
    if (Object.keys(diff).length !== 0) {
      await fetch(`/api/timeline-event/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          student_id: student.id,
          type: 'Information Update',
          employee_id: currentUser.id,
          context: {
            diff,
          },
        }),
      })
      await mutateTimeline()
    }
  }

  const recoveryEmailOnSubmit = async (e, setIsEditing) => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const formProps = Object.fromEntries(formData)

    const response = await fetch(`/api/students/${student.id}/recovery-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...formProps,
      }),
    })

    if (response.status === 200) {
      toast.success('Successfully updated student recovery e-mail')
      e.target.reset()
      setIsEditing(false)
    } else {
      toast.error('Failed to update student recovery e-mail')
    }
  }

  const guardianOnSubmit = async (e, id) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const formProps = Object.fromEntries(formData)
    const student_id = student.id
    const employee_id = currentUser.id

    let { is_primary, relationship, ...guardian } = formProps
    is_primary = is_primary === 'on'

    try {
      if (id) { // Update
        await axios.patch(`guardians/${id}`, guardian)
        await axios.patch(`student-guardians/${student_id}/${id}`, { is_primary, relationship })
      } else { // Create
        await axios.post('guardians', { ...guardian, relationship, is_primary, student_id })
      }
    } catch (e) {
      toast.error('Could not save Guardian! ' + (e?.response?.data?.message || ''))
      return
    }

    const diff = getDiff(guardians.find((g) => g.id === id) || {}, formProps)
    if (Object.keys(diff).length !== 0) {
      await axios.post(`timeline-event/create`, {
        student_id,
        employee_id,
        type: 'Information Update (Guardian)',
        context: { diff },
      })
    }

    mutateStudent()
    await mutateTimeline()
  }

  const emergencyContactOnSubmit = async (e, id) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const formProps = Object.fromEntries(formData)
    const diff = getDiff(emergencyContact, formProps)
    try {
      if (id) { // Update
        await fetch(`/api/students/${student.id}/emergencyContact/${id}`, {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formProps)
        })
      } else { // Create
        await fetch(`/api/students/${student.id}/emergencyContact`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formProps)
        })
      }

      if (Object.keys(diff).length) {
        await fetch(`/api/timeline-event/create`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            student_id: student.id,
            type: 'Information Update (Emergency Contact)',
            employee_id: currentUser.id,
            context: { diff },
          }),
        })
      }

      await mutateTimeline()
      await mutateEmergencyContact()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className='flex flex-col md:flex-row gap-4'>
      <section className="md:overflow-y-auto mb-8 pr-3 space-y-8">
        {isSuspensionFeatureEnabled ? <SuspensionSection studentId={student.id} studentSuspension={student?.suspension?.current} suspensionHistory={student?.suspension?.history} canSuspendStudent={canSuspendStudent} /> : null}
        {canEditStudentRecoveryEmail && <RecoveryEmailForm onSubmit={recoveryEmailOnSubmit} />}
        <ProfileForm onSubmit={studentOnSubmit} title="Student" data={student} fields={getStudentFields(student, canEditStudentData)} />
        <ProfileForm onSubmit={studentOnSubmit} id="learning-details-form" title="Learning Details" data={student} fields={getLearningDetailFields(student, advisors, houses, canEditStudentData, canEditStudentHouse, programs, developmentalStages)} />
        <ProfileForm onSubmit={(e) => guardianOnSubmit(e, guardian1?.id)} title="Guardian 1" data={guardian1} fields={getGuardianFields(canEditStudentData)} />
        <ProfileForm onSubmit={(e) => guardianOnSubmit(e, guardian2?.id)} title="Guardian 2" data={guardian2} fields={getGuardianFields(canEditStudentData)} />
        <ProfileForm onSubmit={(e) => emergencyContactOnSubmit(e, emergencyContact.id)} title="Emergency Contact" data={emergencyContact} fields={getEmergencyContactFields(canEditStudentData)} />
      </section>
      <StudentProfileTimeline />
    </div>

  )
}

const SuspensionSection = ({ studentId, studentSuspension, suspensionHistory, canSuspendStudent }) => {
  const isSuspended = !!studentSuspension

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between items-center">
        <h3 className="font-bold text-2xl mb-1" data-testid="form-title">Suspension</h3>
        {canSuspendStudent ? <SuspensionActions studentId={studentId} isSuspended={isSuspended} studentSuspension={studentSuspension} /> : null}
      </div>
      {isSuspended
        ? <p className="border border-black p-4 bg-notice-10 rounded-lg max-w-96">🚨 This Student is currently suspended.</p>
        : null
      }
      <SuspensionHistory suspensionHistory={suspensionHistory} />
    </div>
  )
}

const SuspensionActions = ({ studentId, isSuspended, studentSuspension }) => {
  const { mutate } = useSWRConfig()

  const revalidator = useRevalidator()

  const createSuspension = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const formProps = Object.fromEntries(formData)

    try {
      await axios.post(`/pages/employee/students/${studentId}/contact/suspension/create`,
        formProps,
      )
      toast.success('Suspension created. Starting to block accesses.')
      e.target.reset()
      revalidator.revalidate()
      mutate(`/timeline-event/fetch?student_id=${studentId}`)
    } catch (error) {
      toast.error('Failed to create suspension.')
    }

  }

  const revokeSuspension = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const formProps = Object.fromEntries(formData)

    try {
      await axios.post(`/pages/employee/students/${studentId}/contact/suspension/revoke`,
        formProps,
      )
      toast.success('Suspension revoked. Restoring student access.')
      e.target.reset()
      revalidator.revalidate()
      mutate(`/timeline-event/fetch?student_id=${studentId}`)
    } catch (error) {
      toast.error('Failed to revoke suspension.')
    }
  }

  return isSuspended
    ? (
      <SuspensionForm title="Revoke Suspension" textAreaPlaceholder="Why is this student being unsuspended?" onSubmit={revokeSuspension}>
        <input type="hidden" name="suspension_id" value={studentSuspension.id} />
        <input type="hidden" name="action" value="revoke_suspension" />
      </SuspensionForm>
    )
    : (
      <SuspensionForm title="Create Suspension" textAreaPlaceholder="Why is this student being suspended?" onSubmit={createSuspension}>
        <input type="hidden" name="student_id" value={studentId} />
        <input type="hidden" name="action" value="start_suspension" />
      </SuspensionForm>
    )
}

const SuspensionForm = ({ title, onSubmit, textAreaPlaceholder, children }) => {
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [submissionBlocked, setSubmissionBlocked] = useState(false)

  return (
    <div>
      <Button onClick={() => setIsFormOpen(true)} size="sm">{title}</Button>
      <PortalModal
        isOpen={isFormOpen}
        className="w-[755px]"
        onClose={() => setIsFormOpen(false)}
      >
        <div className="flex flex-col space-y-10">
          <header className="flex flex-col items-center w-full py-4 border-b-2 border-b-gray-10">
            <h1 className="text-2xl font-bold">{title}</h1>
          </header>
          <form
            onSubmit={(e) => {
              setSubmissionBlocked(true)
              onSubmit(e)
              setSubmissionBlocked(false)
              setIsFormOpen(false)
            }}
            className="p-5 gap-3 flex flex-col"
          >
            {children}
            <TextField as="textarea" name="motivation" label="Motivation" placeholder={textAreaPlaceholder} required style={{ resize: 'none' }} />
            <div className="flex gap-3 justify-end">
              <Button variant="outlined" onClick={() => setIsFormOpen(false)}>Cancel</Button>
              <Button type="submit" disabled={submissionBlocked}>Submit</Button>
            </div>
          </form>
        </div>
      </PortalModal>
    </div>
  )
}

const SuspensionHistory = ({ suspensionHistory }) => {
  return !suspensionHistory?.length
    ? <p>This student does not have a history of suspensions.</p>
    : (
      <div>
        <h4 className="font-bold text-lg mb-1">Suspension History</h4>
        <table className="border-collapse w-full">
          <thead>
            <tr className="bg-white">
              <th className="border border-gray-50 px-4 py-2">Date</th>
              <th className="border border-gray-50 px-4 py-2">User</th>
              <th className="border border-gray-50 px-4 py-2">Action</th>
              <th className="border border-gray-50 px-4 py-2">Motivation</th>
            </tr>
          </thead>
          <tbody>
            {suspensionHistory?.map((suspension) => (
              <tr key={suspension.id} className="bg-white">
                <td className="border border-gray-50 px-4 py-2">{new Date(suspension.suspended_at).toLocaleDateString([], { day: '2-digit', month: 'short', year: 'numeric' })}</td>
                <td className="border border-gray-50 px-4 py-2">{suspension.mutator_name}</td>
                <td className="border border-gray-50 px-4 py-2">{suspension.action}</td>
                <td className="border border-gray-50 px-4 py-2">{suspension.motivation}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
}

const RecoveryEmailForm = ({ onSubmit }) => {
  const [isEditing, setIsEditing] = useState(false)
  return (
    <form onSubmit={e => {
      onSubmit(e, setIsEditing)
    }}>
      <h3 className="font-bold text-2xl mb-1" data-testid="form-title">Recovery e-mail</h3>
      {
        isEditing ?
          <>
            <div className="w-96 inline-block mr-2">
              <TextField name="recovery_email" placeholder="Type the new recovery e-mail" className="w-40 h-10" />
            </div>
            <Button type="submit" size="sm" variant="outlined">Update recovery e-mail</Button>
          </>
          : <Button onClick={() => setIsEditing(!isEditing)} size="sm" variant="outlined">Update recovery e-mail</Button>
      }
    </form>
  )
}

const ProfileForm = ({ id, title, fields, data, onSubmit }) => {
  const [isEditing, setIsEditing] = useState(false)
  return (
    <form id={id} onSubmit={(e) => onSubmit(e) && setIsEditing(false)} className="divide-y-2" autoComplete="off">
      <div className="flex items-center justify-between pb-2">
        <h3 className="font-bold text-2xl" data-testid="form-title">{title}</h3>
        <div className="flex flex-row gap-2">
          <Button
            type="button"
            onClick={() => { setIsEditing(!isEditing) }}
            data-testid="btn-edit"
            variant="outlined"
            size="sm"
          >
            {isEditing ? 'Cancel' : 'Edit'}
          </Button>
          <Button
            type="submit"
            disabled={!isEditing}
            data-testid="btn-save"
            size="sm"
          >
            Save
          </Button>
        </div>
      </div>
      {fields.map((field, idx) => {
        const testid = title.toLowerCase().trim().replace(' ', '_').concat('_', field.name)
        const val = data?.[field.name]
        return (
          <div className="grid grid-cols-3 items-center py-2" key={field.label + field.name + idx}>
            <span className="font-bold">{field.label}</span>
            {isEditing ? (
              field.editable ? (
                field.input
                  ? field.input(val)
                  : <TextField type="text" name={field.name} defaultValue={val} data-testid={testid} />
              ) : field.parse
                ? field.parse(val, 'text-gray-40 border border-transparent')
                : <span data-testid={testid} className="text-gray-40 border border-transparent">{val || '-'}</span>
            ) : field.parse
              ? field.parse(val)
              : <span className="border border-transparent" data-testid={testid}>
                {val ? field.isDate ? format(tzAgnosticDate(val), 'MMM d, yyyy') : val.toString() : '-'}
              </span>
            }
          </div>
        )
      })}
    </form>
  )
}

function getStudentFields(student, editable) {
  return [
    { label: 'Legal First Name', name: 'first_name', editable },
    { label: 'Preferred Name', name: 'preferred_name', editable },
    { label: 'Middle Name', name: 'middle_name', editable },
    { label: 'Last Name', name: 'last_name', editable },
    { label: 'Gender', name: 'gender', editable },
    { label: 'Pronouns', name: 'pronouns', editable },
    { label: 'Ethnicity', name: 'ethnicity', editable },
    { label: 'Religion', name: 'religion', editable },
    { label: 'ID', name: 'id', editable: false },
    { label: 'Email', name: 'sora_email', editable: false },
    { label: 'Year Standing', name: 'year_standing_grade', editable: false },
    {
      label: 'Birthdate',
      name: 'birth_date',
      editable,
      isDate: true,
      input: () => (
        <TextField type="date" name="birth_date" defaultValue={
          student.birth_date ? format(tzAgnosticDate(student.birth_date), 'yyyy-MM-dd') : undefined
        } />
      ),
    },
    { label: 'Timezone', name: 'timezone', editable },
    { label: 'Shirt size', name: 'shirt_size', editable },
    { label: 'Lives with', name: 'lives_with', editable },
    { label: 'Previous courses', name: 'previous_courses', editable },
    { label: 'Languages spoken', name: 'languages_spoken', editable },
    { label: 'Interests in Sora', name: 'interests_in_sora', editable },
    { label: 'Qualities', name: 'qualities', editable },
    { label: 'Aspects to improve', name: 'aspects_to_improve', editable },
    { label: 'Positive Experiences', name: 'positive_experiences', editable },
    { label: 'Negative experiences', name: 'negative_experiences', editable },
    { label: 'Hobbies', name: 'hobbies', editable },
    { label: 'Extra details', name: 'extra_details', editable },
    {
      label: 'Profile Information',
      name: 'profile_information',
      editable,
      input: () => <SlateTextarea id="write-profile-info" name="profile_information" value={Array.isArray(student.profile_information) ? student.profile_information : student.profile_information} />,
      parse: () =>
        student.profile_information
          ? <RenderedSlate id="read-profile-info" value={Array.isArray(student.profile_information) ? student.profile_information : student.profile_information} className="pointer-events-none w-full min-w-[38rem]" />
          : <span>-</span>
    },
    { label: 'Rosetta Username', name: 'rosetta_username', editable },
  ]
}

function getGuardianFields(editable) {
  return [
    {
      label: 'First Name',
      name: 'first_name',
      editable,
    },
    {
      label: 'Last Name',
      name: 'last_name',
      editable,
    },
    {
      label: 'Relationship',
      name: 'relationship',
      editable,
    },
    {
      label: 'Email',
      name: 'email',
      editable,
    },
    {
      label: 'Phone',
      name: 'phone',
      editable,
    },
    {
      label: 'Address',
      name: 'address',
      editable,
    },
    {
      label: 'State',
      name: 'state',
      editable,
    },
    {
      label: 'Is Primary?',
      name: 'is_primary',
      editable,
      input: (is_primary) => <input defaultChecked={!!is_primary} type="checkbox" name="is_primary" />,
      parse: (is_primary, style) => <span className={style}>{is_primary === undefined ? '-' : is_primary ? 'Yes' : 'No'}</span>,
    },
  ]
}

function getLearningDetailFields(student, advisors, houses, editable, canEditStudentHouse, programs, developmentalStages) {
  const expectedGradFirstYear = 2021
  const expectedGradYearOpts = [
    <option key={0} value="">Select Year</option>,
    ...[...Array(
      (new Date().getFullYear() - expectedGradFirstYear) + 10
    ).keys()].map(v => expectedGradFirstYear + v).map(v => <option key={v} value={v}>{v}</option>)
  ]
  const startDateFirstYear = 2016
  const yearOptsStartDate = [
    <option key={0} value="">Select Year</option>,
    ...[...Array(
      (new Date().getFullYear() - startDateFirstYear) + 6
    ).keys()].map(v => startDateFirstYear + v).map(v => <option key={v} value={v}>{v}</option>)
  ]
  return [
    {
      label: '6th Grade Start Date',
      name: 'ms_start_date',
      editable,
      isDate: true,
      input: (field) => <TextField as="select" name="ms_start_date" defaultValue={new Date(field).getFullYear()}>{yearOptsStartDate}</TextField>,
      parse: (field, style) => <div className={style}>{field ? new Date(field).getFullYear() : '-'}</div>,
    },
    {
      label: 'MS Expected Graduation Year',
      name: 'ms_expected_graduation',
      editable,
      parse: (field, style) => <div className={style}>{field ? new Date(field).getFullYear() : '-'}</div>,
      input: (field) => <TextField as="select" name="ms_expected_graduation" defaultValue={new Date(field).getFullYear()}>{expectedGradYearOpts}</TextField>,
    },
    {
      label: 'MS Graduation Date',
      name: 'ms_graduation_date',
      editable,
      isDate: true,
      input: (v) => <TextField type="date" placeholder="-" name="ms_graduation_date" defaultValue={v} />,
    },
    {
      label: '9th Grade Start Date',
      name: 'hs_start_date',
      editable,
      isDate: true,
      input: (field) => <TextField as="select" name="hs_start_date" defaultValue={new Date(field).getFullYear()}>{yearOptsStartDate}</TextField>,
      parse: (field, style) => <div className={style}>{field ? new Date(field).getFullYear() : '-'}</div>,
    },
    {
      label: 'HS Expected Graduation Year',
      name: 'hs_expected_graduation',
      editable,
      parse: (field, style) => <div className={style}>{field ? new Date(field).getFullYear() : '-'}</div>,
      input: (field) => <TextField as="select" name="hs_expected_graduation" defaultValue={new Date(field).getFullYear()}>{expectedGradYearOpts}</TextField>,
    },
    {
      label: 'HS Graduation Date',
      name: 'hs_graduation_date',
      editable,
      isDate: true,
      input: (v) => <TextField type="date" name="hs_graduation_date" defaultValue={v} />,
    },
    student.school_stage === 'hs' && {
      label: 'Developmental Stage',
      name: 'developmental_stage_id',
      editable,
      parse: () => developmentalStages.find(p => p.id === student.developmental_stage_id)?.title || student.developmental_stage_id,
      input: (v) => {
        return (
          <TextField as="select" name="developmental_stage_id" defaultValue={v}>
            {developmentalStages.map(developmentalStage => (
              <option key={developmentalStage.id} value={developmentalStage.id}>
                {developmentalStage.title}
              </option>
            ))}
          </TextField>
        )
      },
    },
    {
      label: 'Program',
      name: 'program_id',
      editable,
      parse: () => programs.find(p => p.id === student.program_id)?.title || student.program_id,
      input: (v) => {
        return (
          <TextField as="select" name="program_id" defaultValue={v}>
            {programs.map((program) => (
              <option key={program.id} value={program.id}>
                {program.title}
              </option>
            ))}
          </TextField>
        )
      },
    },
    {
      label: 'Is on Foundation Experiences',
      name: 'is_foundation',
      editable,
      parse: (field, style) => <div className={style}>{field ? 'Yes' : 'No'}</div>,
      input: (v) => <input type="checkbox" name="is_foundation" defaultChecked={v} />,
    },
    {
      label: 'House',
      name: 'house_title',
      editable: canEditStudentHouse,
      input: (house_title) => {
        const currentHouse = houses.find((h) => h.title === house_title)
        return (
          <HouseCell studentId={student.id} house={currentHouse?.title || ''} />
        )
      },
    },
    {
      label: 'President',
      name: 'is_president',
      editable,
      parse: (field, style) => <div className={style}>{field ? 'Yes' : 'No'}</div>,
      input: (v) => <input type="checkbox" name="is_president" defaultChecked={v} />,
    },
    {
      label: 'Vice President',
      name: 'is_vice_president',
      editable,
      parse: (field, style) => <div className={style}>{field ? 'Yes' : 'No'}</div>,
      input: (v) => <input type="checkbox" name="is_vice_president" defaultChecked={v} />,
    },
    {
      label: 'House Advisor',
      name: 'advisor_id',
      editable: false,
      parse: (field, style) => {
        const advisor = advisors.find((advisor) => advisor?.id === field)
        return advisor
          ? <div className={style}>{`${advisor.first_name} ${advisor.last_name}`}</div>
          : <div>loading...</div>
      },
    },
    {
      label: 'Maths',
      name: 'maths',
      editable: false,
      parse: (field, style) => (
        <div className={`${style} flex flex-col`}>
          {field.map((math, idx) => <span key={idx}>{math}</span>)}
        </div>
      ),
    },
    {
      label: 'Track',
      name: 'track',
      editable: false,
      parse: (field, style) => (
        <div className={`${style} flex flex-col`}>
          {field.map((track, idx) => <span key={idx}>{track}</span>)}
        </div>
      ),
    },
    {
      label: 'Language',
      name: 'languages',
      editable: false,
      parse: (field, style) => (
        <div className={`${style} flex flex-col`}>
          {field.map((track, idx) => <span key={idx}>{track}</span>)}
        </div>
      ),
    },
  ].filter(Boolean)
}

function getEmergencyContactFields(editable) {
  return [
    { label: 'First Name', name: 'first_name', editable },
    { label: 'Last Name', name: 'last_name', editable },
    { label: 'Email', name: 'email', editable },
    { label: 'Phone', name: 'phone', editable },
    { label: 'Relationship', name: 'relationship', editable },
  ]
}
