/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
import { toast } from 'sonner'
import { useRevalidator } from 'react-router-dom'
import useSWR from 'swr'
import axios from 'axios'
import Feedback from '@components/facilitate/feedback/feedback'
import PreSetCommentDrawer from '@components/preset-comment/drawer'
import UnitsAbilitiesAssessment from '@components/facilitate/assessment/unit-abilities-assessment'
import { Button } from '@designsystem'
import { faArrowRight, faRotateBack } from '@fortawesome/free-solid-svg-icons'

export const FeedbackForm = ({
  task_submission_id,
  task_id,
  theme_id,
  student_id,
  assessment,
  contextData,
  commentRef,
  submissionStatus,
  setUnits,
  setAbilities,
  isAssessed,
}) => {
  

  const revalidator = useRevalidator()
  const mutate = revalidator.revalidate

  const abilityUnitRef = useRef(null)
  const drawerPresetCommentRef = useRef(null)

  const {
    data: dataPresetComment,
    mutate: refreshPresetComment,
  } = useSWR(task_id ? `/preset-comments/task/${task_id}` : null)

  const handleUpdateItems = (type) => (items) => {
    switch (type) {
      case 'unit':
        setUnits(items)
        break
      case 'ability':
        setAbilities(items)
        break
    }
  }

  const handleSave = (status = 'draft') => async () => {
    const isAssessed = status === 'assessed'
    const payload = {
      status,
      abilities: contextData.abilities ?? [],
      units: contextData.units ?? [],
      submission_status: !submissionStatus ? 'ontrack' : submissionStatus,
    }

    const missingItems = abilityUnitRef?.current?.getMissingItems(
      payload.units.map(u => u.id),
      payload.abilities.map(u => u.id),
      true
    ) || { abilities: [], units: [] }
    payload.abilities = [...payload.abilities, ...missingItems.abilities]
    payload.units = [...payload.units, ...missingItems.units]
    payload.status = status
    payload.student_id = student_id
    payload.task_id = task_id

    if (!isAssessed || confirm('When publishing it will no longer be possible to change,\nare you sure you want to publish right now?') === true) {
      try {
        await axios.post(`/assessments/final?task_submission=${task_submission_id}`, payload)
        await commentRef?.current?.submitCommentsBeforePublish()
        toast.success(isAssessed ? 'Submission assessed' : 'Submission saved')
        mutate()
        abilityUnitRef?.current?.refreshComponent()
      } catch (err) {
        console.error(err)
        toast.error('Assessment failed, please try again later!')
      }
    }
  }

  return <Feedback submissionStatus={submissionStatus} assessment={assessment}>
    <Feedback.OnTrackStep>
      <PreSetCommentDrawer
        ref={drawerPresetCommentRef} list={dataPresetComment?.preset_comments}
        refreshList={refreshPresetComment} taskId={task_id} themeId={theme_id}
      />
      <UnitsAbilitiesAssessment
        ref={abilityUnitRef}
        apiUrl={`/task-submissions/theme/${theme_id}/student/${student_id}/units_abilities/final?task_submission_id=${task_submission_id ? task_submission_id : ''}&task_id=${task_id}`}
        selectedUnits={contextData?.units ?? []}
        selectedAbilities={contextData?.abilities ?? []}
        onUpdateSelectedUnits={handleUpdateItems('unit')}
        onUpdateSelectedAbilities={handleUpdateItems('ability')}
        disabled={isAssessed}
        compressed={true}
        allowAddFeedback={true}
      />
    </Feedback.OnTrackStep>
    <Feedback.Footer>
      {({ assessed }) => (
        <>
          <div className='flex-auto'></div>
          <Button endIcon={assessed ? faRotateBack : faArrowRight} onClick={handleSave(assessed ? 'draft' : 'assessed')}>
            {assessed ? 'Un - publish' : 'Send'}
          </Button>
        </>
      )}
    </Feedback.Footer>
  </Feedback>
}
