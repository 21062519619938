import React, { forwardRef, useState } from 'react'
import Select from '../select'
import matchMultipleProps from '../../../utils/filter/matchMultipleProps'
import groupBy from '../../../utils/reduce/groupBy'
import debounce from 'lodash/debounce'
import { SubjectIcon } from '../../../utils/subjectIcons'
import { getSubjectColors } from '../../../utils/subjectColors'
import PropTypes from 'prop-types'
import alphabetically from '../../../utils/sort/alphabetically'

function createGroupedOptions(unitsOptions, groupByProperty) {
  const unitsGroupedBySubject = unitsOptions.reduce(groupBy(groupByProperty), {})
  return Object.entries(unitsGroupedBySubject).map(([property, units]) => {
    return {
      subject_title: units[0].subject_title,
      subject_id: units[0].subject_id,
      topic_and_optional: `${units[0].topic_title}${units[0].is_optional ? ' [optional]' : ' [required]'}`,
      subject_and_optional: `${units[0].subject_title}${units[0].is_optional ? ' [optional]' : ' [required]'}`,
      label: property,
      options: units
    }
  }).sort(alphabetically({ by: 'label' }))
}

const SelectUnits = forwardRef(function ({
  units,
  selected_ids,
  groupByProperty,
  onChange,
  isMulti,
  selectAllEnabled,
  onClickGroup,
  ...rest
}, ref) {
  const unitsOptions = units
    .map(unit => ({
      ...unit,
      label: `[${unit.school_stage.toUpperCase()}] ${unit.title}`,
      value: unit.id,
      topic_and_optional: `${unit.topic_title}${unit.is_optional ? ' [optional]' : ' [required]'}`,
      subject_and_optional: `${unit.subject_title}${unit.is_optional ? ' [optional]' : ' [required]'}`,
    }))
    .sort(alphabetically({ by: 'label' }))

  let options = createGroupedOptions(unitsOptions, groupByProperty)

  if (isMulti && selectAllEnabled) options = [{ label: 'All Units', value: 'ALL' }, ...options]

  const searchFunction = debounce((query, callback) => {
    const searchedUnits = unitsOptions.filter(matchMultipleProps(
      query,
      ['title', 'subject_title', 'topic_title', 'topic_and_optional', 'subject_and_optional']
    ))

    callback(createGroupedOptions(searchedUnits, groupByProperty))
  }, 500)

  const value = selected_ids.map(id => unitsOptions.find(option => option.id === id))

  const [inputValue, setInputValue] = useState()
  const onInputChange = (query, { action }) => {
    if (action !== 'set-value') setInputValue(query)
    return inputValue
  }

  const onChangeEvent = (selectedUnitsParam, object) => {
    if (isMulti) {
      if (selectAllEnabled) {
        const selectedStudent = selectedUnitsParam[0]
        if (selectedStudent?.value === 'ALL') {
          onChange(unitsOptions.map(option => option.id), unitsOptions, object)
        }
      } else {
        const selectedUnits = selectedUnitsParam || []
        const orderedSelectedUnits = unitsOptions
          .filter(option => selectedUnits.some((selectedUnits) => selectedUnits.id === option.id))
        onChange(orderedSelectedUnits.map(option => option.id), orderedSelectedUnits, object)
      }
    } else {
      const selectedUnit = selectedUnitsParam || value[0] || unitsOptions[0]
      onChange(selectedUnit.id, selectedUnit)
    }
    setInputValue('')
    setInputValue(inputValue)
  }

  const onClickGroupEvent = (groupUnits) => {
    const groupUnitIds = groupUnits.map((groupUnit) => groupUnit.id)
    const allSelectedUnitsIds = Array.from(new Set([...selected_ids, ...groupUnitIds]))
    const orderedSelectedUnits = unitsOptions
      .filter(option => allSelectedUnitsIds.some((unitId) => unitId === option.id))
    onClickGroup(orderedSelectedUnits.map(option => option.id), orderedSelectedUnits)
  }


  return <Select
    isClearable
    options={options}
    searchFunction={searchFunction}
    isMulti={isMulti}
    value={value}
    placeholder="Type to search units"
    onChange={onChangeEvent}
    inputValue={inputValue}
    onInputChange={onInputChange}
    ref={ref}
    blurInputOnSelect={false}
    closeMenuOnSelect={false}
    GroupHeaderComponent={data => {
      return <div className="flex content-center justify-between text-md font-bold">
        <span
          className={`flex items-start space-x-2 ${getSubjectColors(data.subject_id).text} ${onClickGroup ? 'cursor-pointer' : ''}`}
          onClick={() => onClickGroupEvent(data.options)}>
          <SubjectIcon id={data.subject_id} className="max-w-4 max-h-4" />
          <span>{data?.label}</span>
        </span>
        <span className={`${getSubjectColors(data.subject_id).text} bg-gray-10 h-4 w-4 rounded-full text-center align-middle`}>
          {data?.options.length}
        </span>
      </div>
    }}
    {...rest} />
})

SelectUnits.displayName = 'SelectUnits'

SelectUnits.defaultProps = {
  groupByProperty: 'topic_title',
  onClickGroup: () => null,
  selected_ids: [],
  selectAllEnabled: false
}

SelectUnits.propTypes = {
  units: PropTypes.array,
  groupByProperty: PropTypes.string,
  selected_ids: PropTypes.array,
  isMulti: PropTypes.bool,
  selectAllEnabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClickGroup: PropTypes.func,
}

export default SelectUnits
