import React, { useState } from 'react'
import useSWR from 'swr'
import axios from 'axios'
import { toast } from 'sonner'

import useCurrentUser from './useCurrentUser'

const STATUSES = ['excused', 'present', 'tardy', 'camera_on', 'participated']

export const getName = (attendable_id, student_id) =>
  `student-${student_id}:attendable-${attendable_id}`

export const getKey = (attendable_id, student_id, status) =>
  getKeyFromName(getName(attendable_id, student_id), status)

export const getKeyFromName = (name, status) =>
  `${name}:status-${status}`

const useAttendances = ({ attendable_id, activateRefresh = false }) => {
  const currentUser = useCurrentUser()
  const attendanceSwrOpt = activateRefresh ? { refreshInterval: 15000 } : {}
  const { data, error, mutate } = useSWR(() => attendable_id && `/attendances?attendable_id=${attendable_id}`, attendanceSwrOpt)

  const [override, setOverride] = useState({})

  const rawAttendances = data?.result?.reduce((acc, rawAttendance) => {
    const next = { ...acc }
    const { attendable_id, student_id, notified_at } = rawAttendance
    next[getKey(attendable_id, student_id, 'notified_at')] = notified_at
    for (const status of STATUSES) {
      const key = getKey(attendable_id, student_id, status)
      next[key] = rawAttendance[status]
    }
    return next
  }, {})

  const attendances = {
    ...rawAttendances,
    ...override,
  }



  const setAttendanceForKey = (key, checked) => {
    let [student_id, attendable_id, status] = key.split(':').map(x => x.split('-')[1])
    setOverride(prev => ({
      ...prev,
      [key]: checked,
    }))
    axios.patch('/attendances', {
      student_id,
      employee_id: currentUser?.role !== 'student' ? currentUser.id : null,
      attendable_id,
      [status]: checked,
    }).then(
      mutate
    ).catch(error =>
      toast.error(error.response?.data?.error?.message || error.response.data.message)
    ).finally(() => {
      setOverride(prev => {
        const next = { ...prev }
        delete next[key]
        return next
      })
    })
  }

  const pendings = Object.keys(override).reduce((acc, key) => ({ ...acc, [key]: true }), {})

  const getCheckedFor = key =>
    Boolean(!data?.result || attendances?.[key])

  const getPendingFor = key =>
    !attendances || pendings[key]

  const getDisabledFor = (student_id, status) =>
    getPendingFor(getKey(attendable_id, student_id, status))
    || status === 'excused'
    || getCheckedFor(getKey(attendable_id, student_id, 'excused'))
    || (status !== 'present' && !getCheckedFor(getKey(attendable_id, student_id, 'present')))
    || (status === 'present' && getCheckedFor(getKey(attendable_id, student_id, 'tardy')))

  const getStudentStatus = student_id => {
    if (getStudentNotifiedAt(student_id))
      return <small className="text-danger-30">
        Parents notified at {
          new Date(getStudentNotifiedAt(student_id)).toLocaleDateString([], { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })
        }
      </small>

    const isExcused = getCheckedFor(getKey(attendable_id, student_id, 'excused'))

    if (isExcused)
      return <small className="text-green-40">Excused</small>

    const isUnexcusedAbsent =
      !getCheckedFor(getKey(attendable_id, student_id, 'present'))
      && !isExcused

    if (isUnexcusedAbsent)
      return <small className="text-danger-30">Unexcused absent</small>

    return <small className="text-blue-30">Ok</small>
  }

  const getStudentNotifiedAt = student_id =>
    attendances && attendances[getKey(attendable_id, student_id, 'notified_at')]

  const getStudentNotificationStatus = student_id =>
    data?.result?.find(attendance => attendance.student_id === student_id)?.notified_at

  return {
    isLoading: !error && !attendances,
    isError: error,
    statuses: STATUSES,
    mutate,
    setAttendanceForKey,
    getCheckedFor,
    getPendingFor,
    getDisabledFor,
    getStudentStatus,
    getStudentNotificationStatus,
  }
}

export default useAttendances
