import { PillUnitStudentProgram } from '@blocks/pill-unit-student-program'
import { Button, DropdownMenu, Icon, Pill, Typography } from '@design-system'
import React, { useEffect } from 'react'

const _MASTERY_TEXT = {
  '-': 'Remove from assessment',
  '0.0': '0: Not started',
  '0.5': '0.5: Attempted',
  '1.0': '1: Exposure',
  '1.5': '1.5: Exposure',
  '2.0': '2: Developing',
  '2.5': '2.5: Developing',
  '3.0': '3: Demonstrating',
  '3.5': '3.5: Demonstrating',
  '4.0': '4: Extending',
}

interface FinalTaskPerkCardProps {
  title: string
  currentMastery: string
  studentReflection: string
  name: string
  lastAssessmentValue?: string
  isOffProgram?: boolean
  isExempt?: boolean
}

export function FinalTaskPerkCard({
  title,
  currentMastery,
  studentReflection,
  name,
  lastAssessmentValue = '-',
  isOffProgram = false,
  isExempt = false,
}: FinalTaskPerkCardProps) {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-1">
        <Typography variant="callout" weight="bold">
          {title}
        </Typography>
        <div className="flex gap-2 items-center flex-wrap">
          <Typography variant="callout">Current mastery: {currentMastery}</Typography>
          {isExempt && <PillUnitStudentProgram variant="exempt" />}
          {isOffProgram && <PillUnitStudentProgram variant="off-program" />}
          <PillUnitStudentProgram variant="exempt" />
        </div>
      </div>
      {/* GRADING */}
      <div className="bg-gray-5 p-4">
        <div className="flex justify-between border-b pb-3 items-center">
          <Typography variant="callout" weight="bold">
            Student Reflection
          </Typography>

          <Pill rounded>
            <Pill.Value>{_MASTERY_TEXT[`${studentReflection}`] ?? ` - `}</Pill.Value>
          </Pill>
        </div>

        <div className="flex justify-between pt-3 items-center">
          <Typography variant="callout" weight="bold">
            Assessment
          </Typography>
          <FinalAssessmentDropdown name={name} initialValue={lastAssessmentValue} />
        </div>
      </div>
    </div>
  )
}

function FinalAssessmentDropdown({ initialValue = '0.0', name, onChangeStatus = (value) => {} }) {
  const [status, setStatus] = React.useState(initialValue)

  useEffect(() => {
    setStatus(initialValue)
  }, [initialValue])

  return (
    <>
      <input type="hidden" name={name} value={status} />
      <DropdownMenu>
        <DropdownMenu.Trigger asChild>
          <Button variant="outlined" size="xs" color="soft" className="w-44 justify-between">
            <p className="truncate">{_MASTERY_TEXT[status]}</p> <Icon name="chevron-down" size="xs" />
          </Button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content className="bg-white">
          <DropdownMenu.RadioGroup
            value={status}
            onValueChange={(value) => {
              setStatus(value)
              onChangeStatus(value)
            }}
          >
            {Object.entries(_MASTERY_TEXT).map(([key, value]) => (
              <DropdownMenu.RadioItem key={key} value={key}>
                {value}
              </DropdownMenu.RadioItem>
            ))}
          </DropdownMenu.RadioGroup>
        </DropdownMenu.Content>
      </DropdownMenu>
    </>
  )
}
