import React from 'react'
import axios from 'axios'
import { Outlet } from 'react-router-dom'
import PropTypes from 'prop-types'
import { SoraNavLink } from '@components/link'
import useUserPermissions from '@hooks/useUserPermissions'

const SidebarOpt = ({ url, label }) => (
  <li>
    <SoraNavLink to={url} className={(active) => `hover:underline ${active ? 'font-bold underline' : ''}`}>
      {label}
    </SoraNavLink>
  </li>
)

SidebarOpt.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
}

const AdminReports = () => {
  const { hasPermission, isLoading } = useUserPermissions()
  const hasAnyPermission = [
    'admin_reports',
  ].some(hasPermission)

  if (isLoading) return null

  return (
    <div className="flex gap-6">
      <ul className="flex self-start sticky top-24 flex-col border-l border-l-gray-30 border-r border-r-gray-30 p-3 min-w-52 max-w-52 gap-2">
        <li className="text-lg font-bold text-gray-70 border-b border-b-gray-30">Reports</li>
        {hasAnyPermission && (
          <>
            <SidebarOpt url="students/requests" label="Students' Requests" />
            <SidebarOpt url="students/missing/units" label="Student Missing Units" />
            <SidebarOpt url="students/availability" label="Student Availability" />
            <SidebarOpt url="registration-changes" label="Registration Changes" />
            <SidebarOpt url="experiences-roster" label="Experiences Roster" />
            <SidebarOpt url="units-completion" label="Units Completion" />
            <SidebarOpt url="materials" label="Materials" />
          </>
        )}
      </ul>
      <div className="flex-1 flex flex-col">
        <Outlet />
      </div>
    </div>
  )
}

export async function loader() {
  const result = await axios.get('/pages/admin/reports/index')
  return result.data
}

export default AdminReports
