import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import PropTypes from 'prop-types'

import { getName, getKey, getKeyFromName } from '../../hooks/useAttendances'
import capitalize from '../../utils/capitalize'
import SoraLink from '@components/link'

export default function AttendanceTable({
  isLoading,
  students,
  statuses,
  attendable_id,
  getCheckedFor,
  getPendingFor,
  getDisabledFor,
  getStudentStatus,
  onChange,
  readonlyColumns,
  readonlyRows,
  isAutomated,
  sessionFinished,
  isStudent,
}) {
  const handleChange = e => {
    const { name, value: status, checked } = e.target
    const key = getKeyFromName(name, status)
    onChange(key, checked)
  }

  const handleChangeAll = e => {
    const { value: status, checked } = e.target
    for (const student of students) {
      const key = getKey(attendable_id, student.id, status)
      if (!getDisabledFor(student.id, status))
        onChange(key, checked)
    }
  }

  const allCheckedFor = status =>
    students.some(st => getCheckedFor(getKey(attendable_id, st.id, status)))

  const allPendingFor = status =>
    students.some(st => getPendingFor(getKey(attendable_id, st.id, status)))

  const allDisabledFor = status =>
    students.every(st => getDisabledFor(st.id, status))

  const getAutomatedFieldsRules = (status) => {
    return isAutomated && !sessionFinished && (
      status === 'present' ||
      status === 'tardy'
    )
  }

  return <div>
    {isLoading && <FontAwesomeIcon icon={faSpinner} className="text-blue-70 text-5xl absolute top-1/2 left-1/2" spin />}
    <div className="overflow-auto sticky h-[70vh] lg:h-full w-[90vw] lg:w-full top-0">
      <table className={`table-fixed h-[68vh] lg:h-full w-full text-center mb-2 ${isLoading ? 'opacity-40' : ''}`}>
        <thead className="sticky top-0 z-5 text-xs">
          <tr className="bg-white dark:bg-gray-90">
            <th className="sticky top-0 left-0 z-5 w-40 px-4 text-left bg-white dark:bg-gray-90 border border-gray-30 dark:border-gray-90">Name</th>
            {statuses.map(status =>
              <th key={status} className={`top-0 w-32 sticky px-4 py-2 border border-gray-30 dark:border-gray-90`}>
                <div className="flex flex-row justify-center space-x-2 items-center">
                  {status !== 'excused' && !getAutomatedFieldsRules(status) && (<input type="checkbox"
                    id={'all-' + status}
                    className={`
                    disabled:opacity-50
                    ${status === 'present' ? 'text-green-50' : ''}
                    ${status === 'tardy' ? 'text-yellow-30' : ''}
                    ${allPendingFor(status) ? 'animate-bounce' : ''}
                  `}
                    value={status}
                    disabled={allDisabledFor(status) || status === 'excused' || getAutomatedFieldsRules(status)}
                    checked={allCheckedFor(status)}
                    onChange={handleChangeAll}
                  />)}
                  <label htmlFor={'all-' + status}>{status.split('_').map(capitalize).join(' ')}</label>
                </div>
              </th>
            )}
            {readonlyColumns.map((column) => column)}
          </tr>
        </thead>
        <tbody className="sticky left-0 ">
          {students.sort((s1, s2) => s1.name.localeCompare(s2.name)).map(student => {
            const studentReadonlyRow = readonlyRows.find(({ id }) => id === student.id)

            return (
              <tr key={student.id}>
                <td className="sticky left-0 w-40 bg-white dark:bg-gray-90 px-4 py-2 text-left z-5 border border-gray-30 dark:border-gray-90">
                  <h3>
                    {isStudent ? (
                      <>{student.name}</>
                    ) : (
                      <SoraLink to={`/employee/students/${student.id}`} target="_blank" rel="noopener noreferrer" className="hover:underline">{student.name}</SoraLink>
                    )}
                  </h3>
                  <div className="-mt-1 text-xs flex flex-row justify-between w-full">
                    {getStudentStatus(student.id)}
                  </div>
                </td>
                {statuses.map(status => {
                  const name = getName(attendable_id, student.id)
                  const key = getKey(attendable_id, student.id, status)
                  const checked = status !== 'excused' && getCheckedFor(getKey(attendable_id, student.id, 'excused')) ? false : getCheckedFor(key)
                  const pending = getPendingFor(key)
                  const disabled = getDisabledFor(student.id, status) || getAutomatedFieldsRules(status)

                  return <td key={status} className="w-40 px-4 py-2 border border-gray-30 dark:border-gray-90">
                    <input type="checkbox"
                      className={`
                      disabled:opacity-25 disabled:cursor-not-allowed
                      ${status === 'present' ? 'text-green-50' : ''}
                      ${status === 'tardy' ? 'text-yellow-30' : ''}
                      ${pending ? 'animate-bounce' : ''}
                    `}
                      name={name}
                      value={status}
                      disabled={disabled}
                      checked={Boolean(checked)}
                      onChange={handleChange}
                    />
                  </td>
                })}
                {studentReadonlyRow && studentReadonlyRow.nodes}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  </div >
}

AttendanceTable.propTypes = {
  attendable_id: PropTypes.any.isRequired,
  getCheckedFor: PropTypes.func.isRequired,
  getDisabledFor: PropTypes.func.isRequired,
  getPendingFor: PropTypes.func.isRequired,
  getStudentStatus: PropTypes.func,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  readonlyColumns: PropTypes.arrayOf(PropTypes.node),
  readonlyRows: PropTypes.arrayOf(PropTypes.shape({})),
  statuses: PropTypes.array.isRequired,
  students: PropTypes.array.isRequired,
  isAutomated: PropTypes.bool,
  sessionFinished: PropTypes.bool,
  isStudent: PropTypes.bool,
}

AttendanceTable.defaultProps = {
  isLoading: false,
  readonlyColumns: [],
  readonlyRows: [],
  isAutomated: false,
}
