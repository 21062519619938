import useSWR from 'swr'

const useYearlyCycleData = (student_id, year, cycles, selectedCycle) => {
  const cyclesPerSelectedYear = cycles?.filter((cycle) => {
    return cycle.school_year === year?.value
  }) || []

  const cyclesPerSelectedYearIds = cyclesPerSelectedYear.map(
    (cycle) => cycle.id
  )

  const { data: targetData } = useSWR(
    student_id ? `/individual/target?student_id=${student_id}&cycle_id=${selectedCycle?.id}` : null
  )

  const { data, error } = useSWR(
    student_id
      ? `/individual/yearly-cycles?student_id=${student_id}&cycles=${cyclesPerSelectedYearIds.join()}`
      : null
  )

  let updatedCycles = {}
  if (data) {
    const cycleData = data.result
    updatedCycles = cyclesPerSelectedYear.reduce((acc, cycle) => {
      acc[cycle.id] = {
        ...cycleData[cycle.id],
        ...cycle,
      }
      return acc
    }, {})
  }

  return {
    data: updatedCycles,
    target: targetData?.target?.[0],
    isLoading: !error && !data,
    isError: error,
  }
}
export default useYearlyCycleData
