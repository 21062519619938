import React, { forwardRef, useImperativeHandle, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { toast } from 'sonner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Slideover from '@components/slideover'
import SoraIcon, { soraTrashOutline } from '@components/sora-icon'
import ShortSlateTextarea, { RenderedSlate, slateEmptyValue, isBlankValue } from '@components/forms/short-slate-textarea'
import useConfirmModal from '@hooks/useConfirmModal'

const PreSetCommentDrawer = forwardRef(({ list = [], taskId, themeId, refreshList }, ref) => {
  const confirm = useConfirmModal()
  const slateRef = useRef(null)
  const initialPayload = { id: 0, title: '', body: slateEmptyValue, task_id: taskId, theme_id: themeId, all_tasks: false }
  const [payload, setPayload] = useState(initialPayload)
  const [slateKey, setSlateKey] = useState(Math.random())
  const [showDrawer, setShowDrawer] = useState(false)


  const handleRemoveItem = (id) => async () => {
    if (id && await confirm('Are you sure you want to remove this preset?')) {
      try {
        await axios.delete(`/preset-comments/${id}`)
        toast.success('Comment successfully removed!')
      } catch (error) {
        toast.error('Remove failed, please try again later!')
      }

      refreshList()
    }
  }

  const handleCleanForm = () => {
    setPayload(initialPayload)
    setSlateKey(Math.random())
  }

  const handleSaveItem = async () => {
    try {
      await axios.post('/preset-comments', payload)
      toast.success('Comment added!')
    } catch (error) {
      toast.error('Save failed, please try again later!')
    }
    handleCleanForm()
    refreshList()
  }

  const handleEditItem = (item) => () => {
    if (item) {
      setPayload({
        ...payload,
        id: item.id,
        title: item.title,
        body: item.body
      })
    }
    setSlateKey(Math.random())
  }

  useImperativeHandle(ref, () => ({
    open(id) {

      const selected = list.find(item => item.id === id)
      setShowDrawer(true)
      handleEditItem(selected)()
    }
  }))

  const renderOptions = () => {
    if (!list || list.length === 0) return <li className='text-white text-center'>Preset list is empty</li>
    return list?.map((item, index) => (
      <li key={`preset:comment:item:${item.id}`} className={`group px-4 last:border-b-0 ${item.id === payload.id ? 'bg-blue-20 hover:bg-blue-30' : 'hover:bg-gray-10'}`}>
        <div className={`flex flex-row gap-2 pt-4 ${list.length - 1 !== index ? 'border-b border-b-gray-30' : ''}`}>
          <div className='flex-1 flex flex-col gap-2 cursor-pointer' onClick={handleEditItem(item)}>
            <span className='text-sm font-bold'>{item.title}</span>
            <div className='text-xs'>
              <RenderedSlate value={item.body} />
            </div>
          </div>
          <div className='flex flex-col gap-2 opacity-0 group-hover:opacity-100'>
            <div className='rounded-full cursor-pointer hover:bg-gray-30 p-2' onClick={handleRemoveItem(item.id)}>
              <SoraIcon icon={soraTrashOutline} className='text-white w-4 h-4' />
            </div>
          </div>
        </div>
      </li>
    ))
  }

  return (
    <>
      <Slideover open={showDrawer} setOpen={setShowDrawer}>
        <div className='flex flex-row w-full h-full'>
          <div className='flex flex-col flex-1 gap-2'>
            <span className='font-bold text-xl '>Manage Comments</span>
            <div className='flex flex-row items-center'>
              <div className='flex-1 bg-gray-30 w-full h-px'></div>
              <div className='flex justify-center items-center rounded-full shadow-lg p-4 bg-gray-10 active:bg-gray-30 cursor-pointer select-none' onClick={handleCleanForm}>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </div>
            <span className='font-bold'>Presets</span>
            <ul className='flex flex-col pl-2'>
              {renderOptions()}
            </ul>
          </div>
          <div className='flex flex-col h-full px-2'>
            <div className='bg-gray-30 h-full w-px'></div>
          </div>
          <div className='flex flex-col flex-1'>
            <div className='flex flex-col gap-4' style={{ wordBreak: "break-word" }}>
              <input type="hidden" name="preset_comment_id" value={payload.id} />
              <input onChange={(e) => setPayload({ ...payload, title: e.target.value })} value={payload.title} type="text" name="title" id="title" placeholder='Title' className='py-2 px-4 border border-gray-30 rounded-lg placeholder-gray-40 ring-0 outline-none' />
              <ShortSlateTextarea
                ref={slateRef}
                key={`preset:comment:new:${slateKey}`}
                id='preset:comment:new'
                className='min-h-45'
                placeholder='Add a preset comment...'
                onChange={(v) => setPayload({ ...payload, body: v })}
                value={payload.body}
                compressed={true}
              />
              <div className='flex flex-row justify-between items-center'>
                <div className='flex flex-row gap-1 items-center'>
                  <input onChange={(e) => setPayload({ ...payload, all_tasks: e.target.checked })} checked={payload.all_tasks} type="checkbox" name="all_tasks" id="all_tasks" className='w-4 h-4 rounded-full text-green-40 bg-gray-30 focus:ring-green-50' />
                  <label htmlFor='all_tasks' className='text-sm'>Add to all tasks</label>
                </div>
                <button className='select-none rounded-3xl px-4 py-2 bg-blue-70 text-white cursor-pointer active:bg-blue-50 disabled:opacity-40' disabled={isBlankValue(payload.body) || payload.title.trim().length === 0} onClick={handleSaveItem}>
                  Save Comment
                </button>
              </div>
            </div>
          </div>
        </div>
      </Slideover>
    </>
  )
})
PreSetCommentDrawer.displayName = 'PreSetCommentDrawer'
PreSetCommentDrawer.propTypes = {
  list: PropTypes.array,
  taskId: PropTypes.string,
  themeId: PropTypes.string,
  refreshList: PropTypes.func,
}

export default PreSetCommentDrawer
