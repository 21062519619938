import React from 'react'
import SoraLink from '@components/link'
import { Button } from '@designsystem';

export function StudentsList({ theme_id, tasks, students, hasTasksDetailsRedesignFeatureEnabled }: IStudentsList) {
  return (
    <div className='w-full flex-col rounded-xl bg-white border border-gray-40 overflow-x-auto'>
      <table className="text-sm w-full">
        <thead>
          <tr className="text-center border-b text-xs">
            <th />
            {tasks.map(t => <th className="font-normal p-2" key={t.id}>{t.title}</th>)}
          </tr>
        </thead>
        <tbody>
          {students.map((s, i) => (
            <tr className="text-center border-b h-10" key={s.id} data-testid="student-row">
              <td className="text-left min-w-32 sticky left-0 bg-white pl-2" data-testid={`student-row-${i}-name`}>{s.name}</td>
              {s.tasks.map((t, ii) => (
                <td
                  className={`capitalize font-bold whitespace-nowrap px-1 ${getTaskStatusColor(t.status)}`}
                  key={`${s.id}-${t.id}`}
                  data-testid={`student-row-${i}-task-${ii}-status`}
                >
                  <SoraLink
                    to={`/employee/facilitate/assessment/${theme_id}/task/${t.id}/${s.id}`}
                    className='cursor-pointer hover:bg-gray-10 py-1 px-3 rounded-xl'
                  >
                    {t.status.replace('_', ' ')}
                  </SoraLink>
                    {hasTasksDetailsRedesignFeatureEnabled && <Button size='xxs' display='inline' variant='outlined'>
                      <SoraLink to={`/facilitate/experiences/${theme_id}/tasks/${t.id}/students/${s.id}` }>
                        3.0
                      </SoraLink>
                    </Button>}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

function getTaskStatusColor(taskStatus: string) {
  return {
    assessed: 'text-turquoise-50',
    unassessed: 'text-blue-70',
    no_submission: 'text-gray-40',
    flagged: 'text-danger-50'
  }[taskStatus] || 'text-black'
}

export interface IStudentsList {
  theme_id: number;
  hasTasksDetailsRedesignFeatureEnabled: boolean;
  tasks: {
    id: number;
    title: string;
  }[];
  students: {
    id: number;
    name: string;
    tasks: {
      id: number;
      status: string;
    }[]
  }[]
}
