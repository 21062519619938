import React from "react"
import { useLoaderData } from "react-router-dom"
import axios from "axios"

const loader = async ({ params }) => {
  const { processId } = params
  const result = await axios.get(`/backoffice/workbench/journey-builder/processes/${processId}/schedule/generated/students`)
  return result?.data
}

const Element = () => {
  const { students } = useLoaderData()
  
  const mountSections = (idx, studentId, item, studentIdx, isFirst = false) => {
    const isRedNotice = !item.learning_block_id || !item.employee_id || item.has_conflict ? 'bg-danger-10 text-danger-60' : 'bg-white'
    return (
      <React.Fragment key={`student_${studentId}_lb_${item.learning_block_id}_${idx}`}>
        <div className={`flex flex-col p-3 justify-center ${isFirst ? 'mt-4' : ''} ${isRedNotice}`}>{item.learning_block_title || 'Unassigned'}&nbsp;</div>
        <div className={`flex flex-col p-3 justify-center ${isFirst ? 'mt-4' : ''} ${isRedNotice}`}>{item.employee_name || 'Unassigned'}&nbsp;</div>
        <div className={`flex flex-col p-3 justify-center ${isFirst ? 'mt-4' : ''}  ${isRedNotice}`}>
          {item.template_title} {item.template_category ? `(${item.template_category})` : ''}&nbsp;
        </div>
      </React.Fragment>
    )
  }

  const mountCycles = (idx, studentId, studentCycle, studentIdx, isFirst = false) => {
    const isUnassigned = studentCycle.cycle_id === null
    const isRedNotice = isUnassigned ? 'bg-danger-10 text-danger-60' : 'bg-white'
    const rowSpan = studentCycle.sections.length

    return (
      <React.Fragment key={`student_${studentId}_lb_${studentCycle.cycle_id}_${idx}`}>
        <div className={`flex flex-col p-3 ${isFirst ? 'mt-4' : ''} ${isRedNotice}`} style={{ gridRow: `span ${rowSpan}` }}>
          {studentCycle.cycle_title || 'Unassigned'}&nbsp;
        </div>
        {mountSections(0, studentId, studentCycle.sections[0], studentIdx, isFirst)}
        {((studentCycle.sections.length) > 1) && studentCycle.sections.slice(1).map((x, index) => mountSections(index, studentId, x, studentIdx))}
      </React.Fragment>
    )
  }

  const studentRow = (student, idx) => {
    const rowSpan = student.data.reduce((acc, curr) => acc + curr.sections.length, 0)
    return (
      <React.Fragment key={`student_${student.id}`}>
        <div className='flex flex-col bg-white rounded-l-xl mt-4 p-3' style={{ gridRow: `span ${rowSpan}` }}>
          <div className="flex flex-row gap-1">
            <span className='font-bold'>{student.name}</span>
            <span>[{student.id}]</span>
          </div>
          <span>{student.campus}</span>
        </div>
        <div className='flex flex-col bg-white mt-4 p-3 justify-center' style={{ gridRow: `span ${rowSpan}` }}>
          <span>{student.progress}%</span>
          <span className={`font-bold ${student.progress_improvement > 0 ? 'text-green-40' : ''}`}>+{student.progress_improvement}%</span>
        </div>
        {mountCycles(0, student.id, student.data[0], idx, true)}
        {((student.data.length) > 1) && student.data.slice(1).map((x, index) => mountCycles(index, student.id, x, idx))}
      </React.Fragment>
    )
  }

  return (
    <div className='grid grid-cols-[200px_130px_100px_200px_200px_1fr] auto-cols-max w-full'>
      <div className='font-bold text-lg p-3 sticky top-0 bg-gray-5 border-b border-gray-30'>Student</div>
      <div className='font-bold text-lg p-3 sticky top-0 bg-gray-5 border-b border-gray-30'>Progress Projection</div>
      <div className='font-bold text-lg p-3 sticky top-0 bg-gray-5 border-b border-gray-30'>Cycle</div>
      <div className='font-bold text-lg p-3 sticky top-0 bg-gray-5 border-b border-gray-30'>Learning Block</div>
      <div className='font-bold text-lg p-3 sticky top-0 bg-gray-5 border-b border-gray-30'>Expert</div>
      <div className='font-bold text-lg p-3 sticky top-0 bg-gray-5 border-b border-gray-30'>Template</div>
      {students.map(studentRow)}
    </div>
  )
}

export const JourneyBuilderScheduleGeneratedStudentsRoute = {
  loader,
  Element,
}
