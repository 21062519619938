/* eslint-disable react/prop-types */
import Slideover from '@components/slideover'
import React from 'react'
import AbilityCard from '../ability-card'
import UnitCard from '../graduation/unit-card'

const CycleSlideover = ({ sliderOpen, sliderData, selectedCycle, setSliderOpen }) => {
  return (
    <Slideover
      open={sliderOpen}
      title={
        <>
          <span className="flex items-center mr-4 gap-1">
            <span>{sliderData.title}</span>
            <span className="rounded-full border border-gray-90 bg-gray-90 text-white inline-block w-10 min-w-10 text-center ml-4">
              {sliderData.counter}
            </span>
          </span>
          <h3 className="text-gray-40 text-base font-normal">
            In Cycle {selectedCycle?.relative_counter}
          </h3>
        </>
      }
      setOpen={setSliderOpen}
      maxWidth={sliderData.units.length > 0 ? 'max-w-6xl' : 'max-w-2xl'}
    >
      {sliderData.units.map((unit, idx) => (
        <div key={idx} className="border rounded mb-2">
          <UnitCard unit={unit} />
        </div>
      ))}
      {sliderData.abilities.map((ability, idx) => (
        <div key={idx} className="border rounded mb-2">
          <AbilityCard ability={ability} key={idx} />
        </div>
      ))}
    </Slideover>
  )
}

export default CycleSlideover
